import PropTypes from 'prop-types';
import {formatBytes, mimeTypeToExtension} from "../utils/utils";
import {Image} from "./image";
import classNames from "classnames";
import {aspectRatio} from "../utils/block-utils";
import AnimateHeight from "react-animate-height";
import {Fragment, useContext, useState} from "react";
import {WebsiteContext} from "../index";
import {Dialog, Transition} from "@headlessui/react";
import Video from "./video";

DigitalProductAccessCard.propTypes = {
    product: PropTypes.object,
    productsLocked: PropTypes.bool,
};

export default function DigitalProductAccessCard({product, productsLocked}) {
    const context = useContext(WebsiteContext)
    const imageAspectRatio = {values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}
    const [expandedProducts, setExpandedProducts] = useState({});
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    const image = product.imagesState?.[0]?.__cx__?.key || product?.imageUrl
    const fileText = product.contentAccess.length == 0 ? 'Unlock to access content' : product.contentAccess.length > 1
        ? `${product.contentAccess.length} Files`
        : `${formatBytes(product.contentAccess[0].fileSize)}, ${product.contentAccess[0].title}${mimeTypeToExtension(product.contentAccess[0].type)}`;

    const playVideo = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setIsVideoDialogOpen(true);
    };

    const toggleExpandFiles = (productUuid) => {
        setExpandedProducts(prevState => ({
            ...prevState,
            [productUuid]: !prevState[productUuid]
        }));
    };


    return (
        <div key={product.productUuid}
             className="container-padding-half section-grey rounded-theme flex flex-row justify-between sm:items-center mb-4">
            <div className={"flex flex-row gap-x-2 w-full mr-4"}>
                <Image
                    pictureClassName={"shrink-0"}
                    alt={"Image of " + product.title}
                    width={48}
                    id={product.imagesState?.__cx__?.id ? `image_${product.imagesState?.__cx__?.id}` : null}
                    src={image}
                    className={classNames("flex-none rounded-theme-half object-cover object-center w-12", aspectRatio(imageAspectRatio))}
                />
                <div className={"flex flex-col leading-snug"}>
                    <span className={"text-lg leading-snug"}>{product.title}</span>
                    <span className={classNames("text-gray-800", product.contentAccess.length >= 1 && "font-mono text-sm break-all leading-tight")}>{fileText}</span>
                    {product.contentAccess.length > 1 &&
                        <AnimateHeight height={expandedProducts[product.productUuid] ? 'auto' : 0}>
                            {!productsLocked &&
                                <div className={"mt-2"}>
                                    {product.contentAccess.map((content) => (
                                        <div key={content.uri} className={"flex flex-col justify-between gap-x-4 mb-2 sm:flex-row sm:items-center sm:mb-1"}>
                                                                <span className={"text-gray-800 font-mono text-sm break-all leading-tight"}>
                                                                    {formatBytes(content.fileSize)}, {content.title}{mimeTypeToExtension(content.type)}
                                                                </span>
                                            {content.type.toLowerCase().includes('video') ? (
                                                <button className={"font-semibold text-sm text-link"} onClick={() => playVideo(content.uri)}>Watch</button>
                                            ) : (
                                                <a className={"font-semibold text-sm !text-link"} href={content.uri} download>Download</a>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            }
                        </AnimateHeight>
                    }
                </div>
            </div>
            <div className={"shrink-0 mt-2 sm:mt-0"}>
                {
                    product.contentAccess.length === 0 || productsLocked ?
                        <button onClick={() => window.scrollTo(0, 0)}>
                            <svg viewBox={"0 0 17 24"} width="16.7505" height="23.9484" className={"h-5"}>
                                <path d="M2.71265 23.339L13.6863 23.339C15.4659 23.339 16.399 22.3847 16.399 20.4635L16.399 12.1636C16.399 10.2584 15.4659 9.30399 13.6863 9.30399L2.71265 9.30399C0.930937 9.30399 0 10.2584 0 12.1636L0 20.4635C0 22.3847 0.930937 23.339 2.71265 23.339ZM3.04545 21.2384C2.55842 21.2384 2.28022 20.9409 2.28022 20.3878L2.28022 12.2435C2.28022 11.6883 2.55842 11.4047 3.04545 11.4047L13.3556 11.4047C13.8523 11.4047 14.1166 11.6883 14.1166 12.2435L14.1166 20.3878C14.1166 20.9409 13.8523 21.2384 13.3556 21.2384ZM2.14359 10.2886L4.33803 10.2886L4.33803 6.3457C4.33803 3.5939 6.09819 2.09858 8.19468 2.09858C10.2869 2.09858 12.0727 3.5939 12.0727 6.3457L12.0727 10.2886L14.2575 10.2886L14.2575 6.55383C14.2575 2.22984 11.3958 0 8.19468 0C5.0032 0 2.14359 2.22984 2.14359 6.55383Z" fill="currentColor" />
                            </svg>
                        </button>
                        :
                        product.contentAccess.length > 1 ? (
                            <button className={"button text-sm"} onClick={() => toggleExpandFiles(product.productUuid)}>
                                {expandedProducts[product.productUuid] ? "Hide Files" : "See Files"}
                            </button>
                        ) : product.contentAccess.length === 1 && product.contentAccess[0].type.toLowerCase().includes('video') ? (
                            <button className={"button text-sm"} onClick={() => playVideo(product.contentAccess[0].uri)}>Watch</button>
                        ) : (
                            <a className={"button text-sm"} href={product.contentAccess[0].uri} download>Download</a>
                        )
                }
            </div>
            <Transition.Root show={isVideoDialogOpen} as={Fragment}>
                <Dialog onClose={() => setIsVideoDialogOpen(false)} className={"relative z-50"}>
                    <Transition.Child
                        enter="ease-out duration-200"
                        enterFrom="opacity-0 backdrop-blur-none"
                        enterTo="opacity-100"
                        leave="ease-in duration-75"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        as={Fragment}
                    >
                        <div className="fixed inset-0 bg-black/50 transition-[backdrop-filter] duration-200 backdrop-blur-sm backdrop-filter pointer-events-none" aria-hidden="true"></div>
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full justify-center text-center items-center">
                            <Transition.Child
                                enter="transition duration-300 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                as={Fragment}
                            >
                                <Dialog.Panel className="mx-auto aspect-video w-10/12 mb-16">
                                    <button onClick={() => setIsVideoDialogOpen(false)} className={"border-2 border-white rounded-full mb-4 overflow-hidden"}>
                                        <svg className="h-[32px] w-[32px] stroke-white bg-black/30 p-1" fill="none" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    <Video url={currentVideoUrl} playing={true} loop={false} muted={false} controls={true} width={"100%"} height={"100%"} className={"shadow-2xl bg-black"} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}

