import PropTypes from 'prop-types';
import classNames from 'classnames';

import {evaluate, isChecked} from "../utils";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import Checkbox from "./checkbox";
import _isArray from "lodash/isArray";
import {useContext} from "react";
import FormContext from "../form-context";
import _without from 'lodash/without';

ColourPicker.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
    autoCapitalize: PropTypes.bool,
    autoComplete: PropTypes.string,
    modified: PropTypes.bool,
};

ColourPicker.defaultProps = {
    sorted: false,
    options: [],
    groupFieldPath: 'group.label',
    labelFieldPath: 'label',
    valueFieldPath: 'value',
    onChange: () => {
    },
    onLoad: options => options,
    autoCapitalize: false,
    autoComplete: ''
}

ColourPicker.DisplayView = function (props) {
    const value = props?.value?.[0];
    return <span>{value}</span>
}


export default function ColourPicker(props) {
    const context = useContext(FormContext);
    
    // Filter options out based on showsWhen evaluation
    const options = _isArray(props.options)
        ? props.options.filter(option => !(evaluate(option.value, option.showsWhen, context.values) === false)) 
        : [];

    return (
        <div className={classNames("space-y-2 grid grid-cols-1 gap-2 sm:space-y-0 sm:grid-cols-2", props.className)}>
            {options.map((option, index) => (
                <Checkbox
                    className={classNames("!h-7 !w-7 !mt-1 !rounded-full !shadow-sm !bg-none")}
                    type={"checkbox"}
                    modified={props.modified}
                    name={props.name}
                    key={`${option.label}_${option.value}`}
                    id={`${props.id}_${index}`}
                    label={option.label}
                    count={option.count}
                    colour={option.colour}
                    value={props.value?.includes(option.value) || false}
                    disabled={props.disabled}
                    onChange={(e, name, value) => {
                        // Toggle the item in the array
                        props.onChange(
                            e,
                            props.name,
                            value === true
                                ? [...props.value, option.value]
                                : _without(props.value, option.value)
                        )
                    }}
                    style={{backgroundColor: option.colour}}
                />
            ))}
        </div>
    )
}
