import PropTypes from "prop-types";
import Fields from "./fields";


ProductSearchBlockLayout.propTypes = {
    onFieldChange: PropTypes.func,
    onEditFieldsetSettings: PropTypes.func,
}

ProductSearchBlockLayout.defaultProps = {
    onFieldChange: () => {},
}

/**
 * This will only render the first level of fields. It is designed to be used on the product
 * search block for websites.
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function ProductSearchBlockLayout({children, ...props}) {
    return (
        <>
            <Fields {...props} className={"search-fields w-full flex flex-col flex-grow gap-[1px] sm:flex-row"}>
                {children}
            </Fields>
        </>
    )
};