import PhoneInput from 'react-phone-number-input'
import { useMemo, } from "react";

/**
 * Docs: https://catamphetamine.gitlab.io/react-phone-number-input/docs/index.html#phoneinputwithcountry
 * @returns {JSX.Element}
 * @constructor
 */
export default function PhoneNumberInput(props) {
    const value = useMemo(() => props.value, [props.value])
    
    return (
        <div className={"w-full relative"}>
            <PhoneInput
                className={"flex flex-row vel-input"}
                name={props.name}
                value={value}
                displayInitialValueAsLocalNumber={props.displayInitialValueAsLocalNumber}
                required={props.required}
                placeholder={props.placeholder}
                defaultCountry={props.defaultCountry}
                numberInputProps={{className: "!m-0 !py-0 !px-0.5 !border-none text-sm"}}
                countrySelectProps={{tabIndex: -1, unicodeFlags: true}}
                onChange={(value) => {
                    props.onChange(null, props.name, value)
                }}
                data-error-message={props.errorMessage}
            />
        </div>
    )
}
