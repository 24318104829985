import {importAll} from '../utils/require-utils';

import PropTypes from 'prop-types';
import PlaceholderBlock from "./placeholder-block";
import {evaluate} from "@autocx/forms/src/utils";

// Handles importing all blocks. This code only works in a webpack build environment
// <<<Start>>>
export const BLOCK_COMPONENTS = {};
export const FORM_SPECS = [];
export const BLOCK_PICKER_SPECS = {};

importAll(require.context('./', true, /-block(\.jsx)?$/), BLOCK_COMPONENTS);
importAll(require.context('./', true, /-block\/form-spec\.json$/), FORM_SPECS);
importAll(require.context('./', true, /-block\/block-picker-spec\.json$/), BLOCK_PICKER_SPECS);
// <<<End>>>

const SUPPORTS_FIELD_LOOKUP = FORM_SPECS.reduce((result, spec) => {
    result[spec.type] = (spec.fieldsets || []).reduce((result, fieldset) => {
        (fieldset.fields || []).forEach((field) => {
            if (field.name) {
                result[field.name] = field;
            }
        });
        return result;
    }, {});
    return result;
}, {});

/**
 * Determines if the field is supported for the provided block.  
 * 
 * @param block The block to check
 * @param fieldName The property path of the field defined in the block form spec to evaluate
 * @returns {boolean}
 */
export function supportsField(block, fieldName) {
    const field = SUPPORTS_FIELD_LOOKUP[block.type]?.[fieldName];
    if (!field) return false;
    return evaluate(null, field.showsWhen, block.values) !== false;
}

BlockList.propTypes = {
    blocks: PropTypes.array.isRequired,
    actions: PropTypes.bool,
    onAction: PropTypes.func,
    onSelect: PropTypes.func
};

BlockList.defaultProps = {
    blocks: [],
    actions: false,
    onAction: () => {}
};

export default function BlockList({blocks, ...props}) {
    return (blocks || []).map((block, index) => {
        const Block = BLOCK_COMPONENTS[block.type];
        if (Block) {
            return (
                <Block key={block.id + index} {...props} {...block} index={index} prevBlock={blocks[index - 1]} nextBlock={blocks[index + 1]} />
            );
        } else if (block.placeholder === true) {
            return (
                <PlaceholderBlock key={block.id} {...props} {...block} />
            )
        } else {
            return (
                <div key={`block_${block.id}`} id={`block_${block.id}`} className={"bg-red-500 text-white text-center py-6 text-xl"}>
                    This block doesn't exist or isn't configured correctly
                </div>
            );
        }
    })
};