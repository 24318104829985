import React, {forwardRef} from "react"
import classNames from "classnames"
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/solid/ChevronRightIcon";
import TreeItemContextMenu from "./tree-item-context-menu";

/**
 * Defines an item in the Tree Control. On initial load, the current value of the control will be mapped into these 
 * items. By default; id, type label and children fields are one-to-one mappings. This can be overriden using the 
 * properties suffixed with `FieldPath`
 * 
 * @typedef {object} TreeItem
 * @property {string} id The unique identifier mapped from the original state
 * @property {string} type The type of tree item mapped from the original state
 * @property {string} label The display label of the tree item mapped from the original state
 * @property {TreeItem[]} children A collection of child tree items mapped from the original state
 * @property {?TreeItem} parent A reference to the parent tree item
 * @property {number} index The current position with in it's parent
 * @property {number} depth The depth this item is with in the tree. Starts at 0
 * @property {object} original A reference to the original state before mapping to this model
 * @property {string} pathToSiblings The field path relative to the form context values 
 *                                   which points to siblings of this item
 * @property {string} prefix The field path relative to the form context values which points to this item
 */

export const TreeItem = forwardRef(
    (
        {
            className,
            childCount,
            clone,
            depth,
            disableSelection,
            disableInteraction,
            ghost,
            handleProps,
            indentationWidth,
            indicator,
            collapsed,
            onCollapse,
            style,
            value,
            icon,
            interactions,
            wrapperRef,
            onAddAbove,
            onAddBelow,
            onAddInside,
            hasParent,
            onRemove,
            ...props
        },
        ref
    ) => {

        return (
            <div
                className={classNames(
                    "list-none select-none",
                    className,
                    clone ? "pointer-events-none" : null,
                    ghost && !indicator ? "opacity-50" : null,
                    indicator ? "opacity-100 relative z-10" : null,
                    disableInteraction ? "pointer-events-none" : null
                )}
                ref={wrapperRef}
                style={{
                    paddingLeft: `${indentationWidth * depth}px`,
                }}
                {...props}
            >
                <div className={classNames(
                    "relative flex items-center space-x-1 rounded py-1 px-1.5",
                    "bg-white border border-gray-500",
                    interactions.editable ? "cursor-pointer" : "cursor-default",
                    !ghost ? "shadow-button" : null,
                    ghost && !indicator ? "bg-panels-300" : null,
                    ghost && indicator ? "!p-0 !h-2 bg-primary-400 border-primary-100 rounded-none" : null
                )} ref={ref} style={style} {...handleProps}>
                    {ghost && indicator ? (
                        <div className={"absolute -top-0.5 -left-2 w-3 h-3 rounded-full border border-primary-100 bg-primary-400"} />
                    ) : null}
                    {icon ? icon : null}
                    {onCollapse ? (
                        <button
                            className={classNames(
                                ghost && indicator ? "h-0 opacity-0" : null,
                            )}
                            tabIndex={0}
                            onClick={(e) => {
                                e.stopPropagation();
                                onCollapse(e);
                            }}
                        >
                            <ChevronDownIcon 
                                className={classNames(
                                    "w-5 h-5 transition-transform duration-200 ease-in-out",
                                    !collapsed ? "rotate-180" : null
                                )} 
                            />
                        </button>
                    ): null}
                    <span className={classNames(
                        "grow text-sm font-medium text-gray-700 truncate",
                        ghost && indicator ? "h-0 opacity-0" : null,
                    )}>
                        {value}
                    </span>
                    {interactions.editable ? (
                        <>
                            <TreeItemContextMenu
                                onAddAbove={onAddAbove}
                                onAddBelow={onAddBelow}
                                onAddInside={onAddInside}
                                onRemove={interactions.removable ? onRemove : undefined}
                                hasParent={hasParent}
                            />
                            <ChevronRightIcon className={"w-5 h-5"} />
                        </>
                    ) : null}
                    {clone && childCount && childCount > 1 ? (
                        <span className={classNames(
                            "absolute right-1 flex items-center justify-center w-5 h-5 rounded-full font-medium text-sm text-white bg-primary-500",
                            ghost && indicator ? "h-0 opacity-0" : null
                        )}>{childCount}</span>
                    ) : null}
                </div>
            </div>
        )
    }
)

export default TreeItem;
