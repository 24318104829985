import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlusSmIcon from "@heroicons/react/solid/PlusSmIcon";

InlineAddBlockButton.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

InlineAddBlockButton.defaultProps = {
};

export default function InlineAddBlockButton({className, label, ...props}) {
    return (
        <div className={classNames("w-full absolute z-[1000]", className)} {...props}>
            <div className="relative flex justify-center">
                <button
                    type="button"
                    aria-label={label}
                    className="relative inline-flex items-center h-6 w-6 font-medium rounded-full text-white bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-primary-700"
                    style={{
                        background: "linear-gradient(90deg, rgba(79, 79, 229, 1) 0%, rgba(0, 255, 252, 1) 51%, rgba(79, 79, 229, 1) 100%)", 
                        animation: "animatedgradient 9s cubic-bezier(.68, .9, .48, .21) alternate infinite",
                        backgroundSize: "800% 800%"
                    }}
                >
                    <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
};