import {useContext, Fragment} from 'react';
import classNames from 'classnames';
import {UploaderContext} from "../uploader-context";
import Loader from "../../../components/loader";
import humanFileSize from "../../../hooks/human-file-size";
import XCircleIcon from "@heroicons/react/outline/XCircleIcon";

DefaultUploaderPreview.propTypes = {
    
};

DefaultUploaderPreview.defaultProps = {};

export default function DefaultUploaderPreview(props) {
    const context = useContext(UploaderContext);
    
    return (
        <div className={classNames(props.className)}>
            <a className={classNames(
                "flex items-center justify-between bg-white rounded border border-gray-500 shadow-button py-1 px-1.5 cursor-pointer",
                context.preview.className
            )}
               href={props.file?._links?.src?.href} target="_blank">
                <div className="truncate grow text-ellipsis overflow-hidden mr-2 text-sm font-semibold text-panels-700 text-left">
                    {props.file.name}
                </div>
                <div className="text-xs font-semibold text-panels-600 shrink-0">{humanFileSize(props.file.size, true)}</div>
                <div className={"no-shrink"}>
                    {props.children}
                </div>
            </a>
            <Loader as={Fragment} loading={props.file.status === 'loading'} />
            {props.file.status === 'uploading' || props.file.status === 'error' ? (
                <div className={classNames(
                    "absolute bottom-[16px] left-[16px] right-[16px] inset-x-0 saturate-150 backdrop-blur-md backdrop-filter px-2 py-0.5 bg-white/80 rounded-lg text-[11px] justify-center items-center",
                    props.file.status === 'error' ? 'text-error-500' : 'text-primary-500'
                )}>
                    {props.file.error ? props.file.error : (
                        <div className="flex flex-row justify-between items-center mb-1">
                            Uploading...
                            <div onClick={props.cancelUpload}>
                                <XCircleIcon className={"w-4 h-4 stroke-error-500"}/>
                            </div>
                        </div>
                    )}
                    {props.progress !== undefined && props.progress !== null ? (
                        <div className="relative">
                            <div className="overflow-hidden h-1 mb-1 text-xs flex rounded bg-primary-200">
                                <div style={{width: `${props.progress}%`}}
                                     className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"/>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};