import PropTypes from 'prop-types';
import classNames from 'classnames';

import ExclamationCircleIcon from '@heroicons/react/solid/ExclamationCircleIcon'
import EyeIcon from '@autocx/icons/src/eye-icon';
import EyeSlashIcon from '@autocx/icons/src/eye-slash-icon';
import {forwardRef, useState} from "react";

const DEFAULT_TYPE = 'text'
export const VALID_INPUT_TYPES = ['color', 'date', 'datetime-local', 'email', 'file', 'number', 'password', 'range', 'tel', DEFAULT_TYPE, 'time', 'url', 'hidden']

const Input = forwardRef((props, ref) => {
    const [type, setType] = useState(() => {
        return VALID_INPUT_TYPES.indexOf(props.type) > -1 ? props.type : DEFAULT_TYPE;
    });
    const autoCapitalize = typeof props.autoCapitalize === 'boolean'
        ? (props.autoCapitalize === true ? 'on' : 'off')
        : null;
    let value = props.value;

    if (props.type === 'number') {
        value = props.value ? +value : '';
    }

    if (props.type === 'date') {
        value = (value === undefined || value === null ? '' : value).split('T')[0];
    }

    if (props.type === 'datetime-local') {
        value = (value === undefined || value === null ? '' : value)
            .split('T')
            .map(part => part.split(':').slice(0, 2).join(':'))
            .join('T');
    }

    if (props.prefix && props.type !== 'currency' && !Number.isInteger(value) && props.type !== 'number') {
        value = value?.startsWith(props.prefix) ? value.substr(props.prefix.length) : value;
    }

    if (props.suffix && props.type !== 'currency' && !Number.isInteger(value) && props.type !== 'number') {
        value = value?.endsWith(props.suffix) ? value.substr(0, value.indexOf(props.suffix)) : value;
    }

    const BaseBlock = (
        <div className="w-full relative">
            {props.inlineAddons && props.prefix ? (
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                  <span className="text-sm">
                    {props.prefix}
                  </span>
                </div>
            ) : null}
            <input
                ref={ref}
                autoComplete={props.autoComplete ? props.autoComplete : undefined}
                autoCapitalize={autoCapitalize}
                className={classNames(
                    `flex-1 px-1.5 py-1 block w-full text-sm rounded-none`,
                    props.disabled || props.readOnly ? 'bg-gray-50 border-gray-300 placeholder-gray-400' : null,
                    props.error && !props.disabled ? 'focus:ring-error-500 focus:border-error-500 border-error-300' : null,
                    !props.error && !props.disabled ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300' : null,
                    props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null,
                    !props.prefix || props.inlineAddons ? 'rounded-l' : null,
                    !props.suffix || props.inlineAddons ? 'rounded-r' : null,
                    !((props.prefix || props.suffix) && !props.inlineAddons) ? 'shadow-sm' : null,
                    props.inlineAddons && props.prefix ? '!pl-6' : null,
                    props.inlineAddons && props.suffix ? '!pr-10' : null,
                    props.type === 'password' && props.canRevealSecret ? '!pr-9' : null,
                    props.className
                )}
                type={type}
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                pattern={props.pattern}
                maxLength={props.maxLength}
                required={props.required}
                disabled={props.disabled}
                readOnly={props.readOnly}
                min={props.min}
                max={props.max}
                value={value}
                data-error-message={props.errorMessage}
                onInvalid={props.errorMessage ? (e) => {
                    e.target.setCustomValidity("");
                    if (!e.target.validity.valid) {
                        e.target.setCustomValidity(props.errorMessage)
                    }
                } : null}
                onChange={e => {
                    let value = e.target.value;
                    if (props.prefix && props.type !== 'currency' && props.type !== 'number') {
                        value = !value?.startsWith(props.prefix) ? `${props.prefix}${value}` : value;
                    }

                    if (props.suffix && props.type !== 'currency' && props.type !== 'number') {
                        value = !value?.endsWith(props.suffix) ? `${value}${props.suffix}` : value;
                    }

                    if (props.type === 'datetime-local' && value && value.includes('T')) {
                        props.onChange(e, props.name, `${value}:00`);
                    } else if (props.type === 'number') {
                        props.onChange(e, props.name, Number(value));
                    } else {
                        props.onChange(e, props.name, value);
                    }
                }}
                onBlur={props.onBlur}
                onKeyDown={props.onKeyDown}
            />
            {props.type === 'password' && props.canRevealSecret ? (
                <button tabIndex="-1" className="absolute inset-y-0 right-0 pr-2 flex items-center hover:opacity-80"
                        type={"button"}
                        onClick={(e) => {
                            e.preventDefault();
                            setType(previous => previous === props.type ? 'text' : props.type);
                        }}>
                    {type === 'text' ?
                        <EyeSlashIcon className="w-6 h-6"/>
                        :
                        <EyeIcon className="w-6 h-6"/>
                    }
                </button>
            ) : null}
            {props.error ? (
                <div className={classNames(
                    "absolute inset-y-0 right-0 flex items-center pointer-events-none",
                    props.inlineAddons && props.suffix ? "pr-10" : (props.type === 'datetime-local' ? "pr-6" : "pr-1.5")
                )}>
                    <ExclamationCircleIcon className="h-5 w-5 text-error-500" aria-hidden="true"/>
                </div>
            ) : null}
            {props.inlineAddons && props.suffix ? (
                <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                  <span className="text-sm">
                    {props.suffix}
                  </span>
                </div>
            ) : null}
        </div>
    )

    // If there are prefixes or suffixes to display, the output needs to be wrapped in a flex div
    if ((props.prefix || props.suffix) && !props.inlineAddons) {
        return (
            <div className="flex rounded shadow-sm">
                {props.prefix ? (
                    <span
                        className="inline-flex items-center px-1.5 rounded-l border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shrink-0">
                  {props.prefix}
                </span>
                ) : null}
                {BaseBlock}
                {props.suffix ? (
                    <span
                        className="inline-flex items-center px-3 rounded-r border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shrink-0">
                  {props.suffix}
                </span>
                ) : null}
            </div>
        )
    }

    return BaseBlock;
});

Input.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    inlineAddons: PropTypes.bool,
    canRevealSecret: PropTypes.bool,
    pattern: PropTypes.string,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    autoCapitalize: PropTypes.bool,
    autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onKeyDown: PropTypes.func,
};

Input.defaultProps = {
    type: DEFAULT_TYPE,
    onChange: () => {
    },
    onKeyDown: () => {
    },
    autoComplete: ''
}

Input.WebsiteProductDisplayView = function (props) {
    let value = props.value;
    if ((props.type === 'date' || props.type === 'datetime-local') && Date.parse(value)) {
        try {
            let options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour12: true,
            };
            if (props.type === 'datetime-local') {
                options.hour = "numeric";
                options.minute = "numeric";
            }
            value = new Intl.DateTimeFormat((window?.navigator?.language ?? "en-AU"), options).format(new Date(value));
        } catch (error) {
            console.error("Invalid date format: ", value);
        }
    }

    return (
        <div className="grid grid-cols-2 gap-x-4 items-start mb-1">
            <div className="font-semibold col-span-1">{props.label}</div>
            <div
                className="col-span-1">{props.prefix} {value || '—'} {props.suffix}</div>
        </div>
    )
}

Input.DisplayValue = function (props) {
    let value = props.value;

    if (props.type === 'color') {
        return (<div className="p-1 rounded" style={{backgroundColor: value}}>&nbsp;</div>);
    }
    if ((props.type === 'date' || props.type === 'datetime-local') && Date.parse(value)) {
        try {
            let options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour12: true,
            };
            if (props.type === 'datetime-local') {
                options.hour = "numeric";
                options.minute = "numeric";
            }
            return new Intl.DateTimeFormat((window?.navigator?.language ?? "en-AU"), options).format(new Date(value));
        } catch (error) {
            console.error("Invalid date format: ", value);
            return value;
        }
    }
    if (props.type === 'number' && (value !== undefined && value !== null)) return value
    value = (value === null || value === undefined || typeof value !== 'string') ? '\u2014' : value;
    return value;
}

export default Input;