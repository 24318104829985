import Design1 from './design-1';

ProductsListBlock.propTypes = {
};

ProductsListBlock.defaultProps = {};

export const scrollTop = (element, behavior = 'smooth', yOffset = 0) => {
    const navHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--site-nav-height')) || 0;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset - navHeight;
    if (window.scrollY > y) {
        window.scrollTo({ top: y, behavior });
    }
}

export default function ProductsListBlock(props) {
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};