import {handleBackgroundColour} from "./index";
import {backgroundColour} from "../../utils/block-utils";
import classNames from "classnames";
import {Popover, Transition} from "@headlessui/react";
import {Fragment} from "react";

export default function ContactBar(props) {
    return (
        <div className={classNames("h-[36px] navigation-block-contact",
            handleBackgroundColour(backgroundColour(props.settings?.contactBarBackgroundColour))
        )}>
            {/*desktop design*/}
            <div className={classNames("max-w-7xl mx-auto px-8 sm:flex flex-row justify-between items-center text-sm font-bold h-full hidden leading-none",
                handleBackgroundColour(backgroundColour(props.settings?.contactBarBackgroundColour))
            )}>
                <div className={"flex flex-row gap-1 items-center"}>
                    {props?.settings?.contactBarAddress &&
                        <>
                            <svg width="18" height="18" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1.5c-4.14 0-7.5 3.024-7.5 6.75 0 6 7.5 14.25 7.5 14.25s7.5-8.25 7.5-14.25c0-3.726-3.36-6.75-7.5-6.75ZM12 12a3 3 0 1 1 0-5.999A3 3 0 0 1 12 12Z"></path>
                            </svg>
                            <a href={"https://www.google.com/maps/search/"+props?.settings?.contactBarAddress} target={"_blank"}>{props?.settings?.contactBarAddress}</a>
                        </>
                    }
                </div>
                <div className={"flex flex-row justify-between items-center space-x-4"}>
                    {props?.settings?.contactBarNumber1 && 
                        <div className={"flex flex-row gap-1.5 items-center"}>
                            <svg width="14.8906" height="15.0703">
                                <g>
                                    <rect height="15.0703" opacity="0" width="14.8906" x="0" y="0"/>
                                    <path d="M3.96875 11.0703C6.22656 13.3281 8.96875 15.0703 11.2109 15.0703C12.2188 15.0703 13.1016 14.7188 13.8125 13.9375C14.2266 13.4766 14.4844 12.9375 14.4844 12.4062C14.4844 12.0156 14.3359 11.6406 13.9688 11.375L11.5703 9.67188C11.2109 9.42188 10.8984 9.29688 10.6172 9.29688C10.2656 9.29688 9.94531 9.5 9.58594 9.85156L9.03125 10.3984C8.94531 10.4844 8.83594 10.5234 8.73438 10.5234C8.61719 10.5234 8.5 10.4766 8.42188 10.4375C7.9375 10.1797 7.10938 9.46875 6.33594 8.70312C5.57031 7.9375 4.85938 7.10938 4.60938 6.625C4.57031 6.53906 4.52344 6.42969 4.52344 6.3125C4.52344 6.21094 4.55469 6.10938 4.64062 6.02344L5.19531 5.45312C5.53906 5.09375 5.75 4.78125 5.75 4.42188C5.75 4.14062 5.625 3.83594 5.35938 3.46875L3.67969 1.10156C3.41406 0.726562 3.02344 0.5625 2.60156 0.5625C2.08594 0.5625 1.55469 0.796875 1.09375 1.24219C0.335938 1.96875 0 2.86719 0 3.85938C0 6.10156 1.71094 8.82031 3.96875 11.0703Z" fill="currentColor"/>
                                </g>
                            </svg>
                            <span>
                            {props?.settings?.contactBarNumberLabel1} <a href={"tel:"+props?.settings?.contactBarNumber1}>{props?.settings?.contactBarNumber1}</a>
                            </span>
                        </div>
                    }
                    {props?.settings?.contactBarNumber2 &&
                        <div className={"flex flex-row gap-1.5 items-center"}>
                            <svg width="14.8906" height="15.0703">
                                <g>
                                    <rect height="15.0703" opacity="0" width="14.8906" x="0" y="0"/>
                                    <path d="M3.96875 11.0703C6.22656 13.3281 8.96875 15.0703 11.2109 15.0703C12.2188 15.0703 13.1016 14.7188 13.8125 13.9375C14.2266 13.4766 14.4844 12.9375 14.4844 12.4062C14.4844 12.0156 14.3359 11.6406 13.9688 11.375L11.5703 9.67188C11.2109 9.42188 10.8984 9.29688 10.6172 9.29688C10.2656 9.29688 9.94531 9.5 9.58594 9.85156L9.03125 10.3984C8.94531 10.4844 8.83594 10.5234 8.73438 10.5234C8.61719 10.5234 8.5 10.4766 8.42188 10.4375C7.9375 10.1797 7.10938 9.46875 6.33594 8.70312C5.57031 7.9375 4.85938 7.10938 4.60938 6.625C4.57031 6.53906 4.52344 6.42969 4.52344 6.3125C4.52344 6.21094 4.55469 6.10938 4.64062 6.02344L5.19531 5.45312C5.53906 5.09375 5.75 4.78125 5.75 4.42188C5.75 4.14062 5.625 3.83594 5.35938 3.46875L3.67969 1.10156C3.41406 0.726562 3.02344 0.5625 2.60156 0.5625C2.08594 0.5625 1.55469 0.796875 1.09375 1.24219C0.335938 1.96875 0 2.86719 0 3.85938C0 6.10156 1.71094 8.82031 3.96875 11.0703Z" fill="currentColor"/>
                                </g>
                            </svg>
                            <span>
                            {props?.settings?.contactBarNumberLabel2} <a href={"tel:"+props?.settings?.contactBarNumber2}>{props?.settings?.contactBarNumber2}</a>
                            </span>
                        </div>
                    }
                </div>
            </div>
            {/*mobile design*/}
            <div className={classNames("sm:hidden flex flex-row justify-between items-center max-w-7xl mx-auto px-4 h-full",
                handleBackgroundColour(backgroundColour(props.settings?.contactBarBackgroundColour))
            )}>
                <div>
                    {props?.settings?.contactBarAddress &&
                        <Popover className="relative">
                            <Popover.Button className={"font-bold flex flex-row gap-1.5 items-center outline-none"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15"><path fill="currentColor" fillRule="evenodd" d="M1096.94986,555.540929 L1096.94986,557.665665 L1092.37846,557.665665 C1089.14305,557.665665 1087.6139,559.210928 1087.6139,562.430225 L1087.6139,566.663647 C1087.6139,567.613263 1088.08069,568.176769 1088.94989,568.160492 C1089.78691,568.144421 1090.28591,567.613263 1090.28591,566.663647 L1090.28591,562.558997 C1090.28591,561.062029 1090.99415,560.353784 1092.41064,560.353784 L1096.94986,560.353784 L1096.94986,562.494611 C1096.94986,563.573081 1098.06043,564.039874 1098.91362,563.379923 L1102.84127,560.32158 C1103.87144,559.500654 1103.87144,558.518774 1102.84127,557.697848 L1098.91362,554.655617 C1098.06043,553.995666 1096.94986,554.462459 1096.94986,555.540929 Z" transform="translate(-1087 -554)"/></svg>
                                <span>Directions</span>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-in duration-100 origin-top-left"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-out duration-100 origin-top-left"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel
                                    as={"div"}
                                    style={{borderRadius: 'var(--container-roundness)'}}
                                    className={classNames("popover-panel absolute left-0 space-y-1 flex flex-col w-44 z-50 px-4 py-2 shadow-[0_2px_6px_-4px_rgba(0,0,0,0.3),0px_10px_20px_-15px_rgba(0,0,0,0.2)] section-white")}
                                >
                                    {({ close }) => (
                                        <div className="flex flex-col space-y-2">
                                            <div className={"flex flex-col"}>
                                                <button className="text-left" 
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(props?.settings?.contactBarAddress)
                                                            close()
                                                        }
                                                }>
                                                    Copy Address
                                                </button>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <a href={"https://maps.apple.com/?address="+props?.settings?.contactBarAddress} target={"_blank"}>Apple Maps</a>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <a href={"https://www.google.com/maps/search/"+props?.settings?.contactBarAddress} target={"_blank"}>Google Maps</a>
                                            </div>
                                        </div>
                                    )}
                                </Popover.Panel>
                            </Transition>
                        </Popover>
                    }
                </div>
                {
                    // No numbers
                    (!props?.settings?.contactBarNumber1 && !props?.settings?.contactBarNumber2) ? (
                        <div></div>
                    ) : (
                        // One of the phone numbers is present
                        (props?.settings?.contactBarNumber1 && !props?.settings?.contactBarNumber2) ||
                        (!props?.settings?.contactBarNumber1 && props?.settings?.contactBarNumber2) ? (
                            <a className={"font-bold"} href={`tel:${props?.settings?.contactBarNumber1 || props?.settings?.contactBarNumber2}`}>
                                Call {props?.settings?.contactBarNumber1 || props?.settings?.contactBarNumber2}
                            </a>
                        ) : (
                            // Both numbers present
                            <Popover className="relative">
                                <Popover.Button className={"font-bold flex flex-row gap-1.5 items-center outline-none"}>
                                    <svg width="14.8906" height="15.0703">
                                        <g>
                                            <rect height="15.0703" opacity="0" width="14.8906" x="0" y="0"/>
                                            <path d="M3.96875 11.0703C6.22656 13.3281 8.96875 15.0703 11.2109 15.0703C12.2188 15.0703 13.1016 14.7188 13.8125 13.9375C14.2266 13.4766 14.4844 12.9375 14.4844 12.4062C14.4844 12.0156 14.3359 11.6406 13.9688 11.375L11.5703 9.67188C11.2109 9.42188 10.8984 9.29688 10.6172 9.29688C10.2656 9.29688 9.94531 9.5 9.58594 9.85156L9.03125 10.3984C8.94531 10.4844 8.83594 10.5234 8.73438 10.5234C8.61719 10.5234 8.5 10.4766 8.42188 10.4375C7.9375 10.1797 7.10938 9.46875 6.33594 8.70312C5.57031 7.9375 4.85938 7.10938 4.60938 6.625C4.57031 6.53906 4.52344 6.42969 4.52344 6.3125C4.52344 6.21094 4.55469 6.10938 4.64062 6.02344L5.19531 5.45312C5.53906 5.09375 5.75 4.78125 5.75 4.42188C5.75 4.14062 5.625 3.83594 5.35938 3.46875L3.67969 1.10156C3.41406 0.726562 3.02344 0.5625 2.60156 0.5625C2.08594 0.5625 1.55469 0.796875 1.09375 1.24219C0.335938 1.96875 0 2.86719 0 3.85938C0 6.10156 1.71094 8.82031 3.96875 11.0703Z" fill="currentColor"/>
                                        </g>
                                    </svg>
                                    <span>Call</span>
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-in duration-100 origin-top-left"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="transition ease-out duration-100 origin-top-left"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Popover.Panel
                                        as={"div"}
                                        style={{borderRadius: 'var(--container-roundness)'}}
                                        className={classNames("popover-panel absolute right-0 space-y-1 flex flex-col w-44 z-50 px-4 py-2 shadow-[0_2px_6px_-4px_rgba(0,0,0,0.3),0px_10px_20px_-15px_rgba(0,0,0,0.2)] section-white")}
                                    >
                                        <div className="flex flex-col space-y-2 leading-tight">
                                            {props?.settings?.contactBarNumber1 &&
                                                <div className={"flex flex-col"}>
                                                    <span className={"font-semibold"}>{props?.settings?.contactBarNumberLabel1}</span>
                                                    <a href={"tel:"+props?.settings?.contactBarNumber1}>{props?.settings?.contactBarNumber1}</a>
                                                </div>
                                            }
                                            {props?.settings?.contactBarNumber2 &&
                                                <div className={"flex flex-col"}>
                                                    <span className={"font-semibold"}>{props?.settings?.contactBarNumberLabel2}</span>
                                                    <a href={"tel:"+props?.settings?.contactBarNumber2}>{props?.settings?.contactBarNumber2}</a>
                                                </div>
                                            }
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </Popover>
                        )
                    )
                }
            </div>
        </div>
    )
}