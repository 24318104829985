import tinycolor from "tinycolor2";

export const updateStyles = (styles) => {
    const previews = document.getElementsByClassName('preview');
    const sectionsWithPatterns = document.querySelectorAll('[class*=\'bg-pattern-\']');

    Array.from(previews).forEach((preview) => {
        if (styles?.baseColour) preview?.style.setProperty('--base-colour', styles.baseColour);
        if (styles?.lightBackground) preview?.style.setProperty('--light-background', styles.lightBackground);
        if (styles?.darkBackground) preview?.style.setProperty('--dark-background', styles.darkBackground);
        if (styles?.blackColour) preview?.style.setProperty('--black-background', styles.blackColour);
        if (styles?.lightGreyColour) preview?.style.setProperty('--grey-background', styles.lightGreyColour);
        if (styles?.primaryButtonTextColor) preview?.style.setProperty('--primary-button-text-colour', styles.primaryButtonTextColor);
        if (styles?.primaryButtonBackground) preview?.style.setProperty('--primary-button-background-colour', styles.primaryButtonBackground);
        if (styles?.primaryButtonDarkSectionTextColour) preview?.style.setProperty('--primary-button-dark-section-text-colour', styles.primaryButtonDarkSectionTextColour);
        if (styles?.primaryButtonDarkSectionBackground) preview?.style.setProperty('--primary-button-dark-section-background-colour', styles.primaryButtonDarkSectionBackground);  
        if (styles?.primaryButtonBrandSectionTextColour) preview?.style.setProperty('--primary-button-brand-section-text-colour', styles.primaryButtonBrandSectionTextColour);
        if (styles?.primaryButtonBrandSectionBackground) preview?.style.setProperty('--primary-button-brand-section-background-colour', styles.primaryButtonBrandSectionBackground);
        if (styles?.blackColour) preview?.style.setProperty('--text-colour', styles.blackColour);
        if (styles?.lightGreyColour) preview?.style.setProperty('--light-text-colour', styles.lightGreyColour);
        if (styles?.selectedFont) preview?.style.setProperty('--heading-font', styles.selectedFont.heading);
        if (styles?.selectedFont) preview?.style.setProperty('--base-font', styles.selectedFont.body);
        if (styles?.baseFontSize) preview?.style.setProperty('--base-font-size', styles.baseFontSize);
        if (styles?.buttonRoundness) preview?.style.setProperty('--button-roundness', styles.buttonRoundness);
        if (styles?.containerRoundness) preview?.style.setProperty('--container-roundness', styles.containerRoundness);
        if (styles?.containerSpacing) preview?.style.setProperty('--container-spacing', styles.containerSpacing);
    });

    // Updates the pattern colour
    Array.from(sectionsWithPatterns).forEach(p => {
        p.style.backgroundImage = "";
        p.style.backgroundImage = window.getComputedStyle(p).backgroundImage.replace(/fill='(.+?)'/gm, `fill='%23${styles?.baseColour.substr(1)}'`);
    })
}

export const getLightBackground = (palette, bc) => {
    switch (palette) {
        case "monochrome":
            return getMonoPalette(bc)[0]
        case "complimentary":
            return getComplimentaryPalette(bc)[0]
        case "left-analogous":
            return getLeftAnalogousPalette(bc)[0]
        case "right-analogous":
            return getRightAnalogousPalette(bc)[0]
        default:
            return getMonoPalette(bc)[0]
    }
}

export const getDarkBackground = (palette, bc) => {
    switch (palette) {
        case "monochrome":
            return getMonoPalette(bc)[2]
        case "complimentary":
            return getComplimentaryPalette(bc)[2]
        case "left-analogous":
            return getLeftAnalogousPalette(bc)[2]
        case "right-analogous":
            return getRightAnalogousPalette(bc)[2]
        default:
            return getMonoPalette(bc)[2]
    }
}

export const getMonoPalette = (c) => {
    let mono1 = tinycolor({ h: tinycolor(c).toHsl().h, s: tinycolor(c).toHsl().s, l: 96 }).toHexString()
    let mono2 = tinycolor({ h: tinycolor(c).toHsl().h, s: tinycolor(c).toHsl().s, l: 8 }).toHexString()

    return [mono1, c, mono2];
}

export const getComplimentaryPalette = (c) => {
    let compColours = tinycolor(c).tetrad()
    let comp1 = tinycolor({
        h: tinycolor(compColours[2]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 98
    }).toHexString()
    let comp2 = tinycolor({
        h: tinycolor(compColours[2]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 8
    }).toHexString()
    return [comp1, c, comp2];
}

export const getLeftAnalogousPalette = (c) => {
    let anaColours = tinycolor(c).analogous(12, 30)
    let anaL1 = tinycolor({
        h: tinycolor(anaColours[3]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 98
    }).toHexString()
    let anaL2 = tinycolor({
        h: tinycolor(anaColours[3]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 8
    }).toHexString()

    return [anaL1, c, anaL2]
}

export const getRightAnalogousPalette = (c) => {
    let anaColours = tinycolor(c).analogous(12, 30)
    let anaR1 = tinycolor({
        h: tinycolor(anaColours[11]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 98
    }).toHexString()
    let anaR2 = tinycolor({
        h: tinycolor(anaColours[11]).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: 8
    }).toHexString()

    return [anaR1, c, anaR2]
}

export const getBaseColourContrast = (c) => {
    const luminance = tinycolor(c).getLuminance()
    const lightness = luminance < 0.3 ? 98 : 15

    return tinycolor({
        h: tinycolor(c).toHsl().h,
        s: tinycolor(c).toHsl().s,
        l: lightness
    }).toHexString()
}


export const getBlackColour = (c) => {
    return tinycolor({
        h: tinycolor(c).toHsl().h,
        s: 20,
        l: 8
    }).toHexString()
}

export const getLightGreyColour = (c) => {
    return tinycolor({
        h: tinycolor(c).toHsl().h,
        s: 10,
        l: 96
    }).toHexString()
}

export const getDarkSectionButtonColour = (bc) => {
    let bcolor = tinycolor(bc);
    if (bcolor.toHsl().l < 0.45) {
        return '#ffffff'
    } else {
        return bc
    }
}

export const getDarkSectionButtonTextColour = (bc) => {
    let bcc = getBaseColourContrast(bc);
    let bcolor = tinycolor(bc);
    if (bcolor.toHsl().l < 0.45) {
        return getBlackColour(bc)
    } else {
        return bcc
    }
}

export const getBrandSectionButtonColour = (bc) => {
    let bcolor = tinycolor(bc);
    let bcc = getLightBackground("monochrome", bc)
    if (bcolor.toHsl().l < 0.45) {
        return '#ffffff'
    } else if (bcolor.toHsl().l > 0.88) {
        return getBlackColour(bc)
    }else {
        return bcc
    }
}

export const getBrandSectionButtonTextColour = (bc) => {
    let bcolor = tinycolor(bc);
    if (bcolor.toHsl().l > 0.88) {
        return '#ffffff'
    } else {
        return getBlackColour(bc)
    }
}
export const getButtonRoundness = (roundness) => {
    switch (roundness) {
        case 0:
            return '0px'
        case 1:
            return '0.25rem'
        case 2:
            return '0.625rem'
        case 3:
            return '9999px'
        default:
            return '0.25rem'
    }
}

export const getContainerRoundness = (roundness) => {
    switch (roundness) {
        case 0:
            return '0px'
        case 1:
            return '0.25rem'
        case 2:
            return '0.625rem'
        case 3:
            return '1.5rem'
        default:
            return '0.25rem'
    }
}

export const getContainerSpacing = (spacing) => {
    switch (spacing) {
        case 0:
            return '2rem'
        case 1:
            return '3rem'
        case 2:
            return '4rem'
        case 3:
            return '6em'
        default:
            return '2rem'
    }
}

export const getNavHeight = (design) => {
    switch (design) {
        case 0:
            return '7.3125rem'
        case 1:
            return '4.5rem'
        default:
            return '4.5rem'
    }
}

export const getNavigationSpacing = (design) => {
    switch (design) {
        case 0:
            return '7.3125rem'
        case 1:
            return '4.5rem'
        default:
            return '4.5rem'
    }
}

export const getNavigationMobileSpacing = (design) => {
    switch (design) {
        case 0:
            return '3.75rem'
        case 1:
            return '3.75rem'
        default:
            return '3.75rem'
    }
}