import classNames from 'classnames';
import PropTypes from "prop-types";

import {Disclosure} from "@headlessui/react";
import AnimateHeight from 'react-animate-height';
import ChevronIcon from '@autocx/icons/src/chevron-icon';

import Fields from "./fields";

const CollapseExpandButton = function (props) {
    if (props.canExpand) {
        return (
            <Disclosure.Button as={"div"} className={'flex justify-between group cursor-pointer'}>
                <div>
                    {props.fieldset.label ? (
                        <h3 className="text-lg leading-6 font-medium text-gray-900 group-hover:text-link">{props.fieldset.label}</h3>
                    ) : null}
                    {props.children}
                </div>
                <div className={"group-hover:text-link"}>
                    <span className={classNames("border-current h-6 w-6", props.open ? "chevron--up" : "chevron--down")}>
                        <ChevronIcon />
                    </span>
                </div>
            </Disclosure.Button>
        );
    } else {
        return (
            <>
                {props.fieldset.label ? (
                    <h3 className="text-lg leading-6 font-medium text-gray-900 group-hover:text-link">{props.fieldset.label}</h3>
                ) : null}
                {props.children}
            </>
        )
    }
}

TwoColumnLayout.propTypes = {
    editing: PropTypes.bool,
    fieldset: PropTypes.object,
    onFieldChange: PropTypes.func,
    onEditFieldsetSettings: PropTypes.func,
}

TwoColumnLayout.defaultProps = {
    fieldsets: [],
    onFieldChange: () => {
    },
}

export default function TwoColumnLayout({children, editButton, ...props}) {
    const canExpand = props.fieldset.collapsable === true && !props.fieldset.childrenOnly;
    return (
        <Disclosure as={"div"} defaultOpen={!(props.fieldset.collapsable === true) || props.fieldset.expanded === true}>
            {({open}) => (
                <>
                    <CollapseExpandButton {...props} open={open} canExpand={canExpand}>
                        {editButton}
                    </CollapseExpandButton>
                    {canExpand ? (
                        <AnimateHeight
                            height={open ? 'auto' : 0}
                            animateOpacity={true}
                        >
                            <Disclosure.Panel static
                                              className={"md:mt-0 sm:grid-cols-6 pb-4 md:grid md:grid-cols-5 md:gap-12"}>
                                <div className={"md:col-span-2"}>
                                    {props.fieldset.description ? (
                                        <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                                    ) : null}
                                </div>
                                <Fields {...props} className={"space-y-4 pb-5 md:col-span-3"}>
                                    {children}
                                </Fields>
                            </Disclosure.Panel>
                        </AnimateHeight>
                    ) : (
                        <div className="mb-4 md:grid md:grid-cols-5 md:gap-12">
                            <div className={"md:col-span-2"}>
                                {!props.fieldset.childrenOnly && props.fieldset.description ? (
                                    <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                                ) : null}
                            </div>
                            <Fields {...props} className={"sm:-mt-5 space-y-4 mb-5 md:col-span-3"}>
                                {children}
                            </Fields>
                        </div>
                    )}
                </>
            )}
        </Disclosure>
    )
};

TwoColumnLayout.EditButton = function (props) {
    return (<a className={"display-block"} href="forms/src/layouts/two-column-layout#" onClick={props.onClick}>{props.children}</a>)
};