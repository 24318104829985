import classNames from "classnames";
import Block from '../block'
import {
    backgroundColour,
    textAlignment,
    marginForTextAlignment,
    backgroundPattern
} from "../../utils/block-utils";

export default function Design1(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props): null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto section flex flex-col items-center",
                props.values.options?.fullWidth ? "min-w-full !px-0" : "max-w-7xl",
                !props.values?.title && props.values.options?.fullWidth ? "!py-0 !gap-y-0" : null,
                textAlignment(props),
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props): null,
                props.values.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames("w-full sm:w-3/5")}>
                    {props.values?.title ? (
                        <h3>{props.values.title}</h3>
                    ) : null }
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                </div>
                <div className={classNames("relative w-full")}>
                    {props.values?.iframeUrl
                        ?
                        <iframe id={`iframe-${props.id}`}
                                src={props.values?.iframeUrl}
                                title={"External App"}
                                width={"100%"}
                                height={props.values?.iframeHeight}
                                loading={"lazy"}
                                className={classNames("lazyload lazymotion", props.values?.iframeHeight === 0 && "h-screen")}
                                border={"none"}/>
                        :
                        <div className="skeleton bg-gray-100 rounded-theme p-10 h-[500px] flex-col">
                            <div className="flex items-center justify-start flex-row w-full">
                                <div className="frame bg-[#9e9e9e] rounded-full p-16 relative overflow-hidden"></div>
                                <div className="ml-8 w-full space-y-4">
                                    <div className="frame bg-[#9e9e9e] rounded-full w-3/5 h-6 relative overflow-hidden"></div>
                                    <div className="frame bg-[#9e9e9e] rounded-full w-full h-6 relative overflow-hidden"></div>
                                    <div className="frame bg-[#9e9e9e] rounded-full w-full h-6 relative overflow-hidden"></div>
                                </div>
                            </div>
                            <div className="w-full space-y-4 mt-8">
                                <div className="frame bg-[#9e9e9e] rounded-full w-1/5 h-6 relative overflow-hidden"></div>
                                <div className="frame bg-[#9e9e9e] rounded-full w-3/5 h-6 relative overflow-hidden"></div>
                                <div className="frame bg-[#9e9e9e] rounded-full w-full h-6 relative overflow-hidden"></div>
                                <div className="frame bg-[#9e9e9e] rounded-full w-full h-6 relative overflow-hidden"></div>
                            </div>
                            <div className="w-full flex space-x-8 mt-8">
                                <div className="frame bg-[#9e9e9e] rounded-full h-12 relative overflow-hidden w-1/3"></div>
                                <div className="frame bg-[#9e9e9e] rounded-full h-12 relative overflow-hidden w-1/3"></div>
                                <div className="frame bg-[#9e9e9e] rounded-full h-12 relative overflow-hidden w-1/3"></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Block>
    )

}
