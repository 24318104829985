import './index.css';
import Design1 from './design-1';
import {useState} from "react";
import axios from "axios";
import Spinner from "../../components/spinner";
import {LoginForm, ResetPassword} from "../account-block/account-login-register";

ProductBlock.propTypes = {};

ProductBlock.defaultProps = {};

export default function ProductBlock(props) {
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};

export function InlineAccountForm({setProfile, setShowingSignIn}) {
    const [currentForm, setCurrentForm] = useState("signIn")
    
    return (
        <>
            {currentForm === "signIn" &&
                <LoginForm inline={true} setProfile={setProfile} setShowingSignIn={setShowingSignIn}
                           setCurrentForm={setCurrentForm}/>
            }
            {currentForm === "resetPassword" && 
                <ResetPassword inline={true} setCurrentForm={setCurrentForm}/>}
        </>
    )
}
