import {createContext} from "react";

const FormContext = createContext({
    fieldsets:{},
    fileUploadSettings: {
        cdnUrl: '',
        saveUrl: '',
        basePath: ''
    },
    values: {},
    errors: {},
    original: {},
    customControls: {},
    validate: () => {},
    registerProcessor: () => {},

    /**
     * @type {?number}
     */
    progress: null
});

export default FormContext;