import {useEffect, useMemo, useRef} from "react";
import _debounce from "lodash/debounce";

/**
 * 
 * @param callback Function to call
 * @param wait Time until function is called again
 * @param cancelToken Cancel token
 * @returns {*}
 */
const useDebounce = (callback, wait = 1000, cancelToken) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = cancelToken ? () => callback(cancelToken) : callback;
        return () => cancelToken ? cancelToken.cancel() : null;
    }, [callback]);

    return useMemo(() => {
        const func = (...args) => {
            ref.current?.(...args);
        };

        return _debounce(func, wait);
    }, []);
};

export default useDebounce;