import {useContext, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad-wrapper'
import FormContext from "../form-context";

CustomSignaturePad.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    modified: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

const defaultOptions = {
    minSize: 1,
    maxSize: 2.5,
    throttle: 0,
    minDistance: 1,
    velocityFilterWeight: 0.1
}

CustomSignaturePad.DisplayValue = function (props) {
    return props.value === undefined || props.value === null || props.value === "—" ? '\u2014' : (
        <img src={props.value} className={"h-20"} />
    );
}
CustomSignaturePad.DisplayView = CustomSignaturePad.DisplayValue;

export default function CustomSignaturePad(props) {
    const context = useContext(FormContext);
    const signaturePadRef = useRef();
    const inputRef = useRef();
    const [data, setData] = useState("");

    // Initialise with props.value if it exists
    useEffect(() => {
        if (signaturePadRef.current && props.value) {
            signaturePadRef.current.fromDataURL(props.value);
        }
    }, [props.value]);
    
    useEffect(() => {
        const updateValue = () => {
            const padValue = signaturePadRef.current.toDataURL("image/svg+xml")
            props.onChange({target: inputRef.current}, props.name, padValue);
            setData(padValue)
        }

        signaturePadRef.current.instance.addEventListener("endStroke", updateValue)

        return () => {
            if (signaturePadRef?.current) {
                signaturePadRef.current.instance.removeEventListener("endStroke", updateValue)
                signaturePadRef.current.off()
            }
        }
    }, [])


    return (
        <div className={"cursor-crosshair bg-slate-50 rounded relative"}>
            <input
                id={props.id}
                ref={inputRef}
                tabIndex={-1}
                type="text"
                className="sr-only"
                name={props.name}
                value={data}
                onChange={() => {}}
                required={props.required}
                disabled={props.disabled}
                data-error-message={props.errorMessage}
            />
            <SignaturePad
                redrawOnResize
                placeholder="signature"
                ref={signaturePadRef}
                height={176}
                canvasProps={{className: "relative z-10"}}
                options={{ ...defaultOptions, ...props.options }}
            />
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        props.onChange({target: inputRef.current}, props.name, "");
                        setData("")
                        signaturePadRef.current.clear();
                    }}
                    className="absolute z-10 top-2.5 right-2.5 reset-font p-1 text-[13px] font-semibold rounded text-gray-600 bg-white hover:bg-panels-200 focus:outline-none"
                >
                    Redraw
                </button>
                {/*<button*/}
                {/*    onClick={(e) => {*/}
                {/*        e.preventDefault()*/}
                {/*        console.log(signaturePadRef.current)*/}
                {/*        const data = signaturePadRef.current.toDataURL("image/svg+xml");*/}
                {/*        console.log(data)*/}
                {/*        signaturePadRef.current.clear();*/}
                {/*    }}*/}
                {/*    className="signButtons"*/}
                {/*>*/}
                {/*    submit*/}
                {/*</button>*/}
            <div className={"absolute bottom-1/3 left-0 right-0 sm:mx-8 mx-4 flex flex-row items-end pointer-events-none"}>
                <div className={"sm:left-8 left-4 px-2 text-slate-400 font-bold sm:text-3xl text-2xl"}>&#10007;</div>
                <div 
                    className={"h-[2px] w-full"} 
                    style={{
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CBD5E1FF' stroke-width='4' stroke-dasharray='20' stroke-dashoffset='36' stroke-linecap='square'/%3e%3c/svg%3e")`
                    }}
                ></div>
            </div>
        </div>
    );
}