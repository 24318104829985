import {useContext, useEffect, useState} from "react";
import _get from "lodash/get";

import Input, {VALID_INPUT_TYPES} from "./input";
import Uploader from "./uploader";

import FormLayout from "../layouts/form-layout";

import {isRequired, evaluate, isDifferent, getValue, isDisabled} from "../utils";
import FormContext from "../form-context";

import CONTROLS from "./index";

/**
 * Wrapper component to select to correct form control to render based on the provided type
 *
 * @param conditions An optional object conditions to resolve
 * @param onFieldChange A callback to invoke when a field has changed
 * @param onComponentUpdate A callback to invoke when multiple fields have been changed
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Field({conditions, onFieldChange, onComponentUpdate, ...props}) {
    const context = useContext(FormContext);
    const required = isRequired(props, context.values);
    const disabled = isDisabled(props, context.values);
    const className = typeof props.className === 'function' ? props.className(context.values) : props.className;
    const isNotCheckbox = props.type !== 'checkbox' && props.type !== 'checkbox-list' && props.type !== 'switch';
    const value = getValue(props, context.values);
    const error = _get(context.errors, props.name, false);
    const [allControls, setAllControls] = useState({...CONTROLS, ...context.pluginControls});

    useEffect(() => {
        if (context.pluginControls) setAllControls({...CONTROLS, ...context.pluginControls});
    }, [context.pluginControls]);

    let Field;
    // Handle multiple variants of the uploader control
    if (props.type) {
        if (props.type.includes('uploader')) {
            Field = Uploader;
        } else if (allControls[props.type]) {
            Field = allControls[props.type];
        } else if (context.customControls && context.customControls[props.type]) {
            Field = context.customControls[props.type];
        } else if (VALID_INPUT_TYPES.includes(props.type)) {
            Field = Input;
        }
    }
    
    Field = Field
        ? props.editView
            ? props.editView
            : Field
        : props?.type?.startsWith("plugins")
            ? allControls?.spacer
            : Input

    // console.log(Field);

    const expressions = Object.keys(conditions || {});
    const forms = expressions.filter(expression => evaluate(value, expression, context.values) === true);
    if (expressions.length) {
        console.warn("Using `conditions` on the field `" + props.name + "` is deprecated. Please move conditional fields " +
            "into fields array of a fieldset and use the `showsWhen` property on the field with the predicate to evaluate");

        console.warn(`For example the field \'${props.name}\':`, JSON.stringify(
            expressions.reduce((result, expression) => {
                (conditions[expression].fieldsets || []).forEach(({fields = []}) => {
                    fields.forEach((field) => {
                        result.push({
                            name: field.name,
                            showsWhen: `${props.name} ${expression}`
                        });
                    })
                });
                return result;
            }, []), null, 4)
        );
    }

    // Auto Focus field if not already focused
    useEffect(() => {
        if (props.autoFocus) {
            const element = context.id
                ? document.querySelector(`#${context.id} [name='${props.name}']`)
                : document.querySelector(`[name='${props.name}']`);
            if (element) {
                setTimeout(() => {
                    element.focus();
                    if (props.type === 'text' || props.type === 'textarea') {
                        element.setSelectionRange(0, value.length);
                    }
                }, props.autoFocus);
            }
        }
    }, []);

    return (
        <>
            <Field
                {...props}
                className={className}
                id={props.id || props.name}
                onChange={(e, name, value, ignoreAutoSave) => {
                    // Invoke the provided onChange defined in the settings of the field like as if the native onChange
                    // was implemented
                    if (props.onChange) props.onChange(e);

                    const newForms = expressions
                        .filter(expression => evaluate(value, expression, context.values) === true)
                        .map(expression => conditions[expression]);
                    onFieldChange(e, name, value, newForms, ignoreAutoSave);

                    if (props.onAfterChange) props.onAfterChange(e);
                }}
                onComponentChange={(e, newComponent, ignoreAutoSave) => {
                    // Invoke the provided onChange defined in the settings of the field like as if the native onChange
                    // was implemented
                    if (props.onChange) props.onChange(e);

                    onComponentUpdate(e, newComponent, ignoreAutoSave);

                    if (props.onAfterChange) props.onAfterChange(e);
                }}
                required={required}
                disabled={disabled}
                value={value}
                fieldValue={props.value}
                values={context.values}
                error={error}
                errors={context.errors}
                modified={isDifferent(context.original, context.values, props.name) === true}
            />
            {!error && props.description && isNotCheckbox ? (
                <p className="mt-1 mb-0 text-xs font-medium text-gray-500 whitespace-normal">
                    {props.description}
                </p>
            ) : null}
            {error && error !== true && !props.disabled && typeof error !== 'object' ? (
                <p className="mt-1.5 text-xs font-medium text-error-500">
                    {error}
                </p>
            ) : null}
            {forms.map((expression, index) => (
                <FormLayout
                    key={`${expression}_${index}`}
                    {...conditions[expression]}
                    layout={"simple-stacked"}
                    onFieldChange={onFieldChange}
                />
            ))}
        </>
    );
};