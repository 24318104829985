import classNames from 'classnames';
import Block from '../block'
import {
    backgroundColour,
    backgroundPattern,
    marginForTextAlignment,
    setTitleDecorationByClass, setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";


export default function Design1(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props): null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div
                className={classNames(
                    "mx-auto max-w-7xl section flex flex-col",
                    props.values.options?.position === 1 ? 'sm:flex-col items-center' : 'sm:flex-row items-start',
                    props.values.options?.inset ? 'rounded-theme' : null,
                    props.values.options?.inset ? backgroundPattern(props): null,
                    props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
                )}>
                <div className={classNames("w-full",
                    props.values.options?.position === 1 ? 'sm:w-3/5' : 'sm:w-1/2',
                    textAlignment(props)) }>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text mb-4", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                </div>
                <div className={classNames("sm:flex sm:items-center w-full",
                    props.values.options?.position === 1 ? 'sm:w-3/5' : 'sm:w-1/2',
                )}>
                {props.submitted ? (
                    <div className={"flex flex-col"}>
                        <h6>Message Sent</h6>
                        <p>We'll get back to you as soon as we can.</p>
                    </div>
                ) : (
                    <form className={"flex flex-col w-full space-y-3"} onSubmit={props.onSubmit}>
                        <div className={"flex flex-col"}>
                            <label htmlFor="contact-name">Name</label>
                            <input key="contact-name" id="contact-name" name="contact-name" type="text" placeholder={"Enter Name"} required className={"mt-1"} />
                        </div>
                        <div className={"flex flex-col"}>
                            <label htmlFor="contact-email">Email</label>
                            <input key="contact-email" id="contact-email" name="contact-email" type="email" placeholder={"Enter Email"} required className={"mt-1"} />
                        </div>
                        <div className={"flex flex-col"}>
                            <label htmlFor="contact-message">Message</label>
                            <textarea key="contact-message" id="contact-message" rows="5" name="contact-message" placeholder={"Enter Message"} style={{resize: "vertical"}} className={"mt-1"} />
                        </div>
                        {props.values?.formFields?.map((field, i) => (
                            <div key={i} className={classNames("flex items-center", field.type === "checkbox" ? 'flex-row' : 'flex-col')}>
                                <label htmlFor={field.name} className={field.type === "checkbox" ? 'order-last ml-2' : "w-full"}>{field.name}</label>
                                <input id={field.name} name={field.name} type={field.type ?? "text"} placeholder={`Enter ${field.name ?? ""}`} required={field.required} className={field.type === "checkbox" ? null : "w-full mt-1"} />
                            </div>
                        ))}
                        {props.error ? (
                            <span className={"text-red-500"}>There was a problem sending the message, please try again.</span>
                        ) : null}
                        {props.values?.action?.label ? (
                            <button
                                type="submit"
                                className={"button mt-2 !inline-block self-end cursor-pointer"}
                            >{props.values.action.label}</button>
                        ) : null}
                    </form>
                )}
                </div>
            </div>
        </Block>
    )
}