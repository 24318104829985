import Design1 from './design-1';

FinanceCalculatorBlock.propTypes = {
};

FinanceCalculatorBlock.defaultProps = {};

export default function FinanceCalculatorBlock(props) {
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};