export function importAll(require, lookup) {
    require.keys().forEach((key) => {

        const type = key.split('/').slice(1)[0];
        const module = require(key);

        // Ignore if module is empty
        if (!module) return;

        const ref = module?.default || module;

        if (Array.isArray(lookup)) {
            lookup.push(ref);
        } else if (typeof lookup === 'object') {
            lookup[type] = ref;
        }
    });
}