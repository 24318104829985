import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from "react";
import Input from "./input";
import Select from "./select";
import _get from "lodash/get";

const API_KEY = "";
Address.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.oneOf(['addressLine1', 'addressLine2', 'suburb', 'state', 'postcode', 'country']))
};

Address.defaultProps = {
    onChange: () => {
    },
    fields: ['addressLine1', 'addressLine2', 'suburb', 'state', 'postcode', 'country']
}

const GOOGLE_MAPPING = {
    addressLine1: "addressLine1",
    addressLine2: "subpremise",
    suburb: "locality",
    state: "administrative_area_level_1",
    postcode: "postal_code",
    country: "country",
}

export function formatAddress(address) {
    // because sometimes the object passed in has more than address data
    const addressKeys = ['addressLine1', 'addressLine2', 'suburb', 'state', 'postcode', 'country'];

    if (!address || addressKeys.every(key => !address[key])) {
        return <span>—</span>;  
    }
    
    const parts = [
        address.addressLine1,
        address.addressLine2,
        [address.suburb, address.state, address.postcode].filter(Boolean).join(', ')
    ].filter(part => part);

    if (address.country) {
        parts.push(address.country);
    }

    return parts.map((part, index) => <div key={index}>{part}</div>);
};

Address.DisplayValue = function (props) {
    return (
        <>
            {formatAddress(props?.value)}
        </>
    )
}

Address.WebsiteProductDisplayView = function (props) {
    return (
        <div className="grid grid-cols-2 gap-x-4 items-start mb-1">
            <div className="font-semibold col-span-1">{props.label}</div>
            <div
                className="col-span-1">{props.prefix} {formatAddress(props?.value) || '—'} {props.suffix}</div>
        </div>
    )
}
Address.DisplayView = Address.DisplayValue; 
export default function Address(props) {
    const [loaded, setLoaded] = useState(false);
    const path = props.name || props.id
    const values = _get(props.values, path, props.values);
    const [address, setAddress] = useState({
        addressLine1: values?.addressLine1 || '',
        addressLine2: values?.addressLine2 || '',
        suburb: values?.suburb || '',
        state: values?.state || '',
        postcode: values?.postcode || '',
        country: values?.country || '',
    });

    const autoCompleteRef = useRef();
    const addressRef = useRef();

    const options = {
        fields: ["address_components", "adr_address"],
        types: ["address"]
    };

    useEffect(() => {
        if (API_KEY) {
            let scriptTag;
            if (!document.getElementById('googleApi')) {
                scriptTag = document.createElement('script');
                scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
                scriptTag.id = "googleApi"
                document.body.appendChild(scriptTag);
            } else {
                scriptTag = document.getElementById('googleApi');
            }
            scriptTag.addEventListener('load', () => setLoaded(true));
            return () => {
                scriptTag.removeEventListener('load', () => setLoaded(true));
            }
        }
    }, [])

    useEffect(() => {
        if (!loaded) return;

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            addressRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            populateInputs(place)
        });
        return () => {
            autoCompleteRef.current = null;
        }
    }, [loaded])

    const populateInputs = (place) => {
        console.log(place);

        function getComponentName(componentType) {
            for (const component of place.address_components || []) {
                if (component.types[0] === componentType) {
                    return ["administrative_area_level_1"].includes(componentType)
                        ? component.short_name
                        : component.long_name;
                }
            }
            return '';
        }

        function getComponentText(componentType) {
            return (componentType === 'addressLine1') ?
                `${getComponentName('street_number')} ${getComponentName('route')}` :
                getComponentName(componentType);
        }

        for (const componentType in address) {
            const value = getComponentText(GOOGLE_MAPPING[componentType]);
            setAddress(p => ({...p, [componentType]: value}))
            props.onChange(null, `${path}.${componentType}`, value)

        }
    }

    const handleOnChange = (e, value, name) => {
        setAddress(p => ({...p, [name]: value}));
        props.onChange(e, `${path}.${e.target.name}`, value)
    }

    return (
        <div className="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-4">
            {props.fields?.includes('addressLine1') &&
                <div className="col-span-full">
                    {props.fields.length < 6 &&
                        <label htmlFor={'addressLine1'}
                               className={"block text-sm mb-0.5"}>Address Line 1</label>
                    }
                    <Input
                        required={props.required}
                        disabled={props.disabled}
                        onChange={(e) => handleOnChange(e, e.target.value, 'addressLine1')}
                        value={address.addressLine1} autoComplete="street-address"
                        ref={addressRef} name="addressLine1" type="text"
                        placeholder="Address"/>
                </div>
            }

            {props.fields?.includes('addressLine2') &&
                <div className="col-span-full">
                    {props.fields.length < 6 &&
                        <label htmlFor={'addressLine2'}
                               className={"block text-sm mb-0.5"}>Address Line 2</label>
                    }
                    <Input
                        onChange={(e) => handleOnChange(e, e.target.value, 'addressLine2')}
                        disabled={props.disabled}
                        value={address.addressLine2}
                        name="addressLine2" autoComplete="address-level4" type="text"
                        placeholder="Apt, Unit, Floor, Suite, etc"/>
                </div>
            }
            {props.fields?.includes('suburb') &&
                <div className="sm:col-span-2 sm:col-start-1">
                    {props.fields.length < 6 &&
                        <label htmlFor={'suburb'}
                               className={"block text-sm mb-0.5"}>Suburb</label>
                    }
                    <Input
                        required={props.required}
                        disabled={props.disabled}
                        onChange={(e) => handleOnChange(e, e.target.value, 'suburb')}
                        value={address.suburb} autoComplete="address-level2"
                        name="suburb" type="text" placeholder="Suburb/City"/>
                </div>
            }
            {props.fields?.includes('state') &&
                <div className="sm:col-span-2">
                    {props.fields.length < 6 &&
                        <label htmlFor={'state'}
                               className={"block text-sm mb-0.5"}>State</label>
                    }
                    <Input
                        required={props.required}
                        disabled={props.disabled}
                        onChange={(e) => handleOnChange(e, e.target.value, 'state')}
                        value={address.state} autoComplete="address-level1"
                        name="state" type="text"
                        placeholder="State/Province"/>
                </div>
            }
            {props.fields?.includes('postcode') &&
                <div className="sm:col-span-2">
                    {props.fields.length < 6 &&
                        <label htmlFor={'postcode'}
                               className={"block text-sm mb-0.5"}>Postcode</label>
                    }
                    <Input
                        required={props.required}
                        disabled={props.disabled}
                        onChange={(e) => handleOnChange(e, e.target.value, 'postcode')}
                        value={address.postcode} autoComplete="postal-code"
                        name="postcode" type="text"
                        placeholder="Zip/Postal Code"/>
                </div>
            }
            {props.fields?.includes('country') &&
                <div className="sm:col-span-2">
                    {props.fields.length < 6 &&
                        <label htmlFor={'country'}
                               className={"block text-sm mb-0.5"}>Country</label>
                    }
                    <Select
                        canCreate={false}
                        href={"/api/v2/locale/countries"}
                        labelFieldPath={"label"}
                        valueFieldPath={"value"}
                        required={props.required}
                        disabled={props.disabled}
                        onChange={(e, t, value) => {
                            handleOnChange(e, value, 'country')
                        }}
                        value={address.country}
                        autoComplete="country"
                        name="country"
                        placeholder="Country"/>
                </div>
            }
        </div>
    );
}