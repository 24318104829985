import PropTypes from 'prop-types';
import React, {useContext, useRef} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";

import FormLayout from './form-layout';
import InputGroup from '../controls/input-group';
import ControlGroup from "../controls/control-group";
import Gallery from "../controls/gallery";
import List from "../controls/list";
import Table from "../controls/table";
import Spacer from "../controls/spacer";
import Paragraph from "../controls/paragraph";
import FieldBuilder from "../builder/field-builder";
import {evaluate, getValue} from "../utils";
import FormContext from "../form-context";
import _isObject from "lodash/isObject";
import _isNumber from "lodash/isNumber";
import MenuIcon from '@heroicons/react/solid/MenuIcon'
import classNames from "classnames";
import Block from "../controls/block";
import Tree from "../controls/tree";
import EntityLabeler from "../controls/entity-labeler";
import CategoryField from "../controls/category-field";

const getExtendableSettings = (fieldset, formExtendable) => {
    // Handle when extendable is an object and merge setting together with the form extendable prop
    let extendable;
    if (_isObject(fieldset.extendable) && _isObject(formExtendable)) {
        extendable = {...formExtendable, ...fieldset.extendable};
    } else if (_isObject(fieldset.extendable)) {
        extendable = fieldset.extendable;
    } else {
        extendable = formExtendable;
    }
    return extendable;
}

const DragHandler = SortableHandle ? SortableHandle(() => (
    <div className="shrink-0 flex items-center pr-2 cursor-pointer">
        <MenuIcon className="h-5 w-5 text-panels-700" aria-hidden="true"/>
    </div>
)) : () => {
    return null
};

function Field({index, field, fieldset, ...props}) {
    const context = useContext(FormContext);
    const extendable = getExtendableSettings(fieldset, props.extendable);

    // Render a form control
    let Control;
    switch (field.type) {
        case 'form-layout':
            Control = FormLayout;
            break;
        case 'list':
            Control = List;
            break;
        case 'gallery':
            Control = Gallery;
            break;
        case 'category-field':
            Control = CategoryField;
            break;
        case 'entity-labeler':
            Control = EntityLabeler;
            break;
        case 'tree':
            Control = Tree;
            break;
        case 'table':
            Control = Table;
            break;
        case 'control-group':
            Control = ControlGroup;
            break;
        case 'spacer':
            Control = Spacer;
            break;
        case 'paragraph':
            Control = Paragraph;
            break;
        case 'block':
            Control = Block;
            break;
        default:
            Control = InputGroup;
            // Adds support to allow custom controls to opt-out of wrapping input group
            if (context.customControls && context.customControls[field.type] && context.customControls[field.type].wrapWithInputGroup === false) {
                Control = context.customControls[field.type];
            }
            break;
    }

    // When in an editing state for this field render the edit state for this fields settings
    if (field.editing) {
        const {editing, ...fieldProps} = field;
        return (
            <>
                {index > 0 ? (<hr className={"border-panels-300"}/>) : null}
                <FieldBuilder
                    // When the fieldset is marked as extendable and the form isn't. This states that the 
                    // fields are only editable and therefor need a reference to the fieldset so that the 
                    // field builder knows which fieldset this field lives in 
                    fieldset={!props.extendable && fieldset.extendable ? fieldset : null}
                    extendable={extendable}
                    userDefinedFieldsetsScope={props.userDefinedFieldsetsScope}
                    editing={editing}
                    field={fieldProps}
                    onActionClick={(e, action, values) => props.onFieldSettingsAction(e, action, values, field, fieldset)}
                />
                {index !== (fieldset.fields.length - 1) ? (<hr className={"border-panels-300"}/>) : null}
            </>
        )
    }

    // Handle when it evaluates to false to hide this field otherwise if it's undefined null or true then
    // show it
    if (evaluate(field.value, field.showsWhen, context.values) === false && !fieldset.editing) {
        return null;
    }

    // Handle when the field is a divider and render a hr instead
    if (field.divider === true || _isNumber(field.divider)) {
        const divider = (
            <hr className={classNames(
                "border-panels-300 h-px w-full",
                field.divider === 2 ? "border-b" : null,
                field.divider === 3 ? "border-b-2" : null,
            )}/>
        );
        if (fieldset.sortable && !field.system && SortableHandle) {
            return (
                <div className={"flex items-center justify-between"}>
                    <DragHandler/>
                    {divider}
                    {fieldset.editing ? (
                        <span
                            className={"shrink-0 pl-2 text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.onEditFieldSettings(e, field, fieldset);
                            }}
                        >Edit</span>
                    ) : null}
                </div>
            )
        }
        return divider;
    }

    // Handle when the field is a hidden field and render just the input
    if (field.type === 'hidden') {
        return (
            <input
                type={field.type}
                name={field.name}
                value={getValue(field, context.values)}
            />
        );
    }

    const fieldProps = {
        ...field,
        editing: fieldset.editing,
        onFieldChange: props.onFieldChange,
        onComponentUpdate: props.onComponentUpdate,
        onEditFieldSettings: (e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onEditFieldSettings(e, field, fieldset);
        }
    };

    if (fieldset.sortable && !field.system && SortableHandle) {
        return (
            <div className={"flex items-center"}>
                <DragHandler/>
                <Control {...fieldProps} className={classNames("grow", fieldProps.className)}/>
            </div>
        )
    } else {
        return (<Control {...fieldProps} />)
    }
}

Fields.propTypes = {
    fieldset: PropTypes.object,
    onFieldChange: PropTypes.func,
    onEditFieldSettings: PropTypes.func,
    onFieldSettingsAction: PropTypes.func,
};

Fields.defaultProps = {
    fieldset: {},
    onFieldChange: () => {
    },
    onEditFieldSettings: () => {
    },
    onFieldSettingsAction: () => {
    }
};

function Fields({fieldset, ...props}) {
    const extendable = getExtendableSettings(fieldset, props.extendable);
    const canAddFieldset = (!props.extendable && fieldset.extendable && fieldset.editing) || (props.extendable && fieldset.extendable && fieldset.editing);
    if (fieldset.childrenOnly) return null;
    if (!canAddFieldset && !fieldset.fields?.length) return null;

    const SortableField = fieldset.sortable && SortableElement ? SortableElement(Field) : Field;
    return (
        <>
            {fieldset?.fields?.filter(f => !!f).map((field, index) => {
                let id;
                if (field?.type === 'control-group') id = field.fields.map(f => f.name).join('_');
                return (
                    <SortableField
                        key={field.divider === true ? `${index}_divider` : `${fieldset.id}_${field.name}_${field.id}_${field.showsWhen}_${id}_${field.editing ? '_editing' : ''}`}
                        collection={field.system === true ? 0 : 1}
                        disabled={field.system === true}
                        index={index}
                        field={field}
                        fieldset={fieldset}
                        {...props}
                    />
                )
            })}
            {canAddFieldset ? (
                <FieldBuilder
                    // When the fieldset is marked as extendable and the form isn't. This states that the fields are
                    // only editable and therefor need a reference to the fieldset so that the field builder knows
                    // which fieldset it should add it 
                    fieldset={!props.extendable && fieldset.extendable ? fieldset : null}
                    extendable={extendable}
                    userDefinedFieldsetsScope={props.userDefinedFieldsetsScope}
                    onActionClick={(e, action, values) => props.onFieldSettingsAction(e, action, values, null, fieldset)}
                />
            ) : null}
            {props.children}
        </>
    );
}

export default ({className, ...props}) => {
    const containerRef = useRef();

    const SortableFields = SortableContainer ? SortableContainer(Fields) : null;
    return (
        <div ref={containerRef} className={className}>
            {!(props.fieldset.sortable && SortableFields) ? (
                <Fields {...props} />
            ) : (
                <SortableFields
                    {...props}
                    useDragHandle
                    getContainer={() => {
                        if (!containerRef.current) return document.body;

                        let parent = containerRef.current.parentElement;
                        while (!(parent.tagName === 'BODY' || parent.scrollHeight > parent.offsetHeight)) {
                            parent = parent.parentElement;
                        }

                        return parent || document.body;
                    }}
                    helperClass={"sort-helper"}
                    onSortStart={() => {
                        const helper = document.getElementsByClassName("sort-helper")[0];
                        helper.style.zIndex = 50000;
                    }}
                    onSortEnd={props.onFieldSortEnd ? (
                        (options) => props.onFieldSortEnd({
                            ...options,
                            fieldset: props.fieldset
                        })
                    ) : null}
                />
            )}
        </div>
    )
};