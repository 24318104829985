import {useMemo} from "react";
import _get from 'lodash/get'

/**
 * Handles any overriding properties for block settings that relate to lists 
 * 
 * @param props The props of the block
 * @param fieldName The name of the field the list is stored under. Defaults to `items`
 * @returns {*}
 */
const useApplyUserDefinedListOverrides = (props, fieldName = 'items') => {
    return useMemo(() => {
        const itemsFieldPath = `${fieldName}`
        const positionsFieldPath = `${fieldName}Positions`
        
        let items = _get(props.values, itemsFieldPath, []) || []
        const positions = _get(props.values, positionsFieldPath, []) || []
        
        // Filter the list of items to only show items that are not deleted
        items = items.filter(item => !item.isDeleted)
        
        // Ignore sorting the items if there is no user-defined sort
        if (items.length && positions.length) {
            items.sort((a, b) => positions.indexOf(a.id) - positions.indexOf(b.id))
        }
        
        return ({
            ...props.values,
            items
        })
    }, [fieldName, props.values])
};

export default useApplyUserDefinedListOverrides;