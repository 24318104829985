import {useContext} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {Transition} from "@headlessui/react";

import {WebsiteContext} from "../index";


PlaceholderBlock.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
}

export default function PlaceholderBlock({style, className, children, props, ...block}) {
    const context = useContext(WebsiteContext);
    if (!context.builder) return null;
    
   return (
        <Transition
            appear={true}
            show={true}
            enter="transition-[height] duration-500 ease-[cubic-bezier(0,0.55,0.45,1)]"
            enterFrom="h-0 opacity-0"
            enterTo="h-[300px] opacity-100"
            leave="transition-opacity ease-[cubic-bezier(0,0.55,0.45,1)] duration-500"
            leaveFrom="h-0 opacity-100"
            leaveTo="h-[300px] opacity-0"
            className={"placeholder-block flex flex-col w-full will-change-[height]"}
        >
            <section
                id={`block_${block.id}`}
                style={style}
                className={classNames(
                    "break-words mx-auto max-w-7xl section flex flex-col items-center justify-center text-center h-[300px]",
                    className
                )}
                {...props}
            >
                <h4 className={"reset-font !font-bold"}>Select a Block</h4>
            </section>
        </Transition>

    )
}