import {useState,useContext} from "react";
import axios from "axios";

import {WebsiteContext} from '../../index';
import Design1 from './design-1';

export default function ContactBlock(props) {
    const context = useContext(WebsiteContext);
    const [state, setState] = useState({
        error: false, 
        submitted: false
    })
    
    let Design;
    switch (props.values?.design) {
        case 0:
            Design = Design1
            break;
        default:
            Design = Design1
    }

    return (
        <Design
            {...props}
            submitted={state.submitted}
            error={state.error}
            onSubmit={(event) => {
                event.preventDefault();
                const name = event.target['contact-name'].value;

                const enquiry = {
                    enquiryType: 1,
                    customer: {
                        firstName: name.includes(' ') ? name.split(' ')[0] : name,
                        lastName: name.includes(' ') ? name.substr(name.indexOf(' ')) : '',
                        emailAddress: event.target['contact-email'].value,
                    },
                    initialMessage: event.target['contact-message'].value,
                    enquirySource: "Website"
                };
                
                // When we are not running in dev mode use a relative path
                let url = props.values.options.formUrl;
                if (context.env !== 'development' && props.values.options.formUrl.includes('://')) {
                    url = props.values.options.formUrl.split('/').slice(3).join('/');
                }
                
                axios
                    .post(url, enquiry)
                    .then(d => setState(previous => ({...previous, submitted: true})))
                    .catch(err => {
                        console.error(err);
                        setState(previous => ({...previous, error: true}))
                    });
            }}
        />
    )
}