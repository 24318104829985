import {clearFilters, hasActiveFilters, toggleFilters} from "./filters";
import Currency from "@autocx/forms/src/controls/currency";
import {useContext} from "react";
import {WebsiteContext} from "../../index";

export default function ActiveFilters({activeFilters, setActiveFilters, ...props}) {
    const context = useContext(WebsiteContext)
    return (
        <div className="my-3">
            <div className="-m-1 flex flex-wrap items-center">
                {Object.keys(activeFilters).map((id) => (
                        id !== 'sort' && id !== 'page'
                            ? Array.isArray(activeFilters[id])
                                ? activeFilters[id].map((value) => (
                                    <span key={id + value}
                                          className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"><span>
                                        {value}
                                    </span>
                                        <button type="button"
                                                className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                                                onClick={() => {
                                                    toggleFilters(value, id, activeFilters, setActiveFilters, true, props?.productsList)
                                                }}>
                                            <svg className="h-2 w-2" stroke="currentColor" viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                                            </svg>
                                        </button>
                                    </span>
                                ))
                                : (
                                    <span key={id + activeFilters[id]}
                                          className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900">
                                        <span>
                                            {id.includes('min') && 'Min '}{id.includes('max') && 'Max '}
                                            {id.includes('unitPrice')
                                                ? (
                                                    <Currency.DisplayValue currency={context.currency} valueAsCents={true}
                                                                          value={+activeFilters[id]}/>
                                                )
                                                : id.includes('discount') ? 'Discounted' : activeFilters[id]
                                            }
                                        </span>
                                        <button type="button"
                                                className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                                                onClick={() => {
                                                    toggleFilters(activeFilters[id], id, activeFilters, setActiveFilters, true, props?.productsList)
                                                }}>
                                            <svg className="h-2 w-2" stroke="currentColor" viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                                            </svg>
                                        </button>
                                    </span>
                                )
                            : null
                    )
                )}
                {hasActiveFilters(activeFilters) && 
                    <button className="cursor-pointer ml-2 font-semibold text-sm" onClick={() => {
                        clearFilters(activeFilters, setActiveFilters)
                    }}>Clear Filters</button>
                }
            </div>
        </div>
    )
}