import Block from "../block";
import {WebsiteContext} from "../../index";
import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/spinner";
import Currency from "@autocx/forms/src/controls/currency";
import {formatDateToFriendlyDate} from "./index";

export default function AccountBidsOffers(props) {
    const [bidsData, setbidsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [showLoader, setShowLoader] = useState(false)
    const context = useContext(WebsiteContext)

    useEffect(() => {
        document.title = props?.title
        const bidsUrl = props?.profile?.customer?._links?.bids?.href;

        if (bidsUrl) {
            setLoading(true)
            let timer = setTimeout(() => {
                setShowLoader(true);
            }, 400);
            axios.get(bidsUrl)
                .then(response => {
                    setbidsData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching bids:", error);
                })
                .finally(() => {
                    clearTimeout(timer)
                    setLoading(false)
                    setShowLoader(false)
                })
        }
    }, [])
    useEffect(() => {
        document.title = props?.title
    }, [])

    return (
        <Block {...props}>
            <h2>{props?.title}</h2>
            {loading
                ? showLoader && <Spinner/>
                : bidsData.length > 0
                    ? <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col"
                                className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0">
                                Title
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Amount
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Placed By
                            </th>
                            <th scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                                Bid Placed
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                <span className="sr-only">View Product</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {bidsData.map((bid) => {
                            const friendlyDate = formatDateToFriendlyDate(bid.bidPlaced)
                            return (
                            <tr key={bid.bidPlaced}>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                    {bid.isWinner &&
                                        <>
                                            <span className={"font-semibold mb-0.5"}>🏆 You won!</span>
                                            <br />
                                        </>
                                    }
                                    {bid.productTitle}
                                    <dl className="font-normal sm:hidden leading-tight">
                                        <dt className="sr-only">Amount</dt>
                                        <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                            <Currency.DisplayValue
                                                currency={context.currency} value={bid.amount}/>
                                        </dd>
                                        <dt className="sr-only sm:hidden">Placed By</dt>
                                        <dd className="mt-1 truncate sm:hidden">{bid.customerDisplayName}</dd>
                                        <dt className="sr-only sm:hidden">Date</dt>
                                        <dd className="mt-1 truncate sm:hidden">{friendlyDate}</dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm sm:table-cell">
                                    <Currency.DisplayValue
                                    currency={context.currency} value={bid.amount}/>
                                </td>
                                <td className="hidden px-3 py-4 text-sm sm:table-cell">{bid.customerDisplayName}</td>
                                <td className="hidden px-3 py-4 text-sm sm:table-cell">{friendlyDate}</td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <a href={`product/${context.website.workspaceId}_${bid.productId}/${bid.productTitle.toLowerCase().trim().replace(/[^a-z0-9-\/\s]/g, '').replace(/\s+/g, '-')}`}
                                   className="text-indigo-600 hover:text-indigo-900">
                                        View
                                    </a>
                                </td>
                            </tr>
                        )})}
                        </tbody>
                    </table>
                    : (
                        <div className="mt-4 text-base text-grey-800 font-semibold">
                            <p>You have not made any bids or offers</p>
                        </div>
                    )
            }
        </Block>
    )
}