import React, {memo, useContext, useEffect, useRef, useState} from "react";
import Currency from "@autocx/forms/src/controls/currency";
import {WebsiteContext} from "../../index";
import classNames from "classnames";
import {aspectRatio} from "../../utils/block-utils";
import {Image} from "../../components/image";
import {Icons} from "./components/card-icons";
import BidCountdown from "./components/bid-countdown";
import {AUCTIONSTATUSENUM, getStatus} from "../product-block";
import WebsiteFieldRenderer from "../../components/website-field-renderer";

export const Design1 = memo(({product, id, ...props}) => {
    const salesEvent = product.salesEvent;
    const startDate = salesEvent?.startDate;
    const endDate = salesEvent?.endDate;
    const [secondsRemaining, setSecondsRemaining] = useState(salesEvent?.secondsRemaining)
    const [secondsToStart, setSecondsToStart] = useState(salesEvent?.secondsToStart)

    const [status, setStatus] = useState(() => getStatus(salesEvent, startDate, endDate, secondsToStart, secondsRemaining));
    const nowRef = useRef(Date.now());
    const [now, setNow] = useState(nowRef.current);

    const context = useContext(WebsiteContext)
    const imageUrl = product?.images && product?.images.length > 0
        ? product?.imagesState?.[0]?.__cx__?.key || product?.images?.[0]
        : 'https://dr1k2g3wmnols.cloudfront.net/images/image-placeholder.png';

    useEffect(() => {
        nowRef.current = Date.now();
        setNow(nowRef.current);
    }, []);

    useEffect(() => {
        setStatus(() => getStatus(salesEvent, startDate, endDate, secondsToStart, secondsRemaining));
    }, [secondsRemaining]);

    return (
        <a
            key={id}
            href={`product/${product?.uuid}/${product?.title.toLowerCase().trim().replace(/[^a-z0-9-\/\s]/g, '').replace(/\s+/g, '-')}`}
            className={classNames(
                "group relative flex flex-col overflow-hidden rounded-theme border border-gray-200 bg-white cursor-pointer",
                props.className
            )}>
            <div className={classNames(
                aspectRatio(props),
                "overflow-hidden"
            )}>
                <Image
                    src={imageUrl}
                    alt={product?.title}
                    className="h-full w-full object-cover object-center sm:h-full sm:w-full lazyload lazymotion"
                />
            </div>
            <div className="flex flex-1 flex-col p-4">
                <h6 className="text-sm font-bold text-gray-900 !mb-1">
                    {product?.title}
                </h6>
                {product?.cardDetails && product?.cardDetails.length > 0 &&
                    <div className="grid grid-cols-2 gap-2 my-2">
                        {product.cardDetails.map((data, i) => {
                            const field = product?.userDefinedFieldsets.find(f => f.id === data.field);
                            const Icon = Icons.find(icon => icon.value === data.icon);

                            return (
                                <div className="flex items-start" key={data.field + i}>
                                    {data?.icon && <Icon.icon className={classNames(
                                        Icon.type === 'outline' ? "stroke-black" : null,
                                        Icon.type === 'solid' ? "fill-black" : null,
                                        "w-5 h-5 flex-none"
                                    )}/>}
                                    <span
                                        className="ml-1 text-gray-900 text-sm">{field?.prefix} <WebsiteFieldRenderer
                                        component={"DisplayView"}
                                        fields={[field]}
                                        values={product}/> {field?.suffix}</span>
                                </div>
                            )
                        })}
                    </div>
                }
                <div className="flex flex-1 flex-col justify-end">
                    {product.hidePriceOnWebsite && !product.salesEvent ? null :
                        <div className={"border-t mt-2 mb-3"}></div>}
                    <div className={"text-gray-900"}>
                        {product.salesEvent
                            ? <>
                                {status === AUCTIONSTATUSENUM.NOT_STARTED &&
                                    <>Starts in <BidCountdown
                                        salesEvent={product.salesEvent}
                                        date={now + (secondsToStart * 1000)}
                                        onComplete={() => {
                                            // Add code to trigger the next bid count down seconds remaining.
                                            nowRef.current = Date.now();
                                            setNow(nowRef.current);
                                            setSecondsToStart(0)
                                            setStatus(AUCTIONSTATUSENUM.OPEN);
                                        }}/></>
                                }
                                {status === AUCTIONSTATUSENUM.OPEN && <BidCountdown salesEvent={product.salesEvent}
                                                                                    date={now + (secondsRemaining * 1000)}
                                                                                    onComplete={() => {
                                                                                        // Add code to trigger the next bid count down seconds remaining.
                                                                                        nowRef.current = Date.now();
                                                                                        setNow(nowRef.current);
                                                                                        setSecondsRemaining(0)
                                                                                        setStatus(AUCTIONSTATUSENUM.ENDED);
                                                                                    }}
                                />}
                                {(status === AUCTIONSTATUSENUM.ENDED) &&
                                    <span className={"text-red-500"}>Bidding Ended</span>}
                                {(status === AUCTIONSTATUSENUM.CLOSED) &&
                                    <span>Instantly Won</span>}
                            </>
                            : !product?.hidePriceOnWebsite && <>
                            {product?.discountedSellingPrice ? (<span className={"font-semibold mr-1"}>
                                        <Currency.DisplayValue
                                            currency={context.currency}
                                            value={product?.discountedSellingPrice}
                                            emptyPlaceholder={""}/>
                                    </span>) : null}
                            <span
                                className={classNames(product?.discountedSellingPrice ? "line-through opacity-80 text-sm" : "font-semibold")}>
                                    {product?.sellingPrice
                                        ? <Currency.DisplayValue
                                            currency={context.currency}
                                            value={product?.sellingPrice}
                                            emptyPlaceholder={""}/>
                                        : "Price on Application"}
                                </span>
                            {product?.sellingPriceDisclaimer ? (<span className="ml-1 text-gray-500 text-sm">
                                        {product?.sellingPriceDisclaimer}
                                    </span>) : null}
                        </>
                        }
                    </div>
                </div>
            </div>
        </a>
    )
})

export default Design1;