import {forwardRef, useContext, useEffect, useState, useRef} from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _sortBy from 'lodash/sortBy';
import _startCase from 'lodash/startCase';

import FormContext from "../form-context";
import {evaluate, resolveUrlParams} from "../utils";

import ExclamationCircleIcon from '@heroicons/react/solid/ExclamationCircleIcon'

const toGroups = (props, options) => {
    return (props.sorted ? _sortBy(options, ['group.label', 'label']) : options)
        .reduce((result, option) => {
            const groupLabel = option.group?.label;
            if (!result._groups) {
                result._groups = [groupLabel];
            } else if (!result._groups.includes(groupLabel)) {
                result._groups.push(groupLabel);
            }

            const index = result._groups.indexOf(groupLabel);
            const group = result[index] || [];

            group.push(option);
            result[index] = group;

            return result;
        }, []);
}

export const populateState = (options = [], props, setGroups, append) => {
    if (append) {
        setGroups(previous => toGroups(props, [].concat(...previous, options)));
    } else {
        setGroups(toGroups(props, options));
    }
}

const getSelected = (ref, props, groups) => {
    const flatOptions = [].concat(...groups);

    // Account for the placeholder at the top. This will offset so that the selected index will match up
    flatOptions.unshift({});
    
    if (props.multiple) {
        return Array
            .from(ref.current.options)
            .filter(option => option.selected && !option.disabled)
            .reduce((result, option) => {
                if (flatOptions[option.index]?.value) {
                    result.original.push(flatOptions[option.index]?.original);
                    result.value.push(flatOptions[option.index]?.value);
                }
                return result;
            }, {original: [], value: []})
    } else {
        return {
            original: flatOptions[ref.current.selectedIndex]?.original,
            value: flatOptions[ref.current.selectedIndex]?.value,
        }
    }
}

Select.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    href: PropTypes.string,
    groupFieldPath: PropTypes.string,
    labelFieldPath: PropTypes.string,
    valueFieldPath: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    multiple: PropTypes.bool,
    sorted: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
    autoCapitalize: PropTypes.bool,
    autoComplete: PropTypes.string,
};

Select.defaultProps = {
    sorted: false,
    multiple: false,
    options: [],
    groupFieldPath: 'group.label',
    labelFieldPath: 'label',
    valueFieldPath: 'value',
    onChange: () => {},
    onLoad: options => options,
    autoCapitalize: false,
    autoComplete: ''
}

function Select({forwardedRef, ...props}) {
    const context = useContext(FormContext);
    const selectRef = useRef(forwardedRef);
    const [groups, setGroups] = useState([]);
    const [value, setValue] = useState(props.value);
    let placeholder = props.placeholder;
    if ((placeholder === undefined || placeholder === null)) {
        if (props.label) {
            if (props.label.toLowerCase().startsWith('select')) {
                placeholder = props.label;
            } else {
                placeholder = `Select ${props.label.toLowerCase()}`
            }
        } else {
            placeholder = 'Select...';
        }
    }

    useEffect(() => {
        let cancelled = false;
        const [promise, cancelToken] = LoadOptions(props.href, props, null, context);
        promise
            .then(([options]) => {
                if (cancelled) return;
                options = props.onLoad(options) || options;
                populateState(options, props, setGroups)
            })
            .catch((err) => {
                if (!axios.isCancel(err)) console.log(err);
            });

        return () => {
            cancelled = true;
            cancelToken.cancel();
        }
    }, [props.href, props.options]);

    useEffect(() => {
        selectRef.current.__cx__ = getSelected(selectRef, props, groups);
    }, [groups]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <div className="w-full relative">
            <select
                ref={selectRef}
                autoComplete={props.autoComplete === true ? 'on' : 'off'}
                autoCapitalize={props.autoCapitalize === true ? 'on' : 'off'}
                className={classNames(
                    `px-1.5 py-1 shadow-sm block w-full text-sm rounded`,
                    props.disabled ? 'bg-gray-50 border-gray-300 placeholder-gray-400 text-gray-400 opacity-100' : null,
                    props.error && !props.disabled ? 'focus:ring-error-500 focus:border-error-500 border-error-300' : null,
                    !props.error && !props.disabled ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300' : null,
                    props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null,
                    !props.multiple ? "pr-6" : null,
                    props.className
                )}
                id={props.id}
                multiple={props.multiple}
                size={props.multiple ? 6 : null}
                name={props.name}
                required={props.required}
                disabled={props.disabled}
                value={value}
                data-error-message={props.errorMessage}
                onInvalid={props.errorMessage ? (e) => {
                    e.target.setCustomValidity("");
                    if (!e.target.validity.valid) {
                        e.target.setCustomValidity(props.errorMessage)
                    }
                } : null}
                onChange={e => {
                    const selected = getSelected(selectRef, props, groups);
                    e.target.__cx__ = selected;
                    setValue(selected.value);
                    props.onChange(e, props.name, selected.value);
                }}
            >
                <option label={placeholder} />
                {groups.map(group => {
                    const options = group
                        .filter(option => !(evaluate(props.value, option.showsWhen, context.values) === false))
                        .map(option => (
                            <option
                                key={`${option.label}_${option.value}`}
                                label={option.label}
                                value={option.value}
                                disabled={option.disabled}
                                id={option.original?.id}
                            >{option.label}</option>
                        ));
                    const groupLabel = group[0]?.group?.label;
                    return !(groupLabel === null || groupLabel === undefined) ? (
                        <optgroup key={groupLabel} label={groupLabel}>
                            {options}
                        </optgroup>
                    ) : options;
                })}
            </select>
            {props.error ? (
                <div className={classNames(
                    "absolute inset-y-0 right-0 flex pointer-events-none",
                    !props.multiple ? "items-center pr-6" : "pr-1.5 pt-1"
                )}>
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                </div>
            ) : null}
        </div>
    )
}

function DisplayValue(props) {
    const option = (props.options || []).find(option => option.value === props.value);
    let value = option?.label || props.value || null;
    if (value && option?.group?.visible && option?.group?.label) {
        value += ` (${option?.group?.label})`;
    }
    
    return value;
}

function LoadOptions(href, props, cancelToken, context) {
    cancelToken = cancelToken || axios.CancelToken.source();

    let promise; 
    if (!href) {
        promise = Promise.resolve({data: props.options || []});
    } else {
        
        promise = axios.get(resolveUrlParams(href, {
            ...context.values,
            ...context.hrefUriParams
        }), {cancelToken: cancelToken.token})
    }

    promise = promise
        .then((result) => {
            if (_isArray(result.data)) {
                if (typeof result.data[0] !== 'object') {
                    return [
                        result.data.map((value) => ({label: _startCase(value), value, original: value}))
                    ];
                }
                const groupFieldPath = props.groupFieldPath || Select.defaultProps.groupFieldPath;
                const labelFieldPath = props.labelFieldPath || Select.defaultProps.labelFieldPath;
                const valueFieldPath = props.valueFieldPath || Select.defaultProps.valueFieldPath;
                return [
                    result.data.map((item) => {
                        const group = _get(item, groupFieldPath);
                        return {
                            label: _get(item, labelFieldPath, ''),
                            value: _get(item, valueFieldPath, ''),
                            group: group ? {label: group} : null,
                            showsWhen: item.showsWhen,
                            disabled: item.disabled,
                            original: item
                        }
                    })
                ];
            }

            return [[]];
        });
    return [promise, cancelToken];
}
const ForwardedComponent = forwardRef((props, ref) => {
    return <Select {...props} forwardedRef={ref} />
});

ForwardedComponent.LoadOptions = LoadOptions;
ForwardedComponent.DisplayValue = DisplayValue;

export default ForwardedComponent;