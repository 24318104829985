import classNames from "classnames";
import {AccountButton, handleBackgroundColour} from "../blocks/site-navigation-block";
import {backgroundColour} from "../utils/block-utils";
import {Image} from "./image";
import {WebsiteContext} from "../index";
import {useContext} from "react";

export default function SimpleSiteHeader() {
    const context = useContext(WebsiteContext)

    return (
        <header
            id={"block_navigation"}
            className={classNames(
                "w-full navigation-block-checkout sticky top-0",
                handleBackgroundColour(backgroundColour(context.theme?.header?.backgroundColour), 0))
            }>
            <div
                className={"mx-auto max-w-7xl px-4 sm:px-8 flex justify-between items-center flex-row h-[72px] py-2 sm:py-4"}>
                <div></div>
                <div className={"h-full"}>
                    <a href={"/"}
                       title={"Go Home"}
                       className={"h-full w-full flex items-center"}
                    >
                        {context.theme.header?.logo ? (
                            <Image
                                pictureClassName={"h-full w-full max-w-[144px] sm:max-w-[320px] flex items-center"}
                                alt={"Go Home"}
                                width={320}
                                src={context.theme.header?.logo}
                                className={"h-auto max-w-full max-h-full"}
                            />
                        ) : (
                            <h1 className={"!text-2xl sm:!text-[2.2rem] !mb-0 text-center"}>{context.website?.siteName}</h1>
                        )}
                    </a>
                </div>
                {context.website?.store?.accountManagementEnabled ? <AccountButton className={""}/> : <div></div>}
            </div>
        </header>
    )
}