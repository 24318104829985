import {useContext} from 'react';
import classNames from 'classnames';
import _isArray from "lodash/isArray";
import {UploaderContext} from "../uploader-context";
import _isFunction from "lodash/isFunction";

ImageUploaderUnsplashPicker.propTypes = {};

ImageUploaderUnsplashPicker.defaultProps = {};

export default function ImageUploaderUnsplashPicker() {
    // TODO: Move the current unsplash picker implementation here and change it to use child form feature 
    //  in @autocx/forms
    return null;
};

/**
 * Renders a clickable element that will show the unsplash picker
 * 
 * @returns {JSX.Element}
 * @constructor
 */
ImageUploaderUnsplashPicker.Trigger = function (props) {
    const context = useContext(UploaderContext);
    
    return (
        <span onClick={(e) => {
                
                e.target.dispatchEvent(new CustomEvent('cx:unsplash-picker-initialise', {
                    bubbles: true,
                    cancelable: true,
                    detail: {
                        search: context.unsplash.search,
                        searchSecondary: context.unsplash.searchSecondary,
                        multiple: props.multiple !== undefined && props.multiple !== null 
                            ? props.multiple 
                            : context.multiple,
                        onCancel: props.onCancel,
                        onChange: (value) => props.onChange(mapSelection(context, value)),
                        onDone: (value) => props.onDone(mapSelection(context, value))
                    }
                }));
                
                if (_isFunction(props.onClick)) {
                    props.onClick(e);   
                }
            }}
            className={classNames('grow block menu-item')}
        >
            Search Photos       
        </span>
    )
}

function mapSelection(context, value) {
    return (!_isArray(value) ? [value] : value)
        .map(photo => {
            const paramsObj = {};
            const url = new URL(photo.urls.full)
            const params = new URLSearchParams(url.search);
            const iterator = params.entries();
            let item = iterator.next()
            while (!item.done) {
                paramsObj[item.value[0]] = item.value[1];

                item = iterator.next();
            }

            // Merge cdn settings together
            Object.assign(paramsObj, context.unsplash);

            const newParams = new URLSearchParams();
            Object.keys(paramsObj).forEach(key => {
                newParams.append(key, paramsObj[key])
            });

            url.search = newParams.toString();

            return ({id: photo.id, key: url.toString()})
        });
}