import {useEffect, useState, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _set from "lodash/set";

import FormLayout from "../layouts/form-layout";
import FormContext from "../form-context";
import {nanoid} from "nanoid/non-secure";
import {validate} from "../utils";
import _findIndex from "lodash/findIndex";
import DividerAddButton from "./divider-add-button";
import {arrayMove} from "@dnd-kit/sortable";

FieldsetBuilder.propTypes = {
    className: PropTypes.string,
    fieldset: PropTypes.object,
    editing: PropTypes.bool,
    onActionClick: PropTypes.func,
};

FieldsetBuilder.defaultProps = {
    onActionClick: () => {}
};

/**
 * Manages the edit state of a fieldset.  The usage of this component is complicated in a sense that we a rendering
 * this component in a FormLayout component and using a FormLayout component to render the settings form of the fieldset.
 * 
 * It will take the current form context and create a new one to manage this fieldset settings form instance.
 * 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FieldsetBuilder(props) {
    // Get a reference to the parent form context. When this component is rendered in a form layout the origin of the
    // render will come from the form component. This will return the context of the whole form. 
    const parentFormContext = useContext(FormContext);
    const formRef = useRef();
    const [context, setContext] = useState(() => {
        return {
            ...parentFormContext,
            values: props.editing ? {...props.fieldset} : {fields: []},
            errors: {},
            original: null,
            validate: (ref) => {
                return validate((ref || formRef).current, setContext);
            }
        }
    });
    const [editing, setEditing] = useState(props.editing);

    const fieldsets = [
        {
            label: props.editing ? 'Edit fieldset' : 'Add fieldset',
            fields: [
                {label: "Label", type: "text", name: "label", disabled: context.values.system},
                {label: "Description", type: "textarea", name: "description", disabled: context.values.system},
                {label: "Can collapse section?", type: "switch", name: "collapsable", disabled: context.values.system},
                {label: "Default expanded?", type: "switch", name: "expanded", disabled: context.values.system, showsWhen: 'collapsable === true'},
            ]
        },
        {
            divider: true
        },
        {
            label: "Fields",
            editing: true,
            extendable: true,
            sortable: true,
            childrenOnly: false,
            fields: context.values.fields
        }
    ];
    
    useEffect(() => {
        if (formRef.current && editing) {
            setTimeout(function () {
                formRef.current.parentElement.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
            }, 50);
        }
    }, [formRef, editing]);
    
    return (
        <>
            <div className={classNames(
                "flex w-full",
                !editing ? "justify-center items-center cursor-pointer" : null,
                editing ? "flex-col bg-panels-200 rounded-md p-4" : null,
                props.className
            )}>
                {!editing ? (
                    <DividerAddButton label={"Add Fieldset"} onClick={() => {
                        setEditing(true);
                    }}/>
                ) : null}
                {editing ? (
                    <FormContext.Provider value={context}>
                        <FormLayout
                            extendable={props.extendable}
                            className={"w-full"}
                            ref={formRef}
                            layout={props.layout === 'panel-style' ? 'simple-stacked' : 'two-column'}
                            fieldsets={fieldsets}
                            userDefinedFieldsetsScope={props.userDefinedFieldsetsScope}
                            builder={true}
                            actions={[
                                {label: "Done", primary: true},
                                props.editing && !props.fieldset.system ? {label: "Remove", destructive: true} : null,
                                {label: "Cancel", back: true}
                            ].filter(action => !!action)}
                            onEditFieldSettings={(e, field) => {
                                setContext(previous => {
                                    const fields = [...previous.values.fields];
                                    const index = _findIndex(fields, {id: field?.id});
                                    if (index > -1) {
                                        fields[index].editing = true;

                                        setTimeout(() => {
                                            formRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
                                        }, 50);
                                    }
                                    return {...previous, values: {...previous.values, fields}};
                                });
                            }}
                            onFieldSortEnd={({oldIndex, newIndex, fieldset}) => {
                                setContext(previous => {
                                    let fields = [...previous.values.fields];
                                    fields = arrayMove(fields, oldIndex, newIndex)

                                    return {...previous, values: {...previous.values, fields}};
                                });
                            }}
                            onFieldSettingsAction={(e, action, values, field) => {
                                setContext(previous => {
                                    const fields = [...previous.values.fields];
                                    const index = _findIndex(fields, {id: field?.id});
                                    if (index > -1) {
                                        if (action.primary) {
                                            fields[index] = values;
                                        } else if (action.destructive) {
                                            fields.splice(index, 1)
                                        } else {
                                            fields[index].editing = false;
                                        }
                                    } else if (action.primary) {
                                        fields.push(values);
                                    }

                                    return {...previous, values: {...previous.values, fields}};
                                });
                            }}
                            onFieldChange={(e, name, value) => {
                                setContext(previous => {
                                    const result = {...previous.values};
                                    _set(result, name, value);

                                    return {...previous, values: {...result}};
                                });
                            }}
                            onActionClick={(e, action) => {
                                e.preventDefault();
                                e.stopPropagation();

                                let values = null;
                                if (action.primary) {
                                    if (!validate(formRef.current, setContext)) {
                                        return;
                                    }

                                    const id = context.values.id || `cfs_${nanoid()}`;
                                    values = {extendable: true, ...context.values, id};
                                }

                                setEditing(false);
                                setContext({
                                    values: props.editing ? {...props.fieldset} : {fields: []},
                                    errors: {}
                                });
                                
                                props.onActionClick(e, action, values);
                            }}
                        />
                    </FormContext.Provider>
                ) : null}
            </div>
        </>
    );
};