import {useEffect, useRef} from "react";
import {FocusedImage} from "image-focus";
import classNames from "classnames";
import useCdnUrl from '../hooks/cdn-hook';


export function BgImage(props) {
    if (!props.values) return null;
    if (!props.values.image) return null;

    const imageRef = useRef();
    const focus = props.values.imageState?.focus ?? {x: 0, y: 0};

    useEffect(() => {
        if (!imageRef.current) return;
        if (!focus) return;

        let focusImage = new FocusedImage(imageRef.current, {
            updateOnContainerResize: true,
            focus: focus
        });

        const onLoad = () => {
            if (imageRef.current) {
                imageRef.current.removeEventListener('load', onLoad);
                imageRef.current.classList.remove('invisible');
            }
        }
        imageRef.current.addEventListener('load', onLoad);

        return () => {
            imageRef.current && imageRef.current.removeEventListener('load', onLoad);
            focusImage.stopListening();
            focusImage = null;
        }
    }, [imageRef.current, focus]);

    return (
        <div className="w-full h-full !absolute overflow-hidden">
            {props.hasOverlay &&
                <div className={"!bg-black/[.3] absolute z-[1] w-full min-h-screen"}></div>
            }
            <img ref={imageRef} id={props.values.imageState?.__cx__?.id ? `image_${props.values.imageState.__cx__.id}` : null}
                 data-src={useCdnUrl(props.values.imageState?.__cx__?.key || props.values.image)}
                 alt={props.values?.altText}
                 className={classNames("lazyload lazymotion absolute object-center object-cover invisible max-w-none", props.className)}
                 style={props.style}
            />
        </div>
    )
}