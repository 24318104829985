import classNames from "classnames";

import Block from '../block';
import {
    backgroundColour,
    textAlignment,
    height,
    textPosition,
    backgroundPattern,
    columns,
    setTitleDecorationByClass, 
    setTitleDecorationByElement
} from "../../utils/block-utils";
import Action from "../../components/action";

export default function Design1(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props): null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex items-center text-block",
                height(props),
                textAlignment(props),
                textPosition(props), props.values?.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props): null,
                props.values.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames(
                    'flex-col w-full',
                    props.values?.options?.textPosition === 3 ? null : 'sm:flex-row sm:w-5/12'
                )}>
                    {props.values?.title ? (
                        <>
                        <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                        {props.values?.options?.titleDecoration &&
                            setTitleDecorationByElement(props.values?.options?.titleDecoration)
                        }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                           className={classNames("rich-text max-w-none", columns(props))}
                        />
                    ) : null}
                    <Action className={"mt-6"} {...props.values} title={null} />
                </div>
            </div>
        </Block>
    )
}
