import {useEffect, useContext, useRef, useState} from 'react';
import PropTypes from "prop-types";

import {UploaderContext} from "../uploader-context";
import ImageUploaderFilePreview from "../previews/image-uploader-preview";
import {getFileSource} from "../uploader-utils";
import {Dialog} from '@headlessui/react'
import _cloneDeep from "lodash/cloneDeep";
import {FormContext} from "../../../../index";
import _find from "lodash/find";
import classNames from "classnames";

PinturaFullEditor.propTypes = {
    file: PropTypes.object.isRequired,
};

PinturaFullEditor.defaultProps = {};

export default function PinturaFullEditor(props) {
    const PINTURA_EDITOR_OPTIONS = {
        class: 'absolute z-10 inset-0',
        enableButtonExport: true,
        enableButtonRevert: false,
        enableNavigateHistory: true,
        enableButtonResetHistory: false,
        enableTransparencyGrid: true,
        cropEnableRotationInput: true,
        cropEnableZoomAutoHide: true,
        cropEnableZoomMatchImageAspectRatio: true,
        cropEnableButtonFlipHorizontal: true,
        cropEnableButtonRotateLeft: true,
        cropAutoCenterImageSelectionTimeout: 1000,
        cropEnableRotateMatchImageAspectRatio: 'always'
    };
    
    const [isOpen, setIsOpen] = useState(true)
    const formContext = useContext(FormContext);

    const context = useContext(UploaderContext);
    const imageUploaderRef = useRef();
    const containerRef = useRef();
    const imageRef = useRef();
    const editorRef = useRef();
    const pinturaAspectRatio = [];
    
    const closePintura = (cancel) => {
        setIsOpen(false);
        if (cancel) props.file.update({status: 'ready'})
    };
    
    const getAspectRatio = () => {
        if(!formContext.values?.values?.options?.imageAspectRatio) return [];
        
        const fieldsets = [];
        let options;
        (formContext?.parentContext && formContext?.parentContext?.fieldsets) ? fieldsets.push(...formContext?.parentContext?.fieldsets) : fieldsets.push(...formContext.fieldsets) 
        
        fieldsets.some((fieldset) => {
            if (_find(fieldset.fields, {name: "values.options.imageAspectRatio"})) options = _find(fieldset.fields, {name: "values.options.imageAspectRatio"})
        })
        
        return options
    };

    if (props.objectFit === 'contain') {
        PINTURA_EDITOR_OPTIONS.imageCropAspectRatio = undefined
        PINTURA_EDITOR_OPTIONS.cropEnableImageSelection = false
        PINTURA_EDITOR_OPTIONS.cropEnableRotationInput = false;
        PINTURA_EDITOR_OPTIONS.cropEnableZoom = false;
        PINTURA_EDITOR_OPTIONS.cropEnableZoomAutoHide = false;
        PINTURA_EDITOR_OPTIONS.cropEnableZoomMatchImageAspectRatio = true;
        PINTURA_EDITOR_OPTIONS.utils = [
            'filter',
            'finetune',
            'annotate',
            'decorate',
            'sticker',
            'frame',
            'redact',
        ];
    } else {
        PINTURA_EDITOR_OPTIONS.imageCropAspectRatio = formContext.values?.values?.options?.imageAspectRatio ? formContext.values.values.options.imageAspectRatio : props.file?.pintura?.imageState?.cropAspectRatio;
        getAspectRatio()?.options?.forEach((option) => {
            pinturaAspectRatio.push([option.value, option.label])
        });

        PINTURA_EDITOR_OPTIONS.cropSelectPresetOptions = (pinturaAspectRatio.length > 0) ? pinturaAspectRatio : [[undefined, "Custom"], [1, "1:1"], [1.3333333333, "4:3"], [1.7777777778, "16:9"], [0.75, "3:4"], [0.5625, "9:16"]];
    }
    
    // Handles loading the editor state in the control
    useEffect(() => {
        let processor;
        // Dynamically import pintura
        import('pintura').then(({appendDefaultEditor, createNode}) => {
            window.requestAnimationFrame(() => {
                // Initialise the editing UI
                editorRef.current = appendDefaultEditor(containerRef.current, {
                    ...PINTURA_EDITOR_OPTIONS,
                    imageState: {
                        ...props.file.pintura.imageState,
                        cropAspectRatio: PINTURA_EDITOR_OPTIONS.imageCropAspectRatio,
                    },
                    src: getFileSource(props.file),
                    willRenderToolbar: (toolbar, env, redraw) => {
                        return [
                            createNode('div', 'PinturaNavGroup', {class: 'PinturaNavGroup'},
                                [
                                    createNode('Button', 'close-btn', {
                                        title: 'Close button',
                                        class: 'PinturaButtonIconOnly PinturaNavSet',
                                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M18 6L6 18M6 6l12 12"></path></g></g></svg>',
                                        onclick: () => closePintura(true)
                                    }),
                                    createNode('Button', 'reset-btn', {
                                        title: 'Reset button',
                                        class: 'PinturaButtonIconOnly PinturaNavSet',
                                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M7.388 18.538a8 8 0 10-2.992-9.03"></path><path fill="currentColor" d="M2.794 11.696L2.37 6.714l5.088 3.18z"></path><path d="M12 8v4M12 12l4 2"></path></g></g></svg>',
                                        onclick: () => {
                                            // update the image with the newly received transparent image
                                            editorRef.current?.loadImage(getFileSource(props.file), {}).then(() => {});
                                        }
                                    })
                                ]),
                            ...toolbar,
                        ];
                    },
                    imageWriter: {
                        store: (state, options, onprogress) => {
                            // Update the state of the file so that we can prerender the preview state
                            return props.file.update({
                                pintura: {
                                    // There are more properties then needed on the state variable
                                    // so object deconstruction is not used here for that reason 
                                    src: state.src,
                                    dest: state.dest,
                                    imageState: state.imageState,
                                    blob: state.blob,
                                }
                            })
                                .then((file) => {
                                    // Ignores setting the status to `uploading` as the pintura editor will manage the
                                    // upload progress state then when successful will close the editor
                                    return file.upload(null, (progress, event) => {
                                        onprogress(event);
                                    }, true);
                                })
                                // Finally return the file so that in the process method we can reference it
                                .then((file) => {
                                    state.file = file;
                                    state.file._links = _cloneDeep(props.file._links)
                                    state.file.key = _cloneDeep(props.file.key)
                                    return state;
                                });
                        },
                        outputProps: ['src', 'dest', 'imageState', 'blob', 'file'],
                        targetSize: context.variant === 'favicon' ? {
                            width: 192,
                            height: 192
                        } : undefined
                    }
                });

                editorRef.current?.on('process', (result) => {
                    result.file.update({status: 'ready'}).then(() => {
                        if (result.imageState?.cropAspectRatio && formContext.values?.values?.options?.imageAspectRatio) {
                            const newValuesObj = {
                                values: {
                                    ...formContext.values,
                                    values: {
                                        ...formContext.values.values,
                                        options: {
                                            ...formContext.values.values.options,
                                            imageAspectRatio: result.imageState.cropAspectRatio
                                        }
                                    }

                                }
                            }

                            if (formContext.parentContext) formContext.parentContext.setContext({...newValuesObj});
                            formContext.setContext({...newValuesObj});
                            props.onChange();
                        }
                        closePintura(false);
                    })
                });
            
                editorRef.current?.on('load', () => {
                    imageUploaderRef.current.classList.remove('invisible')
                    // Update the aspect ratio of the editor
                    editorRef.current.imageCropAspectRatio = PINTURA_EDITOR_OPTIONS.imageCropAspectRatio;
                });
                
            });
        });

        return () => {
            // Clean up editor
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }

            // Clean up form processor
            if (processor) {
                processor.destroy();
                processor = null;
            }
        }
    }, []);

    return (
        <Dialog className={"flex justify-center items-center absolute w-full h-full top-0 left-0 z-[10000]"}
                open={isOpen} onClose={() => {
            closePintura(true)
        }}>
            <div className="fixed inset-0 bg-[#212121]" aria-hidden="true" />
            <Dialog.Panel className="w-full h-full">
                <ImageUploaderFilePreview
                    imageUploaderRef={imageUploaderRef}
                    containerRef={containerRef}
                    imageRef={imageRef}
                    objectFit={"cover"}
                    objectPosition={"center"}
                    {...props}
                    className={classNames(...props.className, 'h-full invisible')}
                />
            </Dialog.Panel>
        </Dialog>
    );
}