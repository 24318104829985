import classNames from 'classnames';
import {
    aspectRatio,
    backgroundColour
} from "../../utils/block-utils";
import Block from '../block'
import {SwiperElement} from "../../components/swiper-element";

Design2.propTypes = {

};

Design2.defaultProps = {};


/**
 * Gallery with carousel - Design '1' in form spec
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Design2(props) {
    return (
        <Block {...props}
               className={classNames("overflow-hidden", props.className,
                   aspectRatio(props), backgroundColour(props.values?.options?.backgroundColour))
               }>
            <SwiperElement {...props} aspectRatio={aspectRatio(props)} />
        </Block>
    )
}