import classNames from "classnames";
import Block from '../block'
import {
    backgroundColour,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    frameType,
    marginForTextAlignment,
    backgroundPattern, aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import {useEffect} from "react";
import Video from "../../components/video";
import Action from "../../components/action";
import {Image} from "../../components/image";

export default function Design1(props) {
    const flexDirection = flexDirectionBasedOnImagePosition(props);
    const desktopWidth = props.websiteContext?.rootPage?.nodeType === 'blog' ? 'sm:w-full' : 'sm:w-3/5'

    let radius = '0';
    useEffect(() => {
        if (props?.pageRef?.current) radius = getComputedStyle(props.pageRef.current)?.getPropertyValue('--container-roundness');
    },[props.pageRef]);

    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props): null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex items-center",
                textAlignment(props),
                flexDirection,
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props): null,
                props.values.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames(
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : `w-full ${desktopWidth}`)}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}                    
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                    <Action className={props.values.action?.actionStyle === 'link' ? "mt-3" : "mt-6"} {...props.values} title={null} />
                </div>
                    {props.values?.options?.mediaType === 'image' || props.values?.options?.mediaType === 'video' ? (
                        <div style={{borderRadius: radius === '0px' ? '0' : ''}}
                             className={classNames(
                                 "relative rounded-theme",
                                 flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : `w-full ${desktopWidth}`,
                                 imagePosition(props),
                                 frameType(props) === 'border' ? "border-8 border-base bg-base" : '',
                                 frameType(props) === 'circle' ? 'clip-circle' : '',
                                 frameType(props) === 'slant' ? 'clip-slant !rounded-none' : ''
                                 )}>
                            {props.values?.options?.mediaType === 'image' && props.values?.image ? (
                            <Image
                                id={props.values.imageState?.__cx__?.id ? `image_${props.values.imageState?.__cx__?.id}` : null}
                                alt={props?.values?.altText}
                                src={props.values.imageState?.__cx__?.key || props.values?.image}
                                position={props.position}
                                className={classNames(
                                     "lazyload lazymotion relative z-[1] w-full h-full object-center", 
                                    aspectRatio(props),
                                    objectFit(props.values?.options?.imagePresentation),
                                    frameType(props) === 'slant' ? '!rounded-none' : '',
                                    frameType(props) === 'border' ? "rounded-theme-half" : ''
                                )}
                            />
                            ) : null}
                            {props.values?.options?.mediaType === 'video' ? (
                                <Video {...props.values?.video} className={"z-10 relative"}/>
                            ) : null}
                            {frameType(props) === 'offsetBorder' ? (
                                <div className={"absolute w-full h-full left-3 z-0 top-3 bottom-0 rounded-theme bg-base"}/>
                            ) : null}
                        </div>
                    ) : null}
                </div>
        </Block>
    )
}