import Design1 from './design-1';
import Design2 from "./design-2";
import useApplyUserDefinedListOverrides from "../../hooks/use-user-defined-list-sort";

GalleryBlock.propTypes = {
    
};

GalleryBlock.defaultProps = {};

export default function GalleryBlock(props) {
    const values = useApplyUserDefinedListOverrides(props)
    
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} values={values} />);
        case 1 :
            return (<Design2 {...props} values={values} />);
        default:
            return (<Design1 {...props} values={values} />);
    }
};