import { arrayMove } from "@dnd-kit/sortable"
import _isNumber from 'lodash/isNumber'

// `!global` handles SSR
export const iOS = !global && /iPad|iPhone|iPod/.test(navigator.platform)

function getDragDepth(offset, indentationWidth) {
    return Math.round(offset / indentationWidth)
}

export function getProjection(items, activeId, overId, dragOffset, indentationWidth) {
    const overItemIndex = items.findIndex(({ id }) => id === overId)
    const activeItemIndex = items.findIndex(({ id }) => id === activeId)
    const activeItem = items[activeItemIndex]
    const newItems = arrayMove(items, activeItemIndex, overItemIndex)
    const previousItem = newItems[overItemIndex - 1]
    const nextItem = newItems[overItemIndex + 1]
    const dragDepth = getDragDepth(dragOffset, indentationWidth)
    const projectedDepth = activeItem.depth + dragDepth
    const maxDepth = getMaxDepth({ previousItem, activeItem })
    const minDepth = getMinDepth({ nextItem, activeItem })
    let depth = projectedDepth

    if (projectedDepth >= maxDepth) {
        depth = maxDepth
    } else if (projectedDepth < minDepth) {
        depth = minDepth
    }

    return { depth, maxDepth, minDepth, parent: getParent(), index: overItemIndex }

    function getParent() {
        if (depth === 0 || !previousItem) {
            return null
        }

        if (depth === previousItem.depth) {
            return previousItem.parent
        }

        if (depth > previousItem.depth) {
            return previousItem
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find(item => item.depth === depth)?.parent

        return newParent ?? null
    }
}

function getMaxDepth({ previousItem, activeItem }) {
    // Items that have children can only be positioned at the current level they reside in
    if (activeItem.children?.length) {
        return activeItem.depth;
    }
    
    // Handle if the items 
    if (_isNumber(activeItem.settings.maxDepth) && previousItem)
    {
        if ((previousItem.depth + 1) > activeItem.settings.maxDepth) {
            return activeItem.settings.maxDepth;
        }
    }
    
    return previousItem ? previousItem.depth + 1 : 0;
}

function getMinDepth({ nextItem, activeItem }) {
    if (_isNumber(activeItem.settings.maxDepth) && nextItem)
    {
        if (nextItem.depth > activeItem.settings.maxDepth) {
            return activeItem.settings.maxDepth;
        }
    }
    
    return nextItem ? nextItem.depth : 0;
}

function flatten(name, items = [], parent = null, depth = 0, types = {}) {
    return items.reduce((acc, item, index) => {
        const prefix = depth > 0 ? `${name}.children[${index}]` : `${name}[${index}]`;
        const flatteredItem = {
            ...item,
            settings: types[item.type] || types['default'] || {},
            children: item.children || [],
            parent: parent ? {
                ...parent,
                // Exclude the children from the parent so it stops a circular reference
                children: undefined
            } : null,
            depth,
            prefix,
            pathToSiblings: depth > 0 ? `${name}.children` : name,
            index
        };
        return [
            ...acc,
            flatteredItem,
            ...flatten(`${prefix}`, flatteredItem.children, flatteredItem, depth + 1, types)
        ];
    }, [])
}

export function flattenTree(name, items, types) {
    return flatten(name, items, undefined, undefined, types);
}

export function buildTree(flattenedItems, movedItem) {
    const root = { id: "root", children: [] }
    const nodes = { 
        [root.id]: root,
        
        // Handled when moving a node with children. The children should follow. Moving an item upward works because the
        // flattened items are in the correct order where the parent is first then children follow.
        // Moving the parent node downward causes the parent to be underneath the its children in the flat list so we
        // need to add the moved item first so that later on we can reference it when processing its children
        [movedItem.id]: {...movedItem.original, children: []}
    }
    
    for (const item of flattenedItems) {
        const parentId = item.parent?.id ?? root.id
        const node = movedItem.id !== item.id ? {...item.original, children: []} : nodes[movedItem.id];
        if (nodes[parentId]) {
            nodes[parentId].children.push(node);
        }
        
        if (!nodes[item.id]) {
            nodes[item.id] = node;
        }
    }

    return [
        root.children, 
        nodes[movedItem.id], 
        nodes[movedItem.parent?.id], 
        nodes[movedItem.parent?.id]?.children ?? nodes[root.id].children
    ];
}

export function findItemDeep(items, itemId) {
    for (const item of items) {
        const { id, children } = item

        if (id === itemId) {
            return item
        }

        if (children?.length) {
            const child = findItemDeep(children, itemId)

            if (child) {
                return child
            }
        }
    }

    return undefined
}

export function setProperty(items, id, property, setter) {
    for (const item of items) {
        if (item.id === id) {
            item[property] = setter(item[property])
            continue
        }

        if (item.children.length) {
            item.children = setProperty(item.children, id, property, setter)
        }
    }

    return [...items]
}

function countChildren(items, count = 0) {
    return items.reduce((acc, { children }) => {
        if (children.length) {
            return countChildren(children, acc + 1)
        }

        return acc + 1
    }, count)
}

export function getChildCount(items, id) {
    const item = findItemDeep(items, id)

    return item ? countChildren(item.children || []) : 0
}

export function removeChildrenOf(items, ids) {
    const excludeParentIds = [...ids]

    return items.filter(item => {
        if (item.parent && excludeParentIds.includes(item.parent.id)) {
            if (item.children.length) {
                excludeParentIds.push(item.id)
            }
            return false
        }

        return true
    })
}
