import {useContext, useRef} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {RadioGroup} from "@headlessui/react";

import {evaluate} from "../utils";
import FormContext from "../form-context";
import {Image} from "../../../websites/src/components/image";

CustomRadioGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    style: PropTypes.oneOf(['list', 'cards']),
    description: PropTypes.string,
    options: PropTypes.array.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    deselectable: PropTypes.bool,
    modified: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CustomRadioGroup.defaultProps = {
    options: [],
    style: 'list',
    onChange: () => {
    }
}

export default function CustomRadioGroup(props) {
    const context = useContext(FormContext);
    const inputRef = useRef();

    return (
        <RadioGroup
            value={props.value}
            className={classNames(props.className)}
            onChange={(value) => {
                if (props.disabled) {
                    return;
                }

                props.onChange({target: inputRef.current}, props.name, value);
            }}
            disabled={props.disabled}>
            <input
                ref={inputRef}
                id={props.id}
                type="text"
                className="hidden"
                name={props.name}
                placeholder={props.label}
                defaultValue={props.value}
                required={props.required}
                disabled={props.disabled}
                data-error-message={props.errorMessage}
            />
            {props.label ? (
                <RadioGroup.Label className="sr-only">{props.label}</RadioGroup.Label>
            ) : null}
            <div className={classNames(
                props.style === 'cards' ? "space-y-2 sm:space-y-0 sm:gap-2 sm:grid" : "",
                props.style === 'cards' ? `sm:grid-cols-${props.options.length > 3 ? 3 : props.options.length}` : "",
                props.style === 'list' ? "bg-white rounded -space-y-px shadow-sm" : "",
            )} onClick={e => {
                if (props.deselectable) {
                    setTimeout(() => {
                        const value = inputRef.current.value;
                        if (value && value === props.value) {
                            props.onChange({target: inputRef.current}, props.name, '');
                        }
                    });
                }
            }}>
                {props.options.map((option, index) => (
                    <RadioGroup.Option
                        key={`${option.label}_${index}`}
                        value={option.value}
                        className={({checked}) => classNames(
                            props.style === 'cards' ? "relative border block rounded focus:ring-primary-500 focus:border-primary-500 border-gray-300 bg-white shadow-sm px-1.5 py-1 cursor-pointer hover:border-gray-400 focus:outline-none" : "",
                            props.style === 'list' ? "relative border p-4 flex focus:outline-none" : "",
                            props.style === 'list' && index === 0 ? 'rounded-tl rounded-tr' : null,
                            props.style === 'list' && index === props.options.length - 1 ? 'rounded-bl rounded-br' : null,
                            props.disabled ? 'bg-gray-50 border-gray-200' : (props.error ? 'bg-error-50 border-error-200' : (checked ? 'bg-primary-50 border-primary-700' : 'border-gray-300')),
                            props.style === 'list' && checked ? 'z-10' : null,
                            !props.disabled ? 'cursor-pointer' : null,
                            evaluate(props.value, props.showsWhen, context.values) === false ? 'hidden' : null
                        )}
                    >
                        {({active, checked}) => (
                            <>
                                {props.style === 'list' && (<span
                                    className={classNames(
                                        'shrink-0 h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center',
                                        !checked ? (props.disabled ? 'bg-gray-100' : 'bg-white') : (props.disabled ? 'bg-gray-400' : (props.error ? 'bg-error-600' : 'bg-primary-600')),
                                        checked ? 'border-transparent' : (props.error ? 'bg-white border-error-300' : 'bg-white border-gray-300'),
                                        active && !props.disabled ? (props.error ? 'ring-2 ring-offset-2 ring-error-500' : 'ring-2 ring-offset-2 ring-primary-500') : null,
                                        !props.disabled ? 'cursor-pointer' : null,
                                    )}
                                    aria-hidden="true">
                                  <span className={classNames(
                                      "rounded-full w-1.5 h-1.5",
                                      !checked && props.disabled ? 'bg-grey-100' : 'bg-white')}/>
                                </span>)}
                                <div className={classNames(
                                    props.style === 'cards' ? "flex flex-row sm:flex-col text-sm items-center" : "",
                                    props.style === 'list' ? "ml-3 flex flex-col text-sm" : "",
                                )}>
                                    {option.image && props.style === 'cards' && (
                                        <Image pictureClassName="shrink-0 mr-2 sm:mr-0"
                                               className="object-contain max-h-12 sm:mb-1"
                                               width={200}
                                               cdnUrl={context?.fileUploadSettings?.cdnUrl}
                                               alt={option.label}
                                               src={option.image}/>
                                    )}
                                    <div>
                                        {option.label ? (
                                            <RadioGroup.Label
                                                as="span"
                                                className={classNames(
                                                    'block font-medium',
                                                    props.style === 'cards' ? 'sm:text-center' : '',
                                                    props.disabled ? 'text-gray-500' : (props.error ? 'text-error-900' : (checked ? 'text-primary-700' : 'text-gray-700'))
                                                )}
                                            >
                                                {option.label}
                                            </RadioGroup.Label>
                                        ) : null}
                                        {option.description ? (
                                            <RadioGroup.Description
                                                as="span"
                                                className={classNames(
                                                    props.style === 'cards' ? 'sm:text-center' : '',
                                                    'block',
                                                    props.disabled ? 'text-gray-400' : (props.error ? 'text-error-700' : (checked ? 'text-primary-700' : 'text-grey-500')),
                                                )}
                                            >
                                                {option.description}
                                            </RadioGroup.Description>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    );
}