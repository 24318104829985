import {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import Editor from "@monaco-editor/react";
import classNames from "classnames";

HtmlCodeEditor.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    language: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

HtmlCodeEditor.defaultProps = {
    onChange: () => {
    },
    rows: 8
};

export default function HtmlCodeEditor(props) {
    const inputRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    
    if (!Editor) {
        return null;
    }
    
    return (
        <div>
            <input
                id={props.id}
                ref={inputRef}
                tabIndex={-1}
                type="text"
                className="hidden"
                name={props.name}
                defaultValue={props.value}
                required={props.required}
                disabled={props.disabled}
                data-error-message={props.errorMessage}
            />
            {isOpen ? (
                <div className="fixed z-[1011] inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            ) : null}
            <div className={classNames(
                // Mobile styles
                isOpen ? "fixed z-[1011] top-1/2 bottom-px right-px inset-x-0 mx-auto max-w-7xl overflow-hidden flex flex-col rounded-tl-lg rounded-tr-lg" : null,
                // Small screens 
                isOpen ? "sm:inset-20 sm:rounded-lg sm:rounded-lg" : null,
                // Medium screens
                isOpen ? "md:inset-40" : null,
            )}>
                <div className={classNames(
                    "flex shrink-0 justify-between items-center py-3 px-6 border-b border-panels-300 bg-white",
                    !isOpen ? "hidden" : null 
                )}>
                    <div>
                        {props.label ? (
                            <h2 className={"font-semibold text-sm text-gray-900"}>{props.label}</h2>
                        ) : null}
                        {props.description ? (
                            <p>{props.description}</p>
                        ) : null}
                    </div>
                    <div className={"space-x-1"}>
                        <button type="button"
                                className="shadow-sm inline-flex justify-center py-1.5 px-3 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-primary-600 hover:bg-primary-700 focus:ring-primary-500"
                                onClick={() => setIsOpen(false)}>
                            Done
                        </button>
                    </div>
                </div>
                <div className={"relative w-full h-full"}>
                    <button type="button"
                            className={classNames(
                                "absolute z-10 right-1 bottom-1 shadow-sm inline-flex justify-center py-1.5 px-3 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-primary-600 hover:bg-primary-700 focus:ring-primary-500",
                                isOpen ? "hidden" : null
                            )}
                            onClick={() => setIsOpen(true)}>
                        Fullscreen
                    </button>
                    <Editor
                        className={"overflow-hidden"}
                        theme="vs-dark"
                        defaultPath={`file:///${props.id}.html`}
                        defaultValue={props.value}
                        language={props.language}
                        height={!isOpen ? (props.rows + 1) * 18 : '100%'}
                        loading={(
                            <div className={"flex w-full justify-center"}>
                                <svg className={"animate-spin h-5 w-5 text-primary-500"}
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            strokeWidth="4"/>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                </svg>
                            </div>
                        )}
                        onChange={(value) => {
                            props.onChange({target: inputRef.current}, props.name, value)
                        }}
                    />
                </div>
            </div>
        </div>
    );
};