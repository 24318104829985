import React, {useContext} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _get from "lodash/get";

import {isDisabled, isRequired} from "../utils";
import FormContext from "../form-context";

import Field from "./field";
import { kebabCase } from "lodash";

InputGroup.propTypes = {
    className: PropTypes.string,
    groupClassName: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onFieldChange: PropTypes.func.isRequired,
    optional: PropTypes.bool,
};

InputGroup.defaultProps = {
    optional: true,
};

export default function InputGroup(props) {
    const context = useContext(FormContext);
    const required = isRequired(props, context.values);
    const disabled = isDisabled(props, context.values);
    const isNotCheckbox = props.type !== 'checkbox' && props.type !== 'switch';
    const error = _get(context.errors, props.name, false);

    const hasLabel = !!props.label;
    const showEdit = props.editing && !props.system;
    const showOptional = props.optional && !props.editing && !required && !props.readOnly && !props.disabled && props.type !== 'radio' && props.type !== 'checkbox-list' && props.type !== 'colour-picker' && context.layout !== 'product-search-block' && !props.type.startsWith("plugins");
    const id = props.id || props.name;
    
    return (
        <div className={classNames(props.groupClassName, `grow control-${props.type}-${kebabCase(props.label)}`)}>
            {isNotCheckbox && context.layout !== 'product-search-block' && (hasLabel || showEdit || showOptional)  ? (
                <div className={`flex mb-1 ${props.label ? 'justify-between' : 'justify-end'}`}>
                    {hasLabel ? (
                        <label htmlFor={id} className={classNames(
                            "block text-sm font-semibold",
                            error && typeof error !== 'object' && !props.disabled ? "text-error-500" : "text-gray-700"
                        )}>
                            {props.label}
                        </label>
                    ) : null}

                    {showEdit ? (
                        <span 
                            className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"} 
                            onClick={props.onEditFieldSettings}
                        >Edit</span>
                    ) : null}
                    {showOptional ? (
                        <span className={classNames(
                            "text-xs font-medium",
                            "leading-5",
                            error && typeof error !== 'object' && !props.disabled ? "text-error-500" : "text-gray-500"
                        )}>Optional</span>
                    ) : null}
                </div>
            ) : null}
            <Field {...props} id={id} error={error} required={props.editing ? false : required} disabled={disabled} />
        </div>
    )
}
