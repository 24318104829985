import {useEffect, useState, useMemo} from "react";
import {WebsiteField} from "@autocx/forms/src/layouts/website-layout";
import WebsiteFieldRenderer from "../website-field-renderer";
import classNames from "classnames";
import {Disclosure} from "@headlessui/react";
import AnimateHeight from "react-animate-height";

// create calculator lookup based on calculators folder
function importCalculators() {
    const calculators = {};

    const context = require.context('./calculators', false, /\.jsx$/);

    context.keys().forEach((key) => {
        const module = context(key);
        const calculator = module?.default || module;

        if (calculator) {
            const calculatorName = Object.keys(calculator)[0];  
            calculators[calculatorName] = calculator[calculatorName];
        }
    });

    return calculators;
}

export const CALCULATORS = importCalculators();

export const DynamicCalculator = ({ spec, overrides = {} }) => {
    const [inputs, setInputs] = useState({});
    const [computedValues, setComputedValues] = useState({});
    
    // Function to replace default values in the calculator spec with overrides
    const applyOverridesToSpec = (spec, overrides) => {
        if (!spec) return null;

        let updatedSpec = { ...spec };
        
        if (overrides.calculator?.disclaimer) {
            updatedSpec.disclaimer = {
                text: overrides.calculator.disclaimer
            };
        }

        if (overrides.calculator) {
            const updatedFields = spec.fields.map(field => {
                // If there’s an override for this field name, replace the default
                if (overrides.calculator.hasOwnProperty(field.name)) {
                    return { ...field, default: overrides.calculator[field.name] };
                }
                return field;
            });
            updatedSpec = { ...updatedSpec, fields: updatedFields };
        }
        return updatedSpec;
    };

    // Apply overrides to the spec and initialise inputs
    const processedSpec = useMemo(() => applyOverridesToSpec(spec, overrides), [spec, overrides]);
    
    useEffect(() => {
        if (!processedSpec || !processedSpec.fields) return; // Avoid crashing when not defined

        // Initialise default values
        const initialInputs = {};
        processedSpec.fields.forEach(field => {
            initialInputs[field.name] = field.default || '';
        });
        setInputs(initialInputs);
    }, [processedSpec]);

    useEffect(() => {
        // Compute formulas whenever inputs change
        computeFormulas();
    }, [inputs]);

    const handleInputChange = (e, name, value) => {
        // Update the inputs state with the new value
        setInputs(prev => ({ ...prev, [name]: value }));
    };

    const computeFormulas = () => {
        try {
            if (!processedSpec || !processedSpec.fields) {
                console.warn("Processed spec is undefined or missing fields");
                return;
            }
            
            // Prepare evaluation context
            let contextData = { ...inputs };
            
            const allFieldsValid = processedSpec.fields.every(field => {
                const value = contextData[field.name];

                // Handle validation based on field type
                if (field.type === "number") {
                    const parsedValue = parseFloat(value);
                    return !field.required || (!isNaN(parsedValue) && parsedValue > 0);
                }

                if (field.type === "text") {
                    return !field.required || (value !== undefined && value !== null && value.trim() !== "");
                }

                return true;
            });

            if (!allFieldsValid) {
                // If any required input is missing or invalid, reset computed values
                const resetComputedValues = {};
                if (!processedSpec.outputs) return
                processedSpec.outputs.forEach(output => {
                    resetComputedValues[output.name] = '—'; // Reset to placeholder for any output
                });
                setComputedValues(resetComputedValues);
                return;
            }

            // Evaluate variables first
            if (processedSpec.variables) {
                for (const [varName, variable] of Object.entries(spec.variables)) {
                    contextData[varName] = evaluateExpression(variable.expression, contextData);
                }
            }

            // Evaluate formulas in order, updating context as we compute
            const newComputedValues = {};
            processedSpec.formulas.forEach(formula => {
                const value = evaluateExpression(formula.expression, contextData);
                newComputedValues[formula.name] = value;
                contextData[formula.name] = value; // Update contextData with the new variable
            });
            setComputedValues(newComputedValues);
        } catch (error) {
            console.error('Error computing formulas:', error);
        }
    };


    const evaluateExpression = (expression, context) => {
        // Safe evaluation using Function constructor
        const func = new Function(...Object.keys(context), `return ${expression};`);
        return func(...Object.values(context));
    };

    return (
        <div className="sm:px-8 sm:p-0">
            {processedSpec ? 
            <>
                <h4 className={"text-center"}>{processedSpec.label}</h4>
                <div className={"calculator rounded-theme container-padding-half"}>
                    <form className={"space-y-4"}>
                        {processedSpec.fields.map(field => (
                            <WebsiteField
                                key={field.name}
                                {...field}
                                inlineAddons={true}
                                value={inputs[field.name]}
                                onFieldChange={handleInputChange}
                                className={field?.className}
                            />
                        ))}
                    </form>
                    <div className="outputs mt-4">
                        {processedSpec.output ? (
                            processedSpec.output({ computedValues, inputs })
                        ) : (
                            <div className="outputs mt-4">
                                {processedSpec.outputs.map((output) => {
                                    return (
                                    <div className={output?.className} key={output.name}>
                                        <label className={output?.labelClassName}>{output.label}</label>
                                        <span className={output?.outputClassName}>
                                            {output?.prefix}  
                                            <WebsiteFieldRenderer
                                                component={"DisplayView"}
                                                fields={[{ ...output, id: output.name }]}
                                                values={computedValues}
                                            /> 
                                            {output?.suffix}
                                        </span>
                                    </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    {processedSpec.disclaimer &&
                        <Disclosure
                                    className={classNames(
                                        "flex flex-col border-opacity-25 rounded-theme px-6"
                                    )}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className={classNames(
                                            "w-full flex justify-between items-start mt-4 opacity-80",
                                            open ? 'bg-opacity-100' : 'bg-opacity-10'
                                        )}>
                                        <span className={"text-left font-semibold text-sm"}>
                                            Disclaimer
                                        </span>
                                        <span className="ml-6 h-5 flex items-center">
                                            <span className={classNames("border-current h-5 w-5", open ? "chevron--up" : "chevron--down")}>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    version="1.1"
                                                    viewBox="0 0 100 100"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    preserveAspectRatio="xMidYMid"
                                                    stroke="currentColor">
                                                    <g className="chevron__container">
                                                        <line className="chevron__line1" x1="10" y1="50" x2="50" y2="50"/>
                                                        <line className="chevron__line2" x1="90" y1="50" x2="50" y2="50"/>
                                                    </g>    
                                                </svg>
                                            </span>
                                        </span>
                                    </Disclosure.Button>

                                    <AnimateHeight
                                        height={open ? 'auto' : 0}
                                        animateOpacity={true}
                                    >
                                        <Disclosure.Panel as="div" static>
                                            <p className={classNames("text-xs opacity-80", processedSpec.disclaimer?.className)}>{processedSpec.disclaimer?.text}</p>
                                        </Disclosure.Panel>
                                    </AnimateHeight>
                                </>
                            )}
                        </Disclosure>
                    }
                </div>
            </>
                : "Please select a calculator"
            }
        </div>
    );
};