import Design1 from './design-1';
import Design2 from './design-2';

export default function CountdownBlock(props) {
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} />);
        case 1:
            return (<Design2 {...props} />);
        default:
            return (<Design1 {...props} />);
    }

}

export const pickerDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7)
    return date;
}

