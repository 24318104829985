import Countdown, {zeroPad} from "react-countdown";
import classNames from "classnames";


export default function BidCountdown({date, onComplete, warningMinutes = 1, ...props}) {
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        const timerWarning = warningMinutes !== 0 && days === 0 && hours === 0 && minutes < warningMinutes
        
        if (completed) {
            return (
                <span className={"text-red-500"}>Bidding Ended</span>
            );
        }
        return (
            <span className={classNames("!font-mono", timerWarning && "text-red-500")}>{days > 0 && `${days}d `}{zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s</span>
        )

    }
    
    return (
        <Countdown key={date} date={date} renderer={countdownRenderer} onComplete={onComplete}/>
    )
}