import {useContext, useEffect, useMemo, useRef, useState, Fragment} from "react"
import {createPortal} from "react-dom"
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    DragOverlay,
    MeasuringStrategy,
    defaultDropAnimation
} from "@dnd-kit/core"
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy
} from "@dnd-kit/sortable"

import {
    buildTree,
    flattenTree,
    getProjection,
    getChildCount,
    removeChildrenOf,
    setProperty
} from "./utilities"
import SortableTreeItem from "./components/sortable-tree-item"
import {CSS} from "@dnd-kit/utilities"
import PlusIcon from "@heroicons/react/solid/PlusCircleIcon";
import _cloneDeep from "lodash/cloneDeep";
import Form from "../../index";
import _get from "lodash/get";
import FormContext from "../../form-context";
import _isString from "lodash/isString";
import classNames from "classnames";
import TreeContext from "./tree-context";
import {Menu, Transition} from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import _isArray from "lodash/isArray";
import {getValuesIncludingHidden} from "../../utils";
import _isNumber from "lodash/isNumber";
import _set from "lodash/set";
import _unset from "lodash/unset";

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always
    }
}

const dropAnimationConfig = {
    keyframes({transform}) {
        return [
            {opacity: 1, transform: CSS.Transform.toString(transform.initial)},
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5
                })
            }
        ]
    },
    easing: "ease-out",
    sideEffects({active}) {
        active.node.animate([{opacity: 0}, {opacity: 1}], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing
        })
    }
}

export default function SortableTree({
                                         disabled,
                                         defaultItems = [],
                                         indicator = false,
                                         indentationWidth,
                                         ...props
                                     }) {
    
    const context = useContext(FormContext);
    const treeContext = useContext(TreeContext);
    const treeRef = useRef();
    const [items, setItems] = useState(() => defaultItems)
    const [activeId, setActiveId] = useState(null)
    const [overId, setOverId] = useState(null)
    const [offsetLeft, setOffsetLeft] = useState(0)
    const [currentPosition, setCurrentPosition] = useState(null)
    
    // If default items change then update the internal state
    useEffect(() => setItems(defaultItems), [defaultItems]);

    const interactions = useMemo(() => {
        return Object.assign({
            collapsable: true,
            editable: true,
            sortable: true,
            removable: true,
        }, props.interactions)
    }, [props.interactions]);

    // Compiles the list of tree items that will be rendered in a flat list. This will remove the active and children 
    // of parents that are collapsed
    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(props.name, items, props.types);
        const collapsedItems = flattenedTree.reduce((acc, {children, collapsed, id}) => {
            return collapsed && children.length ? [...acc, id] : acc;
        }, []);

        // Removes the active and children of the collapsed item
        return removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems
        )
    }, [activeId, items]);

    // A reference to what that item state will be after dragging
    const projected = activeId && overId
        ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
        : null
    const sensorContext = useRef({items: flattenedItems, offset: offsetLeft})
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    )

    const sortedIds = useMemo(() => flattenedItems.map(({id}) => id), [flattenedItems]);
    const activeItem = activeId
        ? flattenedItems.find(({id}) => id === activeId)
        : null

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft
        }
    }, [flattenedItems, offsetLeft])

    useEffect(() => {
        if (!treeRef.current) return;

        function onContextMenu(e) {
            e.preventDefault();
            
            // TODO: Find the active tree item then open it's context menu
        }
        
        treeRef.current?.addEventListener('contextmenu', onContextMenu, false);
        return () => {
            treeRef.current?.removeEventListener('contextmenu', onContextMenu, false);
        };
    }, []);

    const announcements = {
        onDragStart({active}) {
            return `Picked up ${active.id}.`
        },
        onDragMove({active, over}) {
            return getMovementAnnouncement("onDragMove", active.id, over?.id)
        },
        onDragOver({active, over}) {
            return getMovementAnnouncement("onDragOver", active.id, over?.id)
        },
        onDragEnd({active, over}) {
            return getMovementAnnouncement("onDragEnd", active.id, over?.id)
        },
        onDragCancel({active}) {
            return `Moving was cancelled. ${active.id} was dropped in its original position.`
        }
    }
    
    const actions = useMemo(() => {
        return _isArray(props.addActions) && props.addActions.length ? props.addActions : [props.defaultItemLabel];
    }, [props.addActions]);
    
    return (
        <div ref={treeRef} className={"relative"}>
            <DndContext
                accessibility={{announcements}}
                sensors={sensors}
                collisionDetection={closestCenter}
                measuring={measuring}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
                    {flattenedItems.map(({id, type, label, children, collapsed, depth}, index) => {
                        const interactions = getInteractions(type);
                        const hasChildren = children?.length;
                        const item = flattenedItems[index];
                        const hasParent = !item?.parent;
                        return (
                            <SortableTreeItem
                                key={`${id}_${depth}_${index}`}
                                id={id}
                                icon={getIcon(type)}
                                value={label}
                                interactions={interactions}
                                disabled={disabled}
                                depth={id === activeId && projected ? projected.depth : depth}
                                className={(id === activeId && projected ? projected.depth : depth) > 0 ? "mt-1" : "mt-2"}
                                indentationWidth={indentationWidth}
                                indicator={indicator}
                                collapsed={Boolean(collapsed && hasChildren)}
                                onCollapse={interactions.collapsable && hasChildren ? () => handleCollapse(id) : undefined}
                                onAddAbove={() => {
                                    addItem(actions[0], item.parent, item.index);
                                }}
                                onAddBelow={() => {
                                    addItem(actions[0], item.parent, item.index + 1);
                                }}
                                onAddInside={()=>{
                                    addItem(actions[0], item, item.children.length);
                                }}
                                hasParent={hasParent}
                                onRemove={() => {
                                    if (!confirm('Are you sure?')) return;
                                    
                                    const item = flattenedItems[index];
                                    const newValues = updateContextValues(item, null, true);
                                    // Notify the parent form that this control has changed it's value
                                    props.onFieldChange({target: treeRef.current}, props.name, newValues);
                                    
                                    return Promise.resolve(treeContext.onRemove(props.name, item.original));
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    // Handle when the item is not editable and ignore click event
                                    if (!interactions.editable) {

                                        // Handle if the item is collapsable and trigger the collapse event instead
                                        if (interactions.collapsable && hasChildren) {
                                            handleCollapse(id);
                                        }
                                        return;
                                    }

                                    return editItem(flattenedItems[index]);
                                }}
                            />
                        )
                    })}
                    {createPortal(
                        <DragOverlay
                            dropAnimation={dropAnimationConfig}
                            modifiers={indicator ? [adjustTranslate] : undefined}
                        >
                            {activeId && activeItem ? (
                                <SortableTreeItem
                                    id={activeId}
                                    icon={getIcon(activeItem.type)}
                                    value={activeItem.label}
                                    interactions={getInteractions(activeItem.type)}
                                    depth={activeItem.depth}
                                    clone
                                    childCount={getChildCount(items, activeId) + 1}
                                    indentationWidth={indentationWidth}
                                />
                            ) : null}
                        </DragOverlay>,
                        document.body
                    )}
                </SortableContext>
            </DndContext>
            {interactions.editable === true ? (
                <div className="relative inline-flex shadow-sm rounded mt-3">
                    <button
                        type={"button"}
                        className={classNames(
                            "inline-flex items-center px-2 h-8 border border-primary-700 shadow-button text-sm font-medium text-primary-700 hover:bg-primary-100 focus:outline-none",
                            actions.length > 1 ? "rounded-l-md" : "rounded-md"
                        )}
                        onClick={() => addItem(actions[0])}
                    >
                        <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true"/>
                        {actions[0]}
                    </button>
                    {actions.length > 1 ? (
                        <Menu as="div" className="-ml-px">
                            <Menu.Button
                                className="relative inline-flex items-center px-2 h-8 rounded-r-md border border-primary-700 shadow-button text-sm font-medium text-primary-700 hover:bg-primary-100 focus:z-10 focus:outline-none">
                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="origin-top-left absolute z-10 left-0 mt-2 -mr-1 w-48 menu-container focus:outline-none">
                                    <div className="flex flex-col">
                                        {actions.slice(1).map((item) => (
                                            <Menu.Item
                                                as={"button"}
                                                key={item}
                                                className={"grow block menu-item"}
                                                onClick={() => addItem(item)}
                                            >
                                                {item}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    ) : null}
                </div>
            ) : null}
        </div>
    )

    /**
     * Returns the settings for the defined type or returns defaults
     * 
     * @param {string} typeName The type of tree item
     * @return {object}
     */
    function getTypeSettings(typeName) {
        return props.types[typeName] || props.types['default'];
    }

    /**
     * Returns the rendered icon for the tree item
     *
     * @param {string?} typeName The type of the tree item
     * @returns {JSX.Element|null}
     */
    function getIcon(typeName) {
        const type = getTypeSettings(typeName)
        
        // Handle if icons are disabled
        if (!type || type.icon === false) return null;
        
        // Only string values are supported
        if (!_isString(type.icon)) return null;
        
        const isSVG = type.icon.startsWith(`<svg`);
        return (
            <div 
                className={classNames(
                    "w-5 h-5",
                    // Handle when the value of the icon field is not an SVG and add classes to the containing element
                    !isSVG ? type.icon : null
                )}
                // Handle when the icon is an SVG and inject it inside the containing element
                dangerouslySetInnerHTML={{
                    __html: isSVG ? type.icon : null
                }}
            />
        );
    }

    /**
     * Returns an object which contains which interactions are available for a given type of tree item
     * 
     * @param {string?} typeName The type of the tree item 
     */
    function getInteractions(typeName) {
        const type = getTypeSettings(typeName)

        // Handle if icons are disabled
        if (!type || !type.interactions) return interactions;
        
        return {
            ...interactions,
            ...type.interactions
        };
    }

    function handleDragStart({active: {id: activeId}}) {
        setActiveId(activeId)
        setOverId(activeId)

        const activeItem = flattenedItems.find(({id}) => id === activeId)

        if (activeItem) {
            setCurrentPosition({
                parent: activeItem.parent,
                overId: activeId
            })
        }

        document.body.style.setProperty("cursor", "grabbing")
    }

    function handleDragMove({delta}) {
        setOffsetLeft(delta.x)
    }

    function handleDragOver({over}) {
        setOverId(over?.id ?? null)
    }

    function handleDragEnd({active, over}) {
        resetState()

        if (projected && over) {
            const {depth, parent, index} = projected;
            
            // Because we are only moving items in arrays. Just clone the array references
            const clonedItems = flattenTree(props.name, items, props.types);
            const overIndex = clonedItems.findIndex(({id}) => id === over.id)
            const activeIndex = clonedItems.findIndex(({id}) => id === active.id)
            const activeTreeItem = clonedItems[activeIndex];

            clonedItems[activeIndex] = {...activeTreeItem, depth, parent, index};

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
            const [newItems] = buildTree(sortedItems, clonedItems[activeIndex]);

            props.onFieldChange({target: treeRef.current}, props.name, newItems);
            
            return Promise.resolve(treeContext.onMove(props.name, activeTreeItem.original, activeTreeItem.parent?.original, newItems))
                .then((newItems) => {
                    if (_isArray(newItems)) {
                        props.onFieldChange({target: treeRef.current}, props.name, newItems);
                    }
                });
        }
    }

    function handleDragCancel() {
        resetState()
    }

    function resetState() {
        setOverId(null)
        setActiveId(null)
        setOffsetLeft(0)
        setCurrentPosition(null)

        document.body.style.setProperty("cursor", "")
    }

    function handleCollapse(id) {
        setItems(items =>
            setProperty(items, id, "collapsed", value => {
                return !value
            })
        )
    }

    function getMovementAnnouncement(eventName, activeId, overId) {
        if (overId && projected) {
            if (eventName !== "onDragEnd") {
                if (
                    currentPosition &&
                    projected.parent?.id === currentPosition.parent?.id &&
                    overId === currentPosition.overId
                ) {
                    return
                } else {
                    setCurrentPosition({
                        parent: projected.parent,
                        overId
                    })
                }
            }

            const clonedItems = JSON.parse(JSON.stringify(flattenTree(props.name, items, props.types)))
            const overIndex = clonedItems.findIndex(({id}) => id === overId)
            const activeIndex = clonedItems.findIndex(({id}) => id === activeId)
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

            const previousItem = sortedItems[overIndex - 1]

            let announcement
            const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved"
            const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested"

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1]
                announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
            } else {
                if (projected.depth > previousItem.depth) {
                    announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
                } else {
                    let previousSibling = previousItem
                    while (previousSibling && projected.depth < previousSibling.depth) {
                        const parentId = previousSibling.parent?.id
                        previousSibling = sortedItems.find(({id}) => id === parentId)
                    }

                    if (previousSibling) {
                        announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
                    }
                }
            }

            return announcement
        }
    }
    
    function addItem(action, parent = null, index = null) {
        // Get the default values of the item from the form spec
        const values = getValuesIncludingHidden(props.fieldsets, props.hiddenFields);
        
        Promise
            .resolve(treeContext.onBeforeAdd(props.name, action, values, parent, index))
            .then((result) => {
                Object.assign(values, result || {});

                const existing = _get(context.values, props.name, []) || [];
                const clone = {};
                _set(clone, props.name, existing ? [...existing] : []);

                // Update sibling with new values
                const pathToSiblings = parent ? `${parent.prefix}.children` : props.name;
                const siblings = [...(_get(clone, pathToSiblings, []) || [])];

                // Default add the item to the bottom otherwise the provided index. 
                index = !_isNumber(index) ? siblings.length : index;
                
                // Ensure the index falls with in the bounds of the array items
                if (index > siblings.length) index = siblings.length;
                if (index < 0) index = 0;

                siblings.splice(index, 0, values);
                _set(clone, pathToSiblings, siblings);

                // Notify the parent form that this control has changed it's value
                props.onFieldChange({target: treeRef.current}, props.name, _get(clone, props.name));

                return editItem({
                    id: _get(values, props.idFieldPath),
                    type: _get(values, props.typeFieldPath),
                    label: _get(values, props.labelFieldPath),
                    children: [],
                    original: values,
                    depth: parent ? parent.depth + 1 : 0,
                    parent: parent,
                    index,
                    prefix: parent ? `${parent.prefix}.children[${index}]` : `${props.name}[${index}]`,
                    pathToSiblings
                }, action);
            });
    }
    
    /**
     * Sets the child form on the current FormContext which presents the edit state
     * 
     * @param {?TreeItem} item A reference to the item that we will be editing 
     * @param {?string} addActionLabel The label of the adding action. This will be used as the header of the child form 
     */
    function editItem(item, addActionLabel) {
        const fieldsets = _cloneDeep(props.fieldsets);

        return Promise
            .resolve(treeContext.onBeforeEdit(props.name, item.original))
            .then((result) => {
                const values = result || item.original;
                const original = _cloneDeep(values);
                context.setContext({
                    showChildForm: true,
                    childForm: (
                        <Form
                            id={"tree_form"}
                            layout={"panel-style"}
                            className={"h-full"}
                            fileUploadSettings={context.fileUploadSettings}
                            heading={addActionLabel ? addActionLabel : `Edit ${props.defaultItemLabel}`}
                            fieldsets={fieldsets}
                            values={values}
                            hrefUriParams={context.hrefUriParams}
                            actions={[
                                {label: "Cancel", back: true},
                                {label: "Done", primary: true, icon: "check"},
                                !addActionLabel && interactions.removable ? {label: "Remove", destructive: true, icon: "trash"} : null,
                            ].filter(item => !!item)}
                            onFieldChange={(e, name, value, values) => {
                                treeContext.onItemChange(props.name, item.prefix, values);
                            }}
                            onSubmit={(e, values, onProgress) => {
                                const newValues = updateContextValues(item, values);

                                // Notify the parent form that this control has changed it's value
                                props.onFieldChange({target: treeRef.current}, props.name, newValues);
                                
                                onProgress(0);
                                return Promise.resolve(treeContext.onEdit(props.name, values, original, newValues))
                                    .then((result) => {
                                        if (result) {
                                            Object.assign(values, result);
                                            
                                            // Remove the children property to ensure that we only update the fields of
                                            // this item
                                            _unset(values, props.childrenFieldPath);
    
                                            const newValues = updateContextValues(item, values);
                                            
                                            // Notify the parent form that this control has changed it's value
                                            props.onFieldChange({target: treeRef.current}, props.name, newValues);
                                        }

                                        // Transition back to the previous form
                                        context.setContext({showChildForm: false});
                                    });
                            }}
                            onActionClick={(e, action) => {
                                let promise = Promise.resolve();
                                
                                // Transition back to the previous form
                                if (action.back) {
                                    // Handle when adding to remove the added item otherwise revert 
                                    // it back to the original state
                                    if (addActionLabel) {
                                        promise = Promise.resolve(updateContextValues(item, null, true))
                                    } else {
                                        promise = Promise.resolve(updateContextValues(item, original));
                                    }
                                }
                                
                                // Handle when removing the item
                                if (action.destructive === true) {
                                    if (!confirm('Are you sure?')) return;

                                    promise = Promise.resolve(updateContextValues(item, null, true));
                                }

                                // Transition back to the previous form
                                promise
                                    .then((newValues) => {
                                        let promise;
                                        if (action.back) {
                                            if (addActionLabel) {
                                                promise = Promise.resolve(treeContext.onRemove(props.name, original))
                                            } else {
                                                promise = Promise.resolve(treeContext.onItemChange(props.name, item.prefix, original))
                                            }
                                        }

                                        if (action.destructive === true) {
                                            promise =  Promise.resolve(treeContext.onRemove(props.name, original));
                                        }
                                        
                                        return Promise.resolve(promise)
                                            .then(() => {
                                                // Notify the parent form that this control has changed it's value
                                                props.onFieldChange({target: treeRef.current}, props.name, newValues);
                                            });
                                        
                                    })
                                    .finally(() => {
                                        context.setContext({showChildForm: false});
                                    });
                            }}
                        />
                    )
                });
            });
    }

    /**
     * A helper method which helps replace or remove an item in the tree
     *
     * @param {TreeItem} item The tree item we are editing
     * @param {?*} [values] The submitted form values of this item. If null and delCount set to 1 then
     *                    this item will be removed
     * @param {boolean} remove True to remove the item from the tree
     * @returns {*} The new value with the updated item in it
     */
    function updateContextValues(item, values, remove) {
        const existing = _get(context.values, props.name, []) || [];
        const clone = {};
        _set(clone, props.name, existing ? [...existing] : []);

        // Update sibling with new values
        const siblings = [...(_get(clone, item.pathToSiblings, []) || [])];
        if (remove) {
            siblings.splice(item.index, 1);
        } else {
            siblings.splice(item.index, 1, {
                ...siblings[item.index],
                ...values
            });
        }

        _set(clone, item.pathToSiblings, siblings);

        return _get(clone, props.name);
    }
}

const adjustTranslate = ({transform}) => {
    return {
        ...transform,
        y: transform.y - 25
    }
}
