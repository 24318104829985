import {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import {nanoid} from "nanoid/non-secure";
import Fuse from 'fuse.js'

import _isArray from "lodash/isArray";
import _get from "lodash/get";

import InfiniteScroll from "react-infinite-scroll-component";

import CheckIcon from '@heroicons/react/solid/CheckIcon';
import SelectorIcon from '@heroicons/react/solid/SelectorIcon';
import ExclamationCircleIcon from '@heroicons/react/solid/ExclamationCircleIcon'

import {Combobox as HCombobox, Transition} from '@headlessui/react';

import FormContext from "../form-context";
import Select, {populateState} from "./select";
import Input from "./input";
import {evaluate, resolveUrlParams} from "../utils";
import _isEqual from "lodash/isEqual";
import _find from "lodash/find";
import useDebounce from "../../../websites/src/hooks/use-debounce";

const getParams = (query, page, props, values, initial) => {
    const params = {...props.params, ...Combobox.defaultProps.params};
    return Object.keys(params)
        .reduce((result, param) => {
            // Takes the value of the key in the params object and resolves it's actual value.
            result[param] = {
                [`${params[param]}`]: _get(values, params[param]),
                __query__: initial === true && props.value ? undefined : query,
                __page__: page,
                __size__: props.fuzzySearch ? 1000 : (props.pageSize || 50),
                __value__: !props.fuzzySearch && initial === true && props.value ? props.value : undefined
            }[params[param]];
            return result;
        }, {});
}

const search = (params, props, state, context, cancelToken) => {
    const [promise,] = Combobox.LoadOptions(props.href, props, cancelToken, context, params);
    if (state.setLoading) state.setLoading(true);
    promise
        .then(([options, page]) => {
            if (typeof props.onLoad === "string") {
                const onLoad = new Function("options", props.onLoad);
                options = onLoad(options);
            } else {
                options = props.onLoad(options) || options;
            }
            populateState(options, props, (groups) => {
                groups._page = page;
                groups._params = params;
                state.setGroups(groups)
                if (props.fuzzySearch) state.setAllOptions(groups)
            });

            if (props.value !== state.selected) {
                state.setSelected(props.value);
            }
        })
        .catch((err) => {
            if (!axios.isCancel(err)) console.log(err);
        })
        .finally(() => {
            if (state.setLoading) state.setLoading(false);
        })
}

const fuzzySearch = (search, groups, setGroups, allOptions) => {
    const fuse = new Fuse(allOptions.flat(), {keys: ["label"], threshold: 0.4});
    const results = fuse.search(search).map(r => r.item);

    const groupedResults = {};
    results.forEach(item => {
        const groupLabel = item.group?.label;
        if (groupLabel) {
            if (!groupedResults[groupLabel]) {
                groupedResults[groupLabel] = [];
            }
            groupedResults[groupLabel].push(item);
        } else {
            if (!groupedResults._flat) {
                groupedResults._flat = [];
            }
            groupedResults._flat.push(item);
        }
    });

    const filterableGroups = Object.keys(groupedResults).map(key => groupedResults[key]);
    setGroups(filterableGroups);
}

Combobox.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    optionsClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    autoSelectIfSingle: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    href: PropTypes.string,
    groupFieldPath: PropTypes.string,
    labelFieldPath: PropTypes.string,
    valueFieldPath: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    multiple: PropTypes.bool,
    params: PropTypes.object,
    pageable: PropTypes.bool,
    canCreate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool,]),
    noResultsMessage: PropTypes.string,
    sorted: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onLoad: PropTypes.oneOfType([PropTypes.string, PropTypes.func,]),
    autoCapitalize: PropTypes.bool,
    autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Combobox.defaultProps = {
    sorted: false,
    canCreate: true,
    noResultsMessage: "No results",
    multiple: false,
    options: [],
    groupFieldPath: 'group.label',
    labelFieldPath: 'label',
    valueFieldPath: 'value',
    params: {
        term: '__query__',
        pageNumber: '__page__',
        pageSize: '__size__',
        value: '__value__'
    },
    pageSize: 50,
    pageable: false,
    onChange: () => {
    },
    onLoad: options => options,
    autoCapitalize: false,
    autoComplete: ''
};

export default function Combobox(props) {
    const context = useContext(FormContext);
    const scrollContainerId = useRef(nanoid());
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const comboboxInputRef = useRef();
    const [allOptions, setAllOptions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selected, setSelected] = useState({});
    const debouncedSearch = useDebounce(search, 300);
    let placeholder = props.placeholder;
    if ((placeholder === undefined || placeholder === null)) {
        if (props.label) {
            if (props.label.toLowerCase().startsWith('select')) {
                placeholder = props.label;
            } else {
                placeholder = `Select ${props.label.toLowerCase()}`
            }
        } else {
            placeholder = 'Select...';
        }
    }

    useEffect(() => {
        const params = getParams(query, 1, props, context.values, groups.length === 0)

        // If the params are the same then ignore because nothing has changed
        if (_isEqual(groups._params, params) && groups[0]?.length === props?.options?.length) return;

        const state = {
            groups, setGroups,
            selected, setSelected,
            allOptions, setAllOptions
        };

        // If initial state then show loading spinner
        if (groups.length === 0) {
            state.loading = loading;
            state.setLoading = setLoading;
        }

        if (props.fuzzySearch && groups.length !== 0) return
        const cancelToken = axios.CancelToken.source();
        debouncedSearch(params, props, state, context, cancelToken);
        return () => {
            debouncedSearch.cancel();
            cancelToken.cancel();
        }
    }, [props.href, props.options, query, props.params, context.values]);

    useEffect(() => {
        let selectedOption;
        groups.find(group => selectedOption = group.find(option => option.value === props.value));
        if (!selectedOption && groups?.[0]?.length === 1 && props.autoSelectIfSingle) {
            props.onChange({target: comboboxInputRef.current}, props.name, groups?.[0][0].value);
        }
        comboboxInputRef.current.__cx__ = {
            original: selectedOption?.original,
            value: selected
        };
    }, [groups]);

    useEffect(() => {
        setSelected(props.value)
    }, [props.value]);

    // Only filter on the front end when we have a query and the result set is not pageable. This means we have all 
    // the results from the server so front end filtering makes sense
    const filtered =
        props.pageable || query === ''
            ? groups
            : groups.map(group => {
                return group.filter(option => option?.label?.toLowerCase().startsWith(query.toLowerCase()))
            });

    const count = (filtered || []).reduce((result, group) => {
        result += group.length;
        return result;
    }, 0);

    return (
        <HCombobox
            as="div"
            className={"relative"}
            nullable
            name={props.name}
            value={selected}
            disabled={props.disabled}
            multiple={props.multiple}
            onChange={(selected) => {
                if (selected === placeholder) {
                    setSelected('');

                    comboboxInputRef.current.__cx__ = {
                        original: '',
                        value: ''
                    };

                    props.onChange({target: comboboxInputRef.current}, props.name, '');
                } else if (selected) {
                    // Don't clear selected when the query is empty
                    setSelected(selected);
                    let selectedOption;
                    groups.find(group => selectedOption = group.find(option => option.value === selected));
                    comboboxInputRef.current.__cx__ = {
                        original: selectedOption?.original,
                        value: selected
                    };

                    props.onChange({target: comboboxInputRef.current}, props.name, selected);
                }
                if (props.fuzzySearch) setGroups(allOptions);
            }}
        >
            <HCombobox.Button
                as={"div"}
                className={classNames(
                    `flex bg-white items-center shadow-sm block border px-1.5 py-1 w-full text-sm rounded focus-within:ring-offset-primary-500 focus-within:ring-offset-2 focus-within:border-primary-500`,
                    props.disabled || props.readOnly ? 'bg-gray-50 border-gray-300 placeholder-gray-400' : null,
                    props.error && !props.disabled ? 'focus:ring-error-500 focus:border-error-500 border-error-300' : null,
                    !props.error && !props.disabled ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300' : null,
                    props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null,
                    props.className
                )}>
                <HCombobox.Input
                    ref={comboboxInputRef}
                    autoComplete={props.autoComplete ? 'on' : 'off'}
                    autoCapitalize={props.autoCapitalize === true ? 'on' : 'off'}
                    className={classNames(
                        `flex items-center p-0 block w-full text-sm border-none rounded-none`,
                        props.inputClassName
                    )}
                    name={props.name}
                    placeholder={placeholder}
                    pattern={props.pattern}
                    maxLength={props.maxLength}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    required={props.required}
                    data-error-message={props.errorMessage}
                    onBlur={() => {
                        if (props.fuzzySearch) {
                            setTimeout(() => setGroups(allOptions), 300)
                        }
                    }}
                    onChange={(e) => {
                        if (props.fuzzySearch) fuzzySearch(e.target.value, groups, setGroups, allOptions);
                        else setQuery(e.target.value)
                    }}
                    onFocus={() => {
                        if (props.fuzzySearch) return setGroups(allOptions);
                        if (!props.canCreate) setQuery('')
                        const params = getParams(query, 1, props, context.values);
                        search(params, props, {
                            groups, setGroups,
                            selected, setSelected,
                            loading, setLoading
                        }, context);
                    }}
                    displayValue={(item) => {
                        return groups.reduce((result, group) => {
                            if (result) return result;
                            result = group.find(option => option.value === item);
                            return result;
                        }, null)?.label;
                    }}
                />
                {props.error ? (
                    <ExclamationCircleIcon className="h-5 w-5 shrink-0 text-red-500" aria-hidden="true"/>
                ) : (
                    <SelectorIcon className="h-5 w-5 shrink-0 text-gray-400" aria-hidden="true"/>
                )}
            </HCombobox.Button>
            <Transition
                id={scrollContainerId.current}
                className={classNames(
                    "combobox-container absolute left-0 right-0 z-[1010] overflow-auto mt-1 min-h-[2rem] max-h-60 w-full rounded-md bg-white p-1.5 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                    props.optionsClassName,
                )}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <HCombobox.Options as={"div"}>
                    <InfiniteScroll
                        scrollableTarget={scrollContainerId.current}
                        scrollThreshold={"200px"}
                        dataLength={count}
                        hasMore={props.pageable && groups._page && groups._page.pageNumber < groups._page.pageCount}
                        hasChildren={filtered.length > 0 || !filtered[0]?.length && query.length > 0}
                        style={{overflow: 'hidden'}}
                        next={() => {
                            if (props.fuzzySearch) return;
                            const params = getParams(query, groups._page?.pageNumber + 1, props, context.values);
                            debouncedSearch(params, props, {
                                groups, setGroups,
                                selected, setSelected
                            }, context);
                        }}
                        loader={<Spinner/>}
                    >
                        {loading ? (
                            <Spinner/>
                        ) : null}
                        {!loading && !filtered[0]?.length && query.length > 0 && props.canCreate ? (
                            <HCombobox.Option
                                as={"div"}
                                className={({active}) => classNames(
                                    'combobox-option relative cursor-default select-none p-2 rounded',
                                    active ? 'bg-black/5' : 'text-gray-900'
                                )}
                                value={query}>
                                {({selected}) => (
                                    <span
                                        className={classNames('block line-clamp-2', selected && 'font-semibold')}>{typeof props.canCreate === 'string' ? props.canCreate : "Create"} "{query}"</span>
                                )}
                            </HCombobox.Option>
                        ) : null}
                        {placeholder && !loading && filtered[0]?.length && !query.length ? (
                            <HCombobox.Option
                                as={"div"}
                                className={({active}) => classNames(
                                    'combobox-option relative cursor-default select-none p-2 rounded',
                                    active ? 'bg-black/5' : 'text-gray-900'
                                )}
                                value={placeholder}>
                                <span className="line-clamp-2">{placeholder}</span>
                            </HCombobox.Option>
                        ) : null}
                        {!loading && !filtered[0]?.length && query.length > 0 && !props.canCreate ? (
                            <p className={"text-sm text-gray-900 text-center my-1"}>{props.noResultsMessage}</p>
                        ) : null}
                        {!loading && !filtered[0]?.length && !query.length ? (
                            <p className={"text-sm text-gray-900 text-center my-1"}>{props.noResultsMessage}</p>
                        ) : null}
                        {!loading && filtered.map(group => {
                            const options = group
                                .filter(option => !(evaluate(props.value, option.showsWhen, context.values) === false))
                                .map(option => (
                                    <HCombobox.Option
                                        as={"div"}
                                        key={`${option.label}_${option.value}`}
                                        value={option.value}
                                        disabled={option.disabled}
                                        className={({active}) => classNames(
                                            option?.original?.icon || option?.original?.colour ? 'flex items-center' : null,
                                            'combobox-option cursor-default select-none p-2 rounded relative',
                                            active ? 'bg-black/5' : 'text-gray-900'
                                        )}
                                    >
                                        {({selected, active}) => {
                                            return (
                                                <>
                                                    {option?.original?.icon &&
                                                        <option.original.icon className={'w-4 h-4 mr-1'}/>}
                                                    {option?.original?.colour &&
                                                        <span
                                                            className={classNames('w-3.5 h-3.5 rounded-full mr-1', option?.original?.colour)}/>}
                                                    <span
                                                        className={classNames(
                                                            'block line-clamp-2',
                                                            selected ? 'font-semibold pr-3' : 'font-normal'
                                                        )}>
                                                    {selected}
                                                        {active}
                                                        {option.label}
                                                </span>

                                                    {selected ? (
                                                        <span
                                                            className={"absolute inset-y-0 right-0 flex items-center text-primary-600 line-clamp-2 py-2"}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )
                                        }}
                                    </HCombobox.Option>
                                ));
                            const groupLabel = group[0]?.group?.label;
                            return !(groupLabel === null || groupLabel === undefined) ? (
                                <div key={groupLabel}>
                                    <div className={"pt-3 pb-1 px-1 font-semibold"}>{groupLabel}</div>
                                    {options}
                                </div>
                            ) : options;
                        })}
                    </InfiniteScroll>
                </HCombobox.Options>
            </Transition>
        </HCombobox>
    );
};

Combobox.FormTableDisplayView = function (props) {
    const selectedOption = props.options.find(o => o.value === props.value);
    
    return selectedOption
        ? <div className={classNames(
            selectedOption.icon || selectedOption.colour ? 'flex items-center' : null,
        )}>
            {selectedOption.icon &&
                <selectedOption.icon className={'w-4 h-4 mr-1'}/>}
            {selectedOption.colour &&
                <span className={classNames('w-3.5 h-3.5 rounded-full mr-1', selectedOption.colour)}/>}
            <span className={'block line-clamp-2 font-normal'}>{selectedOption.label}</span>
        </div>
        : props.values ? props.value : '\u2014'
};

Combobox.WebsiteProductDisplayView = Input.WebsiteProductDisplayView;
Combobox.DisplayValue = Select.DisplayValue;
Combobox.LoadOptions = function (href, props, cancelToken, context, params) {
    cancelToken = cancelToken || axios.CancelToken.source();

    let promise;
    if (!href) {
        const options = props.options ? [...props.options] : [];
        if (props.canCreate) {
            const includesValue = _find(props.options, {value: props.value.value})
            if (!includesValue && props.value) options.push(props.value);
        }
        promise = Promise.resolve({data: options});
    } else {
        promise = axios.get(resolveUrlParams(href, {
            ...context.values,
            ...context.hrefUriParams
        }), {params, cancelToken: cancelToken.token})
    }

    promise = promise
        .then((result) => {
            if (_isArray(result.data)) {
                const [promise,] = Select.LoadOptions(null, {...props, options: result.data}, cancelToken, context);
                return promise
                    .then(([options]) => {
                        if (result.headers && result.headers['x-paging-itemsperpage']) {
                            return [
                                options,
                                {
                                    itemsPerPage: +result.headers['x-paging-itemsperpage'],
                                    pageCount: +result.headers['x-paging-pagecount'],
                                    pageNumber: +result.headers['x-paging-pagenumber'],
                                    totalItems: +result.headers['x-paging-totalitems']
                                }
                            ]
                        }

                        return [options,];
                    });
            }

            return [];
        });
    return [promise, cancelToken];
}

const Spinner = () => (
    <div className={"my-2 flex items-center justify-center"}>
        <svg
            className={"animate-spin h-5 w-5 text-primary-500"}
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
    </div>
)