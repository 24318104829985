import {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {SortableContainer} from "react-sortable-hoc";
import {UploaderContext} from "./uploader-context";
import UploaderFile from "./uploader-file";

/**
 * Contains a list of node names that are classified as invalid sortable targets. Before sorting begins if the event
 * path contains one of these node types then sorting will be cancelled
 *
 * @type {string[]}
 */
const INVALID_SORTABLE_TARGETS = ['input', 'textarea', 'select', 'option', 'button'];

UploaderFiles.propTypes = {};

UploaderFiles.defaultProps = {};

/**
 * Renders a list of {@see UploaderFile}
 *
 * @param props
 * @param props.children An optional reference to children that will be rendered at the top of the list of files
 * @returns {JSX.Element}
 * @constructor
 */
export default function UploaderFiles({children, ...props}) {
    const context = useContext(UploaderContext);
    const containerRef = useRef();
    const [pressDelay, setPressDelay] = useState(0);

    useEffect(() => {
        if (window.innerWidth <= 640) setPressDelay(200);
    }, []);

    return (
        <div ref={containerRef} className={classNames(
            "mt-2",
            context.multiple && context.type === "image-uploader" ? "grid grid-cols-3 gap-2" : 'space-y-2'
        )}>
            {children}
            {props.files?.length ? (
                <SortableList
                    {...props}
                    axis={"xy"}
                    items={props.files}
                    getContainer={() => {
                        // Find the closest scrollable element
                        let parent = containerRef.current?.parentElement;
                        if (parent) {
                            while (!(parent.tagName === 'BODY' || parent.scrollHeight > parent.offsetHeight)) {
                                parent = parent.parentElement;
                            }
                        }

                        return parent || document.body;
                    }}
                    helperClass={"sort-helper"}
                    // Adds z-index to sort helper to handle cases when rendering this control in a modal
                    onSortStart={() => {
                        const helper = document.getElementsByClassName("sort-helper")[0];
                        helper.style.zIndex = 50000;
                    }}
                    shouldCancelStart={(event) => {
                        const path = event.path || event.composedPath();
                        if (!path) return false;

                        // Iterates through the event path and cancels sorting if an invalid target has been found.
                        for (const element of path) {
                            // Break if we have hit the sortable element item. No need to loop through the whole DOM
                            // tree at this point
                            if (element.sortableInfo) break;

                            // Cancel sort if we have hit an invalid target
                            if (INVALID_SORTABLE_TARGETS.includes(element.tagName?.toLowerCase())) return true;

                            if (typeof element?.id === 'string' && element.id?.startsWith('headlessui-menu')) return true;
                        }

                        return false;
                    }}
                    pressDelay={pressDelay}
                    onSortEnd={({oldIndex, newIndex}) => {
                        props.files.move(oldIndex, newIndex);
                    }}
                />
            ) : null}
        </div>
    );
};

const SortableList = SortableContainer((props) => {
    return (
        <>
            {props.files.map((file, index) => (
                <UploaderFile key={`${file.key || file.name}_${index}`} index={index} file={file}
                              onChange={props.onChange} revertFileUploader={props.revertFileUploader}/>
            ))}
        </>
    );
});