import {Icons} from "../../product-card/components/card-icons";
import classNames from 'classnames';
import WebsiteFieldRenderer from "../../../components/website-field-renderer";


/**
 * Component to display pricing fields with labels, icons, and values.
 * @param {Object} props - Component properties.
 * @param {Array<Object>} props.pricingFields - Pricing fields to be displayed.
 * @param {Array<Object>} props.productFields - User defined fieldsets in a flat array.
 * @param {Array<Object>} props.productValues - The values to display from the product
 * @returns {React.ReactElement} The JSX for pricing fields display.
 */
const PricingFields = ({ pricingFields, productFields, productValues, className }) => {
    return (
        pricingFields && pricingFields.length > 0 && (
            <div className={classNames("grid grid-cols-2 gap-2 w-full", className)}>
                {pricingFields.map((data, i) => {
                    const field = productFields.find(f => f.id === data.field);
                    const Icon = Icons.find(icon => icon.value === data.icon);

                    return (
                        field && productValues[data.field] ? (
                            <div className="flex items-start space-x-2" key={data.field + i}>
                                {data?.icon ? (
                                    <Icon.icon className={classNames(
                                        Icon.type === 'outline' ? "stroke-[revert-layer]" : null,
                                        Icon.type === 'solid' ? "fill-[revert-layer]" : null,
                                        "w-5 h-5 shrink-0"
                                    )}/>
                                ) : <div className="w-5 h-5 shrink-0"></div>}
                                <div className="flex flex-col justify-center">
                                    {!data.hideLabel && <span className="text-sm leading-tight">{field?.label}</span>}
                                    <span
                                        className="text-sm font-semibold leading-tight">{field?.prefix}  <WebsiteFieldRenderer
                                        component={"DisplayView"}
                                        fields={[field]}
                                        values={productValues}/> {field?.suffix}</span>
                                </div>
                            </div>
                        ) : null
                    );
                })}
            </div>
        )
    );
};

export default PricingFields;
