import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlusSmIcon from "@heroicons/react/solid/PlusSmIcon";

DividerAddButton.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

DividerAddButton.defaultProps = {
};

export default function DividerAddButton({className, label, ...props}) {
    return (
        <div className={classNames("w-full relative group cursor-pointer", className)} {...props}>
            <div className="absolute inset-0 flex justify-between items-center" aria-hidden="true">
                <div className="grow border-t-2 border-primary-600 mr-3.5 rounded-full group-hover:bg-primary-700" />
                <div className="grow border-t-2 border-primary-600 ml-3.5 rounded-full group-hover:bg-primary-700" />
            </div>
            <div className="relative flex justify-center">
                <button
                    type="button"
                    aria-label={label}
                    className="relative inline-flex items-center h-5 w-5 font-medium rounded-full text-white bg-primary-600 group-hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-primary-700"
                >
                    <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
};