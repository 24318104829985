import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Menu, Transition} from "@headlessui/react";
import MoreIcon from "@autocx/icons/src/more-icon";

TreeItemContextMenu.propTypes = {
    onAddAbove: PropTypes.func,
    onAddBelow: PropTypes.func,
    onRemove: PropTypes.func,
};

TreeItemContextMenu.defaultProps = {
    onAddAbove: () => {},
    onAddBelow: () => {}
};

export default function TreeItemContextMenu(props) {
    return (
        <Menu as={"div"} className="relative flex items-center">
            <Menu.Button
                onClick={(e) => {
                    // Stopping propagation to drag and drop is not invoked 
                    e.stopPropagation();
                }}
                className="bg-panels-100 cursor-pointer text-gray-500 relative inline-flex items-center rounded-full font-medium focus:outline-none hover:bg-panels-200 p-1">
                <MoreIcon className="h-4 w-4" aria-hidden="true"/>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-20 right-0 mt-2 -mr-1 w-48 menu-container">
                    <div className="flex flex-col">
                        <Menu.Item
                            as={"button"}
                            type={"button"}
                            onClick={(e) => {
                                // Stopping propagation to drag and drop is not invoked
                                e.stopPropagation();
                                props.onAddAbove();
                            }}
                            className={"grow block menu-item"}
                        >Add Above</Menu.Item>
                        <Menu.Item
                            as={"button"}
                            type={"button"}
                            className={"grow block menu-item"}
                            onClick={(e) => {
                                // Stopping propagation to drag and drop is not invoked
                                e.stopPropagation();
                                props.onAddBelow();
                            }}
                        >Add Below</Menu.Item>
                        {props.hasParent && (
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item"}
                                onClick={(e) => {
                                    // Stopping propagation to drag and drop is not invoked
                                    e.stopPropagation();
                                    props.onAddInside();
                                }}
                            >Add Inside</Menu.Item>
                        )}
                        {props.onRemove ? (
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item-danger"}
                                onClick={(e) => {
                                    // Stopping propagation to drag and drop is not invoked
                                    e.stopPropagation();
                                    props.onRemove();
                                }}
                            >Remove</Menu.Item>
                        ): null}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};