import useCdnUrl from '../../hooks/cdn-hook';

export const backgroundImage = (props) => {
    if (props.values?.image && (props.values?.design === 0)) {
        // the rest of the CSS properties are defined in .section.background-image in the index.css
        return {backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${useCdnUrl(props.values?.image)}")`}
    }
}

export const hasMedia = (props) => {
    if (props.values?.image || props.values?.video?.url) {
        return true
    }
}