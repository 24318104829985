import classNames from "classnames";
import Currency from "@autocx/forms/src/controls/currency";
import {Disclosure} from '@headlessui/react'
import AnimateHeight from "react-animate-height";
import {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {WebsiteContext} from "../../index";
import {aspectRatio, backgroundColour, backgroundPattern} from "../../utils/block-utils";
import Block from "../block";
import {SwiperElement} from "../../components/swiper-element";
import {throttle} from "lodash";
import useDetectKeyboardOpen from "../../hooks/useDetectKeyboardOpen";
import {Accordion, getProductFields, ProductCtaContainer} from "./index";
import {EnquiryActionBanner, EnquiryPurchaseMethod} from "./enquiry-purchase-method";
import BidSalesEvent from "./bid-sales-event";
import axios from "axios";
import PricingFields from "./components/pricing-fields";
import WebsiteFieldRenderer from "../../components/website-field-renderer";

export default function Design2(props) {
    const context = useContext(WebsiteContext);
    const headerBar = useRef();
    const [profile, setProfile] = useState(false);
    const [isScrolledUnder, setIsScrolledUnder] = useState(false);
    const isKeyboardOpen = useDetectKeyboardOpen()
    const productFields = getProductFields(props?.values?.productTemplate?.userDefinedFieldsets)
    // flattendedProductFields uses userDefinedFieldsets directly instead of the getProductFields function to ignore hidden fields
    const flattendedProductFields = props?.values?.productTemplate?.userDefinedFieldsets?.reduce((acc, cur) => acc.concat(cur.fields || []), []);
    const [usersBid, setUsersBid] = useState(props?.values?.bids?.[0] ?? {})

    function handleBidSuccess(bid) {
        setUsersBid(p => ({...p, ...bid}))
    }

    useEffect(() => {
        const navHeight = document.getElementById('block_navigation')?.getBoundingClientRect()?.height;

        function handleScroll() {
            const headerBarScrollPos = headerBar?.current?.getBoundingClientRect().height + headerBar?.current?.getBoundingClientRect().top - navHeight;
            // Check if the scrollable element is under the fixed element
            setIsScrolledUnder((headerBarScrollPos < 0))
        }

        const throttledHandleScroll = throttle(handleScroll, 250);
        document.addEventListener("scroll", throttledHandleScroll)

        return () => {
            document.removeEventListener(
                "scroll",
                throttledHandleScroll
            );
        };
    }, [headerBar.current]);

    // Get current profile
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        axios.get('/api/v2/publicusers/profile', {cancelToken: cancelToken.token})
            .then((response) => {
                setProfile(response.data);
            })
            .catch((err) => {
                console.log(err)
            })
        return () => cancelToken.cancel();
    }, [])

    const swiperProps = useMemo(() => {
        return {
            video: props.values?.video,
            values: {
                items: props.values.images.map((image, i) => {
                    const obj = {image}
                    obj.imagesState = (props?.values?.imageState && props?.values?.imageState[i]) ? {...props?.values?.imageState[i]} : {};
                    return obj;
                }),
                swiperOptions: {
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    breakpoints: {
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 2,
                        },
                    },
                },
            },
            swiperSlideClassName: aspectRatio({values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}),
        }
    }, [props?.values?.images, props.values.uuid])

    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props) : null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <SwiperElement {...swiperProps}/>
            <header className="section-grey">
                <div className={"section mx-auto max-w-7xl grid-cols-1 sm:grid sm:grid-cols-3"}>
                    <h1 className={classNames("!mb-0 !text-3xl sm:!text-4xl col-span-2")}>
                        {props.values.title}
                    </h1>
                </div>
            </header>
            {/* Inline mobile purchase method banner */}
            <ProductCtaContainer bannerRef={headerBar} className={"h-auto min-h-20 flex-col"}>
                {props.values.salesEvent &&
                    <>
                        <div className={"flex flex-row w-full justify-between items-center"}>
                            <BidSalesEvent
                                profile={profile}
                                salesEvent={props.values.salesEvent}
                                onBidSuccess={handleBidSuccess}
                                usersBid={usersBid}
                                bidButtonClasses={"shrink-0 w-auto h-12"}
                            />
                        </div>
                        <hr className={"my-4 mx-0 w-full"}/>
                    </>
                }
                {props.values.productTemplate.purchaseMethod === 'enquiry' &&
                    <div className={classNames(
                        "flex flex-row w-full items-center",
                        !props?.values?.productTemplate?.hidePriceOnWebsite && (props.values.sellingPrice > 0 || !props.values.salesEvent) ? "justify-between" : "justify-center"
                    )}>
                        <EnquiryActionBanner {...props} context={context} salesEvent={props.values.salesEvent}/>
                    </div>
                }
                {props?.values?.productTemplate?.pricingFields && props?.values?.productTemplate?.pricingFields.length > 0 ? (
                    <>
                        <hr className="my-4 mx-0 w-full"/>
                        <PricingFields
                            pricingFields={props.values.productTemplate.pricingFields}
                            productFields={flattendedProductFields}
                            productValues={props.values}
                        />
                    </>
                ) : null}
            </ProductCtaContainer>
            <div className={classNames("mx-auto max-w-7xl section flex flex-col items-center !pt-0")}>
                <div className={classNames("w-full grid-cols-1 py-4 inline-flex flex-col sm:grid sm:grid-cols-3")}>
                    <div className="text-left col-span-2 order-last sm:order-first sm:mr-8">
                        {productFields?.map((fieldset, i) => {
                            if (props?.values?.productTemplateUuid?.includes('car') && fieldset?.id === 'features') {
                                return (
                                    <Disclosure key={fieldset.id} id={fieldset.id} as="div"
                                                className={"flex flex-col border-opacity-25"}>
                                        {({open}) => (
                                            <>
                                                <Disclosure.Button
                                                    className="w-full flex justify-between items-start pt-5">
                                                    <h3 className={classNames("!text-xl")}>
                                                        {fieldset.label}
                                                    </h3>
                                                    <span className="ml-6 h-7 flex items-center">
                                                        <span className={classNames(
                                                            "border-current h-6 w-6",
                                                            open ? "chevron--up" : "chevron--down"
                                                        )}>
                                                            <svg width="24" height="24" version="1.1"
                                                                 viewBox="0 0 100 100"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 preserveAspectRatio="xMidYMid"
                                                                 stroke="currentColor">
                                                                <g className="chevron__container">
                                                                    <line className="chevron__line1" x1="10"
                                                                          y1="50" x2="50" y2="50"/><line
                                                                    className="chevron__line2" x1="90" y1="50"
                                                                    x2="50" y2="50"/>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        </span>
                                                </Disclosure.Button>
                                                <AnimateHeight
                                                    id={fieldset.id}
                                                    height={open ? 'auto' : 0}
                                                    animateOpacity={true}>
                                                    <Disclosure.Panel as="div" static
                                                                      className={classNames("mb-8 -mt-1.5 rich-text")}>
                                                        {fieldset.fields?.map((field, i) => (
                                                            <div key={field.id + i}
                                                                 className="pt-2 whitespace-pre-line">
                                                                {field.prefix} {props.values[field.id] || '—'} {field.suffix}
                                                            </div>
                                                        ))}
                                                    </Disclosure.Panel>
                                                </AnimateHeight>
                                            </>
                                        )}
                                    </Disclosure>
                                )
                            }
                            return (
                                <div
                                    className={classNames("w-full pt-6 inline-flex flex-col grid grid-cols-1 gap-x-10 items-start sm:grid-cols-3")}
                                    key={fieldset.id}>
                                    {fieldset.label && <div className="text-left col-span-1">
                                        <h3 className={classNames("!text-xl")}>{fieldset.label}</h3>
                                    </div>}
                                    <div className={classNames(
                                        fieldset.label ? "col-span-2" : "col-span-3",
                                        "text-left col-span-2"
                                    )}>
                                        <WebsiteFieldRenderer
                                            fields={fieldset.fields}
                                            values={props.values}/>
                                        {(productFields.length - 1) !== i &&
                                            <hr className="mt-6"/>}
                                    </div>
                                </div>
                            )
                        })}
                        {props.values.description && (
                            <>
                                {productFields &&
                                    <hr className={"my-6"}/>
                                }
                                <h3 className={classNames("!text-xl mt-6")}>Description</h3>
                                <div className={"rich-text"}
                                     dangerouslySetInnerHTML={{__html: props.values.description}}/>
                            </>
                        )}
                        {context?.policies ? (
                            <div className={"pt-6"}>
                                {Object.keys(context.policies).map((key, i) => (
                                    <Fragment key={key}>
                                        {context.policies[key] ?
                                            <Accordion
                                                className={classNames(i === 0 ? "border-t" : null, "border-b border-gray-300")}
                                                title={key.charAt(0).toUpperCase() + key.slice(1)}
                                                text={context.policies[key]}/>
                                            : null}
                                    </Fragment>
                                ))}
                            </div>
                        ) : null}
                        {context?.website?.store?.productFootnote ? (
                            <div className={"pt-8 text-xs text-gray-600 whitespace-pre-line"}>
                                {context?.website?.store?.productFootnote}
                            </div>
                        ) : null}
                    </div>
                    {/* Purchase details and method sidebar */}
                    <div className={"col-start-3 col-span-1 order-last sm:pt-0 pt-6"}>
                        <div
                            className={"mx-0 rounded-theme section-grey shadow-lg border sm:-mt-[80px] sm:!section-white sm:sticky sm:top-[calc(var(--site-nav-height)+16px)] sm:border-0"}>
                            <div
                                className="text-left section-dark container-padding-half rounded-theme sm:rounded-theme-top sm:block hidden">
                                {/*Show Bidding related UI if we have a sales event*/}
                                {props.values.salesEvent &&
                                    <>
                                        <BidSalesEvent
                                            profile={profile}
                                            bids={props?.values?.bids}
                                            salesEvent={props.values.salesEvent}
                                            onBidSuccess={handleBidSuccess}
                                            usersBid={usersBid}
                                        />
                                        {/*Show a horizontal rule under the bidding UI if we have a selling price*/}
                                        {props.values.sellingPrice > 0 && !props?.values?.productTemplate?.hidePriceOnWebsite ?
                                            <hr className={"mt-4 mb-3 mx-0"}/> : null}
                                    </>
                                }

                                {/*Handle showing sale prices. Hide sale price if it's 0 and there's a sales event*/}
                                {!props?.values?.productTemplate?.hidePriceOnWebsite && ((props.values.salesEvent && props.values.sellingPrice !== 0) || !props.values.salesEvent) && <>
                                    {props.values.discountedSellingPrice ? (
                                        <span className={classNames("text-2xl font-semibold leading-none")}>
                                                    <Currency.DisplayValue
                                                        currency={context.currency}
                                                        value={props.values.discountedSellingPrice}
                                                        emptyPlaceholder={""}
                                                    />
                                                </span>
                                    ) : null}
                                    <span
                                        className={classNames("text-2xl leading-none", props.values.discountedSellingPrice ? "text-xl line-through ml-2 opacity-80" : "font-semibold")}>
                                                <Currency.DisplayValue
                                                    currency={context.currency}
                                                    value={props.values.sellingPrice || ""}
                                                    emptyPlaceholder={"Price on Application"}
                                                />
                                            </span>
                                    {props.values.sellingPriceDisclaimer ? (
                                        <div className={"leading-tight"}>
                                            {props.values.sellingPriceDisclaimer}
                                        </div>
                                    ) : null}
                                </>}
                                {props?.values?.productTemplate?.hidePriceOnWebsite && !props.values.salesEvent && <>
                                        <span
                                            className={classNames("text-2xl leading-none", props.values.discountedSellingPrice ? "text-xl line-through ml-2 opacity-80" : "font-semibold")}>
                                               Enquire
                                            </span>
                                </>}
                                {props?.values?.productTemplate?.pricingFields && props?.values?.productTemplate?.pricingFields.length > 0 ? (
                                    <>
                                        <hr className="my-4 mx-0 w-full"/>
                                        <PricingFields
                                            pricingFields={props.values.productTemplate.pricingFields}
                                            productFields={flattendedProductFields}
                                            productValues={props.values}
                                        />
                                    </>
                                ) : null}
                            </div>
                            {/*Container for enquiry purchase method*/}
                            <div
                                className={"max-h-none sm:overflow-y-auto text-left container-padding-half sm:max-h-[calc(100vh-128px-var(--site-nav-height))] "}>
                                {props.values.productTemplate.purchaseMethod === 'enquiry' ? (
                                    <>
                                        <h2 className={"!text-2xl text-center sm:hidden block"}>Enquire</h2>
                                        <EnquiryPurchaseMethod {...props} />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bottom sticky mobile purchase method banner */}
            <ProductCtaContainer
                className={classNames(
                    "sticky container-padding-half",
                    isScrolledUnder && !isKeyboardOpen ? "bottom-0" : "-bottom-20"
                )}
            >
                {/*Hide the enquiry (and any future purchase methods implementations) if there is a sales event*/}
                {props.values.productTemplate.purchaseMethod === 'enquiry' && !props.values.salesEvent ? (
                    <EnquiryActionBanner
                        {...props}
                        context={context}
                    />) : null}
                {/*If there is a sales event lets show that instead*/}
                {props.values.salesEvent &&
                    <div className={"flex flex-row w-full justify-between items-center"}>
                        <BidSalesEvent
                            profile={profile}
                            bids={props?.values?.bids}
                            salesEvent={props.values.salesEvent}
                            onBidSuccess={handleBidSuccess}
                            usersBid={usersBid}
                            bidButtonClasses={"shrink-0 w-auto h-12"}
                        />
                    </div>
                }
            </ProductCtaContainer>
        </Block>
    )
}
