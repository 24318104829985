import classNames from "classnames";
import Countdown, {zeroPad} from "react-countdown";

import {
    backgroundColour,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    marginForTextAlignment,
    backgroundPattern, setTitleDecorationByClass, setTitleDecorationByElement,
} from "../../utils/block-utils";
import Block from '../block'
import Action from "../../components/action";
import {pickerDate} from "./index";

export const countdownRenderer = ({days, hours, minutes, seconds}) => {
    return (
        <>
            <div className={"flex flex-col items-center countdown-number"}>
                <span className={"inline-flex"}>
                    {zeroPad(days)}
                </span>
                <span className={"font-themebold text-base top-4"}>Days</span>
            </div>
            <div className={"flex flex-col items-center countdown-number"}>
                <span className={"inline-flex"}>
                    {zeroPad(hours)}
                </span>
                <span className={"font-themebold text-base top-4"}>Hours</span>
            </div>
            <div className={"flex flex-col items-center countdown-number"}>
                <span className={"inline-flex"}>
                    {zeroPad(minutes)}
                </span>
                <span className={"font-themebold text-base top-4"}>Minutes</span>
            </div>
            <div className={"flex flex-col items-center countdown-number"}>
                <span className={"inline-flex"}>
                    {zeroPad(seconds)}
                </span>
                <span className={"font-themebold text-base top-4"}>Seconds</span>
            </div>
        </>
    )
};

/*
  NOTE: In this context the 'flexDirectionBasedOnImagePosition and 'imagePosition' functions
  refer to the Countdown component.
*/
export const countdownPosition = (props) => {
    if (props.values?.design === 0) {
        const countdownPosition = props.values.options?.imagePosition;
        if (countdownPosition === 0) {
            return 'justify-start'
        } else if (countdownPosition === 2) {
            return 'justify-start sm:justify-end'
        } else {
            switch (props.values?.options?.textAlignment) {
                case 0:
                    return 'justify-start'
                case 1:
                    return 'justify-center'
                case 2:
                    return 'justify-end'
                default:
                    return 'justify-center'
            }
        }
    } else {
        switch (props.values?.options?.textAlignment) {
            case 0:
                return 'justify-start'
            case 1:
                return 'justify-center'
            case 2:
                return 'justify-end'
            default:
                return 'justify-center'
        }
    }

}

export default function Design1(props) {
    const flexDirection = flexDirectionBasedOnImagePosition(props);
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values?.options?.inset ? backgroundPattern(props): null,
                   !props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null)
               }>
            <div
                className={classNames(
                    "mx-auto max-w-7xl section flex items-center",
                    textAlignment(props),
                    flexDirection,
                    props.values?.options?.inset ? 'rounded-theme' : null,
                    props.values?.options?.inset ? backgroundPattern(props): null,
                    props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
                )}>
                <div className={classNames(
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5"
                )}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}

                    <Action className={"mt-6"} {...props.values} title={null} />
                </div>
                <div className={classNames(
                    "relative",
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5",
                    imagePosition(props)
                )}>
                    <div className={classNames(
                        "flex items-center space-x-2 my-5",
                        countdownPosition(props)
                    )}>
                        <Countdown date={props.values?.date ?? pickerDate()}
                                   renderer={countdownRenderer}
                                   zeroPadDays={4}
                                   zeroPadTime={4}
                        />
                    </div>
                </div>
            </div>
        </Block>
    )
}