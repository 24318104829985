import {useContext, useEffect, useState} from "react";
import {
    CONVERSION_RULES,
    getFileSource,
    isExternalFile,
    MIME_TYPES,
    SUPPORTED_FAVICON_IMAGE_MEDIA_TYPES,
    SUPPORTED_IMAGE_MEDIA_TYPES
} from "./uploader-utils";
import axios from "axios";
import {UploaderContext} from "./uploader-context";
import {nanoid} from "nanoid/non-secure";
import _mergeWith from "lodash/mergeWith";
import _isArray from "lodash/isArray";
import {FormContext} from "../../../index";
import {arrayMove} from "@dnd-kit/sortable";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import _set from "lodash/set";

/**
 * @typedef {Object} UploaderFilePintura
 * @property {ImageSource} src
 * @property {File} dest
 * @property {PinturaImageState} imageState
 * @property {Blob} blob
 */

/**
 * @typedef {Object} UploaderFileState
 * @property {string} id
 * @property {string} name
 * @property {string} type
 * @property {number} size
 * @property {string} key
 * @property {UploaderFilePintura} pintura
 * @property {Object} _links A reference to the links object. Present when the file is an internal file loaded
 *                           from the API
 * @property {function} process Loads the image with pintura
 * @property {function} load Loads the file handling if the file is a reference to a file system file
 * @property {function} upload Uploads the file
 * @property {function} update Merges the item with the provided state
 * @property {function} replace Replaces the file with the provided state
 * @property {function} remove Removes the item from the state
 */

/**
 * Extends the provided object with methods that will be used to manage the state in an array
 *
 * @param {FormContext} context A reference to the FormContext
 * @param {UploaderContext} props A reference to the uploader props
 * @param {UploaderFilesState} files The list of files
 * @returns {UploaderFileState}
 */
function extend(context, props, files) {
    // Iterate each file and extend it with helper methods to manage the item
    for (const [index, file] of files.entries()) {
        file.process = (signal) => {// Get the src to process handling when the file is a HTML5 File object or it's and internal/external file
            const src = getFileSource(file);
            if (!['logo', 'document', 'video', 'image'].includes(props.variant)) {
                // Dynamically import the pintura plugin as it is a very large library. This is only supported in a webpack
                // environment
                return import('pintura')
                    .then(({processDefaultImage}) => {
                        if (signal.aborted) return;

                        const supported = props.variant === 'favicon' ? SUPPORTED_FAVICON_IMAGE_MEDIA_TYPES : SUPPORTED_IMAGE_MEDIA_TYPES;
                        // For favicons everything gets converted to image/png
                        const rules = props.variant === 'favicon' ? {} : CONVERSION_RULES;

                        // Process the image with the pintura API to convert it to the write container type based on the
                        // conversion rules
                        return processDefaultImage(src, {
                            imageWriter: {
                                mimeType: !supported.includes(file.type) ? rules[file.type] || 'image/png' : undefined,
                                outputProps: ['src', 'dest', 'imageState', 'blob'],
                                targetSize: props.variant === 'favicon' ? {
                                    width: 192,
                                    height: 192
                                } : undefined
                            }
                        });
                    });
            } else {
                return Promise.resolve({file})
            }
        }

        file.load = (signal) => {
            // Ignore processing files that have already been uploaded or are external
            if (file._links || file.key) {
                return file.update({status: 'ready'});
            }

            // Showing loading state
            return file.process(signal)
                .then((state) => {
                    if (signal.aborted) return;

                    return file.update({
                        // If the file has links then we just loaded an already uploaded file so mark it as `ready`
                        // rather then `ready-for-upload` so that we don't re-upload the file
                        status: 'ready-for-upload',
                        pintura: state
                    });
                })
                .catch(e => console.log(e))
        };

        file.upload = (signal, onProgress, ignoreStatus) => {
            let data = new FormData();
            data.append('file', ['logo', 'document', 'video'].includes(props.variant) || !file.pintura?.dest ? file : file.pintura.dest);
            data.append('action', 'upload');
            if (props.path) {
                data.append('path', props.path);
            }
            if (props.private) {
                data.append('private', props.private.toString());
            }

            const uploadPromise = axios.post(context.fileUploadSettings?.saveUrl, data, {
                signal,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (onProgress) {
                        onProgress(progress, progressEvent);
                    }

                    // Added when the processor has run due to a form submission
                    if (uploadPromise.onProgress) {
                        uploadPromise.onProgress(progress, progressEvent)
                    }
                }
            });

            let wasCalled = false
            let processor;

            // Define the chain of events that need to be run to upload the image and state of this control
            const chain = uploadPromise
                .then(({data: attachments}) => {
                    const {key, name, extension, contentLength, _links} = attachments[0];

                    return file.update({
                        _links,
                        // If we are adjusting/editing/processing this file then keep the original key so we can reference the 
                        // original file on subsequent edits using the loading the current image state
                        key: ['adjusting', 'editing', 'processing'].includes(file.status) ? undefined : key,
                        name: `${name}${extension}`,
                        size: contentLength,
                        status: ignoreStatus ? undefined : 'ready',
                        pintura: {
                            imageState: {__cx__: {key}}
                        }
                    });
                });

            // Register a form processor so that the form knows that we are uploading and to include the progress of the 
            // file upload. Later on we will destroy this processor when the upload is complete.
            processor = context.registerProcessor((onProgress) => {
                wasCalled = true;
                uploadPromise.onProgress = onProgress;

                // Return the full promise chain that we need to wait for
                return chain
                    .then((file) => {

                        // Compile the current state of the uploader control
                        const result = [...files];
                        result[index] = _mergeWith({}, result[index], file);

                        const values = {};

                        // Set the field value to the current state
                        const value = result.filter(file => !!file.key).map(item => item.key);
                        _set(values, props.name, !props.multiple ? value[0] || '' : value);

                        // If pintura or focal point is enabled then set the image state field value 
                        // to the current state
                        if (props.pintura || props.focalPoint) {
                            const states = result
                                .map(({pintura: {imageState = null} = {}}) => imageState)
                                .filter(state => !!state);
                            _set(values, `${props.name}State`, states);
                        }

                        // Returned object will be merged with the form values before submission
                        return values;
                    });
            });

            return chain
                .finally(() => {
                    // Clean up the processor if the form wasn't submitted
                    if (!wasCalled && processor) {
                        processor.destroy();
                    }
                });
        };

        file.update = (updated) => {
            return files.update(index, updated);
        };

        file.replace = (replacement, transient) => {
            if (transient) {
                replacement.transient = true;
            } else {
                delete replacement.transient;
            }

            files.replace(index, replacement);
        }

        file.remove = () => {
            files.remove(index);
        }
    }
}

/**
 * @typedef {UploaderFileState[]} UploaderFilesState
 * @property {function} add Adds one or more items to the state
 * @property {function} update Updates the file merging the provided values
 * @property {function} remove Removes a file from the state
 * @property {function} replace Replaces the file with the provided state
 */

/**
 * Returns a reference to an array in state with extended functionality to manage the array from the returned reference
 *
 * @param {UploaderContext} props A reference to the props of the uploader control
 * @returns {UploaderFilesState}
 */
export default function useUploaderStateManager(props) {
    const context = useContext(FormContext);

    const [files, setFiles] = useState([]);

    // Load the current list of files for this uploader
    useEffect(() => {
        // Ignore loading existing images if there is no save url configured
        if (!context) return;
        if (!context.fileUploadSettings) return;
        if (!context.fileUploadSettings.saveUrl) return;

        const controller = new AbortController();
        const imageStates = _get(context.values, `${props.name}State`);
        const values = (!_isArray(props.value) ? [props.value] : props.value).filter(val => !!val);
        const fileIds = values
            .filter(val => !isExternalFile(val))
            .map((key) => {
                const fileName = key.substring(key.lastIndexOf('/') + 1);
                return fileName.substring(0, fileName.lastIndexOf('.'));
            });

        let promise = Promise.resolve({data: []});
        if (fileIds.length) {
            const data = new FormData();
            data.append('action', 'fetch');
            data.append(`fileIds`, fileIds.join(','));
            let href = context.fileUploadSettings.saveUrl;
            href = href.substring(0, href.lastIndexOf('/'));
            promise = axios.post(href, data, {signal: controller.signal});
        }

        promise
            .then(({data: attachments}) => {
                // Exit here if we have received a signal to abort
                if (controller.signal.aborted) return;

                // When multiple is false and more then one file returns from the server then just show the first one
                if (!props.multiple && attachments.length > 1) {
                    attachments = [attachments[0]];
                }

                // Map the response into how we manage files in the list
                attachments = attachments.map(({key, name, extension, contentType, contentLength, _links}) => ({
                    _links,
                    key,
                    type: contentType,
                    name: `${name}${extension}`,
                    size: contentLength
                }));

                // Add the files to the list
                setFiles(
                    makeUploaderFileList(
                        context,
                        props,
                        values.map((value, index) => {
                            const imageState = !_isArray(imageStates) ? imageStates : imageStates[index];
                            const file = attachments.find(file => file.key === value) || {key: value};
                            file.id = imageState?.__cx__?.id || nanoid();
                            file.status = 'pending';
                            file.pintura = {imageState};
                            return file;
                        }),
                        setFiles
                    )
                );
            });

        return () => controller.abort()
    }, [context?.fileUploadSettings?.saveUrl, props.revert]);

    return files;
};

/**
 *
 * @param {FormContext} context A reference to the FormContext
 * @param {UploaderContext} props A reference to the uploader props
 * @param {any[]} list The list state reference to extend
 * @param {function} setList The set state function to call which will update the state of the list
 * @returns {UploaderFilesState|undefined}
 */
function makeUploaderFileList(context, props, list, setList) {
    if (!list) return;

    const syncChangesWithFormContext = (previous, result) => {
        return new Promise((resolve) => {
            // Handle updating the form context with the any changes
            window.requestAnimationFrame(() => {
                // Compute the current value
                let previousValue = previous
                    .filter(file => !!file.key)
                    .map(item => {
                        if (context?.fileUploadSettings?.source === 'website' && MIME_TYPES.document.includes(item.type) && item?._links?.download?.href) {
                            const url = new URL(item._links.download.href);
                            return url.pathname;
                        }
                        return item.key
                    });

                // Compute the current value
                let value = result
                    .filter(file => !!file.key)
                    .map(item => {
                        if (context?.fileUploadSettings?.source === 'website' && MIME_TYPES.document.includes(item.type) && item?._links?.download?.href) {
                            const url = new URL(item._links.download.href);
                            return url.pathname;
                        }
                        return item.key
                    });

                previousValue = !props.multiple ? previousValue[0] || '' : previousValue;
                value = !props.multiple ? value[0] || '' : value;

                // If nothing has changed then don't bother updating the form context
                if (!_isEqual(previousValue, value)) {
                    props.onChange({target: props.fileUploadRef.current}, props.name, value, true);
                }

                // Set the image state if editing images is enabled
                if (!props.pintura && !props.focalPoint) return resolve(result);

                const previousStates = previous
                    .map(({pintura: {imageState = null} = {}}) => imageState)
                    .filter(state => !!state);
                const states = result
                    .map(({pintura: {imageState = null} = {}}) => imageState)
                    .filter(state => !!state);

                if (!_isEqual(previousStates, states)) {

                    props.onChange(
                        {target: props.fileUploadRef.current},
                        `${props.name}State`,
                        !props.multiple ? states[0] : states,
                        true
                    );
                }

                resolve(result);
            });
        }).then((result) => result);
    }

    list.add = function (files) {
        // Filter the provided list of files down to only valid ones and extend them with an id and status
        files = files
            .filter(file => props.accept.includes(file?.type))
            .map(file => {
                file.id = nanoid();
                file.status = 'pending';
                file.pintura = {imageState: {__cx__: {id: file.id}}};
                return file;
            });

        if (!files.length) return;

        setList(previous => {
            const result = makeUploaderFileList(context, props, [...previous], setList);

            // When only one file is allowed to be added ensure that we only select the first file and set it to the
            // first index of the array
            if (!props.multiple) {
                result[0] = files[0];
            } else {
                for (const file of files) {
                    result.push(file);
                }
            }

            extend(context, props, result);
            syncChangesWithFormContext(previous, result);
            return result;
        });
    }

    list.update = (index, updated) => {
        return new Promise((resolve) => {
            setList(previous => {
                const result = makeUploaderFileList(context, props, [...previous], setList);
                result[index] = _mergeWith(
                    // Handle cloning a file object
                    result[index].constructor.name === 'File'
                        ? new File([result[index]], result[index].name, {type: result[index].type})
                        : {},
                    result[index],
                    updated,
                    (objValue, srcValue) => {
                        if (_isArray(objValue)) return srcValue
                    }
                );

                // This is done due to lodash merge skipping undefined values, so when resetting the image it wont process the values which have been reset. 
                if (result[index]?.pintura?.imageState && updated?.pintura?.imageState) {
                    result[index].pintura.imageState = {
                        ...result[index].pintura.imageState,
                        ...updated?.pintura?.imageState,
                        __cx__: result[index]?.pintura?.imageState.__cx__
                    }
                }

                extend(context, props, result);

                // Resolve this promise when the changes have been made to the form context
                syncChangesWithFormContext(previous, result).then(resolve)

                return result;
            });
        }).then((result) => result[index]);
    }

    list.move = (oldIndex, newIndex) => {
        setList(previous => {
            const result = makeUploaderFileList(
                context,
                props,
                arrayMove(previous, oldIndex, newIndex),
                setList
            );
            extend(context, props, result);
            syncChangesWithFormContext(previous, result);
            return result;
        })
    }

    list.replace = (index, replacement) => {
        replacement.id = nanoid();
        replacement.status = 'pending';
        replacement.pintura = {imageState: {__cx__: {id: replacement.id}}};
        setList(previous => {

            const result = makeUploaderFileList(context, props, [...previous], setList);
            result.splice(index, 1, replacement);
            extend(context, props, result);
            syncChangesWithFormContext(previous, result);
            return result;
        })
    };

    list.remove = (index) => {
        setList(previous => {
            const result = makeUploaderFileList(context, props, [...previous], setList);
            result.splice(index, 1);
            extend(context, props, result);
            syncChangesWithFormContext(previous, result);
            return result;
        });
    };

    list.transient = {
        set: (files, transient, reset) => {
            files = files.map(file => {
                if (!reset) {
                    file.id = nanoid();
                    file.status = 'pending';
                    file.pintura = {imageState: {__cx__: {id: file.id}}};
                }

                if (transient) {
                    file.transient = transient;
                } else {
                    delete file.transient;
                }
                return file;
            });

            setList(previous => {
                const result = makeUploaderFileList(
                    context,
                    props,
                    previous.filter(file => !file.transient),
                    setList
                );

                // Because of how transient files work. The above filter state will remove all transient files before
                // we add the provided files. When an empty array is provided then this acts as a clear rather then an
                // add
                if (files.length) {
                    // When only one file is allowed to be added ensure that we only select the first file and set it to the
                    // first index of the array
                    if (!props.multiple) {
                        result[0] = files[0];
                    } else {
                        for (const file of files) {
                            // Check to see if file already exists, if so revert it
                            const index = result?.findIndex(f => f?.id === file?.id);
                            index >= 0 ? result[index] = file : result.push(file);
                        }
                    }
                }

                extend(context, props, result);
                syncChangesWithFormContext(previous, result);
                return result;
            });
        }
    }

    extend(context, props, list);

    return list;
}