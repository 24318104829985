import Block from '../block'
import {listColumns} from "./design-1";
import {
    backgroundColour,
    textAlignment,
    backgroundPattern,
    aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import classNames from "classnames";
import useCdnUrl from '../../hooks/cdn-hook';
import {WebsiteContext} from '../../index';
import {useContext} from "react";
import Action from "../../components/action";
import {Image} from "../../components/image";

export default function Design3(props) {
    const context = useContext(WebsiteContext);
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props) : null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center",
                textAlignment(props),
                props.values.options?.inset ? 'rounded-theme' : null,
                props.values.options?.inset ? backgroundPattern(props) : null,
                props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values.text &&
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    }
                </div>
                <div className={classNames("relative w-full overflow-x-auto",
                    props.values.options?.listItemsMobileDisplay === "stack" ? null : "snap-mandatory snap-x sm:snap-none"
                )}>
                    <ul className={classNames(
                        "mb-4 lg:space-x-0 lg:grid lg:gap-x-8 lg:gap-y-12",
                        props.values.options.listItemsMobileDisplay === "stack" ? "block space-y-6 sm:space-y-0" : "inline-flex space-x-4",
                        listColumns(props)
                    )}>
                        {props.values.items?.map((item, i) => (
                            <li key={i} className={classNames(
                                "inline-flex flex-col snap-start rounded-theme overflow-hidden lg:w-auto",
                                props.values.options.listItemsMobileDisplay === "stack" ? "w-full" : "w-64"
                            )}>
                                <div className={classNames(
                                    "group rounded-theme overflow-hidden sm:row-span-2 relative", aspectRatio(props)
                                )}>
                                    {item.image ? (
                                        <>
                                            <Image
                                                id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                src={item.imageState?.__cx__?.key || item.image}
                                                key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                alt={item?.altText}
                                                className={classNames("lazyload lazymotion w-full h-full object-center",
                                                    objectFit(props.values.options?.imagePresentation),
                                                    item.action && item.action.type && !['button', 'buttonFull', 'link'].includes(props.values?.options?.actionStyle) ? 'group-hover:opacity-75' : null,
                                                )}
                                            />
                                            <div aria-hidden="true"
                                                 className={classNames("bg-gradient-to-b from-transparent to-black opacity-40 absolute top-0 inset-x-0 bottom-0 rounded-theme", context.builder ? 'z-[1000]' : null)}/>
                                        </>
                                    ) : <div aria-hidden="true"
                                             className="section-dark absolute top-0 inset-x-0 rounded-theme"/>}
                                    <div
                                        className={classNames("p-4 sm:p-8 flex flex-row items-end absolute top-0 bottom-0 inset-x-0",
                                            item.image ? "text-white" : null,
                                            context.builder ? 'z-[1000]' : null)}>
                                        <div className={classNames("w-full", textAlignment(props))}>
                                            <h5>
                                                {(!props.values?.options?.actionStyle || props.values?.options?.actionStyle === 'noStyle') &&
                                                    <Action {...item}
                                                            action={{ ...item.action, label: undefined }}
                                                            itemActionStyle={props.values?.options?.actionStyle}
                                                            title={null}
                                                            button={false} 
                                                            defaultHref={'#'}>
                                                        <span className="absolute inset-0"/>
                                                    </Action>
                                                }
                                                {item.title}
                                            </h5>
                                            {item.text ? (
                                                <div dangerouslySetInnerHTML={{__html: item.text}}
                                                     className="sm:mt-1 rich-text"/>
                                            ) : null}
                                            {props.values?.options?.actionStyle !== 'noStyle' && props.values?.options?.actionStyle &&
                                                <div className={"mt-auto"}>
                                                    <Action className={"mt-3 mb-2"} {...item} itemActionStyle={props.values?.options?.actionStyle} title={null} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <Action className={"mt-6"} {...props.values} title={null}/>
            </div>
        </Block>
    )
}
