import classNames from 'classnames';

import {
    backgroundColour,
    height,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    backgroundPattern, setTitleDecorationByClass, setTitleDecorationByElement,
} from "../../utils/block-utils";
import Block from '../block'
import Action from "../../components/action";
import {BgImage} from "../../components/bg-image";

Design3.propTypes = {
    
};

Design3.defaultProps = {};

/**
 * Header with split colour and media
 * 
 * Extra styles defined in .header.split.section @ index.css because we can't do media queries in here
 * 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function Design3(props) {
    return (
        <Block {...props}
               className={classNames(
                   "sm:relative flex flex-col sm:flex-row",
                   backgroundPattern(props),
                   backgroundColour(props.values?.options?.backgroundColour),
                   textAlignment(props),
                   props.className 
               )}>

            <div className={classNames(
                "header section flex items-center mx-auto max-w-7xl w-full",
                height(props),
            )}>
                <div className={classNames("w-full sm:w-1/2",
                    imagePosition(props) === 'order-first' ? 'sm:mr-auto' : 'sm:ml-auto'
                )}>
                    {props.values?.title ? (
                        <>
                            <h1 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h1>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    ) : null}
                    <Action className={"mt-6"} {...props.values} title={null} />
                </div>
            </div>
            <div className={classNames(
                "relative w-full sm:w-1/2 inset-y-0 sm:absolute sm:inset-y-0 sm:h-full",
                imagePosition(props),
                imagePosition(props) === 'order-first' ? 'sm:right-0' : 'sm:left-0'
            )}>
                {props.values?.image ? (
                    <div className={classNames("h-full", height(props))}>
                        <BgImage {...props} className={"inset-0"} style={{borderRadius: 0}}/>
                    </div>
                ) : null}
            </div>
        </Block>
    )
}


