import PropTypes from "prop-types";
import classNames from 'classnames';

import {Disclosure} from "@headlessui/react";
import AnimateHeight from 'react-animate-height';
import ChevronIcon from '@autocx/icons/src/chevron-icon';

import Fields from "./fields";

const CollapseExpandButton = function (props) {
    if (props.canExpand) {
        return (
            <Disclosure.Button as={"div"} className={classNames("w-full flex justify-between items-center group cursor-pointer")}>
                <div>
                    {props.fieldset.label ? (
                        <h6 className="text-base font-semibold text-gray-700 group-hover:text-link !mb-0">{props.fieldset.label}</h6>
                    ): null}
                    {props.children}
                </div>
                <div className={"group-hover:text-link"}>
                    <span className={classNames("border-current", props.open ? "chevron--up" : "chevron--down")}>
                        <ChevronIcon className={"h-5 w-5"}/>
                    </span>
                </div>
            </Disclosure.Button>
        );
    } else {
        return (
            <>
                {props.fieldset.label ? (
                    <h4 className="text-base font-bold text-gray-700">{props.fieldset.label}</h4>
                ): null}
                {props.children}
            </>
        );
    }
}

PanelLayout.propTypes = {
    fieldset: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func,
    onEditFieldsetSettings: PropTypes.func,
}

PanelLayout.defaultProps = {
    fieldsets: {},
    onFieldChange: () => {},
}

export default function PanelLayout({children, editButton, ...props}) {
    const canExpand = props.fieldset.collapsable === true && !props.fieldset.childrenOnly;
    return (
        <>
            <Disclosure as={"div"} 
                        id={props.fieldset.id} 
                        defaultOpen={!(props.fieldset.collapsable === true) || props.fieldset.expanded === true}
                        className={props.fieldset.className}>
                {({ open }) => (
                    <>
                        <CollapseExpandButton {...props} open={open} canExpand={canExpand}>
                            {editButton}
                        </CollapseExpandButton>
                        {canExpand ? (
                            <AnimateHeight
                                height={open ? 'auto' : 0}
                                animateOpacity={true}
                            >
                                <Disclosure.Panel static>
                                    {!props.fieldset.childrenOnly && props.fieldset.description ? (
                                        <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                                    ) : null}
                                    <Fields {...props} className={"space-y-5 pt-2 pb-5"}>
                                        {children}
                                    </Fields>
                                </Disclosure.Panel>
                            </AnimateHeight>
                        ): (
                            <>
                                {!props.fieldset.childrenOnly && props.fieldset.description ? (
                                    <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                                ) : null}
                                <Fields {...props} className={"space-y-5 pt-2 pb-5"}>
                                    {children}
                                </Fields>
                            </>
                        )}
                    </>
                )}
            </Disclosure>
        </>
    )
};

PanelLayout.EditButton = function (props) {
    return (<a className={"display-block"} href="forms/src/layouts/panel-layout#" onClick={props.onClick}>{props.children}</a>)
};