import {useMemo} from 'react';
import PropTypes from "prop-types";

import TreeContext from "../tree-context";

TreeControlContext.propTypes = {
    onBeforeAdd: PropTypes.func,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onItemChange: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
};

TreeControlContext.defaultProps = {
    onBeforeAdd: () => {},
    onAdd: () => {},
    onEdit: () => {},
    onItemChange: () => {},
    onMove: () => {},
    onRemove: () => {},
};

/**
 * Renders a TreeControlContext around to provided children. 
 * 
 * @param children The optional reference of children to render with in the context provider
 * @param {TreeContext} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TreeControlContext({children, ...props}) {
    const context = useMemo(() => ({...props}), [props]);

    return (
        <TreeContext.Provider value={context}>
            {children}
        </TreeContext.Provider>
    );
}