import classNames from 'classnames';

import {
    backgroundColour,
    textPosition,
    height,
    marginForTextAlignment,
    backgroundPattern, setTitleDecorationByClass, setTitleDecorationByElement, textPositionVertical,
} from "../../utils/block-utils";
import {hasMedia} from './utils';
import Block from '../block'
import useCdnUrl from "../../hooks/cdn-hook";
import Action from "../../components/action";
import {BgImage} from "../../components/bg-image";
import {BgVideo} from "../../components/bg-video";

Design1.propTypes = {
    
};

Design1.defaultProps = {};

function foregroundImagePosition(textPosition) {
    switch (textPosition) {
        case 'justify-start':
            return 'mr-auto'
        case 'justify-center':
            return 'mx-auto'
        case 'justify-end':
            return 'ml-auto'
        default:
            return 'mx-auto'
    }
}

function textAlignment(textPosition) {
    switch (textPosition) {
        case 'justify-start':
            return 'text-left'
        case 'justify-center':
            return 'text-center'
        case 'justify-end':
            return 'text-right'
        default:
            return 'text-center'
    }
}

/**
 * Header with background image          {
 *             "label": "Video",
 *             "type": "video-uploader",
 *             "name": "video"
 *           },
 * 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Design1(props) {
    const mediaType = props?.values?.options?.mediaType ? props.values?.options?.mediaType : 'image';

    return (
        <Block {...props}
               style={{backgroundColor: props.values?.video?.url ? '#000000' : null}}
               className={classNames("relative", props.className, backgroundPattern(props), backgroundColour(props.values?.options?.backgroundColour))
               }>
            {mediaType === 'image' && <BgImage {...props} hasOverlay={true}/>}
            {mediaType === 'video' && <BgVideo {...props} hasOverlay={true}/>}
            <div
                className={classNames(
                    "relative z-[1] mx-auto header section flex",
                    mediaType === 'image' && `${height(props)} w-full sm:max-w-7xl`,
                    mediaType === 'video' && props.values?.options?.backgroundVideoHeight ? props.values.options.backgroundVideoHeight : null,
                    textPosition(props),
                    textPositionVertical(props),
                    textAlignment(textPosition(props)),
                    hasMedia(props) ? 'section-dark' : backgroundColour(props.values?.options?.backgroundColour),
                )}
            >
                <div className={"w-full sm:max-w-[80ch]"}>
                    {props.values?.foregroundImage &&
                        <img src={useCdnUrl(props.values?.foregroundImage)} className={classNames("pb-4 max-w-[360px] max-h-[184px] sm:max-h-[240px] sm:max-w-[512px]", foregroundImagePosition(textPosition(props)))}/>
                    }
                    {props.values?.title ? (
                        <>
                            <h1 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h1>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                    <Action className={"mt-6"} {...props.values} title={null} />
                </div>
            </div>
        </Block>
    )
}