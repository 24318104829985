import {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {UploaderContext} from "../uploader-context";
import {getPreviewSrc} from "../uploader-utils";
import FormContext from "../../../form-context";

FaviconUploaderFilePreview.propTypes = {
    imageRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
    className: PropTypes.string,
    file: PropTypes.object,
};

FaviconUploaderFilePreview.defaultProps = {};

export default function FaviconUploaderFilePreview(props) {
    const context = useContext(UploaderContext);
    const formContext = useContext(FormContext);
    const previewSrc = getPreviewSrc(formContext.fileUploadSettings?.cdnUrl, props.file);
    
    return (
        <div className={classNames("flex p-1.5 bg-white", props.className)}>
            <div className={classNames(
                "grow w-full overflow-hidden relative border-b w-full flex justify-center",
            )}>
                <div style={{background: "linear-gradient(180deg, #F4F5F6 0%, #DCDCDC 92%, #DBDBDB 100%)"}}
                     className={classNames(
                         "max-w-[180px] w-full bg-gray-100 h-[36px] relative rounded-t-md items-center flex",
                         context.preview.className
                     )}>
                    <div className={"flex w-full px-3"}>
                        {!previewSrc || props.file?.status === 'uploading' ? (
                            <div className={classNames("flex w-full items-center", props.file?.status === 'uploading' ? "animate-pulse" : null)}>
                                <div className={"object-center bg-gray-400 w-5 h-5 mr-2 rounded flex-shrink-0"}/>
                                <span className={"font-semibold truncate"}>{context.preview.title}</span>
                            </div>
                        ) : (
                            <>
                                <img 
                                    ref={props.imageRef}
                                    alt={`Preview`} 
                                    src={previewSrc} 
                                    className={classNames("object-center object-contain w-5 mr-2 flex-shrink-0")}
                                />
                                <span className={"font-semibold truncate"}>{context.preview.title}</span>
                            </>
                        )}
                    </div>
                </div>
                <div className={classNames(
                    "w-[130px] w-full bg-gray-100 h-[36px] relative rounded-t-md items-center flex ml-2"
                )}>
                    <div className={"flex w-full px-3"}>
                        <div className={classNames("flex w-full items-center")}>
                            <div className={"object-center bg-gray-300 w-5 h-5 mr-2 rounded flex-shrink-0"}/>
                            <div className="h-2 bg-gray-300 rounded col-span-2 w-[100px]"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};