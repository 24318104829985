import Design1 from './design-1';

import './index.css';

export default function ContentHubBlock(props) {
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
}