import {useContext} from 'react';
import classNames from 'classnames';
import {aspectRatioClassNames, getPreviewSrc} from "../uploader-utils";
import {UploaderContext} from "../uploader-context";
import Loader from "../../../components/loader";
import PropTypes from "prop-types";
import FormContext from "../../../form-context";

ImageUploaderFilePreview.propTypes = {
    className: PropTypes.string,
    file: PropTypes.object.isRequired,
    imageRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
    containerBackground: PropTypes.bool,
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
    objectFit: PropTypes.oneOf(['contain', 'cover', 'fill', 'none', 'scale-down']),
    objectPosition: PropTypes.oneOf(['bottom', 'center', 'left', 'left-bottom', 'left-top', 'right', 'right-bottom', 'right-top', 'top']),
};

ImageUploaderFilePreview.defaultProps = {
    objectFit: 'contain',
    objectPosition: 'center',
    containerBackground: true
};

export default function ImageUploaderFilePreview(props) {
    const context = useContext(UploaderContext);
    const formContext = useContext(FormContext);
    const previewSrc = getPreviewSrc(formContext.fileUploadSettings?.cdnUrl, props.file);

    const objectFit = {
        contain: 'object-contain', 
        cover: 'object-cover', 
        fill: 'object-fill', 
        none: 'object-none', 
        'scale-down': 'object-scale-down'
    }[props.objectFit] || ImageUploaderFilePreview.defaultProps.objectFit;
    
    const objectPosition = {
        'bottom': 'object-bottom', 
        'center': 'object-center', 
        'left': 'object-left', 
        'left-bottom': 'object-left-bottom', 
        'left-top': 'object-left-top', 
        'right': 'object-right', 
        'right-bottom': 'object-right-bottom', 
        'right-top': 'object-right-top', 
        'top': 'object-top'
    }[props.objectPosition] || ImageUploaderFilePreview.defaultProps.objectPosition;

    return (
        <div ref={props.imageUploaderRef} className={classNames("relative flex rounded border border-gray-300", props.className)}>
            {props.file.status === 'processing' && (
                <div className="absolute top-0 left-0 w-full h-full bg-[#212121] flex items-center z-10 justify-center">
                    <div className="text-white">
                        <Loader loading={props.file.status === 'processing'} className={"m-auto"}/>
                        Updating
                    </div>
                </div>
            )}
            <div className={classNames(
                "flex flex-col items-center justify-between hover:shadow-md rounded bg-white",
                context.aspectRatio ? aspectRatioClassNames(context.aspectRatio) : "aspect-square",
                context.preview.className,
            )}>
                <div
                    ref={props.containerRef}
                    style={props.containerBackground
                        ? {background: "repeating-conic-gradient(#e0e0e0 0% 25%, transparent 0% 50%) 50% / 20px 20px"}
                        : null}
                    className={classNames(
                        "grow relative w-full overflow-hidden ",
                        context.aspectRatio ? aspectRatioClassNames(context.aspectRatio) : "aspect-square",
                        props.file.status === 'loading' ? "hidden" : null,
                    )}>
                    {previewSrc ? (
                        <img
                            ref={props.imageRef}
                            alt={`Preview`}
                            src={previewSrc}
                            className={classNames(
                                "w-full",
                                context.aspectRatio ? aspectRatioClassNames(context.aspectRatio) : "aspect-square",
                                objectFit, objectPosition
                            )}
                        />
                    ) : null}
                </div>
                <Loader loading={props.file.status === 'loading'} className={"m-auto"}  />
                {props.file.status !== 'loading' && props.children ? (
                    props.children
                ) : null}
            </div>
            
            {props.file.status === 'uploading' || props.file.status === 'error' ? (
                <div className={classNames(
                    "absolute inset-x-4 bottom-4 py-0.5 px-2",
                    "backdrop-blur-md backdrop-filter bg-white/80 saturate-150",
                    "rounded-lg text-[11px]",
                    props.file.status === 'error' ? 'text-error-500' : 'text-primary-500'
                )}>
                    {props.file.error ? props.file.error : 'Uploading...'}
                    {props.progress !== undefined && props.progress !== null ? (
                        <div className="relative">
                            <div className="overflow-hidden h-1 mb-1 text-xs flex rounded bg-primary-200">
                                <div style={{width: `${props.progress}%`}}
                                     className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
};