import Block from "../block";
import {WebsiteContext} from "../../index";
import {aspectRatio} from "../../utils/block-utils";
import {Fragment, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Image} from "../../components/image";
import Currency from "@autocx/forms/src/controls/currency";
import classNames from "classnames";
import axios from "axios";
import Spinner from "../../components/spinner";
import {formatDateToFriendlyDate} from "./index";

export default function AccountOrders(props) {
    const [orderData, setOrderData] = useState([])
    const [loading, setLoading] = useState(true)
    const [showLoader, setShowLoader] = useState(false)
    const context = useContext(WebsiteContext)
    const imageAspectRatio = {values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}

    useEffect(() => {
        document.title = props?.title
        const ordersUrl = props?.profile?.customer?._links?.orders?.href;

        if (ordersUrl) {
            setLoading(true)
            let timer = setTimeout(() => {
                setShowLoader(true);
            }, 400);
            axios.get(ordersUrl)
                .then(response => {
                    setOrderData(response.data.reverse());
                })
                .catch(error => {
                    console.error("Error fetching orders:", error);
                })
                .finally(() => {
                    clearTimeout(timer)
                    setLoading(false)
                    setShowLoader(false)
                })
        }
    }, [])

    return (
        <Block {...props}>
            <h2>{props?.title}</h2>
            {loading
                ? showLoader && <Spinner/>
                : orderData.length > 0
                    ? (orderData.map((order) => {
                        const numberOfProducts = order?.products?.length;
                        const extraProductCount = numberOfProducts > 4 ? numberOfProducts - 4 : 0;
                        const friendlyDate = formatDateToFriendlyDate(order.dateCreated)

                        return (
                            <div key={order.uuid}
                                 className="container-padding-half section-grey rounded-theme flex flex-col sm:items-center mb-6 sm:flex-row">
                                <div
                                    className={"inline-flex flex-col w-full shrink-0 mb-4 sm:mb-0 sm:w-80 sm:mr-2"}>
                                        <span
                                            className="text-xs section-black font-bold rounded-full px-3 py-1 uppercase w-fit mb-2">{order.status}</span>
                                    {order.orderNumber && <span
                                        className="text-base text-gray-900 font-bold">Order #{order.orderNumber}</span>}
                                    <span className="text-base text-gray-700">{order.orderNumber ? 'Placed' : 'Created'} {friendlyDate}</span>
                                    <span className={"text-base text-gray-700"}>Total <Currency.DisplayValue
                                        currency={context.currency} value={order?.totalPrice}
                                        emptyPlaceholder={""}/></span>
                                    {order.finalBalance === 0
                                        ? <a href={`/orders/confirmation/${order.uuid}`}
                                             className={"font-bold text-base mt-2"}>View Details</a>
                                        :
                                        <a href={`/product/${context.website.workspaceId}_${order.products[0].productUuid}/checkout?orderUuid=${order.uuid}`}
                                           className={"font-bold text-base mt-2"}>Checkout</a>}
                                </div>
                                <div className="grid grid-cols-4 gap-x-4 w-full">
                                    {order?.products?.slice(0, 4).map((product, index) => {
                                        const image = product.imagesState?.[0]?.__cx__?.key || product?.imageUrl
                                        const isLast = index === 3 && extraProductCount > 0;

                                        return (
                                            <div key={product.id} className={"relative"}>
                                                <Image
                                                    pictureClassName={"col-span-1"}
                                                    alt={"Image of " + product.title}
                                                    width={148}
                                                    id={product.imagesState?.__cx__?.id ? `image_${product.imagesState?.__cx__?.id}` : null}
                                                    src={image}
                                                    className={classNames("flex-none rounded-theme object-cover object-center w-full", aspectRatio(imageAspectRatio))}
                                                />
                                                {isLast && (
                                                    <div
                                                        className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 text-white text-2xl font-bold rounded-theme">
                                                        +{extraProductCount}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }))
                    : (
                        <div className="mt-4 text-base text-grey-800 font-semibold">
                            <p>There are no orders to display</p>
                        </div>
                    )
            }
        </Block>
    )
}


