import classNames from "classnames";
import {aspectRatio, backgroundColour, backgroundPattern} from "../../utils/block-utils";
import Block from "../block";
import {Image} from "../../components/image";
import {WebsiteContext} from "../../index";
import {useContext, useEffect, useState} from "react";
import Currency from "@autocx/forms/src/controls/currency";
import AnimateHeight from "react-animate-height";
import SimpleSiteHeader from "../../components/simple-site-header";
import DigitalProductAccessCard from "../../components/digital-product-access-card";
import {SetPassword} from "../account-block/account-login-register";

export default function Design1(props) {
    const context = useContext(WebsiteContext)
    const imageAspectRatio = {values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}
    const [state, setState] = useState('guest');
    
    useEffect(() => {
        if (props?.profile) {
            if (props?.profile?.registered) setState('registered')
            else if (props?.profile?.customer?._links?.setPassword) state !== 'guest' ? setState('guest') : null
            else setState('existing')
        }
    }, [props.profile]);
    
    return (
        <>
            <SimpleSiteHeader/>
            <Block {...props}
                   className={classNames(props.className, !props.values.options?.inset ? backgroundPattern(props) : null, !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
                   }>
                <div className="flex sm:flex-row flex-col gap-12 max-w-5xl mx-auto px-4 py-16 sm:px-6 sm:py-20">
                    {props?.order?.id ? (
                        <>
                            <div className="sm:w-2/5 w-full text-center sm:text-left">
                                <h1 className="!text-3xl !mb-1">Thank you!</h1>
                                <p className="text-base text-gray-800 leading-tight">An email has been sent
                                    to {props?.order?.customer?.emailAddress ?? "your address"} with all the
                                    details.</p>
                                <div className={"text-gray-600 text-xs uppercase mt-2 mb-4 font-semibold"}>
                                    Order No. {props?.order?.orderNumber ?? "TBD"}
                                </div>
                                <div>
                                    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"
                                         className={"w-12 inline"}>
                                        <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd"
                                           strokeLinecap="round" strokeLinejoin="round">
                                            <path className="form-submit-circle"
                                                  d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                                            <path className="form-submit-tick" d="M6.5 14.5L10 18 l8.808621-8.308621"/>
                                        </g>
                                    </svg>
                                </div>
                                <AnimateHeight height={props?.profile?.registered === false ? 'auto' : 0} delay={1000}
                                               className={"sm:w-72 w-full"}>
                                    <div className={"rounded-theme p-4 section-grey border w-full mx-0 mt-6"}>
                                        {state === 'guest' &&
                                            <div className={"mb-4 leading-tight text-center"}>Set a password to
                                                easily view your order history.
                                            </div>
                                        }
                                        <SetPassword profile={props?.profile} onSuccess={() => setState('registered')}
                                                     email={props?.profile?.customer?.emailAddress}/>
                                    </div>
                                </AnimateHeight>
                            </div>
                            <div className="sm:w-3/5 w-full">
                                <h3 className={"!text-lg !my-3"}>Order Summary</h3>
                                {props?.order?.products?.map((product) => {
                                    const image = product.imagesState?.[0]?.__cx__?.key || product?.imageUrl
                                    return (
                                        <div key={product.id} className="flex space-x-3 border-b border-gray-200 pb-6">
                                            <Image
                                                pictureClassName={"shrink-0"}
                                                alt={"Image of " + product.title}
                                                width={48}
                                                id={product.imagesState?.__cx__?.id ? `image_${product.imagesState?.__cx__?.id}` : null}
                                                src={image}
                                                className={classNames("flex-none rounded-theme-half object-cover object-center w-12", aspectRatio(imageAspectRatio))}
                                            />
                                            <div className="flex flex-col justify-center flex-grow">
                                                <div
                                                    className={"flex flex-row justify-between items-baseline font-medium"}>
                                                    <div className="!mb-0 !text-lg">{product.title}</div>
                                                    <div className="shrink-0 text-base">
                                                        <Currency.DisplayValue currency={context.currency}
                                                                               value={product?.discountedSellingPrice ? product?.discountedSellingPrice : product?.sellingPrice}
                                                                               emptyPlaceholder={""}
                                                        />
                                                    </div>
                                                </div>
                                                {product.quantity > 1 &&
                                                    <div
                                                        className="font-medium text-gray-700">Quantity {product.quantity}</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <dl className="space-y-6 border-gray-200 pt-2 text-lg font-bold">
                                    <div className="flex justify-between">
                                        <dt>Total</dt>
                                        <dd>
                                            <Currency.DisplayValue currency={context.currency}
                                                                   value={props?.order?.totalPrice}
                                                                   emptyPlaceholder={""}/>
                                        </dd>
                                    </div>
                                </dl>
                                {
                                    props?.order?.products?.some(product => product.contentAccess.length > 0) &&
                                    <h4 className={"!text-base !mt-10 !mb-2"}>Digital Products</h4>
                                }
                                {props?.order?.products?.map((product) => {
                                    if (product.contentAccess.length > 0) {
                                        return (
                                            <DigitalProductAccessCard key={product.productUuid} product={product}
                                                                      productsLocked={false}/>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="mx-auto">
                            <h1 className="!text-3xl !mb-1">Thank you!</h1>
                            <p className="text-base text-gray-800">An email has been sent with all the details.</p>
                            <p className={"mt-4"}>
                                <a href="/my-account/sign-in">Sign in to view your orders</a>
                            </p>
                        </div>
                    )}
                </div>
            </Block>
        </>
    )
}