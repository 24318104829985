import React from "react"
import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"

import TreeItem from "./tree-item"
import {iOS} from "../utilities"

const animateLayoutChanges = ({isSorting, wasDragging}) => !(isSorting || wasDragging)

/**
 * Wraps a TreeItem component to handle Drag n Drop behaviour
 * 
 * @param {object} props The remaining props that will be passes down to the TreeItem
 * @param {string} props.id The ID of the tree item
 * @param {number} props.depth The depth this tree item should render at
 * @param {boolean} props.disabled True if the item is disabled. 
 * @returns {JSX.Element}
 * @constructor
 */
export default function SortableTreeItem({id, depth, disabled, ...props}) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition
    } = useSortable({
        id,
        disabled: disabled || props.interactions.sortable === false,
        animateLayoutChanges
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    return (
        <TreeItem
            ref={setDraggableNodeRef}
            wrapperRef={setDroppableNodeRef}
            style={style}
            depth={depth}
            ghost={isDragging}
            disableSelection={iOS || disabled}
            disableInteraction={disabled || isSorting}
            handleProps={{...attributes, ...listeners}}
            {...props}
        />
    )
}
