import {Fragment, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UploadIcon from "@heroicons/react/solid/UploadIcon";
import {Menu, Transition} from "@headlessui/react";
import ImageUploaderUnsplashPicker from "./pickers/image-uploader-unsplash-picker";
import {UploaderContext} from "./uploader-context";
import DeleteIcon from "@heroicons/react/outline/TrashIcon";
import CheckIcon from "@heroicons/react/outline/CheckIcon";

let previousFiles;

UploaderActions.propTypes = {
    files: PropTypes.array,
};

UploaderActions.defaultProps = {};

export default function UploaderActions(props) {
    const context = useContext(UploaderContext);

    return (
        <div className={"flex items-center justify-between"}>
            {/* This hidden input is for invoking the native file browser */}
            <input
                ref={context.fileUploadRef}
                type="file"
                className="hidden"
                multiple={context.multiple}
                accept={context.accept}
                disabled={context.disabled}
                onChange={(e) => {
                    props.files.add(Array.from(e.target.files))

                    // Clear the currently selected value so we can handle uploading the same file
                    e.target.value = null;
                }}
            />

            <Menu as="div" className="grow relative cursor-pointer select-none">
                <Menu.Button
                    as={"button"}
                    className={classNames(
                        "flex flex-row items-center justify-center shadow-sm border border-gray-300 p-1.5 w-full h-full font-medium leading-4 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
                        context.disabled ? "bg-gray-100" : null
                    )}
                    onClick={!context.unsplash ? () => {
                        context.fileUploadRef.current.click();
                    } : null}
                    disabled={context.disabled}
                >
                    <UploadIcon className={"w-5 h-5 mr-1 text-inherit"}/>
                    Choose
                </Menu.Button>
                {context.unsplash ? (
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="origin-top-right absolute z-20 mt-1 w-48 menu-container">
                            <div className="flex flex-col">
                                <div
                                    onClick={() => {
                                        context.fileUploadRef.current.click();
                                    }}
                                    className={classNames('grow block menu-item')}>
                                    Upload {context.variant}
                                </div>
                                {context.variant === 'image' ?
                                    <ImageUploaderUnsplashPicker.Trigger
                                        onClick={() => {
                                            previousFiles = props.files.map((file) => ({...file}));
                                        }}
                                        onCancel={() => {
                                            // Reset the list of files to the previous state
                                            props.files.transient.set(previousFiles, false, true);
                                            previousFiles = null;
                                        }}
                                        onChange={(value) => {
                                            // All values will be marked as transient at this stage so that when cancelled
                                            // we can remove the files from the list
                                            props.files.transient.set(value, true);
                                        }}
                                        onDone={(value) => {
                                            // All transient files will be marked as not transient as the user has committed
                                            // to the selection
                                            props.files.transient.set(value);
                                            previousFiles = null;
                                        }}
                                    />
                                : null}

                            </div>
                        </Menu.Items>
                    </Transition>
                ) : null}
            </Menu>
            {/* Show file action when only one file can be uploaded */}
            {!context.multiple && props.files[0] ? (
                <>
                    <button
                        type="button"
                        className={classNames(
                            "shadow-sm border border-gray-300 py-1.5 px-2 shrink-0 font-medium leading-4 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-2",
                            props.files[0].status === 'adjusting' ? "hidden" : null,
                        )}
                        disabled={props.disabled}
                        onClick={() => {
                            if (confirm('Are you sure?')) {
                                props.files[0].remove();
                            }
                        }}>
                        <div className={"flex flex-row items-center justify-center"}>
                            <DeleteIcon className={"w-5 h-5 text-error-500"}/>
                        </div>
                    </button>
                    <button
                        type="button"
                        className={classNames(
                            "shadow-sm border border-gray-300 py-1.5 px-2 shrink-0 font-medium leading-4 rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-2",
                            props.files[0].status === 'adjusting' ? null : "hidden",
                        )}
                        disabled={props.disabled}
                        onClick={() => {
                            props.files[0].update({status: 'ready'})
                        }}>
                        <div className={"flex flex-row items-center justify-center"}>
                            <CheckIcon className={"w-5 h-5 text-primary-700"}/>
                        </div>
                    </button>
                </>
            ) : null}
        </div>
    );
};