import {createElement, Fragment, isValidElement} from 'react';
import {isFragment} from 'react-is';
import PropTypes from 'prop-types';
import classNames from 'classnames';

Loader.propTypes = {
    /**
     * Appends the value to the className of the containing div around the spinner
     */
    className: PropTypes.string,
    /**
     * Appends the value to the className of the spinner
     */
    spinnerClassName: PropTypes.string,
    /**
     * States what the container element should be. If Fragment then className is ignored
     */
    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
        PropTypes.elementType,
    ]),

    /**
     * True to show the spinner
     */
    loading: PropTypes.bool,
};

Loader.defaultProps = {
    as: 'div',
    loading: false
};

export default function Loader(props) {
    if (!props.loading) return null;

    const loaderProps = {};
    if (props.as !== Fragment && (!isValidElement(props.as) || !isFragment(props.as))) {
        loaderProps.className = classNames(
            "flex w-full justify-center",
            !props.loading ? "hidden" : null,
            props.className
        );
    }
    
    return createElement(props.as, loaderProps, (
        <svg className={classNames("animate-spin h-5 w-5 text-brand", props.spinnerClassName)}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
            />
            <path fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
    ));
};