import {createContext} from "react";

/**
 * @typedef {Object} UploaderFilePreviewProp
 * @property {string} className
 * @property {string} title
 */

/**
 * @typedef {Object} UploaderContext
 * @property {string} id
 * @property {string} name
 * @property {string} accept
 * @property {string|null} variant
 * @property {string} path
 * @property {any} value
 * @property {boolean} private
 * @property {boolean} multiple
 * @property {boolean} required
 * @property {boolean} disabled
 * @property {number} aspectRatio
 * @property {Object} fileUploadSettings
 * @property {UploaderFilesState} files
 * @property {UploaderFilePreviewProp} preview
 * @property {Object|boolean} unsplash
 * @property {Object|boolean} pintura
 * @property {Object|boolean} focalPoint
 * @property {React.MutableRefObject<undefined>} fileUploadRef
 * @property {function} onChange
 */

/** 
 * @type {React.Context<UploaderContext>}
 */
export const UploaderContext = createContext(null);