import classNames from 'classnames';
import _isFunction from 'lodash/isFunction';
import {useContext} from "react";
import FormContext from "../form-context";
import _cloneDeep from "lodash/cloneDeep";

export default function Paragraph(props) {
    const context = useContext(FormContext);
    const value = _isFunction(props.value) ? props.value(_cloneDeep(context.values)) : props.value;
    const className = _isFunction(props.className === 'function') ? props.className(context.values) : props.className;
    return (
        <p className={classNames("my-1 text-sm font-medium whitespace-pre-line", className)}>
            {value}
        </p>
    )
};