export const reachSavingsGoalCalculator = {
    label: "Savings Goal Calculator",
    fields: [
        {
            label: "Savings Goal",
            type: "currency",
            name: "savingsGoal",
            inlineAddons: true,
            required: true
        },
        {
            label: "Current Savings",
            type: "currency",
            name: "currentSavings",
            default: 0
        },
        {
            label: "Monthly Contribution",
            type: "currency",
            name: "monthlyContribution",
            required: true
        },
        {
            label: "Annual Interest Rate",
            type: "number",
            name: "annualInterestRate",
            required: true,
            suffix: "%",
            default: "3"
        }
    ],
    variables: {
        monthlyInterestRate: {
            expression: "annualInterestRate / 100 / 12"
        }
    },
    formulas: [
        {
            name: "monthsToGoal",
            expression: "Math.log((savingsGoal * monthlyInterestRate + monthlyContribution) / (monthlyContribution + currentSavings * monthlyInterestRate)) / Math.log(1 + monthlyInterestRate)"
        }
    ],
    output: ({ computedValues }) => {
        const { monthsToGoal } = computedValues;

        if (monthsToGoal <= 0 || isNaN(monthsToGoal) || !isFinite(monthsToGoal)) {
            return (
                <div className="outputs bg-secondary p-4 rounded-theme border border-2 mt-4">
                    <p>
                        Please adjust your inputs to achieve your savings goal.
                    </p>
                </div>
            );
        }
        const years = Math.floor(monthsToGoal / 12);
        const months = Math.ceil(monthsToGoal % 12);
        return (
            <div className="outputs bg-secondary p-4 rounded-theme border-base border-2 mt-4">
                <p>
                    You will reach your savings goal in approximately{" "}
                    {years > 0 && ` ${years} ${years === 1 ? 'year' : 'years'} and `}
                    {months} {months === 1 ? 'month' : 'months'}.
                </p>
            </div>
        );
    },
}
