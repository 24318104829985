import Design1 from './design-1';
import Design2 from './design-2';
import Design3 from './design-3';
import Design4 from './design-4';

import './index.css';

export default function HeaderBlock(props) {
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} />);
        case 1:
            return (<Design2 {...props} />);
        case 2:
            return (<Design3 {...props} />);
        case 3:
            return (<Design4 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
}