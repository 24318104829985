import WebsiteForm from "../../components/website-form";
import classNames from "classnames";
import Currency from "@autocx/forms/src/controls/currency";

/**
 * The Enquiry purchase method UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function EnquiryPurchaseMethod(props) {
    return <WebsiteForm product={{id: props.values?.uuid || props.id}} {...props} label={"Send Enquiry"}
                        submitButtonClassName={"w-full justify-center"}/>
}


export function scrollToForm(e) {
    const firstNameInput = document.querySelector('input[name="customer.firstName"]');
    const formContainer = document.getElementById("purchase-enquiry")
    if (firstNameInput) {
        firstNameInput.focus();
    }
    formContainer.scrollIntoView()
}

export function EnquireButton({className}) {
    return (
        <button className={classNames(className, "button shrink-0")} onClick={(e) => scrollToForm(e)}>
            Enquire Now
        </button>
    )
}

/**
 * The UI for Mobile/Small Screen CTA on producrt pages
 * @param context
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function EnquiryActionBanner({context, className, salesEvent, ...props}) {
    return (
        <>
            {/*Handle showing sale prices. Hide sale price if it's 0 and there's a sales event*/}
            {!props?.values?.productTemplate?.hidePriceOnWebsite && ((salesEvent && props.values.sellingPrice !== 0) || !salesEvent) ?
                <div className={classNames("flex flex-col truncate", className)}>
                    <div>
                        {props.values?.discountedSellingPrice ? (
                            <span className={classNames(
                                "text-2xl",
                                "font-semibold"
                            )}>
                                    <Currency.DisplayValue currency={context.currency}
                                                           value={props.values?.discountedSellingPrice}
                                                           emptyPlaceholder={""}/>
                                </span>
                        ) : null}
                        <span
                            className={classNames("text-xl", props.values?.discountedSellingPrice ? "line-through ml-2 opacity-80" : "font-semibold")}>
                                {props.values?.sellingPrice ?
                                    <Currency.DisplayValue currency={context.currency}
                                                           value={props.values?.sellingPrice}
                                                           emptyPlaceholder={""}/>
                                    : "Price on Application"}
                        </span>
                    </div>
                    {props.values?.sellingPriceDisclaimer ? (
                        <div className="truncate pr-4">
                            {props.values?.sellingPriceDisclaimer}
                        </div>
                    ) : null}
                </div>
                : null}
            <EnquireButton className={"h-12"}/>
        </>
    )
}