import classNames from 'classnames';

export default function Step(props) {
    return (
        (props.title || props.text) ?
            (<div className={classNames(
                "!mt-2",
                props.className
            )}>
                {props.title &&
                    <h4 className={"!mb-0"}>{props.title}</h4>}
                {props.text && <div dangerouslySetInnerHTML={{__html: props.text}} className={"rich-text mb-2"}/>}
            </div>) : null
    );
};