import React, {useContext, useMemo} from "react";
import PropTypes from 'prop-types';
import _isArray from "lodash/isArray";
import _get from "lodash/get";
import _set from "lodash/set";
import classNames from "classnames";
import _kebabCase from "lodash/kebabCase";

import SortableTree from "./tree/sortable-tree";
import {getValue, isDisabled} from "../utils";
import FormContext from "../form-context";
import TreeControlContext from "./tree/components/tree-control-context";

Tree.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    fieldsets: PropTypes.array,
    hiddenFields: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    idFieldPath: PropTypes.string,
    typeFieldPath: PropTypes.string,
    labelFieldPath: PropTypes.string,
    childrenFieldPath: PropTypes.string,
    defaultItemLabel: PropTypes.string,
    addActions: PropTypes.arrayOf(PropTypes.string),
    indicator: PropTypes.bool,
    indentationWidth: PropTypes.number,
    maxDepth: PropTypes.number,
    onFieldChange: PropTypes.func.isRequired,
    types: PropTypes.object,
    interactions: PropTypes.shape({
        collapsable: PropTypes.bool,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
        removable: PropTypes.bool,
    }),
};

Tree.defaultProps = {
    idFieldPath: 'id',
    typeFieldPath: 'type',
    labelFieldPath: 'label',
    childrenFieldPath: 'children',
    defaultItemLabel: "Item",
    addActions: [],
    indicator: false,
    indentationWidth: 24,
    maxDepth: 1,
    onFieldChange: () => {},
    interactions: {
        collapsable: true,
        editable: true,
        sortable: true,
        removable: true,
    }
};

/**
 * @typedef {object} TreeProps
 * @property {string} id
 * @property {string} className
 * @property {object[]} fieldsets
 * @property {object} hiddenFields
 * @property {string} name
 * @property {string} label
 * @property {string} idFieldPath A field path used to get the value of the id. This will be used to map the current 
 *                                values into tree items
 * @property {string} typeFieldPath A field path used to get the value of the type. This will be used to map the current
 *                                values into tree items 
 * @property {string} labelFieldPath A field path used to get the value of the label. This will be used to map the current
 *                                values into tree items
 * @property {string} childrenFieldPath A field path used to get the value of the children. This will be used to map the current
 *                                values into tree items
 * @property {string} [defaultItemLabel='Item'] A label that is used as the suffix of action labels. For example the 
 *                                              value could be `Item` and thus the Add action will display as `Add Item`
 * @property {string[]} [addActions=[`Add ${defaultItemLabel}`]] An array of add actions. By default this will be 
 *                                                               populated with `Add Item`. It allows
 *                                                               to add additional add action options. 
 *                                                               For example [`Add Item`, `Add Group`].
 * @property {boolean} [indicator=false] True to show an indicator when dragging an item otherwise a clone of the item 
 *                                       that looks like a ghost
 * @property {number} indentationWidth States how wide the indentation of nested tree items are in pixels
 * @property {number} [maxDepth=1] States how deep any node can go. Default is 1 level down. This can be overridden 
 *                                 by setting the maxDepth field on the type definition
 * @property {object} types Defines specific settings for types of tree items. The key is the value of the type. The 
 *                          value is the settings to override defaults. To specify default settings for types that don't
 *                          match a specify type. Use `default` as the key.
 * @property {Interactions} interactions Defines what interactions a user can do with the control
 * @property {OnFieldChange} onFieldChange Invoked when this field is changed
 */

/**
 * @typedef {function} OnFieldChange
 * @param {Event} e A reference to the event that has caused the change
 * @param {string} name The name of the field
 * @param {*} value The new value of the field
 * @param {object} values The values in the FormContext
 */

/**
 * @typedef {object} Interactions
 * @property {boolean} collapsable True if items that have children can be collapsed and expanded
 * @property {boolean} editable True if items are editable. This also shows/hides add actions in control
 * @property {boolean} sortable True if items can be rearranged
 * @property {boolean} removable True if items can be removed
 */

/**
 * @param {TreeProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Tree(props) {
    const context = useContext(FormContext);
    const value = getValue(props, context.values);
    const disabled = isDisabled(props, context.values);

    const hasLabel = !!props.label;
    const showEdit = props.editing && !props.system;
    const id = props.id || props.name;
    
    // Take the current value and map it to the sortable tree spec
    const defaultItems = useMemo(() => {
        if (!_isArray(value) || !value.length) return [];
        
        return value.reduce(function mapToTreeItem(result, item) {
            const treeItem = {original: item};
            _set(treeItem, 'id', _get(item, props.idFieldPath));
            _set(treeItem, 'type', _get(item, props.typeFieldPath));
            _set(treeItem, 'label', _get(item, props.labelFieldPath));
            _set(treeItem, 'children', _get(item, props.childrenFieldPath));
            
            if (_isArray(treeItem.children) && treeItem.children.length) {
                treeItem.children = treeItem.children.reduce(mapToTreeItem, []);
            }
            
            result.push(treeItem);
            return result;
        }, []);
    }, [value]);

    return (
        <div className={classNames(props.className, `control-${props.type}-${_kebabCase(props.label)}`)}>
            <div className={`flex mb-1 ${props.label ? 'justify-between' : 'justify-end'}`}>
                {hasLabel ? (
                    <label htmlFor={id} className={"block text-sm font-semibold text-gray-700"}>
                        {props.label}
                    </label>
                ) : null}

                {showEdit ? (
                    <span
                        className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                        onClick={props.onEditFieldSettings}
                    >Edit</span>
                ) : null}
            </div>
            {props.description ? (
                <p className="-mt-1 mb-0 text-xs font-medium text-gray-500">
                    {props.description}
                </p>
            ) : null}
            <SortableTree
                {...props}
                disabled={disabled} 
                defaultItems={defaultItems} 
            />
        </div>
    );
};

export {
    TreeControlContext
};