import React, {useContext, useEffect, useRef, useState} from "react";
import {WebsiteContext} from "../../../../websites";
import axios from "axios";
import classNames from "classnames";
import {RadioGroup} from "@headlessui/react";
import {Image} from "../../../../websites/src/components/image";
import Spinner from "../../../../websites/src/components/spinner";

const VALUEPATH = `evalExpert`;

//Enquiry Settings 
evalexpert.EnquiryEditView = () => false;

// Control Settings
evalexpert.ControlConfigurationFields = () => ([])
evalexpert.ControlDisplaySettings = () => ({
    label: "EvalExpert",
    value: "plugins.Syple.Velocity.Plugins.EvalExpert",
    id: VALUEPATH,
    group: {label: "Plugins"}
});

// Default Settings
evalexpert.DisplayView = (props) => {
    return (props?.value?.EvalID === undefined || props?.value?.EvalID === null) && !props.value.VIN 
        ?
        <div className="flex flex-col w-full">
            <div className="rounded-md bg-yellow-50 p-2 my-2">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor"
                             aria-hidden="true">
                            <path fillRule="evenodd"
                                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <div className="ml-1.5">
                        <p className="text-sm font-medium text-yellow-800">
                            There is not enough information for this vehicle to be sent to EvalExpert
                        </p>
                    </div>
                </div>
            </div>
            <h3 className={"font-semibold text-gray-700"}>Vehicle Details</h3>
            <div className="text-gray-700">
                <span className={"text-gray-500"}>Registration / VIN: </span><span
                className={"uppercase"}>{props.formValues?.vinOrRegistration ? `${props.formValues?.vinOrRegistration} (${props?.formValues?.state})` : "—"}</span>
            </div>
            <div className="text-gray-700">
                <span
                    className={"text-gray-500"}>Odometer: </span>{props.formValues?.odometer ? props.formValues?.odometer + " km" : "—"}
            </div>
            <div>
            </div>
        </div>
        : (
            <div className="flex flex-col w-full">
                <h3 className={"font-semibold text-gray-700"}>{props.value?.Year} {props.value?.Make} {props.value?.Model} {props.value?.Variant} {props.value?.MY}</h3>
                {props.value?.Ext_Colour &&
                    <div className="text-gray-700">
                        <span className={"text-gray-500"}>Colour:</span> {props.value?.Ext_Colour}
                    </div>
                }
                <div className="text-gray-700">
                    <span className={"text-gray-500"}>Registration: </span><span
                    className={"uppercase"}>{props.value?.Registration ? `${props.value?.Registration} (${props?.formValues?.state})` : "—"}</span>
                </div>
                <div className="text-gray-700">
                    <span className={"text-gray-500"}>VIN: </span><span
                    className={"uppercase"}>{props.value?.VIN ? props.value?.VIN : "—"}</span>
                </div>
                <div className="text-gray-700">
                    <span
                        className={"text-gray-500"}>Odometer: </span>{props.formValues?.odometer ? props.formValues?.odometer + " km" : "—"}
                </div>
            </div>
        );
}
evalexpert.EditView = (props) => {
    const websiteContext = useContext(WebsiteContext);
    const [vinOrRegistration, setVinOrRegistration] = useState(props.values?.vinOrRegistration)
    const [state, setState] = useState(props.values?.state)
    const [odometer, setOdometer] = useState(props.values?.odometer)
    const [results, setResults] = useState(props.value);
    const [options, setOptions] = useState();
    const [error, setError] = useState(false);
    const [searching, setSearching] = useState(false)
    const inputRef = useRef();
    const initialiseRef = useRef(false);
    const isWebsite = !!websiteContext.website;

    const handleOnChange = (e) => {
        props.onChange(e, e.target.name, e.target.value);
        setError(false);
    }

    useEffect(() => {
        if (initialiseRef.current) {
            props.onChange({target: inputRef.current}, inputRef.current.name, {...results})
        }
    }, [results]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        if (vinOrRegistration && state && initialiseRef.current) {
            setSearching(true)
            setResults();
            setOptions();
            axios.post('/api/v2/webhooks/plugins/workspace/Syple.Velocity.Plugins.EvalExpert/search', {
                vinOrRegistration,
                state
            }, {cancelToken: cancelToken.token})
                .then((response) => {
                    if (response.data?.type === "multiple_plate_matches") {
                        setOptions(response.data.vehicles);
                    } else if (response.data.EvalID) {
                        setResults(response.data);
                    } else if (response.data.VIN && response.data.VechielDescriptions) {
                        const newOptions = response.data.VechielDescriptions.map(vh => {
                            return {
                                description: vh.Description,
                                VIN: response.data.VIN
                            }
                        })
                        setOptions(newOptions);
                    }
                    setSearching(false)
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.log(err)
                        setResults();
                        setOptions();
                        setError(true);
                        setSearching(false)
                    }
                })
        }
        if (!initialiseRef.current) initialiseRef.current = true;
        return () => cancelToken.cancel();
    }, [vinOrRegistration, state]);

    return (
        <div className="relative">
            {!error &&
                <input ref={inputRef} type="text" className="absolute opacity-0 pointer-events-none" tabIndex={-1}
                       name={VALUEPATH}
                       required={isWebsite} defaultValue={results}/>}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className={"space-y-3 grid-cols-1"}>
                    <div className="flex flex-col space-y-1">
                        <label htmlFor="vinOrRegistration">Registration or VIN*</label>
                        <div className="w-full relative">
                            <input type="text"
                                   className="font-mono uppercase flex-1 px-1.5 py-1 block w-full text-sm rounded-none focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-l rounded-r shadow-sm w-full"
                                   id="vinOrRegistration"
                                   name="vinOrRegistration"
                                   required={isWebsite}
                                   onChange={(e) => {
                                       setVinOrRegistration(e.target.value);
                                       handleOnChange(e)
                                   }}
                                   defaultValue={vinOrRegistration}
                                   data-error-message="Vin or Registration is required"/>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <label htmlFor="state">State*</label>
                        <div className="w-full relative">
                            <select
                                required={isWebsite}
                                onChange={(e) => {
                                    setState(e.target.value);
                                    handleOnChange(e)
                                }}
                                defaultValue={state}
                                className="px-1.5 py-1 shadow-sm block w-full text-sm rounded focus:ring-primary-500 focus:border-primary-500 border-gray-300 pr-6"
                                id="state"
                                name="state">
                                <option label="Select state"></option>
                                <option value="ACT">ACT</option>
                                <option value="NSW">NSW</option>
                                <option value="NT ">NT</option>
                                <option value="QLD">QLD</option>
                                <option value="SA ">SA</option>
                                <option value="TAS">TAS</option>
                                <option value="VIC">VIC</option>
                                <option value="WA ">WA</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <label htmlFor="odometer">How many kms has it done?</label>
                        <div className="w-full relative">
                            <input
                                type="number"
                                className="flex-1 px-1.5 py-1 block w-full text-sm rounded-none focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-l rounded-r shadow-sm w-full"
                                id="odometer"
                                name="odometer"
                                placeholder="Approximate amount is fine"
                                required={isWebsite}
                                onChange={(e) => {
                                    let value = Number(e.target.value);
                                    setOdometer(value);
                                    props.onChange(e, e.target.name, value);
                                }}

                                defaultValue={odometer}
                                data-error-message="Odometer is required"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="grid-cols-1 section-grey rounded-theme container-padding-half flex flex-col items-center justify-center">
                    {searching &&
                        <div className={"text-center font-semibold text-gray-600"}>
                            Finding your vehicle...
                            <Spinner/>
                        </div>
                    }
                    {!error && !options && !results && !searching &&
                        <div className={"text-center font-semibold text-gray-600 leading-[normal]"}>
                            Enter your vehicle details so we can look it up to provide an accurate valuation
                        </div>
                    }
                    {error && (
                        <div className={"font-semibold"}>
                            <div className={"text-red-500"}>
                                We couldn't find your vehicle.<br/>Please double check your details.
                            </div>
                            <div className={"mt-6"}>
                                <input
                                    type={"checkbox"}
                                    name={"verifiedVehicleDetails"}
                                    id={"verifiedVehicleDetails"}
                                    required
                                />
                                <label htmlFor={"verifiedVehicleDetails"} className={"ml-2 !text-sm font-semibold"}>My
                                    vehicle details are correct</label>
                            </div>
                        </div>
                    )}
                    {options && !searching && (
                        <>
                            <span className={"text-center font-semibold text-gray-600 text-sm"}>
                                We found multiple results, please select the most accurate vehicle
                            </span>
                            <RadioGroup
                                className={"mt-2 w-full"}
                                defaultValue={results}
                                onChange={(value) => {
                                    setResults(value)
                                }}>
                                <RadioGroup.Label className="sr-only">Vehicles</RadioGroup.Label>
                                <div className={"bg-white rounded -space-y-px shadow-sm"}>
                                    {options.map((option, index) => (
                                        <RadioGroup.Option
                                            key={index}
                                            value={option}
                                            className={({checked}) => classNames(
                                                "relative border px-3 py-2 flex focus:outline-none cursor-pointer",
                                                index === 0 ? 'rounded-tl rounded-tr' : null,
                                                index === options.length - 1 ? 'rounded-bl rounded-br' : null,
                                                checked ? 'z-10 bg-primary-50 border-primary-200' : 'border-gray-200'
                                            )}>
                                            {({active, checked}) => (
                                                <>
                                                    <div className="flex flex-col text-sm">
                                                        <div>
                                                            <RadioGroup.Label as="span" className={classNames(
                                                                'block font-semibold',
                                                                checked ? 'text-primary-900' : 'text-gray-700'
                                                            )}>
                                                                {option.description}
                                                            </RadioGroup.Label>
                                                            <RadioGroup.Description as="span" className={classNames(
                                                                'block',
                                                                checked ? 'text-primary-700' : 'text-grey-500'
                                                            )}>
                                                                VIN: {option.VIN}
                                                            </RadioGroup.Description>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    ))}
                                </div>
                            </RadioGroup>
                        </>
                    )}
                    {results && results.EvalID && !searching && (
                        <div
                            className={classNames("h-full w-full flex flex-col", results.ImgURL.includes('nostockpic') ? "justify-center" : "justify-between")}>
                            {!results.ImgURL.includes('nostockpic') && (
                                <div className={"flex flex-col items-center"}>
                                    <Image
                                        src={results.ImgURL}
                                        alt={`${results.Make} ${results.Model}`}
                                        className="h-24 rounded-theme"
                                    />
                                </div>
                            )}
                            <div className="mt-1 text-sm font-medium text-gray-900 flex flex-col w-full">
                                <div
                                    className={"text-base font-bold mb-0.5"}>{results.Year} {results.Make} {results.Model} {results.Variant} {results.MY}</div>
                                {results.Ext_Colour &&
                                    <div className="text-sm text-gray-700">Colour: {results.Ext_Colour}</div>}
                                <div className="text-sm text-gray-700 uppercase font-mono">
                                    {results.Registration}
                                    {results.Registration && results.VIN && ' - '}
                                    {results.VIN}
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// Fallback to edit view
export default function evalexpert(props) {
    return <evalexpert.EditView {...props}/>
}