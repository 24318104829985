import PropTypes from 'prop-types';
import classNames from 'classnames';

import {evaluate, isChecked} from "../utils";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import Checkbox from "./checkbox";
import _isArray from "lodash/isArray";
import {useContext, useEffect, useState} from "react";
import FormContext from "../form-context";
import _without from 'lodash/without';

CheckboxList.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
    autoCapitalize: PropTypes.bool,
    modified: PropTypes.bool,
};

CheckboxList.defaultProps = {
    sorted: false,
    options: [],
    groupFieldPath: 'group.label',
    labelFieldPath: 'label',
    valueFieldPath: 'value',
    onChange: () => {
    },
    onLoad: options => options,
    autoCapitalize: false,
}

CheckboxList.DisplayValue = function (props) {
    if (isChecked(props)) {
        if (props.truthyDisplayValue) {
            return (<span>{props.truthyDisplayValue}</span>);
        } else {
            return (<CheckIcon className={"h-5 w-5 text-primary-500"}/>);
        }
    } else {
        if (props.falsyDisplayValue) {
            return (<span>{props.falsyDisplayValue}</span>);
        } else {
            return '\u2014';
        }
    }
}

CheckboxList.AppDisplayValue = function ({value}) {
    if (!Array.isArray(value)) return value;
    return (
        <ul className={"flex flex-row flex-wrap gap-2"}>
            {value?.map((value, index) => (
                <li key={index} className={"flex flex-row gap-0.5 items-center"}>
                    <CheckIcon className={"h-5 w-5 text-inherit"}/><div>{value}</div>
                </li>
            ))}
        </ul>
    )
}


export default function CheckboxList(props) {
    const context = useContext(FormContext);
    const [checkBoxValues, setCheckBoxValues] = useState(props.value || []);
    
    useEffect(() => {
        let value = !Array.isArray(props.value) ? [props.value] : props.value;
        value = value.filter(v => v !== '');
        setCheckBoxValues(value)
    }, [props.value])

    // Filter options out based on showsWhen evaluation
    const options = _isArray(props.options)
        ? props.options.filter(option => !(evaluate(option.value, option.showsWhen, context.values) === false)) 
        : [];

    return (
        <>
            {props.description ? (
                    <p className={classNames(
                        "whitespace-pre-line mr-2 text-sm mb-1.5 -mt-1",
                        props.disabled ? 'text-gray-400' : (props.error ? 'text-error-500' : 'text-gray-600'),
                    )}>
                        {props.description}
                    </p>
                ):null}
            <div className={classNames("space-y-2 grid grid-cols-1 gap-2 sm:space-y-0 sm:grid-cols-2", props.className)}>
                {options.map((option, index) => (
                    <Checkbox
                        type={"checkbox"}
                        modified={props.modified}
                        name={props.name}
                        key={`${option.label}_${option.value}`}
                        id={`${props.id}_${index}`}
                        label={option.label}
                        count={option.count}
                        value={checkBoxValues?.includes(option.value) || false}
                        disabled={props.disabled || option.disabled}
                        onChange={(e, name, value) => {
                            const values = value === true
                                ? [...checkBoxValues, option.value]
                                : _without(checkBoxValues, option.value)
                            setCheckBoxValues(values);
                            // Toggle the item in the array
                            props.onChange(
                                e,
                                props.name,
                                values
                            )
                        }}
                    />
                ))}
            </div>
        </>
    )
}
