import {forwardRef, useEffect, useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useCdnUrl from "../hooks/cdn-hook";

export const Image = forwardRef(function Image({
                                                   id,
                                                   imageId,
                                                   imageWidth,
                                                   imageHeight,
                                                   src,
                                                   pictureClassName,
                                                   className,
                                                   quality,
                                                   width,
                                                   height,
                                                   alt,
                                                   layout,
                                                   style,
                                                   onClick,
                                                   cdnUrl
                                               }, ref) {
    if (!src) src = 'https://dr1k2g3wmnols.cloudfront.net/images/image-placeholder.png';
    const [webpSupported, setWebpSupported] = useState(true);

    useEffect(() => {
        const elem = document.createElement('canvas');
        const supported = !!(elem.getContext && elem.getContext('2d')) ? elem.toDataURL('image/webp').indexOf('data:image/webp') === 0 : false
        setWebpSupported(supported)
    }, []);

    return (
        <>
            {src.endsWith('.svg')
                ? <img onClick={onClick} id={imageId} ref={ref} className={classNames(className)}
                       width={imageWidth} height={imageHeight}
                       src={useCdnUrl(src, null, null, null, quality, cdnUrl)} alt={alt} style={style}/>
                : <picture id={id} className={pictureClassName}>
                    {layout === 'fixed' && (
                        <>
                            {webpSupported
                                ? <source type="image/webp"
                                          srcSet={`${useCdnUrl(src, 'webp', width, height, quality, cdnUrl)} 1x,
                                          ${useCdnUrl(src, 'webp', width * 2, height, quality, cdnUrl)} 2x,
                                          ${useCdnUrl(src, 'webp', width * 3, height, quality, cdnUrl)} 3x`}
                                />
                                : <source type="image/jpeg"
                                          srcSet={`${useCdnUrl(src, null, width, height, quality, cdnUrl)} 1x,
                                          ${useCdnUrl(src, null, width * 2, height, quality, cdnUrl)} 2x, 
                                          ${useCdnUrl(src, null, width * 3, height, quality, cdnUrl)} 3x`}
                                />
                            }
                        </>
                    )}

                    {layout === 'responsive' && (
                        <>
                            {webpSupported
                                ? <>
                                    <source type="image/webp"
                                            srcSet={`${useCdnUrl(src, 'webp', 720, null, quality, cdnUrl)} 1x,
                                            ${useCdnUrl(src, 'webp', 720 * 2, null, quality, cdnUrl)} 2x, 
                                            ${useCdnUrl(src, 'webp', 720 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 640px)"
                                    />
                                    <source type="image/webp"
                                            srcSet={`${useCdnUrl(src, 'webp', 1440, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, 'webp', 1440 * 2, null, quality, cdnUrl)} 2x, 
                                            ${useCdnUrl(src, 'webp', 1440 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 1440px)"
                                    />
                                    <source type="image/webp"
                                            srcSet={`${useCdnUrl(src, 'webp', 1920, null, quality, cdnUrl)} 1x, 
                                             ${useCdnUrl(src, 'webp', 1920 * 2, null, quality, cdnUrl)} 2x, 
                                             ${useCdnUrl(src, 'webp', 1920 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 1920px)"
                                    />
                                    <source type="image/webp"
                                            srcSet={`${useCdnUrl(src, 'webp', 2560, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, 'webp', 2560 * 2, null, quality, cdnUrl)} 2x`}
                                            media="(min-width: 1921px)"
                                    />
                                </>
                                : <>
                                    <source type="image/jpeg"
                                            srcSet={`${useCdnUrl(src, null, 720, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, null, 720 * 2, null, quality, cdnUrl)} 2x, 
                                            ${useCdnUrl(src, null, 720 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 640px)"
                                    />
                                    <source type="image/jpeg"
                                            srcSet={`${useCdnUrl(src, null, 1440, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, null, 1440 * 2, null, quality, cdnUrl)} 2x, 
                                            ${useCdnUrl(src, null, 1440 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 1440px)"
                                    />
                                    <source type="image/jpeg"
                                            srcSet={`${useCdnUrl(src, null, 1920, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, null, 1920 * 2, null, quality, cdnUrl)} 2x, 
                                            ${useCdnUrl(src, null, 1920 * 3, null, quality, cdnUrl)} 3x`}
                                            media="(max-width: 1920px)"
                                    />
                                    <source type="image/jpeg"
                                            srcSet={`${useCdnUrl(src, null, 2560, null, quality, cdnUrl)} 1x, 
                                            ${useCdnUrl(src, null, 2560 * 2, null, quality, cdnUrl)} 2x`}
                                            media="(min-width: 1921px)"
                                    />
                                </>
                            }
                        </>
                    )}
                    <img onClick={onClick} id={imageId} ref={ref} className={classNames(className)}
                         width={imageWidth} height={imageHeight}
                         src={useCdnUrl(src, null, null, null, quality, cdnUrl)} alt={alt} style={style}/>
                </picture>}
        </>
    )
});

Image.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    pictureClassName: PropTypes.string,
    className: PropTypes.string,
    quality: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    layout: PropTypes.oneOf(['fixed', 'responsive'])
};

Image.defaultProps = {
    quality: 75,
    layout: 'fixed',
    width: 720,
};