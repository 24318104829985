import {useEffect} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import Design1 from './design-1';

TextBlock.propTypes = {
};

TextBlock.defaultProps = {};

export default function TextBlock(props) {
    switch (props.settings?.design) {
        case 0:
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};