import React, {useState, useEffect, Fragment, useContext} from 'react';
import axios from 'axios';
import {Combobox, Transition} from '@headlessui/react';
import classNames from "classnames";
import {Loader} from "@autocx/forms";
import PlusIcon from "@heroicons/react/solid/PlusCircleIcon";
import _get from "lodash/get"
import AnimateHeight from "react-animate-height";
import FormContext from "../form-context";
import {getValue} from "../utils";
import {toast} from "sonner";

const fetchTags = (link, cancelToken, setLabels, setLoading) => {
    axios.get(link, {cancelToken: cancelToken.token})
        .then(({data: tags}) => {
            const newTags = tags.map(tag => {
                const id = tag._links.edit ? tag._links.edit.href.split('/').pop() : tag._links.toggle.href.split('/').pop();
                return {...tag, id}
            })
            setLabels(newTags)
            if (setLoading) setLoading(false)
        })
        .catch((err) => {
            if (!axios.isCancel(err)) {
                console.log(err);
                if (setLoading) setLoading(false)
            }
        })
}

const EntityLabeler = ({link, readOnly = false, onLabelChange, ...props}) => {
    const context = useContext(FormContext);
    const [labels, setLabels] = useState([]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true)
    const labelsLink = link || _get(context.values, props?.linkPath) || '/api/v2/tags'
    const value = getValue(props, context.values);

    useEffect(() => {
        if (!labelsLink) return;
        const cancelToken = axios.CancelToken.source();
        fetchTags(labelsLink, cancelToken, setLabels, setLoading)
        return () => cancelToken.cancel();
    }, [link, props?.linkPath]);

    const handleLabelToggle = async (label) => {
        if (readOnly) return;
        if (label?._links?.toggle?.href) {
            axios.post(label._links.toggle.href)
                .then(() => {
                    const toggleState = !label.isTagged;
                    setLabels(currentLabels => currentLabels.map(l => l.id === label.id ? {
                        ...l,
                        isTagged: toggleState
                    } : l));
                    toast.success(toggleState ? 'Added' : 'Removed')
                    onLabelChange && onLabelChange();
                })
                .catch((err) => {
                    console.error('Error toggling label:', err);
                })
        } else {
            const toggleState = !label.isTagged;
            let values = value ? [...value] : [];

            if (toggleState) values.push(label.label)
            else values = values.filter(v => v !== label.label);

            setLabels(currentLabels => currentLabels.map(l => l.id === label.id ? {...l, isTagged: toggleState} : l));

            props.onFieldChange(null, props.name, values)
            onLabelChange && onLabelChange();
        }
    };

    const untaggedLabels = labels.filter(label => !label.isTagged && label.label.toLowerCase())
    // If query is empty show all labels otherwise filter
    const filterableLabels = query === ''
        ? untaggedLabels
        : untaggedLabels.includes(query.toLowerCase());

    const hasLabel = !!props.label;
    const showEdit = props.editing && !props.system;
    const showOptional = props.optional && !props.editing && !required && !props.readOnly && !props.disabled;
    const id = props.id || props.name;
    const error = _get(context.errors, props.name, false);

    return (
        <AnimateHeight height={labels.length > 0 ? 'auto' : 0} className={"!mt-0"}>
            <div className={classNames("mt-5", props.className)}>
                {hasLabel ? (
                    <label htmlFor={id} className={classNames(
                        "block text-sm font-semibold",
                        error && !props.disabled ? "text-error-500" : "text-gray-700",
                        props.labelClassName
                    )}>
                        {props.label}
                    </label>
                ) : null}

                {showEdit ? (
                    <span
                        className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                        onClick={props.onEditFieldSettings}
                    >Edit</span>
                ) : null}
                {showOptional ? (
                    <span className={classNames(
                        "text-xs font-medium",
                        "leading-5",
                        error && !props.disabled ? "text-error-500" : "text-gray-500"
                    )}>Optional</span>
                ) : null}
                <div className="flex flex-wrap gap-2 mt-1 relative">
                    {!loading ?
                        labels.filter(label => label.isTagged).map((label) => (
                            <div key={label.id} title={label.label}
                                 className="group flex items-center font-medium bg-gray-50 border border-panels-200 px-2 h-6 rounded-full text-sm">
                                <span>{label.label}</span>
                                {!readOnly && (
                                    <button type="button"
                                            className="text-gray-700 group-hover:text-red-500 cusor-pointer pl-2 pr-0.5 font-bold text-tiny"
                                            onClick={() => handleLabelToggle(label)}>
                                        &#10005;
                                    </button>
                                )}
                            </div>
                        ))
                        : labelsLink
                            ? <div
                                className="bg-gray-50 border-panels-200 h-6 w-16 animation-pulse-opacity animate-pulse rounded-full"/>
                            : <span>Add labels after saving</span>
                    }

                    {!loading && !readOnly &&
                        <Combobox onChange={(e) => handleLabelToggle(e)}>
                            <Combobox.Button
                                className={classNames("flex items-center justify-center text-xs font-semibold hover:opacity-80 text-link")}
                            >
                                <PlusIcon className="h-6 w-6" aria-hidden="true" aria-label="Add Label"/>
                            </Combobox.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-in duration-50"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-50"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                                afterLeave={() => setQuery('')}
                            >
                                <div className={"absolute w-full flex justify-center z-50"}>
                                    <div
                                        className={"absolute mt-1 max-h-60 w-60 overflow-auto rounded-lg bg-white pb-0.5 space-y-1 text-base shadow-lg border border-panels-300"}>
                                        <div className={"w-full sticky px-1.5 bg-white top-0"}>
                                            <Combobox.Input
                                                placeholder={"Find label..."}
                                                onChange={(event) => setQuery(event.target.value)}
                                                className={"w-full border-none py-1 px-2 text-sm text-gray-900 rounded-md border border-panels-200 mt-1.5 bg-gray-50"}
                                                displayValue={() => ""}
                                            />
                                        </div>
                                        <Combobox.Options>
                                            {loading ?
                                                <Loader loading={loading} className={"py-4"}/>
                                                :
                                                untaggedLabels.length > 0 ? filterableLabels.map((label) => (
                                                    <Combobox.Option key={label.id}
                                                                     value={label}
                                                                     className={({active}) => classNames("menu-item cursor-pointer", active ? "bg-primary-50" : null)}>
                                                        {label.label}
                                                    </Combobox.Option>
                                                )) : (
                                                    <div
                                                        className="px-4 py-2 text-sm text-gray-500">{!labels.length ? "Labels aren't set up" : "No more labels"}</div>
                                                )
                                            }
                                        </Combobox.Options>
                                    </div>
                                </div>
                            </Transition>
                        </Combobox>
                    }
                </div>
            </div>
        </AnimateHeight>
    );
};

EntityLabeler.DisplayView = ({link, readOnly, label, ...props}) => {
    return (
        <div
            className={"text-gray-600 font-medium"}>
            <EntityLabeler
                className={"!mt-0"}
                label={label}
                labelClassName={"text-gray-500 font-semibold h3 !text-[15px]"}
                link={link}
                readOnly={readOnly}
            />
        </div>
    )
}

export default EntityLabeler;
