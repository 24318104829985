export default function MoreIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className={props.className}
        >
            <g fill="none" fillRule="evenodd">
                <circle
                    cx="10"
                    cy="10"
                    r="9.25"
                    fill="#d2d6dc"
                    stroke="#4b5563"
                    strokeWidth="1.5"
                    className={props.innerCircleClassNames}
                ></circle>
                <g fill="#4b5563" className={props.ellipsisClassNames}>
                    <circle cx="5.5" cy="10" r="1.75"></circle>
                    <circle cx="10" cy="10" r="1.75"></circle>
                    <circle cx="14.5" cy="10" r="1.75"></circle>
                </g>
            </g>
        </svg>
    );
};