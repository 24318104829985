import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Fragment, useContext} from "react";
import FormContext from "../form-context";
import _isArray from "lodash/isArray";

import {Menu, Transition} from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/solid/ChevronRightIcon";
import ChevronLeftIcon from "@heroicons/react/solid/ChevronLeftIcon";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import PlusCircleIcon from "@heroicons/react/solid/PlusCircleIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import _isFunction from "lodash/isFunction";
import ProgressLoader from "../components/progress-loader";

const colorizeLink = {
    primary: 'text-primary-700',
    gray: 'text-gray-700',
    error: 'text-error-700',
}

const colorizeButton = {
    primary: 'text-primary-700 border-primary-700 bg-primary-50 hover:bg-primary-100 focus:ring-2 shadow-button',
    gray: 'text-gray-700 border-gray-700 bg-gray-50 hover:bg-gray-100 focus:ring-gray-500 shadow-button',
    error: 'text-error-700 border-error-700 bg-error-50 hover:bg-error-100 focus:ring-error-500 shadow-button',
}

const icons = {
    check: CheckCircleIcon,
    plus: PlusCircleIcon,
    trash: TrashIcon,
    'chevronRight': ChevronRightIcon,
    'chevronLeft': ChevronLeftIcon,
}

Action.propTypes = {
    primary: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'gray', 'error']),
    icon: PropTypes.oneOf(['check', 'plus', 'trash']),
    label: PropTypes.string,
    link: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}

Action.defaultProps = {
    primary: false,
    color: 'gray',
    link: false
}

/**
 * @typedef {Object} ActionProps
 * @property {boolean} primary
 * @property {string} className
 * @property {string} color
 * @property {string} icon
 * @property {string} label
 * @property {boolean} link
 * @property {function} onClick
 */

/**
 * Renders a form action as a button or a link
 * 
 * @param {ActionProps} props
 * @returns {JSX.Element}
 * @constructor
 */
function Action(props) {
    const context = useContext(FormContext);
    const Icon = icons[props.icon] || null;
    const submitting = context.progress !== undefined && context.progress !== null;

    return (
        <button
            type={props.primary ? "submit" : "button"}
            disabled={submitting}
            tabIndex={0}
            onClick={(e) => {
                if (submitting) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                props.onClick(e);
            }}
            className={classNames(
                // Base styles for all variants
                "relative inline-flex items-center justify-center px-2 h-8 text-sm font-medium focus:outline-none",

                // Style a link
                props.link ? (colorizeLink[props.color] || colorizeLink.gray) : null,

                // Style a button
                !props.link ? "border rounded-md focus:ring-2" : null,
                !props.link ? (colorizeButton[props.color] || colorizeButton.gray) : null,

                // Add any custom styles
                props.className
            )}
        >
            <span className={classNames("flex items-center", props.primary && submitting ? "invisible" : null)}>
                {Icon ? <Icon className={"w-5 h-5 mr-1"} /> : null}
                {props.label}
            </span>

            {props.primary && submitting ? (
                <>
                    {_isFunction(context.submittingState)
                        ? context.submittingState({progress: context.progress})
                        : context.submittingState || (
                        <ProgressLoader
                            className={"absolute inset-0 items-center"}
                            loading={true}
                            progress={context.progress}
                        />
                    )}
                </>
            ) : null}
        </button>
    )
}

ActionWithDropDown.propTypes = {
    primary: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'gray', 'error']),
    icon: PropTypes.oneOf(['check', 'plus', 'trash']),
    label: PropTypes.string,
    link: PropTypes.bool,
    onClick: PropTypes.func,
    onActionClick: PropTypes.func.isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
}

/**
 * @typedef {ActionProps} ActionWithDropDownProps
 * @property {function} onActionClick
 * @property {ActionProps[]} actions
 */

/**
 * Renders an {@link Action} with optional drop down if sub actions are defined for this action
 * 
 * @param {ActionWithDropDownProps} props
 * @param {function} props.onActionClick
 * @returns {JSX.Element}
 * @constructor
 */
function ActionWithDropDown({onActionClick, ...props}) {
    const context = useContext(FormContext);
    if (!_isArray(props.actions)) return <Action 
        {...props}
        onClick={e => props.onClick ? props.onClick(e, context.values) : onActionClick(e, props)}
    />

    return (
        <div className="relative inline-flex shadow-button rounded-md">
            <Action 
                {...props}
                className={classNames("rounded-r-none", props.className)}
                onClick={e => props.onClick ? props.onClick(e, context.values) : onActionClick(e, props)}
            />
            <Menu as="div" className="-ml-px relative">
                <Menu.Button
                    className="relative inline-flex items-center px-2 h-8 rounded-r-md border border-primary-700 bg-primary-50 text-sm font-medium text-primary-700 hover:bg-primary-100 focus:z-10 focus:outline-none">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right absolute z-10 right-0 mt-2 -mr-1 w-48 menu-container">
                        <div className="flex flex-col">
                            {props.actions.map((action, index) => (
                                <Menu.Item key={`${action.label}_${index}`}>
                                    <Action 
                                        {...action} 
                                        className={classNames(
                                            "grow block menu-item !justify-start",
                                            action.className
                                        )}
                                        onClick={e => action.onClick ? action.onClick(e, context.values) : onActionClick(e, action)}
                                    />
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

/**
 * @typedef {Object} ActionsProps
 * @property {string} className
 * @property {boolean} header
 * @property {ActionWithDropDownProps[]} actions
 * @property {function} onActionClick
 */

Actions.propTypes = {
    className: PropTypes.string,
    header: PropTypes.bool,
    actions: PropTypes.array,
    onActionClick: PropTypes.func,
};

Actions.defaultProps = {
    header: false,
    actions: [],
    onActionClick: () => {
    },
};

/**
 * Renders actions with in a flex container
 * 
 * @param {ActionsProps} props
 * @param {boolean} props.header
 * @param {string} props.className
 * @param {ActionWithDropDownProps[]} props.actions
 * @param {function} props.onActionClick
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Actions({header, className, actions, onActionClick}) {
    const context = useContext(FormContext);

    // Don't render actions if it's invalid or empty
    if (!_isArray(actions) || !actions.length) return null;

    const primaryActions = [];
    const cancelActions = [];
    const secondaryActions = [];
    const destructiveActions = [];

    // Map actions into there category
    for (const action of actions) {
        if (action.primary) {
            primaryActions.push(action);
        } else if (action.destructive) {
            destructiveActions.push(action)
        } else if (action.back) {
            cancelActions.push(action);
        } else {
            secondaryActions.push(action);
        }
    }

    const left = (
        <div className={"flex items-center space-x-1"}>
            {destructiveActions.map((action, index) => (
                <Action
                    key={`${action.label}_${index}`}
                    color={"error"}
                    {...action}
                    onClick={e => action.onClick ? action.onClick(e, context.values) : onActionClick(e, action)}
                />
            ))}
        </div>
    );

    const right = (
        <div className={classNames(
            "flex items-center space-x-1",
            header ? className : null
        )}>
            {cancelActions.map((action, index) => (
                <Action
                    key={`${action.label}_${index}`}
                    color={'gray'}
                    {...action}
                    link
                    onClick={e => action.onClick ? action.onClick(e, context.values) : onActionClick(e, action)}
                />
            ))}
            {header && destructiveActions.map((action, index) => (
                <Action
                    key={`${action.label}_${index}`}
                    color={"error"}
                    {...action}
                    onClick={e => action.onClick ? action.onClick(e, context.values) : onActionClick(e, action)}
                />
            ))}
            {secondaryActions.map((action, index) => (
                <ActionWithDropDown
                    key={`${action.label}_${index}`}
                    color={'gray'}
                    {...action}
                    onActionClick={onActionClick}
                />
            ))}
            {primaryActions.map((action, index) => (
                <ActionWithDropDown
                    key={`${action.label}_${index}`}
                    color={'primary'}
                    {...action}
                    onActionClick={onActionClick}
                />
            ))}
        </div>
    )

    return header ? right : (
        <>
            {context.layout !== "product-search-block" ?
                <div className={classNames("flex justify-between", className)}>
                    {left}
                    {right}
                </div>
                :
                secondaryActions.map((action, index) => (
                    <Action
                        key={`${action.label}_${index}`}
                        {...action}
                        onClick={e => action.onClick ? action.onClick(e, context.values) : onActionClick(e, action)}
                    />
                ))
            }
        </>
    );
};