import Design1 from './design-1';
import Design2 from "./design-2";
import Design3 from "./design-3";

ProductCard.propTypes = {};

ProductCard.defaultProps = {};

export default function ProductCard(props) {
    const design = props?.product?.salesEvent ? 0 : props.settings?.design;

    switch (design) {
        case 0 :
            return (<Design1 {...props} />);
        case 1 :
        case 'Syple.Velocity.Plugins.TruckDealer' :
            return (<Design2 {...props} />);
        case 2 :
        case 'Syple.Velocity.Plugins.CarDealer' :
            return (<Design3 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};