import {useEffect, useRef} from 'react';

import ImageUploaderFilePreview from "../previews/image-uploader-preview";

FocalPointEditor.propTypes = {
    
};

FocalPointEditor.defaultProps = {};

/**
 * Integrates the image-focus library with the uploader to set a focal point on the image
 * 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FocalPointEditor(props) {
    const containerRef = useRef();
    const imageRef = useRef();

    useEffect(() => {
        if (props.file.status === 'adjusting') {
            let focusedImage;
            let focusPicker;
            let focusProcessor;
            let onMouseUp;

            // Dynamically load image-focus when it's needed
            window.requestAnimationFrame(() => {
                import('image-focus')
                    .then(({FocusedImage, FocusPicker}) => {
                        // Setup focal point image
                        const previewImage = document.querySelector(`#image_${props.file.id}`);
                        if (!previewImage) return;
    
                        const initialFocus = props.file.pintura.imageState.focus || {x: 0, y: 0};

                        window.requestAnimationFrame(() => {
                            focusPicker = new FocusPicker(imageRef.current, {
                                onChange: focus => {
                                    if (focusedImage) {
                                        focusedImage.setFocus(focus);
                                    }
                                },
                                focus: initialFocus,
                                retina: "data:image/svg+xml,%0A%3Csvg width='36px' height='36px' viewBox='0 0 36 36' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M24.875,31 L24.875,36.25 L23.125,36.25 L23.125,31 L24.875,31 Z M17,23.125 L17,24.875 L11.75,24.875 L11.75,23.125 L17,23.125 Z M36.25,23.125 L36.25,24.875 L31,24.875 L31,23.125 L36.25,23.125 Z M24.875,11.75 L24.875,17 L23.125,17 L23.125,11.75 L24.875,11.75 Z M38,13 L38,35 C38,36.6568542 36.6568542,38 35,38 L13,38 C11.3431458,38 10,36.6568542 10,35 L10,13 C10,11.3431458 11.3431458,10 13,10 L35,10 C36.6568542,10 38,11.3431458 38,13 Z M34.75,11.75 L13.25,11.75 C12.4215729,11.75 11.75,12.4215729 11.75,13.25 L11.75,34.75 C11.75,35.5784271 12.4215729,36.25 13.25,36.25 L34.75,36.25 C35.5784271,36.25 36.25,35.5784271 36.25,34.75 L36.25,13.25 C36.25,12.4215729 35.5784271,11.75 34.75,11.75 Z' id='path-cxqhphlajd-1'%3E%3C/path%3E%3Cfilter x='-21.4%25' y='-21.4%25' width='142.9%25' height='142.9%25' filterUnits='objectBoundingBox' id='filter-cxqhphlajd-2'%3E%3CfeOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0.13747597 0 0 0 0 0.138277358 0 0 0 0 0.00119342608 0 0 0 0.402886664 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-Focal-Point-UI' transform='translate(-6.000000, -6.000000)' fill-rule='nonzero'%3E%3Cg id='Rectangle'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-cxqhphlajd-2)' xlink:href='%23path-cxqhphlajd-1'%3E%3C/use%3E%3Cuse fill='%23FEFF00' xlink:href='%23path-cxqhphlajd-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E",
                            });

                            onMouseUp = function () {
                                // Update the state on mouse up so that we can persist the changes
                                props.file.update({pintura: {imageState: {focus: focusPicker.focus}}});
                            };

                            focusPicker.container.addEventListener('mouseup', onMouseUp);

                            focusedImage = new FocusedImage(previewImage, {
                                focus: initialFocus
                            });
                        });
                    });
            });

            return () => {
                focusProcessor && focusProcessor.destroy();
                focusedImage && focusedImage.stopListening();
                onMouseUp && focusPicker.container.removeEventListener('mouseup', onMouseUp);
                focusPicker && focusPicker.disable();
                focusedImage = null;
                focusPicker = null;
                focusProcessor = null;
            }
        }
    }, [props.file.status]);
    
    return (
        <ImageUploaderFilePreview
            containerRef={containerRef}
            imageRef={imageRef}
            objectFit={"contain"}
            objectPosition={"center"}
            {...props}
        />
    );
};