import Design1 from './design-1';
import Design2 from './design-2';

import './index.css';
import {Disclosure} from "@headlessui/react";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";

export const AUCTIONSTATUSENUM = {
    NOT_OPEN: 'notOpen',
    NOT_STARTED: 'notStarted',
    OPEN: 'open',
    CLOSED: 'closed',
    ENDED: 'ended'
};

export function getStatus(salesEvent, startDate, endDate, secondsToStart, secondsRemaining, winner) {
    if (salesEvent?.lotStatus === "Sold") return AUCTIONSTATUSENUM.CLOSED;
    if (!salesEvent || (startDate === null && endDate === null)) return AUCTIONSTATUSENUM.NOT_OPEN;

    if (secondsToStart > 0) {
        return AUCTIONSTATUSENUM.NOT_STARTED;
    } else if (secondsRemaining > 0) {
        return AUCTIONSTATUSENUM.OPEN;
    } else if (secondsRemaining < 1 && secondsToStart === 0) {
        return winner ? AUCTIONSTATUSENUM.CLOSED : AUCTIONSTATUSENUM.ENDED;
    }
    return AUCTIONSTATUSENUM.NOT_OPEN;
}

ProductBlock.propTypes = {};

ProductBlock.defaultProps = {};


export function Accordion({title, text, className}) {
    return (<Disclosure as="div" className={classNames(className, "flex flex-col py-2")}>
        {({open}) => (
            <>
                <Disclosure.Button className="w-full flex justify-between items-center">
                    <div className={"text-left font-semibold text-base"}>
                        {title}
                    </div>
                    <span className="ml-6 flex items-center">
                        <span className={classNames(
                            "border-current",
                            open ? "chevron--up" : "chevron--down"
                        )}>
                            <svg
                                className={"h-5 w-5"}
                                width="24"
                                height="24"
                                version="1.1"
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="xMidYMid"
                                stroke="currentColor">
                                <g className="chevron__container">
                                    <line className="chevron__line1" x1="10" y1="50" x2="50"
                                          y2="50"/>
                                    <line className="chevron__line2" x1="90" y1="50" x2="50"
                                          y2="50"/>
                                </g>    
                            </svg>
                        </span>
                    </span>
                </Disclosure.Button>

                <AnimateHeight
                    id={`${title}`}
                    height={open ? 'auto' : 0}
                    animateOpacity={true}
                >
                    <Disclosure.Panel as="div" static className={classNames("mb-2 rich-text")}>
                        {text}
                    </Disclosure.Panel>
                </AnimateHeight>
            </>
        )}
    </Disclosure>)
}

/**
 * Displays a CTA on mobile/small screens
 * @param children
 * @param className
 * @param bannerRef
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ProductCtaContainer({children, className, bannerRef, ...props}) {
    return (
        <div className={classNames(
            "text-left section-dark container-padding flex flex-row justify-between items-center h-20 sm:hidden transition-[padding,bottom] duration-300",
            className
        )} ref={bannerRef}>
            {children}
        </div>
    )
}

export function getProductFields(userDefinedFieldsets) {
    return userDefinedFieldsets?.map((userDefinedFieldset) => {
        const fieldsets = {...userDefinedFieldset, fields: []}

        userDefinedFieldset.fields.forEach((field) => {
            if ((field.label || field.value) && !field.hideOnProductDetailPage) fieldsets.fields.push(field);
        })
        return fieldsets.fields.length > 0 ? fieldsets : null;
    }).filter(f => !!f);
}

export default function ProductBlock(props) {
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} />);
        case 1 :
        case 'Syple.Velocity.Plugins.TruckDealer':
        case 'Syple.Velocity.Plugins.CarDealer':
        case 'SALESEVENT':
            return (<Design2 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};