import {useContext, useState} from "react";
import axios from "axios";

import Design1 from './design-1';
import {WebsiteContext} from "../../index";
import {HONEY_POT_FIELD_NAME} from "../../components/website-form";

export default function FormBlock(props) {
    let Design;
    switch (props.values?.design) {
        case 0:
            Design = Design1
            break;
        default:
            Design = Design1
    }

    return (
        <Design
            {...props}
            label={props?.values?.action?.label}
        />
    )
}