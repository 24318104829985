import React, {useCallback, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import Action from "../components/action";
import {Menu, Transition} from '@headlessui/react'

import {WebsiteContext} from "../index";

MultiColumn.propTypes = {};

MultiColumn.defaultProps = {};

const blogPages = (siblings) => {
    const context = useContext(WebsiteContext);
    const key = context.builder ? 'children' : 'items';

    return siblings.map((sibling, index) => {
        // Ignore rendering empty folders
        if (sibling.nodeType === 'folder' && !sibling[key]?.length) return null;

        return (
            <ul role={"list"} className={"space-y-9"} key={`ul_${sibling.uniqueUri}_${index}`}>
                <li key={`${sibling.uniqueUri}_${index}`} className="list-none leading-snug mb-1">
                    {sibling.action
                        ? (
                            <a href={sibling.action.value}
                               className={classNames(
                                   sibling.action.value === location.pathname ? 'font-semibold' : '',
                                   "!text-inherit"
                               )}>{sibling.title}</a>
                        )
                        : sibling.nodeType === 'folder'
                            ? (
                                <a href={sibling.uri}
                                   className={classNames(
                                       sibling.uri === location.pathname ? 'font-semibold' : '',
                                       "!text-inherit"
                                   )}>{sibling.title}</a>
                            )
                            : (
                                <span className={"text-inherit"}>{sibling.title}</span>
                            )}
                    {sibling.nodeType !== 'blog' ? (
                        <ul role={"list"}
                            className={"mt-1 mb-2"}>
                            {sibling[key]?.map((child, index) => (
                                <li key={`${child.uniqueUri}_${index}`} className={"relative leading-snug mb-1"}>
                                    <Action
                                        {...child}
                                        button={false}
                                        showingActionButton={true}
                                        className={classNames(
                                            "block pl-3 -ml-px",
                                            child.action?.value === location.pathname
                                                ? '!text-[color:var(--primary-button-background-colour)] border-current font-semibold'
                                                : '!text-inherit hover:opacity-80'
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </li>
            </ul>
        )
    })
}

const sections = (headings, currentBlock) => {
    const context = useContext(WebsiteContext);
    const key = context.builder ? 'children' : 'items';

    function isActive(block) {
        if (block?.id === currentBlock) return true;
        if (!block[key]?.length) return false;
        return block[key].findIndex(isActive) > -1
    }

    return headings?.map((heading) => (
        <li key={heading?.id} className="list-none">
            <a href={`${location.pathname}#${heading.id}`} className={classNames(
                "group flex items-start mb-0.5",
                isActive(heading) ? "!text-[color:var(--primary-button-background-colour)] font-semibold" : "!text-inherit hover:opacity-80",
                context.builder ? "pointer-events-none" : null,
            )}>
                {heading.title}
            </a>
        </li>
    ))
}

export default function MultiColumn({children, ...props}) {
    const context = useContext(WebsiteContext);
    const key = context.builder ? 'children' : 'items';
    const [currentBlock, setCurrentBlock] = useState();
    const rootPage = context?.rootPage ? {...context?.rootPage, [key]: []} : {[key]: []}
    const siblings = context?.rootPage?.[key] ? [rootPage, ...context.rootPage?.[key]] : [rootPage];
    const allLinks = [];

    siblings?.forEach((item) => {
        if (item.action) allLinks.push(item);
        if (item[key]) {
            item[key].forEach((subItems) => {
                if (subItems.action) allLinks.push(subItems)
            })
        }
    })

    let headerBlockList = null;
    let restBlockList;

    if (children && children.props.blocks && children.props.blocks[0].type === 'header-block') {
        const headerBlock = children.props.blocks[0];
        // Create a new BlockList instance with only the header block
        headerBlockList = React.cloneElement(children, {
            ...children.props,
            blocks: [headerBlock]
        });
        // Create another BlockList instance without the header block
        restBlockList = React.cloneElement(children, {
            ...children.props,
            blocks: children.props.blocks.slice(1)
        });
    } else {
        // If there's no header-block, just render children as is
        restBlockList = children;
    }

    let linkIndex = allLinks.findIndex((link) => link.action?.value === location.pathname);
    const previousPage = allLinks[linkIndex - 1];
    const nextPage = allLinks[linkIndex + 1];

    // This is a differnt children field from what the component expects in the builders context.
    if (context.builder) {
        delete nextPage?.children
        delete previousPage?.children
    }

    // Gather all the heading elements for all the blocks
    const getHeadings = useCallback((blocks) => {
        const headings = [];
        blocks?.map(block => {
            const id = `block_${block.id}`
            let top;
            if (typeof window !== 'undefined') {
                const blockElement = document.getElementById(id);
                if (blockElement) {
                    const scrollMt = parseFloat(window.getComputedStyle(blockElement)?.scrollMarginTop)
                    top = window.scrollY + blockElement?.getBoundingClientRect().top - scrollMt - 1;
                }
            }

            if (block.values?.title) headings.push({id, title: block.values?.title, top})
        })
        return headings;
    }, []);

    const headings = getHeadings(restBlockList?.props?.blocks);

    useEffect(() => {
        if (!headings.length) return;
        const navHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--site-nav-height')) || 0;

        function onScroll() {
            let top = window.scrollY + navHeight + 16
            let current = headings[0]?.id
            for (let heading of headings) {
                if (top >= heading.top) {
                    current = heading?.id
                } else {
                    break
                }
            }
            setCurrentBlock(current)
        }

        window.addEventListener('scroll', onScroll, {passive: true})
        onScroll()
        return () => {
            window.removeEventListener('scroll', onScroll, {passive: true})
        }
    }, [headings]);

    return (
        <>
            {!context.builder && <div
                className={"relative sticky top-[var(--navigation-mobile-spacing)] sm:top-[var(--navigation-spacing)] z-10"}>
                <div
                    className={"sm:hidden w-full section-dark border-t border-white items-center p-2 flex justify-between"}>
                    <div className="flex items-center max-w-[75%]">
                        <Menu as="div">
                            {({open}) => (
                                <>
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center"
                                                     style={{fontWeight: 501}}>
                                            <div className="truncate max-w-[100px]">{context.node.title}</div>
                                            <span className={classNames(
                                                "border-current ml-1 h-6 w-6",
                                                open ? "chevron--up" : "chevron--down"
                                            )}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        version="1.1"
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        preserveAspectRatio="xMidYMid"
                                                        stroke="currentColor">
                                                        <g className="chevron__container">
                                                            <line className="chevron__line1" x1="20" y1="50" x2="50"
                                                                  y2="50"/>
                                                            <line className="chevron__line2" x1="80" y1="50" x2="50"
                                                                  y2="50"/>
                                                        </g>    
                                                    </svg>
                                                </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items
                                            className="absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-theme section-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="p-4 space-y-4">
                                                {blogPages(siblings)}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </>)}
                        </Menu>
                        {allLinks && linkIndex !== null && linkIndex !== undefined ? (
                            <></>
                            // <span className="ml-4 text-white truncate">{allLinks[linkIndex].title}</span>
                        ) : null}
                    </div>
                    {headings?.length > 0 && (
                        <Menu as="div">
                            {({open}) => (
                                <>
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center"
                                                     style={{fontWeight: 501}}>
                                            Sections
                                            <span className={classNames(
                                                "border-current ml-1 h-6 w-6",
                                                open ? "chevron--up" : "chevron--down"
                                            )}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        version="1.1"
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        preserveAspectRatio="xMidYMid"
                                                        stroke="currentColor">
                                                        <g className="chevron__container">
                                                            <line className="chevron__line1" x1="20" y1="50" x2="50"
                                                                  y2="50"/>
                                                            <line className="chevron__line2" x1="80" y1="50" x2="50"
                                                                  y2="50"/>
                                                        </g>    
                                                    </svg>
                                                </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-theme section-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="p-4">
                                                <ol role="list" className="space-y-1 text-sm">
                                                    {sections(headings, currentBlock)}
                                                </ol>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </>)}
                        </Menu>
                    )}
                </div>
            </div>
            }
            <div className={"relative flex flex-col ch text-[larger]"}>
                {headerBlockList}
                <div className={"flex justify-center mt-8"}>
                    <div className="sm:w-[930px] w-full">
                        {restBlockList}
                        {(previousPage || nextPage) ? (
                            <div className="mx-auto max-w-7xl flex flex-col items-center text-left text-base">
                                <dl className="mt-12 flex border-t border-slate-200 p-6 w-full">
                                    {previousPage ? (
                                        <div className={"w-64"}>
                                            <Action
                                                {...previousPage}
                                                startAdornment={<span aria-hidden="true"
                                                                      className={"text-2xl"}>&larr;&nbsp;</span>}
                                                button={false}
                                                showingActionButton={true}
                                                className="font-semibold text-inherit inline-flex items-center"
                                            />
                                        </div>
                                    ) : null}
                                    {nextPage ? (
                                        <div className="ml-auto text-right w-64">
                                            <Action
                                                {...nextPage}
                                                endAdornment={<span aria-hidden="true"
                                                                    className={"text-2xl"}>&nbsp;&rarr;</span>}
                                                button={false}
                                                showingActionButton={true}
                                                className="font-semibold text-inherit inline-flex items-center"
                                            />
                                        </div>
                                    ) : null}
                                </dl>
                            </div>
                        ) : null}
                    </div>
                    {!context.builder &&
                        <div
                            className={"sticky top-[calc(16px+var(--navigation-mobile-spacing))] sm:top-[calc(16px+var(--navigation-spacing))] " +
                                "w-72 z-10 mx-4 overflow-visible section-white p-4 mt-8 overflow-y-auto self-start space-y-6 hidden sm:block text-base"}>
                            <div>
                                <h5 className={"font-semibold !mb-1"}>{headings?.length > 0 && `Sections`}</h5>
                                {sections(headings, currentBlock)}
                            </div>
                            <div>
                                <h5 className={"font-semibold !mb-1"}>Read Next</h5>
                                {blogPages(siblings)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};