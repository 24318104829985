import classNames from "classnames";

import Block from '../block'
import {
    backgroundColour,
    backgroundPattern,
} from "../../utils/block-utils";

/*
    Sizes: small, medium, large, extra-large, full
 */

export default function Design1(props) {

    return (
        <Block {...props} className={classNames(backgroundPattern(props), backgroundColour(props.values?.options?.backgroundColour))}>
            <div
                className={classNames(
                    "section",
                    props?.values?.size ?? 'small'
                )}
            />
        </Block>
    )
}
