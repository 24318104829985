import PropTypes from "prop-types";
import classNames from "classnames";
import {getSteps, validate} from "@autocx/forms/src/utils";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import {textAlignment} from "../utils/block-utils";
import AnimateHeight from "react-animate-height";
import Form, {FormContext} from "@autocx/forms";
import {WebsiteContext} from "../index";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import _get from "lodash/get";
import _set from "lodash/set";
import _unset from "lodash/unset";
import {getActionLink} from "./action";

export const HONEY_POT_FIELD_NAME = 'hp_rOjpajZy97BsNhvhRc3dJ';

WebsiteForm.propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    submitting: PropTypes.bool,
    submitted: PropTypes.bool,
};

WebsiteForm.defaultProps = {
    onSubmit: () => {
    },
};

/**
 * Returns form spec fields used to capture customer data
 *
 * @param options {customer: boolean, matchBy: number}
 * @param disabled
 * @param honeyPotFieldName
 * @param context
 * @param step
 * @returns {object}
 */
export function getSubmissionHandlingFields(options, disabled = false, honeyPotFieldName, context, step) {
    const {customer, matchBy, requriedFields, registerCustomer} = options || {};
    const emailRequirued = (matchBy === 3 && requriedFields)
        ? (requriedFields === 1 || requriedFields === 3)
        : "customer.phoneNumbers[0].number === undefined || customer.phoneNumbers[0].number === null || customer.phoneNumbers[0].number === ''";

    const phoneRequirued = (matchBy === 3 && requriedFields)
        ? (requriedFields === 2 || requriedFields === 3)
        : matchBy === 2;

    return [
        {
            system: true,
            type: "step",
            id: "customerStep",
            title: step?.title,
            text: step?.text,
            name: "customerStep"
        },
        customer ? {
            system: true,
            type: "control-group",
            fields: [
                {
                    system: true,
                    type: "text",
                    label: "First Name",
                    name: "customer.firstName",
                    maxLength: 150,
                    required: true,
                    errorMessage: "First name is required",
                    disabled,
                },
                {
                    system: true,
                    type: "text",
                    label: "Last Name",
                    name: "customer.lastName",
                    maxLength: 150,
                    disabled,
                },
            ],
        } : null,
        matchBy === 3 || matchBy === 1 ? {
            system: true,
            type: "email",
            autoComplete: "email",
            label: "Email",
            id: "customer.emailAddress",
            name: "customer.emailAddress",
            maxLength: 250,
            required: matchBy === 3 ? emailRequirued : true,
            errorMessage: "Email or phone number is required",
            disabled,
        } : null,
        matchBy === 3 || matchBy === 2 ? {
            system: true,
            type: "phone-number-input",
            label: "Contact Number",
            id: "customer.contactNumber",
            autoComplete: "tel",
            name: "customer.contactNumber",
            maxLength: 30,
            defaultCountry: context?.locale?.split('-')[1] ?? "AU",
            displayInitialValueAsLocalNumber: true,
            required: phoneRequirued,
            disabled,
        } : null,
        registerCustomer ? {
            system: true,
            type: "password",
            label: "Create Password",
            autoComplete: "new-password",
            name: "customer.password",
            canRevealSecret: true,
            id: "customer.password",
            maxLength: 50,
            required: true,
            errorMessage: "Password is required",
            disabled,
        } : null
    ].filter(field => !!field);
}

const Steps = ({currentStep, steps, ...props}) => {
    return (
        <nav aria-label="Progress" className="w-full mb-4">
            <ol role="list" className="flex items-center">
                {steps?.map((step, stepIdx) => (
                        <li key={stepIdx}
                            className={classNames(stepIdx !== steps.length - 1 ? 'flex-grow' : '', 'relative')}>
                            {currentStep > (stepIdx + 1) ? (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-base"/>
                                    </div>
                                    <div onClick={(e) => {
                                        e.preventDefault();
                                        const newFieldSet = getSteps([...props.values.form.userDefinedFieldsets], (stepIdx + 1));
                                        setState({
                                            error: false,
                                            errorMessage: null,
                                            submitted: false,
                                            submitting: false,
                                            form: {
                                                ...props.values?.form,
                                                userDefinedFieldsets: newFieldSet
                                            }
                                        })
                                    }}
                                         className="relative flex h-6 w-6 items-center justify-center rounded-full bg-base hover:bg-base cursor-pointer">
                                        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                                    </div>
                                </>
                            ) : currentStep === (stepIdx + 1) ? (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-gray-200"/>
                                    </div>
                                    <div
                                        className="relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-base bg-white"
                                        aria-current="step"
                                    >
                                        <span className="h-2.5 w-2.5 rounded-full bg-base" aria-hidden="true"/>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="h-0.5 w-full bg-gray-200"/>
                                    </div>
                                    <div
                                        className="group relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                    <span className="h-2.5 w-2.5 rounded-full bg-transparent"
                                          aria-hidden="true"/>
                                    </div>
                                </>
                            )}
                        </li>
                    )
                )}
            </ol>
        </nav>
    )
}

export default function WebsiteForm(props) {
    const [height, setHeight] = useState('auto');
    const context = useContext(FormContext);
    const websiteContext = useContext(WebsiteContext);
    const contentDiv = useRef(null);
    const action = props?.values?.overrideFormAction ? props?.values?.action : props?.values?.form?.action
    const href = action && action?.type ? getActionLink(action, websiteContext, '/') : null;
    const [state, setState] = useState(() => {
        // noinspection UnnecessaryLocalVariableJS
        /**
         * Here to give the idea i hit as to what the return type is
         * @type {{}}
         */
        const form = props.values?.form ? {...props.values.form} : {}
        const fields = getSubmissionHandlingFields(form?.rules, false, HONEY_POT_FIELD_NAME, websiteContext, form?.step);
        const fieldsets = fields?.length > 0 ? fields : [];
        let newUserDefinedFieldsets = [];
        if (props.values?.form?.userDefinedFieldsets) {
            newUserDefinedFieldsets = [...props.values.form.userDefinedFieldsets]
            newUserDefinedFieldsets[0].fields = [...fieldsets, ...newUserDefinedFieldsets[0].fields]
        } else {
            newUserDefinedFieldsets = [{fields: fieldsets}]
        }

        form.userDefinedFieldsets = getSteps([...newUserDefinedFieldsets])
        return {
            error: false,
            errorMessage: null,
            submitted: false,
            submitting: false,
            form,
        }
    })

    const formUuid = state?.form?.uuid || state?.form?._id;
    const currentStep = state?.form?.userDefinedFieldsets?.[0]?.currentStep;
    const totalSteps = state?.form?.userDefinedFieldsets?.[0]?.totalSteps;
    const steps = state?.form?.userDefinedFieldsets?.[0]?.steps;
    const fileUploadSettings = {
        cdnUrl: websiteContext?.cdnUrl,
        saveUrl: `/api/v2/attachments/${websiteContext?.website?.uuid}`,
        basePath: websiteContext?.website?.uuid,
        source: 'website'
    };

    useEffect(() => {
        if ((!websiteContext.builder && !websiteContext.picker) || !props.values?.form?.uuid) return;
        const form = state?.form ? {...state?.form} : {}
        const fields = getSubmissionHandlingFields(form?.rules, false, HONEY_POT_FIELD_NAME, websiteContext, form?.step);
        const fieldsets = fields?.length > 0 ? fields : [];
        let newUserDefinedFieldsets = [];
        if (state?.form?.userDefinedFieldsets) {
            newUserDefinedFieldsets = [...state?.form.userDefinedFieldsets]
            newUserDefinedFieldsets[0].fields = [...fieldsets, ...newUserDefinedFieldsets[0].fields]
        } else {
            newUserDefinedFieldsets = [{fields: fieldsets}]
        }
        form.userDefinedFieldsets = getSteps([...newUserDefinedFieldsets])

        setState({
            error: false,
            errorMessage: null,
            submitted: false,
            submitting: false,
            form
        })
    }, [state?.form?.rules, state?.form?.step]);

    // Handle when the builder has changed the form to update the form for this block 
    useEffect(() => {
        // Ignore it when we are not in the builder/picker or there is no form uuid to lookup
        if ((!websiteContext.builder && !websiteContext.picker) || !props.values?.form?.uuid) return;

        const cancelToken = axios.CancelToken.source();
        axios
            .get(`/api/v2/channels/${websiteContext.channel}/forms/${props.values.form.uuid}`, {cancelToken: cancelToken.token})
            .then(({data: form}) => {
                setState(previous => ({...previous, form}));
            })
            .catch(err => {
                if (!axios.isCancel(err)) console.log(err);
            })
        return () => cancelToken.cancel();
    }, [props.values?.form?.uuid]);

    useEffect(() => {
        if (contentDiv.current) {
            const element = contentDiv.current
            const resizeObserver = new ResizeObserver(() => {
                setHeight(element.clientHeight)
            })

            resizeObserver.observe(element)

            return () => resizeObserver.disconnect()
        }
    }, [contentDiv?.current])

    useEffect(() => {
        setState((p) => ({
            ...p,
            error: props.error,
            errorMessage: props.errorMessage,
            submitted: props.submitted,
            submitting: props.submitting
        }))
    }, [props.error, props.errorMessage, props.submitted, props.submitting]);

    return (
        <>
            {steps && steps.length > 1 && !state.submitted && (
                <Steps currentStep={currentStep} totalSteps={totalSteps} steps={steps} {...props}/>
            )}
            {state.submitted && !action?.type ? (
                <div className={classNames("flex flex-col w-full gap-1", textAlignment(props))}>
                    <div>
                        <div className={"inline-block"}>
                            <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" className={"w-14"}>
                                <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd"
                                   strokeLinecap="round" strokeLinejoin="round">
                                    <path className="form-submit-circle"
                                          d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                                    <path className="form-submit-tick" d="M6.5 14.5L10 18 l8.808621-8.308621"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {props?.values?.form?.customSuccessMessage
                        ?
                        <div dangerouslySetInnerHTML={{__html: props?.values?.form?.customSuccessMessage}}
                             className={"rich-text"}/>
                        :
                        <p>Thank you, we've received your submission.</p>
                    }
                </div>
            ) : (
                <AnimateHeight
                    height={(steps && steps.length > 1 || !websiteContext?.picker) ? height : 'auto'}
                    contentRef={contentDiv}
                    animateOpacity={true}
                    className={classNames(
                        (steps && steps.length > 1 || !websiteContext?.picker) ? "!overflow-visible" : null,
                        "flex flex-col w-full px-1"
                    )}>
                    <Form
                        id={state.form.uuid || state.form._id}
                        fileUploadSettings={fileUploadSettings}
                        noValidate={false}
                        values={context.values}
                        onFieldChange={(e, name, value, values) => {
                            if (state.error) setState((p) => ({...p, error: false, errorMessage: ''}))
                            context.values = values
                        }}
                        userDefinedFieldsets={state.form.userDefinedFieldsets}
                        fieldsets={state.form.fieldsets}
                        layout={"website"}
                        className={classNames("flex flex-col w-full")}
                        onSubmit={(e, values) => {
                            setState(previous => ({...previous, submitting: true, error: false, errorMessage: null}));

                            const honeyPotElem = document.querySelector(`#${HONEY_POT_FIELD_NAME}`);

                            // If there is any sign of a value on the honey pot input then ignore submission and go to success state
                            if (honeyPotElem.value || honeyPotElem._valueTracker?.getValue()) {
                                setState({
                                    error: false,
                                    errorMessage: null,
                                    submitting: false,
                                    submitted: true,
                                })
                                return;
                            }
                            if (values.customer) values.customer.enableLogin = state?.form?.rules?.enableLogin ?? true

                            values.form = {uuid: e.target.id};
                            if (props.node) values.node = props.node;
                            if (props.product) values.product = props.product;

                            // Remove all whitespace from the number if it's set when the phone number is present
                            const matchBy = state.form.rules?.matchBy;
                            if (matchBy === 2 || matchBy === 3) {
                                const number = _get(values, 'customer.contactNumber');
                                if (number) {
                                    _set(values, 'customer.contactNumber', number.replace(/ +/g, '').trim());
                                } else {
                                    _unset(values, 'customer.contactNumber')
                                }
                            }

                            return axios
                                .post(`/api/v2/channels/${websiteContext.channel}/responses`, values)
                                .then(() => {
                                    if (props?.values?.form?.formSubmission) new Function(props.values.form.formSubmission)()
                                    setState(previous => ({...previous, submitted: true, submitting: false}))

                                    if (action && action?.type && href) {
                                        // Redirect users after delay
                                        setTimeout(() => {
                                            window.location.href = href;
                                        }, 2000)
                                    }

                                })
                                .catch(err => {
                                    if (err?.response?.status === 409 && err?.response?.data) setState(previous => ({
                                        ...previous,
                                        error: true,
                                        errorMessage: "There is already an account associated with this email.",
                                        submitting: false
                                    }))
                                    else {
                                        console.log(err)
                                        setState(previous => ({...previous, error: true, submitting: false}))
                                    }
                                });
                        }}>
                        {/* This is here to prevent spam bots */}
                        <p hidden>
                            <input
                                id={HONEY_POT_FIELD_NAME}
                                type="text"
                                name={HONEY_POT_FIELD_NAME}
                                autoComplete="off"
                            />
                        </p>

                        {state.error ? (
                            <div
                                className={"mt-2 text-red-500 font-semibold leading-tight text-sm animate__animated animate__headShake"}>
                                {state.errorMessage ?? "There was a problem sending the message, please try again."}
                            </div>
                        ) : null}
                        <div className={classNames(
                            currentStep > 1 && currentStep <= totalSteps ? 'flex justify-between space-x-6' : null
                        )}>
                            {currentStep > 1 && currentStep <= totalSteps && (
                                <div className={"flex justify-end mt-4"}>
                                    <button
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const newFieldSet = getSteps([...props.values.form.userDefinedFieldsets], (currentStep - 1));
                                            setState({
                                                error: false,
                                                errorMessage: null,
                                                submitted: false,
                                                submitting: false,
                                                form: {
                                                    ...props.values?.form,
                                                    userDefinedFieldsets: newFieldSet
                                                }
                                            })
                                        }}
                                        className={classNames("button hover:opacity-80 relative", state.submitting ? "pointer-events-none" : null)}>
                                        <span>Back</span>
                                    </button>
                                </div>
                            )}
                            {currentStep < totalSteps && (
                                <div className={"flex ml-auto justify-end mt-4"}>
                                    <button
                                        type="submit"
                                        form={state.form.uuid}
                                        onClick={(e) => {
                                            const form = document.getElementById(formUuid)
                                            const isValid = validate(form, setState, false);
                                            if (!isValid) return;
                                            e.preventDefault();
                                            const newFieldSet = getSteps([...props.values.form.userDefinedFieldsets], (currentStep + 1));
                                            setState({
                                                error: false,
                                                errorMessage: null,
                                                submitted: false,
                                                submitting: false,
                                                form: {
                                                    ...props.values?.form,
                                                    userDefinedFieldsets: newFieldSet
                                                }
                                            })
                                        }}
                                        className={classNames("button hover:opacity-80 relative", state.submitting ? "pointer-events-none" : null)}>
                                        <span>Next</span>
                                    </button>
                                </div>
                            )}
                            {currentStep === totalSteps ? (
                                <div className={"flex justify-end flex-grow mt-4"}>
                                    <button
                                        type="submit"
                                        className={classNames("button overflow-hidden justify-center hover:opacity-80 relative", props?.submitButtonClassName, (state.submitting || state.submitted) ? "pointer-events-none" : null)}
                                        form={state.form.uuid}>
                                            <span
                                                className={classNames("transition ease-in duration-200", (state.submitting || state.submitted) ? "opacity-0" : "opacity-100")}>{props?.label || "Submit"}</span>
                                        {state.submitting ? (
                                            <svg
                                                className={"absolute animate-spin h-5 w-5 top-0 bottom-0 left-0 right-0 m-auto"}
                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"/>
                                                <path className="opacity-75" fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                            </svg>
                                        ) : null}
                                        {state.submitted && action?.type &&
                                            <span
                                                className={classNames(
                                                    "absolute flex transition ease-in duration-200 animate__animated",
                                                    state.submitted ? "animate__fadeInUp" : "opacity-0"
                                                )}
                                            >
                                                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"
                                                     className={"w-6"}>
                                                    <g stroke="currentColor" strokeWidth="2" fill="none"
                                                       fillRule="evenodd"
                                                       strokeLinecap="round" strokeLinejoin="round">
                                                        <path className="form-submit-circle"
                                                              d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                                                        <path className="form-submit-tick"
                                                              d="M6.5 14.5L10 18 l8.808621-8.308621"/>
                                                    </g>
                                                </svg>
                                            </span>
                                        }
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </Form>
                </AnimateHeight>
            )}
        </>
    )
};