import PropTypes from 'prop-types';
import classNames from 'classnames';

import {isChecked} from "../utils";
import CheckIcon from "@heroicons/react/outline/CheckIcon";

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    disclaimer: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    truthyValue: PropTypes.any,
    falsyValue: PropTypes.any,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    onChange: () => {}
}

Checkbox.DisplayValue = function (props) {
    if (isChecked(props)) {
        if (props.truthyDisplayValue) {
            return (<span>{props.truthyDisplayValue}</span>);
        } else {
            return (<CheckIcon className={"h-5 w-5 text-primary-500"} />);
        }
    } else {
        if (props.falsyDisplayValue) {
            return (<span>{props.falsyDisplayValue}</span>);
        } else {
            return '\u2014';
        }
    }
}

export default function Checkbox(props) {
    return (
        <div className={classNames(
            props.colour ? "items-center" : "items-top",
            "relative flex"
        )}>
            <div className={classNames(
                "flex items-center",
                props.colour ? "h-7" : "h-5")}>
                {props.colour && isChecked(props) && (
                    <div className={"h-7 w-7 absolute pointer-events-none left-0 top-0.5 rounded-full bg-neutral-800/25 bg-[url('data:image/svg+xml,%3csvg%20viewBox=%270%200%2016%2016%27%20fill=%27white%27%20xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath%20d=%27M12.207%204.793a1%201%200%20010%201.414l-5%205a1%201%200%2001-1.414%200l-2-2a1%201%200%20011.414-1.414L6.5%209.086l4.293-4.293a1%201%200%20011.414%200z%27/%3e%3c/svg%3e')]"}/>
                )}
                <input
                    className={classNames(
                        `h-4 w-4 mt-1 rounded shadow-sm`,
                        props.disabled ? 'bg-gray-50 border-gray-300' : null,
                        props.error && !props.disabled ? 'bg-error-50 focus:ring-error-500 focus:border-error-500 border-error-300' : null,
                        !props.error && !props.disabled ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300' : null,
                        props.modified ? 'bg-primary-50' : null,
                        props.className
                    )}
                    style={props.style}
                    type="checkbox"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    checked={isChecked(props)}
                    required={props.required}
                    disabled={props.disabled}
                    data-error-message={props.errorMessage}
                    onChange={e => {
                        // If either truthy or falsy value is provided then use these values or empty string when 
                        // setting the value of this switch
                        if ((props.truthyValue !== undefined && props.truthyValue !== null) || (props.falsyValue !== undefined && props.falsyValue !== null)) {
                            props.onChange(e, props.name, e.target.checked ? props.truthyValue : props.falsyValue);
                        } else if (props.invertValue) {
                            props.onChange(e, props.name, !e.target.checked);
                        } else {
                            props.onChange(e, props.name, e.target.checked);
                        }
                    }}
                />
            </div>
            <div className="ml-2 text-sm">
                <label htmlFor={props.id} className={classNames(
                    "font-medium cursor-pointer",
                    props.disabled ? 'text-gray-500' : (props.error ? 'text-error-900' : null)
                )}>
                    {props.label}
                    {props.editing && !props.system ? (
                        <span
                            className={"ml-1 text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                            onClick={props.onEditFieldSettings}
                        >Edit</span>
                    ) : null}
                </label>
                {props.count !== undefined && props.count !== null && (
                    <span
                        className="ml-1.5 rounded bg-gray-200 px-1 py-0.5 text-xs font-semibold tabular-nums text-gray-600">
                        {props.count}
                    </span>
                )}
                {props.disclaimer ? (
                    <span className={classNames(
                        props.disabled ? 'text-gray-400' : (props.error ? 'text-error-500' : 'text-gray-500')
                    )}> {props.disclaimer}</span>
                ) : null}
                {props.description ? (
                    <p className={classNames(
                        "whitespace-pre-line text-xs font-medium",
                        props.disabled ? 'text-gray-400' : (props.error ? 'text-error-500' : 'text-gray-500')
                    )}>
                        {props.description}
                    </p>
                ) : null}
            </div>
        </div>
    )
}
