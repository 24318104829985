import {useContext} from 'react';
import {WebsiteContext} from '../index';

export default function useCdn(value, type, width, height, quality, cdnUrl) {
    const context = useContext(WebsiteContext);
    if (!context.cdnUrl) context.cdnUrl = cdnUrl;
    // Handle if we are not working in the website context
    if (!context) return value;

    // Check if we can work with a string value
    if (value === undefined || value === null || typeof value !== 'string' || !value.trim()) return value;

    height = height ? height : 0;
    width = width ? width : context.picker ? 400 : 0;
    quality = quality ? quality : context.picker ? 40 : 88;

    // If it's an unsplash image link then rewrite the image resize options 
    if (value.includes('unsplash.com')) {
        const url = new URL(value)
        if (width > 0) url.searchParams.set('w', width.toString())

        if (height > 0) url.searchParams.set('h', height.toString())

        url.searchParams.set('q', quality.toString());

        if (type === 'webp') url.searchParams.set('fm', 'webp');

        return url.toString();
    }

    // Ignore absolute urls that are not unsplash
    if (value.startsWith('//') || value.includes('://')) return value;

    value = !value.startsWith('/') ? `/${value}` : value;

    if (value.endsWith('.svg') || value.endsWith('.gif') || type === 'file' || type === 'video') {
        return `${context.fileDownloadUrl}${value}`;
    } else if (value.startsWith('/resize')) {
        return `${context.cdnUrl}${value}`;
    } else if (type === 'webp') {
        return `${context.cdnUrl}/resize${value}/fit-in/${width}x${height}/filters:quality(${quality})/filters:format(webp)`;
    } else if (value.endsWith('.png')) {
        return `${context.cdnUrl}/resize${value}/fit-in/${width}x${height}/filters:quality(${quality})/filters:format(webp)`;
    } else {
        return `${context.cdnUrl}/resize${value}/fit-in/${width}x${height}/filters:quality(${quality})/filters:format(jpg)`;
    }
}