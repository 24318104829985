import {forwardRef} from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ExclamationCircleIcon from '@heroicons/react/solid/ExclamationCircleIcon'

const Textarea = forwardRef((props, ref) => {
    return (
        <div className="w-full relative">
            <textarea
                autoFocus={props.autofocus}
                ref={ref}
                className={classNames(
                    `px-1.5 py-1 shadow-sm block w-full text-sm rounded`,
                    props.disabled ? 'bg-gray-50 border-gray-300 placeholder-gray-400' : null,
                    props.error && !props.disabled ? 'focus:ring-error-500 focus:border-error-500 border-error-300' : null,
                    !props.error && !props.disabled ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300' : null,
                    props.modified ? 'bg-primary-50' : null,
                    props.className
                )}
                id={props.id}
                name={props.name}
                rows={props.rows}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                required={props.required}
                disabled={props.disabled}
                data-error-message={props.errorMessage}
                onInvalid={props.errorMessage ? (e) => {
                    e.target.setCustomValidity("");
                    if (!e.target.validity.valid) {
                        e.target.setCustomValidity(props.errorMessage)
                    }
                } : null}
                value={props.value}
                onChange={e => {
                    props.onChange(e, props.name, e.target.value);
                }}
            />
            {props.error ? (
                <div className="absolute inset-y-0 right-0 pt-1.5 pr-1.5 flex items-start pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-error-500" aria-hidden="true"/>
                </div>
            ) : null}
        </div>
    )
});

Textarea.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

Textarea.defaultProps = {
    onChange: () => {},
    rows: 8
}

Textarea.DisplayValue = function (props) {
    return props.value === null || props.value === undefined ? '\u2014' : props.value;
}

export default Textarea;