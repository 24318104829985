// Handles importing all controls. This code only works in a webpack build environment
// <<<Start>>>
const CONTROLS = {};

function importAll(r) {
    r.keys().forEach((key) => {
        const type = key.split('/').slice(-1)[0].split('.').shift();
        // exclude self
        if (type !== 'index' && type !== 'field') {
            CONTROLS[type] = r(key)?.default;
        }
    });
}

importAll(require.context('./', false, /\.jsx$/));

// <<<End>>>

export default CONTROLS;