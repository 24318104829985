import classNames from "classnames";
import Block from "../block";
import {useContext, useEffect, useState} from "react";
import _startCase from "lodash/startCase";
import {WebsiteContext} from "../../index";
import Form from "@autocx/forms";
import axios from "axios";
import DateOfBirth from "@autocx/forms/src/controls/date-of-birth";
import {formatAddress} from "@autocx/forms/src/controls/address";

export default function AccountProfile(props) {
    const context = useContext(WebsiteContext);
    const [state, setState] = useState('');
    const [profileData, setProfileData] = useState(props?.profile?.customer);
    const [editableProfileData, setEditableProfileData] = useState(profileData);

    const displayableFields = [
        "firstName", "lastName", "primaryPhoneNumber", "emailAddress", "businessName", "dateOfBirth"
    ];

    useEffect(() => {
        setProfileData(props?.profile?.customer)
    }, [props?.profile?.customer]);

    useEffect(() => {
        document.title = props?.title
    }, []);

    return (
        <Block {...props}>
            <h2>{props?.title}</h2>
            <div className={"flex sm:flex-row flex-col items-start gap-6"}>
                <div className="section-grey rounded-theme container-padding-half grow-1 w-full sm:max-w-sm sm:w-1/3">
                    {state === 'editing'
                        ? (
                            <>
                                <Form
                                    id={'edit-profile'}
                                    noValidate={false}
                                    values={editableProfileData}
                                    fieldsets={[
                                        {
                                            fields: [
                                                {
                                                    type: "text",
                                                    label: "First Name",
                                                    placeholder: "First Name",
                                                    name: "firstName",
                                                    maxLength: 150,
                                                    required: "lastName === undefined || lastName === null || lastName === ''",
                                                    errorMessage: "First name or last name is required",
                                                    autoFocus: true
                                                },
                                                {
                                                    type: "text",
                                                    label: "Last Name",
                                                    placeholder: "Last Name",
                                                    name: "lastName",
                                                    maxLength: 150
                                                },
                                                {
                                                    type: "text",
                                                    label: "Email",
                                                    placeholder: "Email",
                                                    name: "emailAddress",
                                                    pattern: "(^$|\\w+([-+.'\\w])*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*)",
                                                    maxLength: 250,
                                                    required: true,
                                                },
                                                {
                                                    type: "phone-number-input",
                                                    label: "Phone Number",
                                                    placeholder: "Phone Number",
                                                    name: "primaryPhoneNumber",
                                                    required: "emailAddress === undefined || emailAddress === null || emailAddress === ''",
                                                    defaultCountry: context?.locale?.split('-')[1] ?? "AU",
                                                    displayInitialValueAsLocalNumber: true,
                                                },
                                                {
                                                    type: "text",
                                                    label: "Business Name",
                                                    name: "businessName",
                                                    placeholder: "Business Name",
                                                },
                                                {
                                                    type: "date-of-birth",
                                                    label: "Date Of Birth",
                                                    name: "dateOfBirth",
                                                    description: "E.g. 31 12 1988"
                                                },
                                                {
                                                    type: "address",
                                                    label: "Address",
                                                    name: "address",
                                                }
                                            ]
                                        }
                                    ]}
                                    layout={"website"}
                                    className={classNames("flex flex-col w-full")}
                                    onSubmit={(e, values) => {
                                        axios.post(profileData._links.edit.href, values)
                                            .then(({data}) => {
                                                setProfileData(data);
                                                setEditableProfileData(data)
                                                setState('')
                                            })
                                    }}
                                />
                                <button type="button" 
                                        onClick={() => {
                                            setEditableProfileData(profileData)
                                            setState('')
                                        }}
                                        className={"text-link hover:opacity-80 mt-3 mr-3"}>Cancel
                                </button>
                                <button type="submit"
                                        form={'edit-profile'}
                                        className={"text-link font-semibold hover:opacity-80 mt-3"}>Save Details
                                </button>
                            </>
                        )
                        : (
                            <>
                                {displayableFields.map(field => (
                                    <div key={field} className="mb-3 leading-none">
                                        <div className="text-gray-600 capitalize">{_startCase(field)}</div>
                                        <div className="text-lg">
                                        {field === "dateOfBirth" ?
                                            <DateOfBirth.DisplayView date={profileData["dateOfBirth"]} context={context} formatter={customDobFormatter}/>
                                            :
                                            profileData[field] || '—'
                                        }
                                        </div>
                                    </div>
                                ))}
                                <div className={"mb-3 leading-none"}>
                                    <div className="text-gray-600 capitalize">Address</div>
                                    <div className="text-lg leading-tight">
                                        {formatAddress(profileData.address)}
                                    </div>

                                </div>
                                <button onClick={(e) => {
                                            e.preventDefault();
                                            setEditableProfileData({ ...profileData });
                                            setState('editing')
                                        }}
                                        className={"text-link font-semibold hover:opacity-80"}>Update Details
                                </button>
                            </>
                        )}
                </div>
                {/*<div className="section-grey rounded-theme container-padding-half w-full sm:max-w-sm sm:w-1/3">*/}
                {/*    <h6>Address Book</h6>*/}
                {/*    <button className={"text-link font-semibold hover:opacity-80"}>Add Address</button>*/}
                {/*</div>*/}
                {/*<div className="section-grey rounded-theme container-padding-half w-full sm:max-w-sm sm:w-1/3">*/}
                {/*    <h6>Payment Methods</h6>*/}
                {/*    <button className={"text-link font-semibold hover:opacity-80"}>Add Payment</button>*/}
                {/*</div>*/}
            </div>
        </Block>
    )
}

const customDobFormatter = (date) => {
    const dob = new Date(date);
    const day = dob.getDate();
    const month = dob.toLocaleString('en-AU', { month: 'long' });
    const year = dob.getFullYear();
    return `${month} ${day}, ${year}`
};