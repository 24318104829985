import {createContext} from "react";

/**
 * Exposes an interface for handling various operations with a Tree Control
 *
 * @typedef {object} TreeContext
 * @property {OnBeforeAdd} onBeforeAdd Invoked before an item is added to the tree
 * @property {OnBeforeEdit} onBeforeEdit Invoked before an item is edit form is rendered
 * @property {OnAdd} onAdd Invoked when an item has been added to the tree
 * @property {OnEdit} onEdit Invoked when an item has been modified in the tree
 * @property {OnItemChange} onItemChange Invoked when a field on an item in the tree has changed. Mainly used 
 *                                       to propagate changes in state for live updates. This is not debounced 
 *                                       and will be called on every `onChange` event from form controls in 
 *                                       the item form.
 * @property {OnMove} onMove Invoked when an item has been moved to a different position in the tree
 * @property {OnRemove} onRemove Invoked when an item has been removed from the tree
 */

/**
 * Invoked before the new tree item is added. This allows mutating the default values of the item.
 *
 * @typedef {function} OnBeforeAdd
 * @param {string} name The name of the field
 * @param {string} action The label of the action that was clicked
 * @param {object} item The item that will be added
 * @param {?object} parent A reference to the parent item
 * @param {?number} index The index this item should be inserted at 
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * Invoked before the new tree item is added. This allows mutating the default values of the item.
 *
 * @typedef {function} OnBeforeEdit
 * @param {string} name The name of the field
 * @param {object} item The item that will be edited
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * Invoked when a new tree item as been added
 *
 * @typedef {function} OnAdd
 * @param {string} name The name of the field
 * @param {object} item The item that was just added
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * Invoked when a tree item as been modified (excluding a positional change)
 *
 * @typedef {function} OnEdit
 * @param {string} name The name of the field
 * @param {object} item The item that was just edited
 * @param {object} original The item state before it was edited
 * @param {object[]} value The new value of the control
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * Invoked when a field on an item as been modified
 *
 * @typedef {function} OnItemChange
 * @param {string} name The name of the field that references the tree control
 * @param {string} itemName The name of the field that references the item in the tree control
 * @param {object} values The values of the tree item that represent to current state on the form
 */

/**
 * Invoked when a tree item as been moved
 *
 * @typedef {function} OnMove
 * @param {string} name The name of the field
 * @param {object} item The item that has been moved
 * @param {?object} prevParent A reference to the previous parent this item has been moved from
 * @param {object[]} newValue A reference to the new value of the tree control
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * Invoked when a tree item as been removed
 *
 * @typedef {function} OnRemove
 * @param {string} name The name of the field
 * @param {object} item The item that has been removed
 * @return {?Promise} A reference to a Promise to include in the chain when saving
 */

/**
 * @type {React.Context<TreeContext>}
 */
const TreeContext = createContext(null);

export default TreeContext;