import {useEffect} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import fitty from "fitty";

import Design1 from './design-1';

FooterNavigationBlock.propTypes = {
    items: PropTypes.array,
    settings: PropTypes.object,
};

FooterNavigationBlock.defaultProps = {};

export default function FooterNavigationBlock(props) {
    useEffect(() => {
        fitty("footer .fit-text", {maxSize: 16, observeMutations: {attributes: true, subtree: true, childList: true, characterData: true}});
    }, [])
    
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};