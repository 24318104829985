import {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


UploaderDropZone.propTypes = {
    disabled: PropTypes.bool,
    onDrop: PropTypes.func,
};

UploaderDropZone.defaultProps = {
    onDrop: () => {}
};

export default function UploaderDropZone(props) {
    const dropZoneRef = useRef();
    
    // This counter is used to determine how many containers the drag event as entered
    // Due to the nature of drag and drop in elements children in the container can cause the drag leave event to occur.
    // Using a count, when it reaches 0 indicating that we have left the drop container the drop effect classes will be 
    // removed.
    let counter = 0;

    /**
     * Adds/Removes the drop effect classes to the drag and drop container
     * 
     * @param force True to add, false to remove
     */
    const toggleDropEffect = (force) => {
        dropZoneRef.current.classList.toggle("ring-2", "ring-blue-500", "ring-offset-4", "rounded", force);
    }

    /**
     * Fired on onDragEnter, onDragLeave, onDragOver, onDrop. See JXS below
     * 
     * @param e The {@link DragEvent}
     */
    const onDragEvent = (e) => {
        e.preventDefault();
        if (props.disabled) return;
        e.stopPropagation();
        
        switch (e.type) {
            case 'dragenter':
                counter++;
                toggleDropEffect(true);
                return;
            case 'dragleave':
                counter--;
                if (counter === 0) {
                    toggleDropEffect(false);
                    dropZoneRef.current.classList.remove("ring-2", "ring-blue-500", "ring-offset-4", "rounded");
                }
                return;
            case 'drop':
                props.onDrop(e)
                toggleDropEffect(false);
                return;
        }
    }
    
    return (
        <div
            ref={dropZoneRef}
            className={classNames(
                "relative flex flex-col text-sm focus:ring-primary-500 focus:border-primary-500 rounded",
                props.disabled ? "pointer-events-none" : null,
                props.error && !props.disabled ? 'border-error-300' : null,
                !props.error && !props.disabled ? 'border-gray-300' : null,
                props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null,
            )}
            onDragEnter={onDragEvent}
            onDragLeave={onDragEvent}
            onDragOver={onDragEvent}
            onDrop={onDragEvent}
        >
            {props.children}
        </div>
    );
};