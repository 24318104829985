import Video from "./video";
import classNames from "classnames";

export function BgVideo(props) {
    if (!props.values) return null;
    if (!props.values.video) return null;

    return (
        <div className="w-full h-full !absolute overflow-hidden flex items-center">
            {props.hasOverlay &&
                <div className={"!bg-black/[.3] absolute z-[1] w-full h-full"}></div>
            }
            <Video {...props.values?.video} containerHeight={props.values?.options?.backgroundVideoHeight} backgroundVideo={true} height={'100%'} className={classNames("absolute top-0 left-0 block h-full object-cover max-w-none", props.className)}/>
        </div>
    )
}