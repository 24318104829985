import classNames from "classnames";
import Currency from "@autocx/forms/src/controls/currency";
import {WebsiteContext} from "../../index";
import {useContext} from "react";


export function BuyNowPurchaseMethod(props) {
    return (
        <a
            href={`product/${props.id}/checkout`}
            className={classNames("button hover:opacity-90 w-full justify-center mt-4 relative")}
        >
            Buy Now
        </a>
    )
}

export function BuyNowActionBanner(props) {
    const context = useContext(WebsiteContext)
    return (
        <>
            {!props?.values?.productTemplate?.hidePriceOnWebsite &&
                <div className={classNames("flex flex-col truncate")}>
                    <div>
                        {props.values?.discountedSellingPrice ? (
                            <span className={classNames(
                                "text-2xl",
                                "font-semibold"
                            )}>
                                <Currency.DisplayValue currency={context.currency}
                                                       value={props.values?.discountedSellingPrice}
                                                       emptyPlaceholder={""}/>
                            </span>
                        ) : null}
                        <span
                            className={classNames("text-xl", props.values?.discountedSellingPrice ? "line-through ml-2 opacity-80" : "font-semibold")}>
                            {props.values?.sellingPrice ?
                                <Currency.DisplayValue currency={context.currency}
                                                       value={props.values?.sellingPrice}
                                                       emptyPlaceholder={""}/>
                                : "Price on Application"}
                    </span>
                    </div>
                    {props.values?.sellingPriceDisclaimer ? (
                        <div className="truncate pr-4">
                            {props.values?.sellingPriceDisclaimer}
                        </div>
                    ) : null}
                </div>}
            <a
                href={`product/${props.id}/checkout`}
                className={classNames("button h-12 shrink-0")}
            >
                Buy Now
            </a>
        </>
    )
}