import {useMemo, useContext} from 'react';

import TextMediaBlock from "../blocks/text-media-block";
import {WebsiteContext} from "../index";
import _cloneDeep from "lodash/cloneDeep";
import ContentHubBlock from "../blocks/content-hub-block";

Blog.propTypes = {};

Blog.defaultProps = {};

export default function Blog({children, ...props}) {
    const context = useContext(WebsiteContext);
    const blocks = useMemo(() => {
        let items = [];
        if (context.pages) {
            items = _cloneDeep(context.pages);
            // Ensure the items are in the same order as the tree in the builder.
            items.sort((a, b) => (a.uniqueUri.length + a.position) - (b.uniqueUri.length + b.position))
            // Store child nodes under their parent
            items = items.reduce((result, item) => {
                const paths = item?.uniqueUri?.split('/');
                if (paths?.length <= 2) {
                    result.push(item);
                } else {
                    const parentNodeIndex = result.findIndex((parentItem) => parentItem.uuid === paths[1]);
                    if (!result[parentNodeIndex]) result[parentNodeIndex] = {};
                    if (!result[parentNodeIndex]?.children) result[parentNodeIndex].children = [];
                    result[parentNodeIndex]?.children.push(item)
                }
                return result;
            }, []);
        } else if (context.rootPage?.children) {
            items = _cloneDeep(context.rootPage?.children);
        }

        return items.reduce((result, item, currentIndex) => {
            const hasChildren = item?.children?.length > 0;
            // Generate a text and media block
            if (!hasChildren && item.nodeType === 'page') {
                result.push({
                    type: "text-media-block",
                    id: item.uniqueUri,
                    label: "Text & Media Block",
                    values: {
                        action: {
                            label: "Read Now",
                            type: "page",
                            uniqueUri: item.uniqueUri
                        },
                        image: item?.components?.find(c => c?.values?.image)?.values?.image,
                        imageState: item?.components?.find(c => c?.values?.imageState)?.values?.imageState,
                        options: {
                            "imagePosition": currentIndex % 2 === 0 ? 2 : 0,
                            "imageAspectRatio": 1.7777777778,
                            "textAlignment": 0,
                            "backgroundColour": 1,
                            "inset": false,
                            "mediaType": "image"
                        },
                        showingActionButton: true,
                        title: item.page?.title || item.title || item?.components?.find(c => c?.values?.title)?.values?.title,
                        text: item.page?.description || item?.components?.find(c => c?.values?.text)?.values?.text
                    }
                });
            }

            // Generate a list of items block
            if (hasChildren) {
                result.push({
                    id: item.uniqueUri,
                    label: "Content Hub Block",
                    type: "content-hub-block",
                    values: {
                        action: {
                            label: "See More",
                            type: item.nodeType,
                            uniqueUri: item?.uniqueUri,
                            value: item?.uri
                        },
                        design: 0,
                        text: item.page?.description,
                        itemLimit: 3,
                        items: item?.children.map(childItem => ({
                            title: childItem.title,
                            image: childItem?.components?.find(c => c?.values?.image)?.values?.image,
                            imageState: childItem?.components?.find(c => c?.values?.imageState)?.values?.imageState,
                            text: childItem.page?.description || childItem?.components?.find(c => c?.values?.text)?.values?.text,
                            action: {
                                type: 'page',
                                uniqueUri: childItem.uniqueUri
                            },
                            showingActionButton: true
                        })),
                        options: {
                            backgroundColour: 1,
                            columns: 3,
                            imageAspectRatio: 1.3333333333,
                            inset: false,
                            textAlignment: 0,
                            mobileDisplay: 'stack'
                        },
                        showingActionButton: true,
                        title: item.title
                    }
                });
            }
            return result;
        }, []);
    }, [context.rootPage]);

    if (!blocks.length) return children;

    return (
        <div className="ch">
            {children}
            {blocks.map(block => {
                return block.type === 'text-media-block' ? <TextMediaBlock key={block.id} {...block} /> :
                    <ContentHubBlock key={block.id} {...block} />
            })}
        </div>
    );
};