import {createElement, Fragment, isValidElement} from 'react';
import {isFragment} from 'react-is';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from "./loader";

ProgressLoader.propTypes = {
    /**
     * Appends the value to the className of the containing div around the spinner
     */
    className: PropTypes.string,
    /**
     * Appends the value to the className of the spinner
     */
    spinnerClassName: PropTypes.string,
    /**
     * States what the contain element should be. If Fragment then className is ignored
     */
    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
        PropTypes.elementType,
    ]),

    /**
     * True to show the spinner
     */
    loading: PropTypes.bool.isRequired,

    /**
     * When progress is 0 then the Loader component will be rendered with props parsed down
     */
    progress: PropTypes.number,
};

ProgressLoader.defaultProps = {
    as: 'div',
};

export default function ProgressLoader(props) {
    // Return intermediate loader with no progress 
    if (!props.progress || props.progress >= 100) return <Loader {...props} />
    const size = 24;
    const strokeWidth = 4;
    const centre = (size / 2);
    const radius = centre - (strokeWidth / 2)
    const loaderProps = {};
    
    if (props.as !== Fragment && (!isValidElement(props.as) || !isFragment(props.as))) {
        loaderProps.className = classNames(
            "flex w-full justify-center",
            !props.loading ? "hidden" : null,
            props.className
        );
    }

    return createElement(props.as, loaderProps, (
        <svg className={classNames("h-5 w-5 text-primary-500", props.spinnerClassName)}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox={`0 0 ${size} ${size}`}>
            <circle className="donut-hole text-white"
                    cx={centre}
                    cy={centre}
                    r={radius}
                    fill="transparent"
                    pathLength="100"
            />
            <circle className="donut-ring opacity-25"
                    cx={centre}
                    cy={centre}
                    r={radius}
                    fill="transparent"
                    stroke={"currentColor"}
                    strokeWidth={strokeWidth}
                    pathLength="100"
            />
            <circle className="donut-segment opacity-75 transition-[stroke-dasharray] duration-500 ease-[cubic-bezier(0,0.55,0.45,1)]"
                    cx={centre}
                    cy={centre}
                    r={radius}
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${props.progress} ${100 - props.progress}`} 
                    strokeDashoffset="25" 
                    pathLength="100" 
            />
        </svg>
    ));
}