import classNames from 'classnames';

import Block from '../block'
import {backgroundColour} from "../../utils/block-utils";
import useCdnUrl from "../../hooks/cdn-hook";
import Action from "../../components/action";
import { SocialIcon } from 'react-social-icons';
import { getBlackColour, getLightGreyColour } from "../../utils/theme-utils";
import {Image} from "../../components/image";


Design1.propTypes = {
    
};

Design1.defaultProps = {};

const getIconColor = (showColour, value, baseColour) => {
    if (showColour) return;
    const textColour = getBlackColour(baseColour);
    const lightTextColour = getLightGreyColour(baseColour);
    switch (value) {
        case 'section-white':
            return textColour;
        case 'section-light':
            return textColour;
        case 'section-grey':
            return textColour;
        case 'section-black':
            return lightTextColour;
        case 'section-brand':
            return 'var(--primary-button-text-colour)';
        case 'section-dark':
            return lightTextColour;
        default:
            return ''
    }
}

export default function Design1(props) {
    const copyright = `Copyright © ${new Date().getFullYear()}`;
    return (
        <Block id={props.id} actions={props.actions} hideInlineAddBlockButton={true} as={"footer"} className={classNames(props.className, backgroundColour(props.settings?.backgroundColour), "mt-auto")}>
            <div className={"mx-auto max-w-7xl py-6 px-4 space-y-4"}>
                <div className={"grow w-full h-full max-w-[108px] sm:max-w-[144px]"}>
                    {props.settings?.logo ? (
                        <Image
                            alt={"Footer Logo"}
                            src={props.settings?.logo}
                            width={144}
                            className={"h-auto w-auto max-w-full max-h-10 sm:max-h-8"}
                        />
                    ) : (
                        <h2 className={"fit-text"}>{props.title}</h2>
                    )}
                </div>
                <div className={"columns-1 sm:flex sm:flex-row"}>
                    {props.settings?.details &&
                        <div dangerouslySetInnerHTML={{ __html: props.settings?.details }} className={"text-sm w-full rich-text mb-8 sm:mb-4 sm:pr-2 sm:w-2/5"} />
                    }
                    <ul className={"text-sm sm:columns-2 space-y-2 w-full mb-8 sm:mb-4"}>
                        {props.items?.map(({children = [], ...item}) => (
                            <li key={item.uniqueUri}
                                className={"break-inside-avoid"}
                            >
                                {!children.length ? (
                                    <Action {...item} button={false} showingActionButton={true} style={{fontWeight: '501'}}/>    
                                ) : null}
                                {children.length ? (
                                    <>
                                        <div className={"flex flex-col"}>
                                            <div style={{fontWeight: '501'}}>{item.title}</div>
                                            {item.action?.type === 'page' ? (
                                                <Action {...item} button={false} showingActionButton={true} className={"font-light"}/>
                                            ) : null}
                                            {children.map(({children = [], ...item}) => (
                                                <Action key={item.uniqueUri} {...item} button={false} showingActionButton={true} className="font-light" />
                                            ))}
                                        </div>
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={"flex justify-between items-center pt-4 border-t"}>
                    <div className={"flex items-center min-w-fit"}>
                        <span className={"text-xs"}>{copyright}</span>
                    </div>
                    {(props.settings?.social && props.settings?.social.length > 0) ? (
                        <div className={"flex items-center justify-center flex-wrap gap-x-2 gap-y-2 px-4 sm:gap-x-3"}>
                            {props.settings?.social?.map((item, i) => (
                                <div key={i} className="break-inside-avoid">
                                    <SocialIcon className={"!w-7 !h-7"}
                                                bgColor={getIconColor(!props.settings?.socialMonochromeColours, backgroundColour(props.settings?.backgroundColour), props.baseColour)}
                                                target={"_blank"}
                                                url={item?.action?.value?.toLowerCase()}/>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div className={"flex items-center"}>
                        <a href="https://vert.works" target="_blank">
                            <svg
                                id="vert-badge"
                                xmlns="http://www.w3.org/2000/svg"
                                width="82"
                                height="32"
                                viewBox="0 0 82 32"
                            >
                                <g fill="var(--primary-button-text-colour)" fillRule="nonzero">
                                    <path
                                        d="M25.94 0h-7.61c-.488 0-.488 0-.805.516 0 0-6.98 12.988-8.425 15.147-1.166 1.74-2.914.58-2.914.58s0 1.74 1.247 1.74h10.409c1.076 0 .812.198 1.28-.771.468-.969 7.21-14.74 7.627-15.976C27.166 0 27.166 0 25.941 0z"
                                        transform="translate(6 7)"
                                    ></path>
                                    <path
                                        d="M5.25 14.119a1.23 1.23 0 01-.345-.425L.393 4.137C-.225 2.9-.225 2.9 1.202 2.9h7.61a.953.953 0 01.805.515l1.844 3.722a.64.64 0 01.002.345c-1.58 2.839-2.943 5.565-3.529 6.44-.902 1.346-2.17.593-2.684.196z"
                                        transform="translate(6 7)"
                                    ></path>
                                    <path d="M78 0a4 4 0 014 4v24a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h74zm0 1H4a3 3 0 00-2.995 2.824L1 4v24a3 3 0 002.824 2.995L4 31h74a3 3 0 002.995-2.824L81 28V4a3 3 0 00-2.824-2.995L78 1z"></path>
                                    <g transform="translate(39 6)">
                                        <path d="M0 6.48V.18h2.376c.654 0 1.149.153 1.485.459.336.306.504.69.504 1.152 0 .402-.108.722-.324.958a1.62 1.62 0 01-.783.482 1.548 1.548 0 011.089.828c.108.216.162.45.162.702 0 .324-.08.617-.239.877a1.633 1.633 0 01-.697.617c-.306.15-.681.225-1.125.225H0zm.954-3.6h1.305c.366 0 .645-.085.837-.256.192-.171.288-.407.288-.707 0-.294-.095-.528-.284-.702-.188-.174-.475-.261-.859-.261H.954V2.88zm0 2.817h1.368c.384 0 .683-.09.895-.27.213-.18.32-.432.32-.756s-.111-.58-.333-.77c-.222-.188-.522-.283-.9-.283H.954v2.079zm5.96.891c-.54 0-.965-.168-1.273-.504-.31-.336-.464-.837-.464-1.503V2.016h.954v2.466c0 .864.354 1.296 1.062 1.296.354 0 .645-.126.873-.378.228-.252.342-.612.342-1.08V2.016h.954V6.48h-.846l-.072-.783c-.138.276-.34.494-.608.652a1.768 1.768 0 01-.922.239zm3.953-5.418a.621.621 0 01-.446-.166.553.553 0 01-.175-.419c0-.168.059-.307.175-.418A.621.621 0 0110.867 0c.18 0 .328.056.445.167.118.11.176.25.176.418a.553.553 0 01-.175.419.621.621 0 01-.446.166zm-.477 5.31V2.016h.954V6.48h-.954z"></path>
                                        <path d="M12.354 6.48L12.354 0 13.308 0 13.308 6.48z"></path>
                                        <path d="M16.136 6.48c-.438 0-.786-.107-1.044-.32-.258-.213-.387-.592-.387-1.138V2.817h-.765v-.801h.765l.117-1.134h.837v1.134h1.26v.801h-1.26v2.205c0 .246.052.415.157.508.105.093.287.14.545.14h.513v.81h-.738z"></path>
                                        <path d="M20.559 6.48L19.245 2.016 20.199 2.016 21.063 5.382 22.035 2.016 23.097 2.016 24.069 5.382 24.933 2.016 25.887 2.016 24.573 6.48 23.592 6.48 22.566 2.97 21.54 6.48z"></path>
                                        <path d="M26.96 1.17a.621.621 0 01-.445-.166.553.553 0 01-.176-.419c0-.168.059-.307.176-.418A.621.621 0 0126.96 0c.18 0 .328.056.445.167.117.11.176.25.176.418a.553.553 0 01-.175.419.621.621 0 01-.446.166zm-.477 5.31V2.016h.954V6.48h-.954zm3.836 0c-.438 0-.786-.107-1.044-.32-.258-.213-.387-.592-.387-1.138V2.817h-.765v-.801h.765l.117-1.134h.837v1.134h1.26v.801h-1.26v2.205c0 .246.053.415.158.508.105.093.286.14.544.14h.513v.81h-.738zm1.514 0V0h.954v2.736c.15-.258.358-.46.626-.608.267-.147.562-.22.886-.22.534 0 .954.168 1.26.504.306.336.459.837.459 1.503V6.48h-.945V4.014c0-.864-.345-1.296-1.035-1.296-.36 0-.658.126-.895.378s-.356.612-.356 1.08V6.48h-.954z"></path>
                                    </g>
                                    <g transform="translate(39 14)">
                                        <path d="M3.085 10.811L0 2.514 3.301 2.514 4.797 7.684 4.905 7.684 6.402 2.514 9.425 2.514 6.309 10.811z"></path>
                                        <path d="M14.065 2.326c1.49 0 2.63.356 3.416 1.068.787.713 1.18 1.802 1.18 3.269v.534h-6.108c0 .597.131 1.048.393 1.352.262.303.676.455 1.242.455.514 0 .892-.11 1.134-.33.242-.22.362-.513.362-.88h2.978c0 1.006-.376 1.792-1.127 2.357-.75.566-1.846.849-3.285.849-1.512 0-2.684-.359-3.517-1.076-.833-.718-1.25-1.805-1.25-3.261 0-1.425.406-2.504 1.219-3.237.812-.734 1.933-1.1 3.363-1.1zm.123 1.995c-.967 0-1.507.467-1.62 1.399h2.993c0-.43-.12-.77-.363-1.021-.241-.252-.578-.378-1.01-.378zm10.81-2.011c.236 0 .447.029.632.086.185.058.278.092.278.103v2.624h-.987c-.638 0-1.098.167-1.381.503-.283.335-.424.833-.424 1.493v3.692h-3.07V2.514h2.514l.201 1.257c.185-.481.478-.846.88-1.092.4-.246.853-.369 1.357-.369zM33 2.514v2.122h-1.728v3.158c0 .377.062.652.185.825.124.173.35.26.68.26H33v1.838a5.199 5.199 0 01-.956.204c-.391.053-.73.079-1.019.079-.905 0-1.601-.168-2.09-.503-.488-.335-.732-.906-.732-1.713V4.636H27.06V2.514h1.265L28.989 0h2.283v2.514H33z"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                
            </div>
        </Block>
    )
}