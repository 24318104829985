import classNames from 'classnames';
import _isFunction from 'lodash/isFunction';
import React, {useContext} from "react";
import FormContext from "../form-context";
import _cloneDeep from "lodash/cloneDeep";

FormText.AppDisplayValue = function (props) {
    return props.value === undefined || props.value === null ? '\u2014' : (
        <div className={"rich-text"} dangerouslySetInnerHTML={{__html: props.value}}/>
    );
}

export default function FormText(props) {
    const context = useContext(FormContext);
    let value = _isFunction(props.value) ? props.value(_cloneDeep(context.values)) : props.value;
    value = !!value ? value : props.fieldValue
    const className = _isFunction(props.className === 'function') ? props.className(context.values) : props.className;

    return (
        <div className={classNames("rich-text whitespace-pre-line", className)}
             dangerouslySetInnerHTML={{__html: value}}/>
    )
};