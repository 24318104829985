import {useContext, useEffect, useState} from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {SortableElement} from "react-sortable-hoc";

import {UploaderContext} from "./uploader-context";
import UploaderFileActions from "./uploader-file-actions";

// Previews
import FaviconUploaderFilePreview from "./previews/favicon-uploader-preview";
import ImageUploaderFilePreview from "./previews/image-uploader-preview";
import DefaultUploaderPreview from "./previews/default-uploader-preview";

// Editors
import FocalPointEditor from "./editors/focal-point-editor";
import PinturaFullEditor from "./editors/pintura-full-editor";
import {FormContext} from "../../../index";

UploaderFile.propTypes = {
    index: PropTypes.number.isRequired,
};

UploaderFile.defaultProps = {};

/**
 * Renders a file with the available actions that a user can interact with
 *
 * @param props
 * @param {number} props.index
 * @returns {JSX.Element}
 * @constructor
 */
export default function UploaderFile({index, ...props}) {
    const context = useContext(UploaderContext);
    
    return (
        <SortableItem 
            index={index} 
            itemIndex={index} 
            disabled={!context.multiple || props.file.status !== 'ready'} 
            {...props} 
        />
    );
};

const SortableItem = SortableElement(({className, itemIndex, ...props}) => {
    const context = useContext(UploaderContext);
    const [progress, setProgress] = useState(null);

    const cancelUpload = () => props.file.update({status: 'ready'})
    
    // Handle when the file moves to certain statuses
    useEffect(() => {
        if (props.file.status === 'pending') {
            props.file.update({status: 'loading'});
            return;
        }
        
        if (props.file.status === 'loading') {
            const controller = new AbortController();
            props.file.load(controller.signal)
                .catch((err) => {
                    if (axios.isCancel(err)) return;
                    if (controller.signal.aborted) return;

                    console.log(err);
                    return props.file.update({status: 'error', error: 'Failed to process image'})
                });
            
            return () => controller.abort();
        }
        
        // Move file to uploading state if it's ready for upload
        if (props.file.status === 'ready-for-upload') {
            props.file.update({status: 'uploading'});
            return;
        }

        // Init the uploading process when status is uploading
        if (props.file.status === 'uploading') {
            // Init uploading
            setProgress(0);
            let controller = new AbortController();

            props.file
                .upload(controller.signal, (progress) => {
                    if (controller.signal.aborted) return;
                    
                    setProgress(progress);
                })
                .then(() => setProgress(null))
                .catch((err) => {
                    props.revertFileUploader(err);
                    if (axios.isCancel(err)) return;
                    if (controller.signal.aborted) return;
                    
                    setProgress(null);
                    console.log(err);
                });

            return () => {
                controller?.abort();
            }
        }
    }, [props.file.status]);

    let UploaderFilePreview;
    let previewProps = {
        objectFit: 'contain',
        objectPosition: 'center',
    };
    
    // Render a fav icon preview
    if (context.variant === 'favicon') {
        UploaderFilePreview = FaviconUploaderFilePreview;
    } 
    // Render a preview of the image
    else if (['image', 'logo'].includes(context.variant)) {
        UploaderFilePreview = ImageUploaderFilePreview;

        // If pintura is enabled then 
        if (context.pintura) {
            previewProps.objectFit = context.pintura.objectFit ? context.pintura.objectFit : 'cover';
        }
        
        // If focal point is enabled. Render the focal point editor
        if (context.focalPoint) {
            UploaderFilePreview = FocalPointEditor;
            previewProps.objectFit = 'contain';
        }
    }
    // Render a default preview of the uploaded file
    else {
        UploaderFilePreview = DefaultUploaderPreview;
    }

    // When the file is in a `editing` state. Render the editor.
    if (props.file.status === 'editing') {
        if (context.pintura) {
            previewProps.objectFit = context.pintura.objectFit ? context.pintura.objectFit : 'cover';
            UploaderFilePreview = PinturaFullEditor;
        }
    }

    return (
        <UploaderFilePreview 
            key={props.file.id}
            className={classNames(
                "grow",
                context.multiple ? "cursor-move" : null,
                context.multiple && props.file.status === 'adjusting' ? "col-span-2": null,
                className
            )}
            progress={progress}
            cancelUpload={cancelUpload}
            {...previewProps}
            {...props}
        >
            {context.multiple && props.file.status === 'ready' ? (
                <UploaderFileActions className={"no-shrink"} contextMenuDirection={itemIndex % 2 === 0 ? 'left' : 'right'} {...props} />
            ) : null}
            {!context.multiple && (context.pintura || context.focalPoint) ? (
                <div className="absolute flex flex-row top-4 right-4 bottom-[unset] left-[unset] gap-x-1">
                    {context.pintura &&
                        (<button
                            type={"button"}
                            className={classNames(
                                "flex flex-row",
                                "w-auto h-auto bg-black bg-opacity-70 backdrop-blur-md backdrop-filter",
                                "shadow-lg text-white fill-current inline-flex border border-white items-center px-3",
                                "py-1.5 rounded-full text-sm font-medium hover:bg-black focus:outline-none",
                                props.file.status === 'editing' ? "hidden" : null,
                                props.file.status !== 'adjusting' ? null : "hidden"
                            )}
                            onClick={() => props.file.update({status: 'editing'})}>
                            <div>Edit</div>
                        </button>)
                    }
                    {context.focalPoint && (
                        <button
                            type={"button"}
                            className={classNames(
                                "flex flex-row",
                                "w-auto h-auto bg-black bg-opacity-70 backdrop-blur-md backdrop-filter",
                                "shadow-lg text-white fill-current inline-flex border border-white items-center px-3",
                                "py-1.5 rounded-full text-sm font-medium hover:bg-black focus:outline-none",
                                props.file.status === 'adjusting' ? "hidden" : null,
                                props.file.status !== 'editing' ? null : "hidden"
                            )}
                            onClick={() => props.file.update({status: 'adjusting'})}>
                            <svg height="20" viewBox="0 0 30 30" width="20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m24.875 31v5.25h-1.75v-5.25zm-7.875-7.875v1.75h-5.25v-1.75zm19.25 0v1.75h-5.25v-1.75zm-11.375-11.375v5.25h-1.75v-5.25zm13.125 1.25v22c0 1.6568542-1.3431458 3-3 3h-22c-1.6568542 0-3-1.3431458-3-3v-22c0-1.6568542 1.3431458-3 3-3h22c1.6568542 0 3 1.3431458 3 3zm-3.25-1.25h-21.5c-.8284271 0-1.5.6715729-1.5 1.5v21.5c0 .8284271.6715729 1.5 1.5 1.5h21.5c.8284271 0 1.5-.6715729 1.5-1.5v-21.5c0-.8284271-.6715729-1.5-1.5-1.5z"
                                    fill="#fff" transform="translate(-9 -9)"/>
                            </svg>
                            <div className={"ml-2"}>Focus</div>
                        </button>
                    )}
                </div>
            ) : null}
        </UploaderFilePreview>
    )
});