import Block from "../block";
import {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import Spinner from "../../components/spinner";
import Countdown, {zeroPad} from "react-countdown";
import Video from "../../components/video";
import {Dialog, Transition} from "@headlessui/react";
import DigitalProductAccessCard from "../../components/digital-product-access-card";

export default function AccountDigitalProducts(props) {
    const [productsData, setProductsData] = useState([])
    const [loading, setLoading] = useState(true)
    const [showLoader, setShowLoader] = useState(false)
    const [productsLocked, setProductsLocked] = useState(props.profile?.unlockTimeRemaining === 0)

    
    useEffect(() => {
        document.title = props?.title
        const productsUrl = props?.profile?.customer?._links?.digitalProducts?.href
            ? props.profile.customer._links.digitalProducts.href + window.location.search
            : undefined;
        
        if (productsUrl) {
            setLoading(true)
            let timer = setTimeout(() => {
                setShowLoader(true);
            }, 400);
            axios.get(productsUrl)
                .then(response => {
                    setProductsData(response.data.reverse());
                })
                .catch(error => {
                    console.error("Error fetching digital products:", error);
                })
                .finally(() => {
                    clearTimeout(timer)
                    setLoading(false)
                    setShowLoader(false)
                })
        } else {
            setLoading(false);
        }
    }, [])

    return (
        <Block {...props}>
            <div className={"flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3"}>
                <h2 className={"!mb-0"}>{props?.title}</h2>
                {props?.profile?.customer?._links?.digitalProducts && <ProductsLockCountdown productsLocked={productsLocked} date={Date.now() + (props.profile?.unlockTimeRemaining * 1000)} onComplete={() => setProductsLocked(true)} {...props} />}
            </div>
            {loading ?
                showLoader && <Spinner/>
                :
                <>
                    {productsData.length > 0 ? (
                        productsData.map((product) => (
                            <DigitalProductAccessCard key={product.productUuid} product={product} productsLocked={productsLocked} />
                        ))
                    ) : (
                        <div className="mt-4 text-base text-grey-800 font-semibold">
                            <p>You have not purchased any digital products</p>
                        </div>
                    )}
                </>
            }
        </Block>
    )
}

function ProductsLockCountdown({productsLocked, date, onComplete,...props}) {
    const [emailSent, setEmailSent] = useState({sent: false, to: ''})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setEmailSent({ sent: false, to: '' });
    }, [productsLocked]);
    
    const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
        if ((completed || productsLocked) && !emailSent.sent) {
            return (
                <button className={"button text-sm justify-center gap-x-2 sm:!mt-0 animate__animated animate__headShake"}
                        disabled={loading}
                        onClick={() => {
                            setLoading(true)
                            axios.post(props?.profile?.customer?._links?.sendUnlockEmail.href)
                                .then((response) => {
                                    setEmailSent({sent: true, to: response.data.email});
                                })
                                .catch((e) => {
                                    console.log(e)
                                })
                                .finally(() => setLoading(false))
                        }}
                >
                    <svg viewBox={"0 0 17 24"} width="16.7505" height="23.9484" className={"h-5"}>
                        <path d="M2.71265 23.339L13.6863 23.339C15.4659 23.339 16.399 22.3847 16.399 20.4635L16.399 12.1636C16.399 10.2584 15.4659 9.30399 13.6863 9.30399L2.71265 9.30399C0.930937 9.30399 0 10.2584 0 12.1636L0 20.4635C0 22.3847 0.930937 23.339 2.71265 23.339ZM3.04545 21.2384C2.55842 21.2384 2.28022 20.9409 2.28022 20.3878L2.28022 12.2435C2.28022 11.6883 2.55842 11.4047 3.04545 11.4047L13.3556 11.4047C13.8523 11.4047 14.1166 11.6883 14.1166 12.2435L14.1166 20.3878C14.1166 20.9409 13.8523 21.2384 13.3556 21.2384ZM2.14359 10.2886L4.33803 10.2886L4.33803 6.3457C4.33803 3.5939 6.09819 2.09858 8.19468 2.09858C10.2869 2.09858 12.0727 3.5939 12.0727 6.3457L12.0727 10.2886L14.2575 10.2886L14.2575 6.55383C14.2575 2.22984 11.3958 0 8.19468 0C5.0032 0 2.14359 2.22984 2.14359 6.55383Z" fill="currentColor" />
                    </svg>
                    <span className={"leading-none"}>Unlock Access</span>
                </button>
            );
        }
        if (emailSent.sent) {
            return (
                <div className={"sm:h-9 sm:mt-0 mt-2 h-8 flex items-center"}>
                    <span>An email has been sent to {emailSent.to}</span>
                </div>
            )
        }

        return (
            <div className={"sm:h-9 sm:mt-0 sm:text-right mt-2 h-8 flex items-center"}>
                <span>
                    <span>Product access locks in&nbsp;</span><span className={"!font-mono"}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
                </span>
            </div>
        )
        
    }
    return (
        <Countdown key={Math.random()} date={date} renderer={countdownRenderer} onComplete={onComplete}/>
    )
}