import axios from "axios";
require.context('./', true, /\.jsx$/);

const loadPluging = async (plugins, modules) => {
    for (const p of plugins) {
        const module = await import(`./${p.namespace}/index`).then(i => i.default).catch(err => console.warn(`There is no form control set up for ${p.name}`))
        if (module) modules[module?.ControlDisplaySettings().value || p.name.toLowerCase()] = module;
    }
}

export const plugins = async (plugins) => {
    const modules = {};
    if (plugins) {
        await loadPluging(plugins, modules);
    } else {
        await axios.get(`/api/v2/workspaces/current/configuration`)
            .then(async ({data}) => {
                if (data.enabledPlugins) await loadPluging(data.enabledPlugins, modules)
            })
    }

    return modules;
};