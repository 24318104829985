import Block from '../block'
import {
    backgroundColour,
    textAlignment,
    frameType,
    backgroundPattern,
    aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import classNames from "classnames";
import Action from "../../components/action";
import {Image} from "../../components/image";
import Pagination from "../products-list-block/pagination";
import {useState} from "react";
import ChevronLeftIcon from "@heroicons/react/solid/ChevronLeftIcon";

export function listColumns(props) {
    switch (props.values?.options?.columns) {
        case 1:
            return 'lg:grid-cols-1'
        case 2:
            return 'lg:grid-cols-2'
        case 3:
            return 'lg:grid-cols-3'
        case 4:
            return 'lg:grid-cols-4'
        case 5:
            return 'lg:grid-cols-5'
        case 6:
            return 'lg:grid-cols-6'
        case 7:
            return 'lg:grid-cols-7'
        default:
            return 'lg:grid-cols-3'
    }
}

export default function Design1(props) {
    const paginationLimit = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const pages = Math.ceil(props.values.items.length / paginationLimit) || 1;

    return (
        <Block {...props}
               className={classNames(
                   "grow flex flex-col",
                   props.className,
                   !props.values?.options?.inset ? backgroundPattern(props) : null,
                   !props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null)
               }>
            <div className={classNames(
                "mx-auto max-w-7xl section flex flex-col items-center grow w-full",
                textAlignment(props),
                props.values?.options?.inset ? 'rounded-theme' : null,
                props.values?.options?.inset ? backgroundPattern(props) : null,
                props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={"w-full"}>
                    {!props.values.itemLimit && props?.values?.contentHubPage?.action?.value &&
                        <div className="inline-flex mb-2">
                            <a className="flex items-center font-semibold"
                               href={props.values.contentHubPage.action.value}><ChevronLeftIcon
                                className="shrink-0 w-5 h-5"/>{props.values.contentHubPage.title}</a>
                        </div>}
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>
                    ) : null}
                </div>
                <div className="relative w-full overflow-x-auto snap-mandatory snap-x">
                    <ul className={classNames(
                        "mb-4 lg:space-x-0 lg:grid lg:gap-x-8 lg:gap-y-12",
                        props.values?.options?.mobileDisplay === "stack" ? "block space-y-6 sm:space-y-0" : "inline-flex space-x-4",
                        listColumns(props)
                    )}>
                        {props.values.items?.map((item, i) => {
                            const pageItem = (currentPage - 1) * paginationLimit;
                            if (props.values.itemLimit && i >= props.values.itemLimit) return;
                            if (pageItem > i) return;
                            if (i >= (pageItem + paginationLimit)) return;
                            return (
                                <li key={i}
                                    className={classNames("inline-flex flex-col overflow-hidden snap-start lg:w-auto",
                                        props.values?.options?.mobileDisplay === "stack" ? "w-full" : "w-64"
                                    )}>
                                    <div className="group relative">
                                        <div className={classNames(
                                            "w-full overflow-hidden relative",
                                            aspectRatio(props),
                                            frameType(props) === 'circle' ? 'clip-circle' : '',
                                            frameType(props) === 'slant' ? 'clip-slant !rounded-none' : '',
                                        )}>
                                            {item.image
                                                ? <Image
                                                    id={item.imageState?.__cx__?.id ? `image_${item.imageState?.__cx__?.id}` : null}
                                                    src={item.imageState?.__cx__?.key || item.image}
                                                    key={item.image + item.imageState + props.values?.options?.imagePresentation}
                                                    alt={item?.altText}
                                                    className={classNames(
                                                        "lazyload lazymotion w-full h-full object-center rounded-theme",
                                                        objectFit(props.values?.options?.imagePresentation),
                                                        item.action && item.action.type ? 'group-hover:opacity-75' : '',
                                                        frameType(props) === 'slant' ? '!rounded-none' : '',
                                                    )}
                                                />
                                                : <div className={classNames(
                                                    "lazyload lazymotion w-full h-full object-center rounded-theme",
                                                    objectFit(props.values?.options?.imagePresentation),
                                                    item.action && item.action.type ? 'group-hover:opacity-75' : '',
                                                    frameType(props) === 'slant' ? '!rounded-none' : '',
                                                )}/>
                                            }
                                        </div>
                                        <div className={classNames("mt-4", textAlignment(props))}>
                                            <h6 className="mt-1">
                                                <Action {...item} title={null} button={false} defaultHref={'#'}>
                                                    <span className="absolute inset-0"/>
                                                </Action>
                                                <span>{item.title}</span>
                                            </h6>
                                            {item.text ? (
                                                <div dangerouslySetInnerHTML={{__html: item.text}}
                                                     className="mt-1 rich-text line-clamp-3"/>
                                            ) : null}
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {(props.value?.showingActionButton === undefined || props.value?.showingActionButton === null || props.value?.showingActionButton) && (props.values?.items?.length > props.values?.itemLimit) &&
                    <Action className={"mt-6"} {...props.values} title={null}/>}
            </div>
            {!props.values.itemLimit && <Pagination
                className={"mx-auto max-w-7xl w-full px-4 pb-24"}
                onPreviousClick={() => setCurrentPage((currentPage - 1))}
                onNextClick={() => setCurrentPage((currentPage + 1))}
                onPaginationClick={(pageNumber) => setCurrentPage(pageNumber)}
                pages={pages}
                currentPagination={currentPage}
            />}
        </Block>
    )
}