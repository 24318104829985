import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import _get from 'lodash/get';
import _set from 'lodash/set';
import _mergeWith from 'lodash/mergeWith';
import {arrayMove} from "@dnd-kit/sortable";

import PlusIcon from '@heroicons/react/solid/PlusCircleIcon'
import MenuIcon from '@heroicons/react/solid/MenuIcon'
import MoreIcon from "@autocx/icons/src/more-icon";

import FormContext from "../form-context";
import {getValuesIncludingHidden, prefixFieldNames} from "../utils"

import {Menu, Transition} from "@headlessui/react";
import _cloneDeep from "lodash/cloneDeep";
import FormLayout from "../layouts/form-layout";
import Form from "../index";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
import {nanoid} from "nanoid/non-secure";
import _isEqual from "lodash/isEqual";

const POSITIONS_FIELD_NAME_SUFFIX = 'Positions'

/**
 * Handles adding a new item to the list
 *
 * @param context The form context
 * @param props The list props
 * @param listRef The react ref to the list
 * @param existing A reference to an existing item
 * @param items The current list of items in the list state
 */
const addItem = (context, props, listRef, existing, items) => {
    const values = _get(context.values, props.name, []) || [];
    const item = getValuesIncludingHidden(props.fieldsets, props.hiddenFields);

    if (!existing) {
        item.id = nanoid()

        // Add custom metadata to the newly added item so that we can use it later on in this control
        Object.defineProperty(item, '__cx__', {
            enumerable: false, // Make sure we don't serialised this field
            value: {added: true},
            writable: false,
        });
    } else {
        // Add custom metadata to the newly added item so that we can use it later on in this control
        Object.defineProperty(item, '__cx__', {
            enumerable: false, // Make sure we don't serialised this field
            value: {edit: true},
            writable: false,
        });

        // Merge existing item with new item
        _mergeWith(item, existing, (objValue, srcValue) => {
            if (_isArray(objValue)) {
                return srcValue;
            }
        });
    }

    if (_isFunction(props.beforeItemAdd)) {
        props.beforeItemAdd(item);
    }


    // Handle if we are adding objects of primitive types to an array
    if (props.fieldsets.length === 1 && props.fieldsets[0].fields.length === 1 && !props.fieldsets[0].fields[0].name) {
        props.onFieldChange({target: listRef.current}, props.name, [...values, '']);
    } else {
        // Handle when the list is inherited and set the position array  
        if (!!context.original) {
            const positionsFieldName = getPositionFieldName(context, props)
            props.onFieldChange({target: listRef.current}, positionsFieldName, [...items.map(item => item.id), item.id]);
        }

        props.onFieldChange({target: listRef.current}, props.name, [...values, item]);
    }
}

/**
 * Handles duplicating an existing item in the list
 *
 * @param context The form context
 * @param props The list item props
 */
const duplicateItem = (context, props) => {
    const values = _get(context.values, props.name, []) || [];
    const item = _cloneDeep(props.item)

    // Handle if we are adding objects of primitive types to an array
    if (props.fieldsets.length === 1 && props.fieldsets[0].fields.length === 1 && !props.fieldsets[0].fields[0].name) {
        props.onFieldChange({target: props.listRef.current}, props.name, [...values, '']);
    } else {
        item.id = nanoid()


        if (item.imageState?.__cx__) {
            // If there's an image in the item let's update its ID so it maintains its own state
            item.imageState.__cx__.id = nanoid();
        }

        const newValues = [...values, item]

        // Handle when the list is inherited and set the position array  
        if (!!context.original) {
            const positionsFieldName = getPositionFieldName(context, props)
            props.onFieldChange({target: props.listRef.current}, positionsFieldName, [...props.items.map(item => item.id), item.id]);
        }

        props.onFieldChange({target: props.listRef.current}, props.name, newValues);
    }
}

/**
 * Handles editing an item in the list
 *
 * @param context The form context
 * @param props The list item props
 */
const editItem = (context, props) => {
    const fieldsets = prefixFieldNames(props.fieldsets, props.prefix);
    const original = _cloneDeep(props.item);
    const fileUploadSettings = _isFunction(props.fileUploadSettings)
        ? props.fileUploadSettings(props.item)
        : props.fileUploadSettings;

    context.setContext({
        showChildForm: true,
        childForm: (
            <Form
                id={"child_form"}
                layout={"panel-style"}
                className={"h-full"}
                fileUploadSettings={fileUploadSettings || context.fileUploadSettings}
                heading={props.item.__cx__?.added === true
                    ? `Add ${props.defaultItemLabel}`
                    : `Edit ${props.defaultItemLabel}`}
                fieldsets={fieldsets}
                parentContext={context}
                values={context.values}
                original={context.original}
                hrefUriParams={context.hrefUriParams}
                actions={[
                    {label: "Cancel", back: true},
                    {label: "Done", primary: true, icon: "check"},
                    {label: "Remove", color: "error", icon: "trash", destructive: true}
                ]}
                onSubmit={(e, values, onProgress) => {
                    const updated = [].concat(_get(values, props.name, []));

                    props.onFieldChange({target: props.listRef.current}, props.name, updated);

                    return (
                        _isFunction(props.onItemChange)
                            ? Promise.all([props.onItemChange(e, updated[props.itemIndex], updated, onProgress)])
                            : Promise.resolve()
                    ).finally(() => {
                        // Transition back to the previous form
                        context.setContext({showChildForm: false});
                    });
                }}
                onActionClick={(e, action) => {
                    // When navigating back if the item was added then remove it otherwise revert it
                    if (action.back) {
                        const values = [].concat(_get(context.values, props.name, []));

                        // Remove the items if it was added
                        if (props.item.__cx__?.added === true) {
                            values.splice(props.itemIndex, 1);
                        }
                        // Otherwise revert the changes
                        else {
                            values.splice(props.itemIndex, 1, original);
                        }

                        props.onFieldChange({target: props.listRef.current}, props.name, values);
                    }

                    // Handle when removing the item
                    if (action.destructive === true) {
                        e.stopPropagation();
                        deleteItem(context, props)
                    }

                    // Transition back to the previous form
                    context.setContext({showChildForm: false});
                }}
                onFieldChange={(e, name, value) => {
                    props.onFieldChange(e, name, value);
                }}
            />
        )
    });
}

/**
 * Handles removing and item from the list
 *
 * @param context The form context
 * @param props The list item props
 */
const deleteItem = (context, props) => {
    if (!confirm('Are you sure?')) return
    const values = [].concat(_get(context.values, props.name, []) || []);

    // When deleting an item from an inherited list, then softly delete items that are inherited.
    // Items that have been added in any case are deleted.
    if (!!context.original) {
        const originalValues = [].concat(_get(context.original, props.name, []) || []);
        const originalIds = originalValues.map(item => item.id)

        const item = values[props.itemIndex]
        if (originalIds.includes(item.id)) {
            _set(values, `${props.itemIndex}.isDeleted`, true)
        } else {
            values.splice(props.itemIndex, 1);

            // Remove this item from the position array and re-sync it
            const positionFieldName = getPositionFieldName(context, props)
            const positions = (_get(context.values, positionFieldName, []) || []).filter(position => position !== item.id)
            const originalValues = [].concat(_get(context.original || {}, props.name, []))
            const originalPositions = originalValues.map(({id}) => id)

            // If the array matches the original state, then clear it
            if (_isEqual(positions, originalPositions)) {
                props.onFieldChange({target: props.listRef.current}, positionFieldName, null);
            } else {
                props.onFieldChange({target: props.listRef.current}, positionFieldName, positions);
            }
        }
    }
    // Otherwise, remove the item from the list
    else {
        values.splice(props.itemIndex, 1);
    }

    props.onFieldChange({target: props.listRef.current}, props.name, values);
}

/**
 * Returns the field name of the array which will store positional data for the list of items.
 *
 * @param context The form context
 * @param props The list props
 * @return {string}
 */
function getPositionFieldName(context, props) {
    return `${props.name}${POSITIONS_FIELD_NAME_SUFFIX}`
}

/**
 * Handles setting an additional field using the current name of this field to capture the sort positions of items
 *
 * @param context The form context
 * @param props The list props
 * @param listRef An element reference to the list react element
 * @param values The current state of the list values
 */
const populateItemPositions = (context, props, listRef, values) => {
    if (!props.sortable) return

    if (!context.original) {
        props.onFieldChange({target: listRef.current}, props.name, values);
        return
    }

    const positionsFieldName = getPositionFieldName(context, props)
    const originalValues = [].concat(_get(context.original || {}, props.name, []))
    const originalPositions = originalValues.map(({id}) => id)
    const positions = values.map(({id}) => id)

    // If the array matches the original state, then clear it
    if (_isEqual(positions, originalPositions)) {
        props.onFieldChange({target: listRef.current}, positionsFieldName, null);
    } else {
        props.onFieldChange({target: listRef.current}, positionsFieldName, positions);
    }
}


const DragHandle = SortableHandle(() => (
    <div className="flex items-center cursor-pointer">
        <MenuIcon className="h-5 w-5 text-panels-700" aria-hidden="true"/>
    </div>
));

const DefaultSortableItem = SortableElement(({item, ...props}) => {
    const fieldsets = prefixFieldNames(props.fieldsets, props.prefix);
    const context = useContext(FormContext);
    const label = _get(item, props.labelFieldPath)

    return (
        <li className={"list-none select-none"}>
            <FormLayout
                {...props}
                layout={"panel-list-form"}
                className={classNames("w-full bg-panels-200 rounded py-2 px-3", props.itemIndex > 0 ? "mt-2" : null)}
                actions={[{label: "Remove", color: "error", icon: "trash"}]}
                fieldsets={fieldsets.map(fieldset => ({
                    ...fieldset,
                    collapsable: fieldset.collapsable !== false,
                    expanded: fieldset.collapsable !== false && item.__cx__?.added === true
                }))}
                onActionClick={() => {
                    const values = [].concat(_get(context.values, props.name, []));
                    values.splice(props.itemIndex, 1);
                    props.onFieldChange({target: props.listRef.current}, props.name, values);
                }}
            >
                {props.sortable ? (
                    <DragHandle className={"shrink-0"}/>
                ) : null}
                <span className={classNames(
                    "grow text-sm font-semibold text-panels-700 text-left text-ellipsis overflow-hidden",
                    props.sortable ? "mx-2" : "mr-2"
                )}>
                    {label ? label : `${props.defaultItemLabel} ${props.itemIndex + 1}`}
                </span>
            </FormLayout>
        </li>
    );
});

const NavigableSortableItem = SortableElement((props) => {
    const context = useContext(FormContext);
    const label = _get(props.item, props.labelFieldPath);
    useEffect(() => {
        if (props.item.__cx__?.added === true || props.item.__cx__?.edit) {
            editItem(context, props);
        }
    }, []);

    return (
        <li
            className={classNames(
                "list-none bg-white flex flex-row items-center justify-between rounded border border-gray-500 shadow-button py-1 px-1.5 cursor-pointer",
                // Hide the item if it's just been added
                props.item.__cx__?.added === true ? "hidden" : null
            )}
            onClick={() => editItem(context, props)}
        >
            {props.sortable ? (
                <DragHandle className={"shrink-0"}/>
            ) : null}
            <span className={classNames(
                "grow text-sm font-semibold text-panels-700 text-left text-ellipsis overflow-hidden",
                props.sortable ? "mx-2" : "mr-2"
            )}>
                {label ? label : `${props.defaultItemLabel} ${props.itemIndex + 1}`}
            </span>
            <Menu as="div" className="-ml-px relative flex items-center">
                <Menu.Button
                    className="bg-panels-100 cursor-pointer text-gray-500 relative inline-flex items-center rounded-full font-medium focus:outline-none hover:bg-panels-200 p-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    <MoreIcon className="h-4 w-4" aria-hidden="true"/>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="origin-top-right absolute z-10 right-0 mt-2 -mr-1 w-48 menu-container">
                        <div className="flex flex-col">
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item-primary"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItem(context, props)
                                }}
                            >Edit</Menu.Item>
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    duplicateItem(context, props)
                                }}
                            >Duplicate</Menu.Item>
                            <Menu.Item
                                as={"button"}
                                type={"button"}
                                className={"grow block menu-item-danger"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteItem(context, props)
                                }}
                            >Remove</Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </li>
    )
});

const SortableList = SortableContainer((props) => {
    const context = useContext(FormContext);
    const Item = context.layout === 'panel-style' ? NavigableSortableItem : DefaultSortableItem;

    // Computes a lookup of index positions which correlate to the actual value in form values. 
    // When inheriting changes and the user has changed sort positions, then when modifying the list items, we need to
    // reference the original index not the user-defined one
    const indexLookup = useMemo(() => {
        const items = _get(context.values, props.name, []) || []

        return items.reduce((result, item, index) => {
            if (item.id) {
                result[item.id] = index
            }
            return result
        }, {})
    }, [context.values, props.name])

    return (
        <ul ref={props.ref} className={"sortable-list space-y-2"}>
            {props.items.map((item, index) => {
                const itemIndex = indexLookup[item.id] ?? index
                const prefix = `${props.name}[${itemIndex}]`;
                return (
                    <Item
                        {...props}
                        key={prefix}
                        index={index}
                        itemIndex={itemIndex}
                        item={item}
                        prefix={prefix}
                        items={props.items}
                    />
                )
            })}
        </ul>
    )
});

List.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    fieldsets: PropTypes.array,
    hiddenFields: PropTypes.object,
    name: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    onItemChange: PropTypes.func,
    placeholder: PropTypes.string,
    labelFieldPath: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    defaultItemLabel: PropTypes.string,
    onSelectExisting: PropTypes.func,
    beforeItemAdd: PropTypes.func,
    fileUploadSettings: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

List.defaultProps = {
    onFieldChange: () => {},
    onItemChange: () => {},
    defaultItemLabel: "Item",
    beforeItemAdd: () => {},
}

export default function List(props) {
    const context = useContext(FormContext);
    const listRef = useRef();
    const containerRef = useRef();
    const [items, setItems] = useState([]);
    const showEdit = props.editing && !props.system;

    useEffect(() => {
        let items = [].concat(_get(context.values, props.name, []) || []).map(i => i.id ? i : {...i, id: nanoid()});
        const positionsFieldName = `${props.name}${POSITIONS_FIELD_NAME_SUFFIX}`
        const positions = _get(context.values, positionsFieldName)

        items = items.filter(item => !item.isDeleted)

        // Handle when this control is sortable and there are sort positions defined
        if (props.sortable && positions?.length) {
            items.sort((a, b) => positions.indexOf(a.id) - positions.indexOf(b.id))
        } 

        setItems(items)
    }, [context.values, props.name]);

    return (
        <div id={props.id} ref={containerRef} className={classNames("relative", props.className)}>
            <div className={classNames("flex mb-2", props.label ? "justify-between" : "justify-end")}>
                {props.label ? (
                    <label htmlFor={props.id} className={"block text-sm font-semibold text-gray-700"}>
                        {props.label}
                    </label>
                ) : null}
                {showEdit ? (
                    <span
                        className={"text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                        onClick={props.onEditFieldSettings}
                    >Edit</span>
                ) : null}
            </div>
            {props.description ? (
                <p className="mt-1 mb-0 text-xs text-gray-500">
                    {props.description}
                </p>
            ) : null}
            {items.length ? (
                <SortableList
                    ref={listRef}
                    listRef={listRef}
                    {...props}
                    items={items}
                    useDragHandle
                    getContainer={() => {
                        let parent = containerRef.current.parentElement;
                        while (!(parent.tagName === 'BODY' || parent.scrollHeight > parent.offsetHeight)) {
                            parent = parent.parentElement;
                        }

                        return parent || document.body;
                    }}
                    helperClass={"sort-helper"}
                    // Adds z-index to sort helper to handle cases when rendering this control in a modal
                    onSortStart={() => {
                        const helper = document.getElementsByClassName("sort-helper")[0];
                        helper.style.zIndex = 50000;
                    }}
                    onSortEnd={({oldIndex, newIndex}) => {
                        const newValues = arrayMove(items, oldIndex, newIndex)
                        populateItemPositions(context, props, listRef, newValues)
                    }}
                />
            ) : null}
            {props.onSelectExisting ? (
                <Menu as="div" className="-ml-px relative block">
                    <Menu.Button
                        className="items-center mt-3 flex flex-row shadow-button border border-primary-500 h-8 px-2 shrink-0 font-semibold leading-4 rounded-md text-blue-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2">
                        <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true"/>
                        Add {props.defaultItemLabel}
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="origin-top-right absolute z-10 left-0 mt-2 -mr-1 w-48 rounded-md shadow-lg bg-white focus:outline-none">
                            <div className="flex flex-col">
                                <Menu.Item
                                    as={"button"}
                                    type={"button"}
                                    className={"grow block menu-item"}
                                    onClick={() => {
                                        props.onSelectExisting()
                                            .then((existing) => {
                                                addItem(context, props, listRef, existing, items);
                                            })
                                    }}
                                >Select existing {props.defaultItemLabel.toLowerCase()}</Menu.Item>
                                <Menu.Item
                                    as={"button"}
                                    type={"button"}
                                    className={"grow block menu-item"}
                                    onClick={() => addItem(context, props, listRef, null, items)}
                                >Create new {props.defaultItemLabel.toLowerCase()}</Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            ) : (
                <button
                    type={"button"}
                    disabled={props.disabled}
                    className="items-center mt-3 flex flex-row shadow-button border border-blue-500 h-8 px-2 shrink-0 font-semibold leading-4 rounded-md text-primary-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2"
                    onClick={() => addItem(context, props, listRef, null, items)}
                >
                    <PlusIcon className="h-5 w-5 mr-1" aria-hidden="true"/>
                    Add {props.defaultItemLabel}
                </button>
            )}
        </div>
    );
};