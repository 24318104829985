import classNames from "classnames";
import _get from "lodash/get";
import _isString from "lodash/isString";
import _set from "lodash/set";
import _mergeWith from "lodash/mergeWith";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _isEqual from "lodash/isEqual";
import _has from "lodash/has";
import _unset from "lodash/unset";
import _isFunction from "lodash/isFunction";
import _find from "lodash/find";
import _transform from "lodash/transform";
import _reduce from "lodash/reduce";
import _isEmpty from "lodash/isEmpty";

/**
 * Determines if the field is required
 *
 * @param props The props of the field
 * @param values The current form values
 * @returns {boolean|boolean|boolean|*}
 */
export function isRequired(props, values) {
    if (!props || props.required === undefined || props.required === null) {
        return false;
    } else if (typeof props.required === 'string') {
        return evaluate(null, props.required, values);
    } else {
        return props.required;
    }
}

/**
 * Determines if the field is disabled
 *
 * @param props The props of the field
 * @param values The current form values
 * @returns {boolean|boolean|boolean|*}
 */
export function isDisabled(props, values) {
    if (!props || props.disabled === undefined || props.disabled === null) {
        return false;
    } else if (typeof props.disabled === 'string') {
        return evaluate(null, props.disabled, values);
    } else {
        return props.disabled;
    }
}

/**
 * Resolves the expression with the provided values and returns the evaluated value
 *
 * @param value The value of the input. TODO: Remove this param when conditions property on fields have been removed
 * @param expression The expression to resolve
 * @param values The current form values which will be as the context to resolve the lhs of expressions
 * @returns {boolean|undefined}
 */
export function evaluate(value, expression, values) {
    if (expression === undefined || expression === null) {
        return;
    }
    try {
        const totalOrs = (expression.match(/[|][|]/g) || []).length;
        const totalAnds = (expression.match(/[&][&]/g) || []).length;
        const startingCompound = totalOrs > totalAnds ? '&&' : '||';
        const endingCompound = totalOrs > totalAnds ? '||' : '&&';
        const startingAggregate = totalOrs > totalAnds ? 'every' : 'some';
        const endingAggregate = totalOrs > totalAnds ? 'some' : 'every';

        return expression.split(startingCompound)[startingAggregate]((orExpression) => {
            orExpression = orExpression.trim();

            return orExpression.split(endingCompound)[endingAggregate]((andExpression) => {
                andExpression = andExpression.trim();

                const parts = andExpression.trim().split(' ');
                if (parts.length === 3) {
                    const index = andExpression.indexOf(' ');
                    let lhs = _get(values, andExpression.substr(0, index));

                    if (typeof lhs === 'string') {
                        lhs = `"${lhs?.replace(/"+/g, '\\"')}"`;
                    }

                    return window.eval(`${lhs}${andExpression.substr(index)}`) === true;
                }
                // TODO: Remove this else statement when the conditions field has been removed
                else {
                    if (typeof value === 'string') {
                        value = `"${value?.replace(/"+/g, '\\"')}"`;
                    }

                    return window.eval(`${value}${andExpression}`) === true;
                }
            });
        });
    } catch (e) {
    }
}

/**
 * Determines if the field has a different value then its original state. Nulls are ignored.
 *
 * @param original The original state of the form
 * @param current The current state of the form
 * @param name The field name in question
 * @returns {boolean}
 */
export function isDifferent(original, current, name) {
    if (original && current && name) {
        const lhs = _get(original, name, null);
        const rhs = _get(current, name, null);

        if (_isString(lhs) && _isString(rhs) && lhs !== rhs) {
            return rhs.trim() !== '';
        }

        if (!_isEqual(lhs, rhs)) {
            return rhs != null;
        }
    }

    return false;
}

/**
 * Prefixes field names used in expressions with the provided prefix
 *
 * @param expression
 * @param prefix
 * @returns {*}
 */
export function prefixExpression(expression, prefix) {
    if (!(expression && typeof expression === 'string' && expression.trim() !== '')) return expression;
    if (!(prefix && typeof prefix === 'string' && prefix.trim() !== '')) return expression;

    return expression.split('||').reduce((result, or) => {
        or = or.trim()
        const expressions = or.split('&&').reduce((result, and) => {
            and = and.trim();
            // Ignore legacy expressions
            if (!(and.match(/^[\W_]/g) || []).length) {
                result.push(`${prefix}.${and}`);
            } else if (and.startsWith('..')) {
                result.push(and.split('..')[1])
            } else {
                result.push(and);
            }
            return result;
        }, []).join(' && ');

        result.push(expressions);
        return result;
    }, []).join(' || ')
}

/**
 * Prefixes all fields in the provided fieldsets with the provided prefix. It will also prefix any fields used in
 * expressions.
 *
 * @param fieldsets
 * @param prefix
 * @returns {[{}]}
 */
export function prefixFieldNames(fieldsets = [], prefix) {
    if (!prefix || !fieldsets) return fieldsets;

    const prefixed = [];
    for (const fieldset of fieldsets) {
        if (!_isObject(fieldset)) {
            prefixed.push(fieldset);
            continue;
        }

        const prefixedFieldset = {...fieldset};
        if (_isArray(fieldset.fields)) prefixedFieldset.fields = [];
        if (prefixedFieldset.showsWhen) prefixedFieldset.showsWhen = prefixExpression(fieldset.showsWhen, prefix);

        prefixed.push(prefixedFieldset);

        for (const field of (fieldset.fields || [])) {
            if (!_isObject(field)) {
                prefixedFieldset.fields.push(field);
                continue;
            }

            const prefixedField = {...field};

            if (field.showsWhen) prefixedField.showsWhen = prefixExpression(field.showsWhen, prefix);

            // Handle the form layout type of control and process it like a list of fieldsets
            if (field.type === 'form-layout') {
                if (_isArray(field.fieldsets)) {
                    prefixedField.fieldsets = prefixFieldNames(field.fieldsets, prefix);
                }

                if (_isObject(field.hiddenFields)) {
                    prefixedField.hiddenFields = Object.keys(field.hiddenFields).reduce((result, key) => {
                        result[key ? `${prefix}.${key}` : key] = field.hiddenFields[key];
                        return result;
                    }, {});
                }

                prefixedFieldset.fields.push(prefixedField);
                continue;
            }
            // Handle the control group type of field and process it like it's a fieldset
            else if (field.type === 'control-group') {
                prefixedField.fields = prefixFieldNames([field], prefix)[0].fields;
                prefixedFieldset.fields.push(prefixedField);
                continue;
            } else {
                if (field.name) prefixedField.name = `${prefix}.${field.name}`;
                if (field.required) prefixedField.required = prefixExpression(field.required, prefix);
                if (field.disabled) prefixedField.disabled = prefixExpression(field.disabled, prefix);
                if (field.pathToValue) prefixedField.pathToValue = `${prefix}.${field.pathToValue}`;

                if (_isArray(field.options)) {
                    prefixedField.options = field.options.map(option => {
                        if (!_isObject(option)) return option;
                        const prefixedOption = {...option};
                        if (option.showsWhen) prefixedOption.showsWhen = prefixExpression(option.showsWhen, prefix)
                        return prefixedOption;
                    });
                }
                prefixedFieldset.fields.push(prefixedField);
            }

            // Handle condition definitions on a field
            // TODO: Legacy code | this should be removed when `conditions` is removed from the spec 
            if (_isObject(field.conditions)) {
                const expressions = Object.keys(field.conditions);
                if (expressions.length) {
                    prefixedField.conditions = {};
                    for (const expression of expressions) {
                        prefixedField.conditions[expression] = {
                            ...field.conditions[expression],
                            fieldsets: prefixFieldNames(field.conditions[expression].fieldsets, prefix)
                        };

                        if (_isObject(field.conditions[expression].hiddenFields)) {
                            const keys = Object.keys(field.conditions[expression].hiddenFields);
                            if (keys.length) {
                                prefixedField.conditions[expression].hiddenFields = keys.reduce((result, name) => {
                                    result[name ? `${prefix}.${name}` : name] = field.conditions[expression].hiddenFields[name];
                                    return result;
                                }, {});
                            }
                        }
                    }
                }
            }
        }
    }

    return prefixed;
}

/**
 * Returns an object where the keys are the names of the fields and the value is the value from the property path.
 *
 * @param fieldsets The list of fieldsets to process
 * @param fieldProp An optional property path to use when calculating the value of the key
 * @param contextValues
 * @returns {*}
 */
export function fieldsetsToObject(fieldsets = [], fieldProp = 'value', contextValues = {}) {
    if (!_isArray(fieldsets) || !fieldProp) return {};

    const fieldsWithShowsWhen = [];
    const fieldsWithConditions = [];
    const result = fieldsets.reduce((result, fieldset) => {
        if (!_isObject(fieldset)) return result;

        // Skip the fieldset all together if showsWhen resolves to false
        if (evaluate(null, fieldset.showsWhen, result) === false) return result;

        if (!_isArray(fieldset.fields)) return result;

        for (const field of fieldset.fields) {
            if (!_isObject(field)) continue;
            if (field.type === 'form-layout') {
                _mergeWith(result, fieldsetsToObject(field.fieldsets, fieldProp));
            } else if (field.type === 'control-group') {
                _mergeWith(result, fieldsetsToObject([field], fieldProp));
            } else if (field.showsWhen) {
                fieldsWithShowsWhen.push(field);
            } else if (field[fieldProp] !== undefined
                && field[fieldProp] !== null
                && _isString(field.name)) {
                const inputtedValue = _get(contextValues, field.name);
                inputtedValue !== null && inputtedValue !== undefined ? _set(result, field.name, inputtedValue) : _set(result, field.name, field[fieldProp]);
            }

            // Handle when a field has conditions to also get the value of field defined in conditions
            // TODO: Remove this logic when conditions field has been replaced with showsWhen
            if (_isObject(field.conditions)) {
                fieldsWithConditions.push(field);
            }
        }
        return result;
    }, {});

    // Shows When fields are done after so the results object has all the correct data in order to get the correct value.
    for (const field of fieldsWithShowsWhen) {
        // This is done so fields that are reliant on other fields with shows when can evaluate first.
        if (evaluate(null, field.showsWhen, {...contextValues, ...result}, field) === false) {
            if (!field.hit) {
                fieldsWithShowsWhen.push({...field, hit: true});
            }
        } else if (field[fieldProp] !== undefined
            && field[fieldProp] !== null
            && !(evaluate(null, field.showsWhen, result) === false)
            && _isString(field.name)) {
            const inputtedValue = _get(contextValues, field.name);
            if (inputtedValue !== null && inputtedValue !== undefined) {
                if (field.options?.length > 0) {
                    // If field has options we want to check if the value matches one of those options. If it doesn't we default back to the form spec value.
                    (_find(field.options, {value: inputtedValue})) ? _set(result, field.name, inputtedValue) : _set(result, field.name, field[fieldProp]);
                } else {
                    _set(result, field.name, inputtedValue);
                }
            } else {
                _set(result, field.name, field[fieldProp]);
            }
        }
        // This is done for the uploader to add the adjust button back in for designs that do not support image presentation.
        else if (field[fieldProp] !== undefined
            && field[fieldProp] !== null
            && (evaluate(null, field.showsWhen, result) === false)
            && _isString(field.name)
            && field.name.includes('imagePresentation')) {
            _set(result, field.name, '');
        }
    }

    // Process the list of fields that have conditions
    // TODO: Remove this logic when conditions field has been replaced with showsWhen
    for (const field of fieldsWithConditions) {
        Object.keys(field.conditions)
            .filter(expression => evaluate(field.value, expression, result) === true)
            .map(expression => {
                const conditionalValues = fieldProp === 'value'
                    ? getValuesIncludingHidden(field.conditions[expression].fieldsets, field.conditions[expression].hiddenFields)
                    : fieldsetsToObject(field.conditions[expression].fieldsets, fieldProp);
                _mergeWith(result, conditionalValues);
            });
    }

    return result;
}

/**
 * Returns an object where the keys are the names of the fields and the value is the value from the property path.
 *
 * @param values An object which contains all the values to apply to the fieldsets
 * @param fieldsets The list of fieldsets to process
 * @returns {*}
 */
export function valuesToFieldsets(values = {}, fieldsets = []) {
    const fieldsWithConditions = [];
    fieldsets.forEach(({fields = []}) => {
        fields.forEach(function reducer(field) {
            if (field.type === 'form-layout') {
                (field.fieldsets || []).forEach(({fields = []}) => fields.forEach(reducer));
            } else if (field.type === 'control-group') {
                (field.fields || []).forEach(reducer);
                return;
            }

            const value = _get(values, field.name);
            if (value !== undefined && value !== null) {
                field.value = value;
            }

            // Handle when a field has conditions to also get the value of field defined in conditions
            // TODO: Remove this logic when conditions field has been replaced with showsWhen
            if (field.conditions) {
                fieldsWithConditions.push(field)
            }
        })
    });

    // Process the list of fields that have conditions
    // TODO: Remove this logic when conditions field has been replaced with showsWhen
    fieldsWithConditions.forEach((field) => {
        Object.keys(field.conditions)
            .filter(expression => evaluate(field.value, expression, values) === true)
            .forEach(expression => valuesToFieldsets(values, field.conditions[expression].fieldsets));
    });
}

/**
 * Returns an object where the keys are the names of the fields and the value is the value of the field. This also
 * includes the the hidden fields object.
 *
 * @param fieldsets
 * @param hiddenFields
 * @param contextValues
 * @returns {*}
 */
export function getValuesIncludingHidden(fieldsets = [], hiddenFields = {}, contextValues) {
    const values = fieldsetsToObject(fieldsets, 'value', contextValues);

    if (_isObject(hiddenFields)) {
        Object.keys(hiddenFields)
            .filter(name => hiddenFields[name] !== undefined)
            .forEach((path) => {
                _set(values, path, hiddenFields[path]);
            });
    }

    return values;
}

/**
 * Returns the list of use defined fieldsets from the forms fieldsets
 *
 * @param fieldsets The list of fieldsets with both user defined and system fieldsets
 * @returns {*}
 */
export function getUserDefinedFieldsets(fieldsets) {
    return fieldsets
        .filter(fieldset => fieldset.extendable && fieldset.id !== undefined && fieldset.id !== null)
        .map(({fields = [], ...fieldset}) => {
            // `name` is ignored because it will be set on the fly as the value of 
            // `${userDefinedFieldsetsScope}.${field.id}`. See mergeFieldsets function. 
            const mappedFields = fields.filter(field => !field.system).map(({name, ...field}) => field);

            // When the fieldset is a system fieldset then only fields can be added therefore we only need to store the
            // id for the merging logic
            return fieldset.system === true ? {id: fieldset.id, fields: mappedFields} : {
                ...fieldset,
                fields: mappedFields
            }
        })
        .filter(f => f.fields.length);
}

/**
 * Extracts any fields where there `showsWhen` expression evaluates to true and returns a values object which can be
 * used to merge with form values
 *
 * @param fieldsets The list of fieldsets to process the list of fields
 * @param name The name of the field that has changed so that we only process fields that are looking at this field
 *             in there showsWhen expression
 * @param value The value of the field
 * @param values The current values of the form
 * @returns {{}}
 */
export function applyDefaultValuesForExpressions(fieldsets = [], name, value, values) {

    // Get all fields that have an expression
    fieldsets.forEach(({fields = []}) => {
        fields
            .forEach(function reducer(field) {
                const currentValue = _get(values, field.name);

                if (field.type === 'form-layout') {
                    (field.fieldsets || []).forEach(({fields = []}) => fields.forEach(reducer));
                } else if (field.type === 'control-group') {
                    (field.fields || []).forEach(reducer);
                } else if (field.showsWhen?.includes(`${name} `)
                    && field.value !== undefined && field.value !== null
                    && evaluate(null, field.showsWhen, values) === true
                    && (currentValue === undefined || currentValue === null)) {
                    _set(values, field.name, field.value);
                }
            });
    });
}

/**
 * Merges only if there is a difference in the matching property. If the source is null undefined or empty string, then
 * it's ignored.
 *
 * @param targetValue
 * @param sourceValue
 * @returns {*}
 */
export function mergeDiff(targetValue, sourceValue) {
    if (_isArray(targetValue) && _isArray(sourceValue)) {
        // Only add entries that don't exist keeping the array reference of the target in tacked
        sourceValue.filter(si => targetValue.every(ti => ti.id !== si.id)).forEach(item => targetValue.push(item));
        return targetValue;
    }

    if (_isObject(targetValue) && _isObject(sourceValue)) {
        return _mergeWith({}, targetValue, sourceValue, mergeDiff);
    }

    return sourceValue !== null && sourceValue !== undefined && sourceValue !== '' ? sourceValue : targetValue;
}

/**
 * Returns an object which contains only the fields that have changed
 *
 * @param current The current state
 * @param base The state to compare with
 * @return {object}
 */
export function deepDiff(current, base) {
    // Handle when the current value is new
    if (base === undefined || base === null) return current

    return _transform(current, (result, value, key) => {
        // Handle arrays matching items by id and compare them
        if (_isArray(value)) {
            const previous = base[key]
            result[key] = _reduce(value, (result, item) => {
                let existing = item.id !== undefined ? previous.find(i => i.id === item.id) : undefined

                // Add if not exists
                if (!existing) {
                    result.push(item)
                }
                // Handle if the item exists and only push the different
                else if (!_isArray(existing) && _isObject(existing)) {
                    const itemDiff = deepDiff(item, existing)
                    if (!_isEmpty(itemDiff)) {
                        result.push({
                            id: existing.id,
                            ...itemDiff
                        })
                    }
                }
                return result
            }, [])

            // Ignore if empty array
            if (_isEmpty(result[key])) delete result[key]
            return result
        }

        // Handle nested objects
        if (!_isArray(value) && _isObject(value)) {
            result[key] = deepDiff(value, base[key])

            // Ignore if empty object
            if (_isEmpty(result[key])) delete result[key]
            return result
        }

        // Handle primitives
        if (!_isEqual(value, base[key]) && value !== undefined && value !== null) {
            result[key] = value
        }

        return result
    }, {})
}

/**
 * Merges the list of system fieldsets with the list of user defined fieldsets and sets up the field names
 *
 * @param fieldsets The list of system fieldsets
 * @param userDefinedFieldsets The list of user defined fieldsets
 * @param userDefinedFieldsetsScope The property path prefix to apply to user defined field names
 * @param extendable Whether you can add fields to the form.
 * @returns {[]}
 */
export function mergeFieldsets(fieldsets = [], userDefinedFieldsets = [], userDefinedFieldsetsScope, extendable) {
    const lookup = userDefinedFieldsets.reduce((result, {fields = [], ...fieldset}) => {
        result[fieldset.id] = fieldset;

        fieldset.fields = fields.map((field) => {
            if (extendable) field.disabled = true;
            return {
                ...field,
                name: `${userDefinedFieldsetsScope ? `${userDefinedFieldsetsScope}.` : ''}${field.id}`
            }
        });

        return result;
    }, {});

    return fieldsets.map(fieldset => {
        if (fieldset.id && lookup[fieldset.id]) {
            const source = lookup[fieldset.id];

            // Take this fieldset out of the lookup because we have processed it
            delete lookup[fieldset.id];

            return _mergeWith(fieldset, source, mergeDiff);
        }

        return fieldset;
    }).concat(Object.values(lookup));
}

/**
 * Checks to see if theres a step field in fieldset if so hide all fields in the next steps.
 *
 * @param fieldsets The list of system fieldsets
 * @param currentStep
 * @returns {[]}
 */
export function getSteps(fieldsets = [], currentStep) {
    if (fieldsets?.length === 1 && fieldsets[0].fields) {
        if (fieldsets[0].originalFields) fieldsets[0].fields = fieldsets[0].originalFields;
        const steps = fieldsets[0].fields.filter(f => f?.type === 'step');
        if (steps.length > 0) {
            fieldsets[0].originalFields = fieldsets[0].fields;
            let count = 0;
            currentStep = currentStep ? currentStep : 1;

            fieldsets[0].fields = fieldsets[0].fields?.map(f => {
                if (f?.type === 'step') ++count;
                return (count === currentStep) ? f : {...f, type: 'hidden', required: false}
            });


            fieldsets[0].steps = steps;
            fieldsets[0].currentStep = currentStep;
            fieldsets[0].totalSteps = steps.length;
        }
    }
    return fieldsets;
}

/**
 * Returns the value of a field or it's default value
 *
 * @param props The field props to interpret
 * @param values The values object to get it's values or return the default value
 * @returns {string|boolean}
 */
export function getValue(props, values) {
    if (!props || !values) return undefined;

    const hasOptions = !!props.options;
    const isCheckbox = props.type === 'checkbox' || props.type === 'switch' || props.type === 'checkbox-list';
    const hasTruthyFalsyValues = (props.truthyValue !== undefined && props.truthyValue !== null)
        || (props.falsyValue !== undefined && props.falsyValue !== null);

    if (isCheckbox && !hasTruthyFalsyValues) {
        const value = _get(values, props.name, props.value !== undefined ? props.value : false)
        if (value) return value
        else if (props.type !== 'checkbox-list') return false
    }

    const result = _get(values, props.name);

    if (hasOptions) {
        return result === undefined || result === null
            ? (props.value === undefined || props.value === null)
                ? ''
                : props.value
            : _find(props.options, {"value": result})
                ? result
                : (props.value === undefined || props.value === null)
                    ? ''
                    : props.value;
    }

    return result === undefined || result === null
        ? (props.value === undefined || props.value === null)
            ? ''
            : props.value
        : result;
}

/**
 * Determines if a field is checked. This function should only be used for fields that are driven by a checked state
 *
 * @param props The field props to interpret
 * @returns {boolean|boolean}
 */
export function isChecked(props) {
    if (props.type !== 'checkbox' && props.type !== 'switch') {
        return false;
    }

    if (props.truthyValue !== undefined && props.truthyValue !== null && props.value === props.truthyValue) {
        return true;
    } else if (props.falsyValue !== undefined && props.falsyValue !== null && props.value === props.falsyValue) {
        return false;
    } else {
        return props.invertValue ? !Boolean(props.value) : Boolean(props.value);
    }
}

const VALID_NODE_NAMES = ["input", "select", "textarea"];

/**
 * Returns a list of HTML elements that match valid form field types we interpret as fields for a form.
 * See `VALID_NODE_NAMES` above
 *
 * @param element The element to scope the query in
 * @returns {NodeListOf<HTMLElementTagNameMap[string]> | NodeListOf<Element> | NodeListOf<SVGElementTagNameMap[string]>}
 */
export const getFormFields = (element) => {
    return element.querySelectorAll(VALID_NODE_NAMES.join(','));
}

/**
 * Determines if fields in a form are valid natively
 *
 * @param element The element to scope the query in
 * @param setContext The react context FormContext setContext function to change the error state if there are errors
 * @param autoSaving True if the form was submitted via an auto save event otherwise false
 * @returns {boolean}
 */
export const validate = (element, setContext, autoSaving) => {
    let focusedFirst = false;
    const errors = {};

    getFormFields(element)
        .forEach((element) => {
            // If the element has a name and is not valid then update the fieldsets state
            if (!(!element.name || element.validity.valid)) {

                // Use placeholder otherwise fall back to the first label assigned to the input otherwise finally the word `Field`
                const label = (element.labels?.[0] || {textContent: 'Field'}).textContent.trim();
                _set(
                    errors,
                    element.name,
                    classNames({
                        [element.dataset.errorMessage || `${element.validationMessage}`]: element.validity.customError,
                        [element.dataset.errorMessage || `${label} is required|`]: element.validity.valueMissing,
                        [`${label} is not in the correct format|`]: element.validity.badInput || element.validity.patternMismatch,
                        [`${label} is too long|`]: element.validity.tooLong,
                        [`${label} is too short|`]: element.validity.tooShort,
                        [`${label} is too big|`]: element.validity.rangeOverflow,
                        [`${label} is too small|`]: element.validity.rangeUnderflow,
                        [`${label} is invalid|`]: element.validity.stepMismatch,
                    }).split('|')[0] || `${label} is invalid`
                );

                // Focus element that is invalid. Do this only when we are not auto saving
                if (!focusedFirst && !autoSaving) {
                    // Handle when the form control is using a hidden native element and fall back to scrolling the 
                    // window to the offset top of the parent element
                    if (element.classList.contains('hidden') && element.getAttribute('type') !== 'hidden') {
                        window.scroll(0, element.parentNode.offsetTop);
                    } else {
                        // Checks to see if required input is hidden within an accordion
                        const accordion = element?.closest('[id^="headlessui-disclosure"]')?.parentNode?.parentNode;
                        if (accordion) {
                            const accordionBtn = accordion.previousSibling;
                            accordion.getAttribute('aria-hidden') === 'true' ? accordionBtn.click() : null;
                            setTimeout(() => {
                                element.focus();
                            }, 500)
                        } else {
                            element.focus();
                        }
                    }

                    focusedFirst = true;
                }
            }
        });

    if (Object.keys(errors).length > 0) {

        // Only update the form context with the error states when we are not auto saving the form
        if (!autoSaving) {
            setContext(previous => ({
                ...previous,
                errors: {...previous.errors, ...errors},
            }));
        }
        return false;
    } else {
        return true;
    }
}

/**
 * Sets the custom validity for unique fields where there is more then one row with the same value as the field.
 *
 * @param context The content of the form
 * @param fieldsets The list of fieldsets where `prefixFieldNames(...)` has already been run on them.
 */
export const validateCollection = (context, fieldsets = []) => {
    fieldsets
        .reduce((result, {fields = []}) => {
            result.push(...fields.filter(field => field.unique));
            return result;
        }, [])
        .forEach((field) => {
            const collectionFieldName = field.name.substr(0, field.name.lastIndexOf('['));
            const fieldName = field.name.substr(collectionFieldName.length + 4);
            const collection = _get(context.values, collectionFieldName);
            const value = _get(context.values, field.name);

            const count = collection.reduce((count, row) => {
                count += +(_get(row, fieldName) === value);
                return count;
            }, 0);

            if (count > 1) {
                const element = document.querySelector(`[name='${field.name}']`);
                element.setCustomValidity(field.uniqueErrorMessage || 'There can only be one row with this value');
            }
        });
}

/**
 * Clears all fields in all rows that are `onlyOneItem` fields except for the row that was saved.
 *
 * @param props The collection component props
 * @param rows The collection of rows to process
 * @param index The index of the row just saved
 */
export const ensureOnlyOneItemFields = (props, rows, index) => {
    (props.fieldsets || []).forEach(({fields = []}) => {
        fields.forEach((field) => {
            if (field.onlyOneItem === true) {
                const value = getValue(field, rows[index]);
                if (!value) return;

                // If the field has a value then default all the rows field to there default value
                rows.forEach((value, i) => {
                    if (index !== i) {
                        _set(value, field.name, getValue(field, null));
                    }
                });
            }
        })
    });
}

/**
 * Reutns an object where the key is the field name and the value is an array of showsWhen expressions
 *
 * @param fieldsets The list of fieldsets to process
 * @param expressions An optional object to house the results
 * @returns {{}} The object with expressions
 */
function getAllFieldExpressions(fieldsets = [], expressions = {}) {
    // Build a lookup so that we can resolve all expressions for a field name. Because you can have multiple fields
    // with the same name but different expressions we aggregate all the expressions by field name
    fieldsets.forEach(function processFieldset({fields = []}) {
        fields.forEach(function processField(field) {
            if (field.type === 'form-layout') {
                (field.fieldsets || []).forEach(processFieldset);
            } else if (field.type === 'control-group') {
                (field.fields || []).forEach(processField);
            } else if (field.name && field.showsWhen) {
                expressions[field.name] = expressions[field.name] || [];
                expressions[field.name].push(field);
            }
        })
    });

    return expressions;
}

/**
 * Sets field values to null where there shows when expression is false
 *
 * @param fieldsets The list of fieldsets to process
 * @param values The current state of form values to mutate
 */
export const clearShowsWhenFieldValues = (fieldsets = [], values) => {
    const expressions = getAllFieldExpressions(fieldsets);

    Object
        .keys(expressions)
        .forEach((fieldName) => {
            const shouldClear = (expressions[fieldName] || [])
                .every(field => {
                    const result = evaluate(field.value, field.showsWhen, values) === false;
                    if (result) return result;
                    if (field.options?.length) {
                        const selectedValue = getValue(field, values);
                        const option = field.options.find((option) => {
                            if (option.value === selectedValue && evaluate(field.value, field.showsWhen, values) === false) {
                                return option;
                            }
                        });

                        if (option) return true;
                    }

                    return false;
                });

            if (shouldClear && _has(values, fieldName)) {
                _unset(values, fieldName);
            }
        })
}

/**
 * Gets the value of a field from the provided values object relative to a field. Use this when you require to get a
 * value from a field path and you need to account for dynamic prefixing. Prefixing can be applied to the name of the
 * field manually or when located in a list or table control.
 *
 * This method ensures that the sourceName and destinationName start with the same property path
 *
 * @param sourceName The source name of the field that requires the value of the destination field
 * @param destinationName The destination name of the field that we want to get the value for
 * @param values The values of the form
 * @returns {null}
 */
export const getValueRelativeToField = (sourceName, destinationName, values) => {
    if (!sourceName || !destinationName) return null;

    const sourceParts = sourceName.split('.');
    let destinationParts = destinationName.split('.');
    let index = 0;

    if (sourceParts.length > destinationParts.length) {
        destinationParts = [].concat(sourceParts.slice(0, sourceParts.length - destinationParts.length), destinationParts);
    }

    for (let i = 0; i < sourceParts.length - 1; i++) {
        if (sourceParts[index] !== destinationParts[index]) {
            destinationParts.splice(index, 0, sourceParts[index]);
        }
    }

    return _get(values, destinationParts.filter(part => !!part).join('.')) || null
}

/**
 * Loops through the list of provided fieldsets and specific controls that nested fieldsets or fields
 *
 * @param fieldsets The list of fieldsets to traverse
 * @param {function(field)} iteratee A function which is called of every field
 * @returns {void}
 */
export const iterateFields = (fieldsets = [], iteratee) => {
    iteratee = !_isFunction(iteratee) ? () => {
    } : iteratee;
    if (!_isArray(fieldsets)) return;

    for (const fieldset of fieldsets) {
        if (!_isObject(fieldset)) continue;
        if (!_isArray(fieldset.fields)) continue;

        for (const field of fieldset.fields) {
            if (!_isObject(field)) continue;

            iteratee(field);

            if (field.type === 'form-layout') {
                iterateFields(field.fieldsets, iteratee);
            }

            if (field.type === 'control-group') {
                iterateFields([field], iteratee);
            }
        }
    }
}

/**
 * Takes a string and replaces `:someParam` words with the value of field in the provided values object
 * @param href
 * @param values
 * @returns {*}
 */
export function resolveUrlParams(href, values) {
    if (!_isString(href) || !_isObject(values)) return href;
    return href
        .split(/(:[^0-9][a-z0-9.\[\]]+)/i)
        .map(part => {
            if (part.startsWith(':')) {
                const value = _get(values, part.substring(1));

                // Handle if the value cannot be converted to string
                if (value === undefined || null || _isObject(value)) return '';

                // Convert value to string
                if (!_isString(value)) return value.toString();

                return value;
            }

            return part;
        })
        .filter(part => part !== undefined && part !== null && part.trim() !== '').join('');
}

/**
 * Returns a list of required fields that have missing values
 *
 * @param {[object]} fieldsets A list of fieldsets to check
 * @param {object} values The values of the form to check against
 */
export function getFieldsWithMissingValues(fieldsets, values) {
    const errors = []

    iterateFields(fieldsets, (field) => {
        if (!field.name) return

        const value = getValue(field, values)
        let error = {label: field.label, name: field.name}

        if (isRequired(field, values)
            && !(evaluate(null, field.showsWhen, values) === false)
            && (value === undefined || value === null || value === '')
        ) {
            errors.push(error)
            return
        }

        // Exit here if this field does not have a child form
        if (!_isArray(field.fieldsets)) return

        // Handle when a field has nested forms and check its values 
        if (_isArray(value)) {
            error.errors = []
            value.forEach((item, index) => {
                const prefix = `${field.name}[${index}]`
                const prefixedFields = prefixFieldNames(field.fieldsets, prefix)
                const childErrors = getFieldsWithMissingValues(prefixedFields, values)

                if (childErrors.length) {
                    error.errors.push({
                        index,
                        name: prefix,
                        errors: childErrors
                    })
                }
            })
        } else if (_isObject(value)) {
            const prefixed = prefixFieldNames(field.fieldsets, field.name)
            error.errors = getFieldsWithMissingValues(prefixed, values)
        }

        // Add if there are errors
        if (error.errors.length) {
            errors.push(error)
        }
    })
    return errors
}