import Design1 from './design-1';
import Design2 from './design-2';
import Design3 from './design-3';

import './index.css';

export default function CollapseExpandBlock(props) {
    switch (props.values?.design) {
        case 0 :
            return (<Design1 {...props} />);
        case 1 :
            return (<Design2 {...props} />);
        case 2 :
            return (<Design3 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
}