import {useEffect, useMemo, useRef, useState, useContext} from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import axios from 'axios'
import {useErrorBoundary} from "use-error-boundary"
import _set from 'lodash/set';
import _mergeWith from "lodash/mergeWith";
import _cloneDeep from "lodash/cloneDeep";
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _isFunction from "lodash/isFunction";
import {arrayMove} from "@dnd-kit/sortable";
import {Transition} from "@headlessui/react";
import {plugins} from "./plugins/index";

import FormLayout from "./layouts/form-layout";
import FormContext from "./form-context";
import {
    applyDefaultValuesForExpressions,
    clearShowsWhenFieldValues,
    fieldsetsToObject,
    getFormFields,
    getUserDefinedFieldsets,
    getValuesIncludingHidden,
    mergeFieldsets,
    validate
} from "./utils";
import _isObject from "lodash/isObject";
import {WebsiteContext} from "../../websites";
import _get from "lodash/get";

const getFormAction = (e) => {
    return e.nativeEvent.submitter?.getAttribute('formaction') || e.target.getAttribute('action') || null
}

const onSubmitForm = (e, values, setContext) => {
    const form = e.target;

    return axios
        .post(getFormAction(e), values)
        .then((result) => {
            setContext(previous => ({...previous, progress: null}));
            form.dispatchEvent(new CustomEvent('cx:form-submitted', {
                bubbles: true, cancelable: true, detail: result
            }));
        })
        .catch((err) => {
            setContext(previous => ({...previous, progress: null}));
            if (err.isAxiosError) {
                switch (err.response.status) {
                    case 409:
                    case 422:
                        // Here we need to set the field(s) custom validity
                        getFormFields(form)
                            .forEach((element) => {
                                // If the element has a name and is not valid then update the fieldsets state
                                if (err.response.data[element.name] !== null && err.response.data[element.name] !== undefined) {
                                    if (err.response.data[element.name].length) {
                                        element.setCustomValidity(err.response.data[element.name].map(message => message.trim()).join('. '));
                                    }
                                    else {
                                        element.setCustomValidity(`${element.placeholder.trim()} is invalid`)
                                    }
                                }
                            });

                        validate(e.target, setContext, e.nativeEvent.detail?.autoSave);
                        break;
                    default:
                        console.log(err);
                }
            }
            else {
                console.log(err);
            }
        });
}

const getFormBody = (fieldsets, hiddenFields, values, userDefinedFieldsetsScope) => {
    const body = {};
    Object.keys(hiddenFields).forEach(key => _set(body, key, hiddenFields[key]));
    Object.assign(body, _cloneDeep(values));

    const userDefinedFieldsets = getUserDefinedFieldsets(fieldsets);
    if (userDefinedFieldsets) {
        if (userDefinedFieldsetsScope) {
            _set(body, `${userDefinedFieldsetsScope}.userDefinedFieldsets`, userDefinedFieldsets);
        }
        else {
            _set(body, 'userDefinedFieldsets', userDefinedFieldsets);
        }
    }

    // Clear fields where they are hidden by showsWhen expressions
    clearShowsWhenFieldValues(fieldsets, body);
    return body;
}

Form.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    noValidate: PropTypes.bool,
    autoSaveAfter: PropTypes.number,
    heading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string,]),
    extendable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            canChangeFieldName: PropTypes.bool,
            copy: PropTypes.shape({
                fieldset: PropTypes.object,
                field: PropTypes.object,
            }),
            controls: PropTypes.arrayOf(
                // FieldBuilder OPTIONS for all available options
                PropTypes.oneOf([
                    "text", "textarea", "currency", "checkbox", "switch", "select", "radio",
                    "rich-text", "image-uploader", "video-uploader", "document-uploader", "uploader"
                ])
            )
        })
    ]),
    layout: PropTypes.oneOf(['simple-stacked', 'two-column', 'panel-style', 'product-search-block', 'website']),
    actions: PropTypes.array,
    submittingState: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element, PropTypes.elementType,]),
    hiddenFields: PropTypes.object,
    fieldsets: PropTypes.array,
    userDefinedFieldsets: PropTypes.array,
    userDefinedFieldsetsScope: PropTypes.string,
    /**
     * When provided the form fields will detect changes and if the field is different then shows as a modified state
     */
    original: PropTypes.object,
    inset: PropTypes.bool,
    fileUploadSettings: PropTypes.shape({
        saveUrl: PropTypes.string, basePath: PropTypes.string,
    }),
    hrefUriParams: PropTypes.object,
    onSubmit: PropTypes.func,
    onFieldChange: PropTypes.func,
    onComponentUpdate: PropTypes.func,
    onActionClick: PropTypes.func,
    onFieldsetSettingsUpdated: PropTypes.func,
    onFieldsetSettingsAdded: PropTypes.func,
    onFieldsetSettingsDeleted: PropTypes.func
};

Form.defaultProps = {
    extendable: false,
    layout: 'two-column',
    inset: false,
    actions: [],
    hiddenFields: {},
    fieldsets: [],
    userDefinedFieldsets: [],
    userDefinedFieldsetsScope: '',
    onSubmit: () => {},
    onFieldChange: () => {},
    onComponentUpdate: () => {},
    onActionClick: () => {},
    onFieldsetSettingsUpdated: () => {},
    onFieldsetSettingsAdded: () => {},
    onFieldsetSettingsDeleted: () => {},
    onFieldSettingsAction: () => {},
    onEditFieldsetSettings: () => {}
};

Form.CustomControls = {};

const PROCESSORS = {};

export default function Form({id, className, onSubmit, original, children, enabledPlugins, appContext, ...props}) {
    const {
        ErrorBoundary, didCatch, error
    } = useErrorBoundary();
    const websiteContext = useContext(WebsiteContext);
    const formRef = useRef();
    const initialRender = useRef(true);
    const autoSaveFn = useMemo(() => {
        if (props.autoSaveAfter > 0) {
            return _debounce(() => {
                if (formRef.current) {
                    const event = new Event('submit', {bubbles: true});
                    event.detail = {autoSave: true};
                    formRef.current.dispatchEvent(event);
                }
            }, props.autoSaveAfter)
        }
        else {
            return () => {
            };
        }
    }, [props.autoSaveAfter]);

    const memoFieldsets = useMemo(() => {
        return mergeFieldsets(props.fieldsets, props.userDefinedFieldsets, props.userDefinedFieldsetsScope, (props.extendable && !props?.canEdit))
    }, [props.fieldsets, props.userDefinedFieldsets, props.userDefinedFieldsetsScope]);

    const memoValues = useMemo(() => {
        return getValuesIncludingHidden(memoFieldsets, props.hiddenFields, props?.parentContext?.values);
    }, [memoFieldsets, props.hiddenFields]);

    const memoErrors = useMemo(() => {
        return fieldsetsToObject(memoFieldsets, 'error')
    }, [memoFieldsets]);

    const [scrollPosition, setScrollPosition] = useState();
    const [fieldsets, setFieldsets] = useState(memoFieldsets);
    const [context, setContext] = useState(() => {
        return {
            id,
            layout: props.layout,
            hrefUriParams: props.hrefUriParams,
            submittingState: props.submittingState,
            fileUploadSettings: props.fileUploadSettings,
            original,
            customControls: Form.CustomControls,
            values: _cloneDeep(props.values) || memoValues,
            errors: memoErrors,
            showChildForm: false,
            childForm: null,
            fieldsets: props.fieldsets,
            customFields: fieldsets,
            appContext,
            parentContext: props.parentContext,
            validate: (ref) => {
                return validate((ref || formRef).current, setContext);
            },
            registerProcessor: (handler) => {
                PROCESSORS[id] = PROCESSORS[id] || [// A no op processor that kicks off the loading state
                    (onProgress) => new Promise((resolve) => {
                        onProgress(0);
                        resolve()
                    })];
                const count = PROCESSORS[id].push(handler);
                return {
                    destroy: () => PROCESSORS[id]?.splice(count - 1, 1),
                };
            },
            setContext: (values) => {
                setContext(_isFunction(values) ? values : (previous => ({...previous, ...values})));
            },
        }
    });

    useEffect(() => {
        plugins(websiteContext?.enabledPlugins || enabledPlugins).then(data => {
            context.setContext({pluginControls: data})
        })
    }, []);

    // Handle when the fieldsets or values prop has changed 
    useEffect(() => {
        if (!initialRender.current) {
            setFieldsets(memoFieldsets);
            setContext(previous => ({
                ...previous, ...({
                    original,
                    customControls: Form.CustomControls,
                    values: _cloneDeep(props.values) || memoValues,
                    errors: memoErrors
                })
            }));
        }
        else {
            initialRender.current = false;
        }
    }, [memoFieldsets, props.values || memoValues, memoErrors]);

    useEffect(() => {
        const initial = _cloneDeep(context.values);

        setContext(previous => ({...previous, customFields: fieldsets}));

        formRef.current.dispatchEvent(new CustomEvent('cx:form-user-defined-fieldsets-changed', {
            bubbles: true, cancelable: true, detail: getUserDefinedFieldsets(fieldsets)
        }));

        const eventListener = (e) => {
            // Stops the native alert from showing if you're on a Suttons website or channel.
            const preventNativeAlert = !!(context.values?.website?.customProperties?.locationCode || context.values?.channel)
            const isDirty = !_isEqual(initial, context.values);

            if (isDirty && !preventNativeAlert) {
                e.preventDefault();
                return e.returnValue = 'Your changes will be lost. You should Save before navigating away.'
            }
        };

        window.addEventListener('beforeunload', eventListener);

        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, [fieldsets]);

    useEffect(() => {
        setContext(previous => ({...previous, fileUploadSettings: props.fileUploadSettings}));
    }, [props.fileUploadSettings]);

    useEffect(() => {
        const scrollContainer = formRef?.current?.closest('.overflow-y-auto');
        if (scrollContainer && context.showChildForm) setScrollPosition(scrollContainer.scrollTop); else if (scrollContainer && !context.showChildForm && scrollPosition) {
            setTimeout(() => {
                scrollContainer.scroll({
                    top: scrollPosition, behavior: 'auto'
                })
            }, 100)
            setScrollPosition()
        }
    }, [context.showChildForm]);

    const primaryAction = props.actions.find(action => action.primary) || props.actions[0];
    return (<>
        {didCatch ? (<p>{error}</p>) : (<ErrorBoundary>
            <FormContext.Provider value={context}>
                <Transition
                    show={!context.showChildForm}
                    unmount={false}
                    className={classNames("flex flex-col w-full h-full", props.autocx ? "!flex" : "flex")}
                    enter={"transition-transform duration-500 ease-[cubic-bezier(0,0.55,0.45,1)]"}
                    enterFrom="-translate-x-full sm:-translate-x-full"
                    enterTo="translate-x-0 sm:translate-x-0"
                    leave={"transition-transform duration-500 ease-[cubic-bezier(0,0.55,0.45,1)]"}
                    leaveFrom="translate-x-0 sm:translate-x-0"
                    leaveTo="-translate-x-full sm:-translate-x-full">
                    <form
                        id={id}
                        ref={formRef}
                        action={primaryAction?.href}
                        className={classNames("relative w-full m-0 p-0", props.containerClassName)}
                        onSubmit={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            // Prevents form from submitting again 
                            if (_isFunction(autoSaveFn.cancel)) autoSaveFn.cancel();

                            if (!e.nativeEvent.detail?.autoSave) {
                                // If there is no submitter then try find the submit button as a child of the form
                                if (!e.nativeEvent.submitter) {
                                    e.nativeEvent.submitter = e.target.querySelector(`button[type='submit']`);
                                }

                                // If there is no submitter and there is an id for this form then try find a submit 
                                // button with the form attribute that matches the id of this form
                                if (id && !e.nativeEvent.submitter) {
                                    e.nativeEvent.submitter = document.querySelector(`button[form='${id}']`);
                                }
                            }

                            const isValid = validate(e.target, setContext, e.nativeEvent.detail?.autoSave);
                            if (!isValid) return;

                            const values = context.values;
                            const promise = PROCESSORS[id]?.length > 1 ? Promise.all(PROCESSORS[id].map(processor => {
                                // Invoke the processor and wrap with a promise. Handles async or sync 
                                // results
                                const promise = Promise.resolve(processor((progress) => {
                                    setContext(previous => ({...previous, progress}));
                                }, PROCESSORS[id].length));

                                // Merge any changes we need to make to the form before submission
                                return promise.then((updatedFormValues) => {
                                    if (_isObject(updatedFormValues)) {
                                        _mergeWith(values, updatedFormValues);
                                    }
                                });
                            })) : Promise.resolve();

                            promise
                                .then(() => {
                                    // Clean up processes
                                    delete PROCESSORS[id];

                                    const body = getFormBody(fieldsets, props.hiddenFields, values, props.userDefinedFieldsetsScope);

                                    if (props.extendable || props.cleanUserDefinedFieldsets) {
                                        let userDefinedFieldsets = props.userDefinedFieldsetsScope ? _get(body, `${props.userDefinedFieldsetsScope}.userDefinedFieldsets`) : _get(body, 'userDefinedFieldsets');

                                        userDefinedFieldsets = userDefinedFieldsets?.reduce((result, fieldset) => {
                                            fieldset.fields = fieldset?.fields?.map(f => {
                                                delete f.disabled;
                                                return {...f, editing: false}
                                            })
                                            result.push({...fieldset, editing: false})
                                            return result;
                                        }, [])

                                        props.userDefinedFieldsetsScope ? _set(body, `${props.userDefinedFieldsetsScope}.userDefinedFieldsets`, userDefinedFieldsets) : _set(body, 'userDefinedFieldsets', userDefinedFieldsets);
                                    }

                                    if (getFormAction(e)) {
                                        return onSubmitForm(e, body, setContext);
                                    }
                                    else {
                                        e.target.dispatchEvent(new CustomEvent('cx:form-submitted', {
                                            bubbles: true, cancelable: true, detail: body
                                        }));

                                        return onSubmit(e, body, (progress) => {
                                            setContext(previous => ({...previous, progress}));
                                        })
                                    }
                                })
                                .finally(() => {
                                    setContext(previous => ({...previous, progress: null}));
                                })
                        }}
                        noValidate={props.noValidate === true ? true : null}
                    >
                        <FormLayout
                            {...props}
                            fieldsets={fieldsets}
                            className={classNames(props.inset ? "sm:mx-6 p-6 rounded-md bg-panels-100 m-4" : null, className)}
                            onEditFieldsetSettings={(e, index, editing) => {
                                setFieldsets(previous => {
                                    const fieldsets = [...previous];
                                    fieldsets[index].editing = editing;
                                    return fieldsets;
                                });
                                props.onEditFieldsetSettings(e, index, editing)
                            }}
                            onFieldsetSettingsAdded={(e, fieldset) => {
                                setFieldsets([...fieldsets, fieldset]);
                                props.onFieldsetSettingsAdded(e, fieldset);
                            }}
                            onFieldsetSettingsUpdated={(e, index, fieldset) => {
                                setFieldsets(previous => {
                                    const fieldsets = [...previous];
                                    fieldsets[index] = {...fieldset, editing: false};

                                    return fieldsets;
                                });
                                props.onFieldsetSettingsUpdated(e, index, fieldset);
                            }}
                            onFieldsetSettingsDeleted={(e, index) => {
                                let deletedFieldset;
                                setFieldsets(previous => {
                                    const fieldsets = [...previous];
                                    deletedFieldset = fieldsets.splice(index, 1);
                                    return fieldsets;
                                });
                                props.onFieldsetSettingsDeleted(e, index, deletedFieldset[0]);
                            }}
                            onEditFieldSettings={(e, field, fieldset) => {
                                setFieldsets(previous => {
                                    const fieldsets = [...previous];

                                    // Find the fieldset and shallow clone it
                                    let fsIndex = _findIndex(fieldsets, {id: fieldset.id});
                                    fieldsets[fsIndex] = {...fieldsets[fsIndex]};

                                    // Find the field in the found fieldset and shallow clone it setting the editing
                                    // state
                                    let fIndex = _findIndex(fieldsets[fsIndex].fields, {id: field.id});
                                    fieldsets[fsIndex].fields[fIndex] = {
                                        ...fieldsets[fsIndex].fields[fIndex], editing: true
                                    }

                                    return fieldsets;
                                });
                            }}
                            onFieldSettingsAction={(e, action, values, field, fieldset) => {
                                let newFieldsets;
                                setFieldsets(previous => {
                                    // Shallow clone the fieldsets array
                                    const fieldsets = [...previous];

                                    // Find the fieldset and shallow clone it
                                    let fsIndex = _findIndex(fieldsets, {id: fieldset.id});
                                    fieldsets[fsIndex] = {...fieldsets[fsIndex]};

                                    // Shallow clone the fields array on the fieldset
                                    const fields = [...fieldsets[fsIndex].fields];

                                    // Find the field we are managing
                                    const index = _findIndex(fields, {id: field?.id});
                                    if (index > -1) {
                                        // Update the field settings
                                        if (action.primary) {
                                            fields[index] = values;
                                        }
                                        // Remove field
                                        else if (action.destructive) {
                                            fields.splice(index, 1)
                                        }
                                        // Ignore changes and revert editing state
                                        else {
                                            fields[index].editing = false;
                                        }
                                    }
                                    // Add the new field
                                    else if (action.primary) {
                                        fields.push(values);
                                    }

                                    fieldsets[fsIndex].fields = fields;
                                    newFieldsets = fieldsets;
                                    return fieldsets;
                                });
                                props.onFieldSettingsAction(e, newFieldsets);
                            }}
                            onFieldSortEnd={({oldIndex, newIndex, fieldset}) => {
                                setFieldsets(previous => {
                                    // Shallow clone the fieldsets array
                                    const fieldsets = [...previous];

                                    // Find the fieldset and shallow clone it
                                    let fsIndex = _findIndex(fieldsets, {id: fieldset.id});
                                    fieldsets[fsIndex] = {...fieldsets[fsIndex]};
                                    fieldsets[fsIndex].fields = arrayMove(fieldsets[fsIndex].fields, oldIndex, newIndex);
                                    return fieldsets;
                                });
                            }}
                            onComponentUpdate={(e, newComponent, ignoreAutoSave) => {
                                // Clear custom validation when the field has changed
                                if (e?.target?.setCustomValidity) {
                                    e.target.setCustomValidity('');
                                }

                                setContext(previous => {
                                    const result = {
                                        ...previous,
                                        values: {..._mergeWith(previous.values, newComponent)},
                                        errors: {...previous.errors},
                                    };

                                    // Call this handler on the next tick to give time for the form to render
                                    // the new context change
                                    setTimeout(() => {
                                        props.onFieldChange(e, null, null, newComponent);

                                        // Invoke auto save
                                        if (!ignoreAutoSave) {
                                            autoSaveFn();
                                        }
                                    })

                                    return result;
                                });
                            }}
                            onFieldChange={(e, name, value, newConditionalForms, ignoreAutoSave) => {
                                // Clear custom validation when the field has changed
                                if (e?.target?.setCustomValidity) {
                                    e.target.setCustomValidity('');
                                }

                                setContext(previous => {
                                    const result = {
                                        ...previous, values: {...previous.values}, errors: {...previous.errors},
                                    };

                                    newConditionalForms
                                        ?.map(form => getValuesIncludingHidden(form.fieldsets, form.hiddenFields))
                                        .forEach(newConditionalFormValues => _mergeWith(result.values, newConditionalFormValues));

                                    _set(result.values, name, value);
                                    _set(result.errors, name, false);

                                    // Apply new defaults of fields that have expressions
                                    applyDefaultValuesForExpressions(fieldsets, name, value, result.values);

                                    // Call this handler on the next tick to give time for the form to render
                                    // the new context change
                                    setTimeout(() => {
                                        const body = getFormBody(fieldsets, props.hiddenFields, result.values, props.userDefinedFieldsetsScope);

                                        props.onFieldChange(e, name, value, body);

                                        // Invoke auto save
                                        if (!ignoreAutoSave) {
                                            autoSaveFn();
                                        }
                                    })

                                    return result;
                                });
                            }}
                            onActionClick={(e, action) => {
                                if (action.primary) return;

                                e.target.dispatchEvent(new CustomEvent('cx:form-action', {
                                    bubbles: true, cancelable: true, detail: action
                                }));

                                props.onActionClick(e, action);
                            }}
                        />
                        {children}
                    </form>
                </Transition>
                {context.childForm ? (<Transition
                    show={context.showChildForm === true}
                    appear={"true"}
                    className={"absolute z-10 inset-0 flex flex-col w-full h-full sm:mt-auto bg-white overflow-y-auto"}
                    enter={"transition-transform ease-[cubic-bezier(0,0.55,0.45,1)] duration-500"}
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave={"transition-transform ease-[cubic-bezier(0,0.55,0.45,1)] duration-500"}
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    {context.childForm}
                </Transition>) : null}
            </FormContext.Provider>
        </ErrorBoundary>)}
    </>)
}
