import classNames from "classnames";
import {aspectRatio, backgroundColour, backgroundPattern} from "../../utils/block-utils";
import Block from "../block";
import {Image} from "../../components/image";
import {WebsiteContext} from "../../index";
import {useContext, useEffect, useMemo, useState} from "react";
import {handleBackgroundColour} from "../site-navigation-block";
import axios from "axios";
import AnimateHeight from "react-animate-height";
import Spinner from "../../components/spinner";
import Currency from "@autocx/forms/src/controls/currency";
import SimpleSiteHeader from "../../components/simple-site-header";


export default function Design1(props) {
    const context = useContext(WebsiteContext)
    const imageAspectRatio = {values: {options: {imageAspectRatio: context?.website?.store?.productCardAspectRatio}}}
    const image = props.values?.images?.length ? props.values?.images[0] : null;
    const imageState = props.values?.imagesState?.length ? props.values?.imagesState[0] : null;
    const [linkSent, setLinkSent] = useState(false)
    
    return (
        <>
            <SimpleSiteHeader />
            <Block {...props}
                   className={classNames(props.className, !props.values.options?.inset ? backgroundPattern(props) : null, !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
                   }>
                <div className={classNames("mx-auto max-w-7xl section")}>
                    <div className="flex flex-col lg:flex-row gap-x-16 gap-y-8">
                        <div className="lg:w-[55%]">
                            <h2 className="!text-2xl !mb-4">Your products</h2>
                            <div className={"flex flex-col gap-y-2"}>
                                <div
                                    className={"flex flex-row gap-x-4 gap-y-6 w-full justify-between items-center text-lg"}>
                                    <div className={"flex flex-row items-center gap-x-3"}>
                                        <div className={classNames("w-16", aspectRatio(imageAspectRatio))}>
                                            <Image
                                                alt={"Image of " + props.values.title}
                                                width={64}
                                                id={imageState?.__cx__?.id ? `image_${imageState?.__cx__?.id}` : null}
                                                src={imageState?.__cx__?.key || image}
                                                className={classNames("w-full h-full object-center object-cover rounded-theme-half")}
                                            />
                                        </div>
                                        <div>
                                            {props.values.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.values.linkStatus === 'valid' ?
                            <div className={"lg:w-[45%] flex flex-col"}>
                                <div className="p-4 sm:px-8 sm:pb-8 sm:pt-6 section-grey rounded-theme">
                                    <div className={"relative z-10"}>
                                        <h3 className="!text-xl !mb-2">Download</h3>

                                        <p>Download/video here</p>

                                        <p>We could also put the error states here, a button to resend
                                            the access email or a message about why they can't access the product
                                        </p>

                                    </div>
                                </div>
                            </div>
                        :null }

                        {props.values.linkStatus === 'expired' ?
                            <div className={"lg:w-[45%] flex flex-col"}>
                                <div className="p-4 sm:px-8 sm:pb-8 sm:pt-6 section-grey rounded-theme">
                                    <div className={"relative z-10"}>
                                        <h3 className="!text-xl !mb-2">Download Expired</h3>
                                        
                                        <p>The link to download this product has expired but don't worry your access hasn't. Request a new link now. </p>

                                            <button
                                                className={classNames("button hover:opacity-90 w-full justify-center mt-4 relative")}
                                                onClick={() => {
                                                    axios.post(props.values.resendUrl)
                                                        .then(() => {
                                                           setLinkSent(true)
                                                        })
                                                        .finally(() => {
                                                            setLinkSent(true)
                                                        })
                                                }}>
                                                Request New Link
                                            </button>

                                            <AnimateHeight height={linkSent ? 'auto' : 0} animateOpacity={true}>
                                                {linkSent &&
                                                    <div className="py-2 text-sm text-center font-semibold text-green-600">
                                                        A new link has been sent to {props.values.email}
                                                    </div>
                                                }
                                            </AnimateHeight>

                                            <p className={"mt-2"}>Not your link? <a href={`product/${props.values.workspaceId}_${props.values.uuid}/checkout`}>Get your own access</a> for just
                                                <span
                                                    className={classNames(props.values?.discountedSellingPrice ? "line-through mx-1 opacity-80" : "font-semibold")}>
                                                    {props.values?.sellingPrice ?
                                                        <Currency.DisplayValue currency={context.currency}
                                                                               value={props.values?.sellingPrice}
                                                                               emptyPlaceholder={""}/>
                                                        : "Price on Application"}
                                                </span>
                                                {props.values?.discountedSellingPrice ? (
                                                <span className={classNames(
                                                    "font-semibold"
                                                )}>
                                                    <Currency.DisplayValue currency={context.currency}
                                                                           value={props.values?.discountedSellingPrice}
                                                                           emptyPlaceholder={""}/>
                                                </span>
                                                ) : null}
                                            </p>
                                    </div>
                                </div>
                            </div>

                        :null }

                    </div>
                </div>
            </Block>
        </>
    )
}