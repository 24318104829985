import {useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {evaluate} from "../utils";
import FormContext from "../form-context";

ButtonGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    horizontal: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    modified: PropTypes.bool,
    value: PropTypes.any,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.string,
};

ButtonGroup.defaultProps = {
    horizontal: false,
    options: []
};

const Buttons = function ({context, inputRef, ...props}) {
    return (
        <div className={classNames(
            "relative w-full z-0 flex shadow-sm rounded",
            props.horizontal ? "flex-row" : "flex-col",
            props.modified && 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400'
        )}>
            <input
                ref={inputRef}
                id={props.id}
                type="text"
                className={"absolute opacity-0 pointer-events-none"}
                name={props.name}
                placeholder={props.label}
                required={props.required}
                disabled={props.disabled}
                defaultValue={props.value}
                data-error-message={props.errorMessage}
            />
            {props.options.map((option, index) => {
                const srHtml = option.label ? `<span class="sr-only">${option.label}</span>` : '';
                const iconHtml = option.icon !== undefined && option.icon !== null ? `<div class="w-6 h-6">${option.icon}</div>` : '';
                const labelHtml = option.label !== undefined && option.label !== null ? `<span${option.icon ? ' class="ml-1"' : ''}>${option.label}</span>` : '';
                const checked = props.value === option.value;
                return (
                    <button
                        key={`${option.label}_${index}`}
                        type="button"
                        disabled={props.disabled}
                        className={classNames(
                            "relative inline-flex shrink grow items-center py-0.5 border border-gray-300",
                            "text-sm font-medium text-gray-700 focus:ring-offset-2 focus:ring-primary-500",
                            props.disabled ? 'bg-gray-50 border-gray-400' : null,
                            props.error && !props.disabled ? 'bg-error-50 border-error-200' : null,
                            checked && props.modified && !props.disabled && !props.error ? 'bg-primary-100 border-indigo-500 hover:border-indigo-500' : null,
                            checked && !props.modified && !props.disabled && !props.error ? 'border-indigo-500 hover:border-indigo-500' : null,
                            !checked && !props.disabled && !props.error ? 'bg-white border-gray-300' : null,
                            !props.disabled ? 'cursor-pointer hover:border-gray-400' : 'cursor-default',
                            checked ? 'z-10 border-indigo-500 bg-indigo-50' : null,
                            props.horizontal ? 'justify-center' : 'px-4',
                            index === 0 ? (props.horizontal ? 'rounded-l' : 'rounded-t') : null,
                            index === props.options.length - 1 ? (props.horizontal ? 'rounded-r' : 'rounded-b') : null,
                            index > 0 ? (props.horizontal ? '-ml-px' : '-mt-px') : null,
                            evaluate(props.value, option.showsWhen, context.values) === false ? 'hidden' : null
                        )}
                        dangerouslySetInnerHTML={{
                            __html: props.horizontal ? `${iconHtml ? srHtml : ''}${iconHtml || labelHtml}` : `${iconHtml}${labelHtml}`
                        }}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            // If this control is not required then enable deselection
                            let value = option.value;
                            if (!props.required) {
                                value = option.value !== props.value ? option.value : null;
                            }

                            props.onChange({target: inputRef.current}, props.name, value);
                        }}>
                    </button>
                )
            })}
        </div>
    );
}

const Tabs = function ({context, inputRef, ...props}) {
    return (
        <div className={classNames(
            "w-full z-0 shadow-sm bg-gray-100 rounded-lg p-1",
        )}>
            <input
                ref={inputRef}
                id={props.id}
                type="text"
                className={"hidden"}
                name={props.name}
                placeholder={props.label}
                required={props.required}
                disabled={props.disabled}
                defaultValue={props.value}
                data-error-message={props.errorMessage}
            />
            <div className={classNames(
                "relative w-full z-0 flex",
                props.horizontal ? "space-x-1 flex-row" : "flex-col space-y-1"
            )}>
                {props.options.map((option, index) => {
                    const srHtml = option.label ? `<span class="sr-only">${option.label}</span>` : '';
                    const iconHtml = option.icon !== undefined && option.icon !== null ? `<div class="w-6 h-6">${option.icon}</div>` : '';
                    const labelHtml = option.label !== undefined && option.label !== null ? `<span${option.icon ? ' class="ml-1"' : ''}>${option.label}</span>` : '';
                    const checked = props.value === option.value;
                    return (
                        <button
                            key={`${option.label}_${index}`}
                            type="button"
                            disabled={props.disabled}
                            className={classNames(
                                "relative inline-flex shrink grow items-center py-0.5 rounded-sm",
                                "text-sm font-medium justify-center",
                                props.disabled ? 'bg-gray-50 text-gray-50' : null,
                                props.error && !props.disabled ? 'bg-error-50' : null,
                                checked && props.modified && !props.disabled && !props.error ? 'bg-gradient-to-r from-[#E3E7ED] to-[#DCDFE5]' : null,
                                checked && !props.modified && !props.disabled && !props.error ? 'bg-gradient-to-r from-[#E3E7ED] to-[#DCDFE5]' : null,
                                !checked && !props.disabled && !props.error ? 'bg-transparent' : null,
                                !props.disabled ? 'cursor-pointer hover:opacity-80' : 'cursor-default',
                                checked ? 'z-10 text-gray-600 shadow' : "text-gray-800",
                                evaluate(props.value, option.showsWhen, context.values) === false ? 'hidden' : null
                            )}
                            dangerouslySetInnerHTML={{
                                __html: props.horizontal ? `${iconHtml ? srHtml : ''}${iconHtml || labelHtml}` : `${iconHtml}${labelHtml}`
                            }}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                // If this control is not required then enable deselection
                                let value = option.value;
                                if (!props.required) {
                                    value = option.value !== props.value ? option.value : null;
                                }

                                props.onChange({target: inputRef.current}, props.name, value);
                            }}>
                        </button>
                    )
                })}
            </div>
        </div>
    );
}

export default function ButtonGroup({style, ...props}) {
    const context = useContext(FormContext);
    const inputRef = useRef();
    switch (style) {
        case "tabs" :
            return (<Tabs context={context} inputRef={inputRef} {...props} />);       
        default:
            return (<Buttons context={context} inputRef={inputRef} {...props} />);
    }
};