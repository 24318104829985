import {Fragment, useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Menu, Transition} from "@headlessui/react";
import MoreIcon from "@autocx/icons/src/more-icon";
import {UploaderContext} from "./uploader-context";
import ImageUploaderUnsplashPicker from "./pickers/image-uploader-unsplash-picker";
import _cloneDeep from "lodash/cloneDeep";
import Loader from "../../components/loader";

const MENU_ITEM_CLASSNAME = "grow block menu-item";

let previousFile;

UploaderFileActions.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOf(['button', 'icon']),
    file: PropTypes.object.isRequired,
    contextMenuDirection: PropTypes.oneOf(['left', 'right']),
};

UploaderFileActions.defaultProps = {
    style: 'icon',
    contextMenuDirection: 'left'
};

export default function UploaderFileActions(props) {
    const context = useContext(UploaderContext);
    const replacerRef = useRef();
    const [loading, setLoading] = useState(false);

    return (
        <Menu
            as="div"
            className={classNames(
                "relative select-none",
                context.multiple ? "w-full flex items-center justify-center group" : null,
                props.className
            )}
        >
            {/* This hidden input is for invoking the native file browser */}
            <input
                ref={replacerRef}
                type="file"
                className="hidden"
                accept={context.accept}
                onChange={(e) => {
                    props.file.replace(Array.from(e.target.files)[0]);

                    // Clear the currently selected value so we can handle uploading the same file
                    e.target.value = null;
                }}
            />
            {/* Show the name of the file when multiple files can be uploaded */}
            {context.multiple && context.type === "image-uploader" ? (
                <div className={"w-full line-clamp-1 px-1 my-0.5 text-xs font-medium"} title={props.file.name}>{props.file.name}</div>
            ): null}
            <Menu.Button 
                as={"button"} 
                className={classNames(
                    "cursor-pointer",
                    props.style === 'icon' ? "rounded p-1" : null,
                    props.style === 'button' ? "bg-panels-100 cursor-pointer text-gray-500 relative inline-flex items-center px-1.5 py-1 rounded font-medium focus:outline-none hover:bg-panels-200" : null
                )}>
                <MoreIcon className={"w-4 h-4 transition-transform group-hover:scale-105"} innerCircleClassNames={"fill-white group-hover:stroke-primary-500"} ellipsisClassNames={"group-hover:fill-primary-500"} />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={"origin-top-right absolute z-20 top-6 right-0 mt-2 -mr-1 w-36 rounded-md shadow-lg bg-white focus:outline-none"}>
                    <div className="flex flex-col">
                        {/* Only image files are adjustable */}
                        {context.pintura ? (
                            <Menu.Item
                                as={"div"}
                                className={classNames(MENU_ITEM_CLASSNAME, "text-primary-500")}
                                onClick={() => props.file.update({status: 'editing'})}
                            >
                                {loading ? (
                                    <Loader loading={true} />
                                ) : 'Edit'}
                            </Menu.Item>
                        ) : null}
                        <Menu as="div" className="grow relative cursor-pointer select-none">
                            <Menu.Button
                                as={"div"}
                                className={({open}) => {
                                    return classNames(
                                        MENU_ITEM_CLASSNAME, 
                                        "text-primary-500",
                                        open ? "bg-panels-100" : null
                                    );
                                }}
                                onClick={!context.unsplash ? () => {
                                    replacerRef.current.click();
                                } : null}
                            >
                                Replace
                            </Menu.Button>
                            {context.unsplash ? (
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className={"origin-top-left absolute z-10 right-0 -mr-1 w-36 rounded-md shadow-lg bg-white focus:outline-none"}>
                                        <div className="flex flex-col">
                                            <Menu.Item
                                                as={"div"}
                                                onClick={() => {
                                                    replacerRef.current.click()
                                                }}
                                                className={classNames('grow block menu-item')}>
                                                Upload image
                                            </Menu.Item>
                                            <ImageUploaderUnsplashPicker.Trigger
                                                multiple={false}
                                                onClick={() => {
                                                    previousFile = _cloneDeep(props.file);
                                                }}
                                                onCancel={() => {
                                                    // Reset the list of files to the previous state
                                                    props.file.replace(previousFile);
                                                    previousFile = null;
                                                }}
                                                onChange={(value) => {
                                                    // All values will be marked as transient at this stage so that when cancelled 
                                                    // we can remove the files from the list
                                                    props.file.replace(value[0], true);
                                                }}
                                                onDone={(value) => {
                                                    // All transient files will be marked as not transient as the user has committed
                                                    // to the selection
                                                    props.file.replace(value[0]);
                                                    previousFile = null;
                                                }}
                                            />
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            ) : null}
                        </Menu>
                        <Menu.Item
                            as={"div"}
                            className={classNames(MENU_ITEM_CLASSNAME, "text-red-500")}
                            onClick={() => {
                                if (confirm('Are you sure?')) {
                                    props.file.remove();
                                }
                            }}
                        >
                            Remove
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};