export const backgroundColour = (value) => {
    switch (value) {
        case 0:
            return 'section-brand'
        case 1:
            return 'section-white'
        case 2:
            return 'section-light'
        case 3:
            return 'section-grey'
        case 4:
            return 'section-dark'
        case 5:
            return 'section-black'
        case 6:
            return ['section-white', 'section-grey']
        case 7:
            return ['section-white', 'section-black']
        case 8:
            return ['section-light', 'section-white']
        case 9:
            return ['section-black', 'section-white']
        case 10:
            return ['section-brand', 'section-white']
        default:
            return 'section-white'
    }
}

export const textPosition = (props) => {
    switch (props.values?.options?.textPosition) {
        case 0:
            return 'justify-start'
        case 1:
            return 'justify-center'
        case 2:
            return 'justify-end'
        case 3:
            return ''
        default:
            return 'justify-center'
    }
}

export const textPositionVertical = (props) => {
    switch (props.values?.options?.textPositionVertical) {
        case 0:
            return 'items-start'
        case 1:
            return 'items-center'
        case 2:
            return 'items-end'
        case 3:
            return ''
        default:
            return 'items-center'
    }
}

export const textAlignment = (props) => {
    switch (props.values?.options?.textAlignment) {
        case 0:
            return 'text-left'
        case 1:
            return 'text-center'
        case 2:
            return 'text-right'
        case 3:
            return 'text-justify'
        default:
            return 'text-center'
    }
}

export const columns = (props) => {
    switch (props.values?.options?.columns) {
        case 0:
            return 'sm:columns-1'
        case 1:
            return 'sm:columns-2'
        case 2:
            return 'sm:columns-3'
        case 3:
            return 'sm:columns-4'
        default:
            return 'sm:columns-1'
    }
}

export const height = (props) => {
    switch (props.values?.options?.height) {
        case 0:
            return 'small'
        case 1:
            return 'medium'
        case 2:
            return 'large'
        case 3:
            return 'min-h-screen'
        default:
            return 'medium'
    }
}

// This is used for items that have a fixed width or a width based on max characters like paragraphs
export const marginForTextAlignment = (props) => {
    switch (props.values?.options?.alignment) {
        case 'text-left':
            return 'mr-auto'
        case 'text-center':
            return 'm-auto'
        case 'text-right':
            return 'ml-auto'
        case 'text-justify':
            return 'mr-auto'
        default:
            return 'm-auto'
    }
}

/*
This function usually needs to be paired with another function that 
determines how elements around the image must change (like the flexDirectionBasedOnImagePosition util). 
The nomenclature of the cases are 0 = left, 1 = top, 2 = right and 3 = bottom.
*/
export const imagePosition = (props) => {
    switch (props.values?.options?.imagePosition) {
        case 0:
            return 'order-first'
        case 1:
            return 'order-first'
        case 2:
            return 'order-last'
        case 3:
            return 'order-last'
        default:
            return 'order-first'
    }
}

export const flexDirectionBasedOnImagePosition = (props) => {
    const imagePosition = props?.values?.options?.imagePosition;
    if (imagePosition === 0 || imagePosition === 2) {
        return 'flex-col sm:flex-row'
    } else {
        return 'flex-col'
    }
}

export const frameType = (props) => {
    switch (props.values?.options?.mediaFrame) {
        case 0:
            return 'offsetBorder'
        case 1:
            return 'border'
        case 2:
            return 'circle'
        case 3:
            return 'slant'
        default:
            return ''
    }
}

export const objectFit = (value) => {
    switch (value) {
        case 'cover':
            return 'object-cover'
        case 'contain':
            return 'object-contain'
        default:
            return 'object-cover'
    }
}

export const backgroundPattern = (props) => {
    if (!props?.values?.options?.backgroundPattern) return '';
    return `bg-pattern-${props.values.options.backgroundPattern}`;
}

export function aspectRatio(props) {
    switch (props.values?.options?.imageAspectRatio) {
        case 1:
            return 'aspect-[1/1]'
        case 1.3333333333:
            return 'aspect-[4/3]'
        case 1.7777777778:
            return 'aspect-[16/9]'
        case 1.5:
            return 'aspect-[3/2]'
        case 0.75:
            return 'aspect-[3/4]'
        case 0.8:
            return 'aspect-[4/5]'
        case 2:
            return 'aspect-[2/1]'
        case 2.4:
            return 'aspect-[2.4/1]'
        case 3.5:
            return 'aspect-[7/2]'
        case 4:
            return 'aspect-[4/1]'
        case 2.8571:
            return 'aspect-[20/7]'
        case 0.5625:
            return 'aspect-[9/16]'
        default:
            return 'aspect-[1/1]'
    }
}

/*
Eventually turn this into a rect component to do something like:
    <Parallax speed=x>
        <Child />
    </Parallax>
 */
export const parallax = (el) => {
    let parallaxEl = document.getElementsByClassName(el);
    const doParallax = () => {
        for (let i = 0; i < parallaxEl.length; i++) {
            let speed = (parallaxEl[i].getBoundingClientRect().top / 8);
            parallaxEl[i].style.transform = "translateY( " + speed + "px)";
        }
        requestAnimationFrame(doParallax);
    };
    requestAnimationFrame(doParallax);
};

export function setTitleDecorationByClass(decoration) {
    switch (decoration) {
        case "quote":
            return 'quote'
        case "quoteTwo":
            return 'quoteTwo'
        case "quoteContainer":
            return 'quoteContainer'
        case "ellipse":
            return 'ellipse'
        default:
            break;
    }
}

export function setTitleDecorationByElement(decoration) {
    switch (decoration) {
        case "lineSeparator":
            return (
                <div className={"leading-none"}>
                    <div className={"lineSeparator"} />
                </div>
            )
        case "squiggleSeparator":
            return (
                <div className={"squiggleSeparatorContainer"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="460"
                        height="18"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        clipRule="evenodd"
                    >
                        <g>
                            <path
                                className="squiggleSeparator"
                                fill="none"
                                stroke="#000"
                                strokeWidth="4"
                                d="M.051 2.428A16.908 16.908 0 019.45 5.27l9.749 6.5a16.926 16.926 0 0018.777 0l9.749-6.5a16.909 16.909 0 019.797-2.837l.057-.005a16.907 16.907 0 019.398 2.842l9.749 6.5a16.926 16.926 0 0018.777 0l9.75-6.5a16.906 16.906 0 019.796-2.837v-.005a16.905 16.905 0 019.398 2.842l9.749 6.5a16.928 16.928 0 0018.778 0l9.749-6.5a16.908 16.908 0 019.796-2.837l.057-.005a16.907 16.907 0 019.398 2.842l9.75 6.5a16.926 16.926 0 0018.777 0l9.749-6.5a16.909 16.909 0 019.797-2.837l.009-.005a16.91 16.91 0 019.399 2.842l9.749 6.5a16.928 16.928 0 0018.778 0l9.749-6.5a16.907 16.907 0 019.795-2.837l.058-.005a16.907 16.907 0 019.398 2.842l9.75 6.5a16.924 16.924 0 0018.776 0l9.749-6.5a16.91 16.91 0 019.797-2.837v-.005a16.907 16.907 0 019.398 2.842l9.75 6.5a16.926 16.926 0 0018.777 0l9.749-6.5a16.909 16.909 0 019.797-2.837l.056-.005a16.91 16.91 0 019.399 2.842l9.749 6.5a16.924 16.924 0 0018.776 0l9.751-6.5a16.907 16.907 0 019.795-2.837"
                            ></path>
                        </g>
                    </svg>
                </div>
            )
        default:
            break;
    }
}