// Create an isolated Handlebars instance so that we don't reference anything from the global instance
const Handlebars = require('handlebars').create()

// Register Handlebars Helpers

Handlebars.registerHelper('now', require('./helpers/now-helper'))
Handlebars.registerHelper('coalesce', require('./helpers/coalesce-helper'))

// End

/**
 * Resolves any Handlebars bindings found in values of the provided data object. 
 * 
 * @param {*} data The object to find and Handlebars bindings and resolve them
 * @param {object} [context={}] A data context that is available for bindings
 * @return {Promise<*>}
 */
const resolve = (data, context = {}) => {
    const templateString = JSON.stringify(data)
    const template = Handlebars.compile(templateString)
    try {
        return JSON.parse(template(context))
    } catch (err) {
        return data
    }
}

module.exports = { 
    resolve 
}
