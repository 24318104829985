import {forwardRef, Fragment, useContext} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import FieldsetBuilder from "../builder/fieldset-builder";
import Actions from "./actions";
import {evaluate} from "../utils";
import FormContext from "../form-context";
import _isString from "lodash/isString";

import SimpleStackedLayout from "./simple-stacked-layout";
import TwoColumnLayout from "./two-column-layout";
import PanelListForm from "./panel-list-form";
import PanelLayout from "./panel-layout";
import WebsiteLayout from "./website-layout";
import ProductSearchBlockLayout from "./product-search-block-layout";

import _isNumber from "lodash/isNumber";
import _isUndefined from "lodash/isUndefined";

const FormLayout = forwardRef(({className, layout, hiddenFields, fieldsets, onEditFieldsetSettings, children, ...props}, ref) => {
    const context = useContext(FormContext);
    
    // Divide the list of action by header only actions and body only actions
    const headerActions = props.heading 
        ? props.actions.filter(a => a.position === 'heading' || ((a.primary || a.back) && !a.destructive)) 
        : [];
    
    const bodyActions = props.heading 
        ? props.actions.filter(a => a.position === 'body' || (!a.primary && !a.back && _isUndefined(a.position))) : props.actions;
    
    return (
        <>
            {props.heading ? (
                <div className={"sticky top-0 z-10 flex shrink-0 justify-between items-center px-6 h-[44px] sm:h-[57px] bg-[#FCFCFD] border-b border-panels-300"}>
                    <div>
                        {_isString(props.heading) ? (
                            <h2 className="text-sm font-medium text-gray-900">{props.heading}</h2>
                        ) : null}
                    </div>
                    <Actions
                        header
                        actions={headerActions}
                        onActionClick={props.onActionClick}
                    />
                </div>
            ): null}
            <div ref={ref} className={classNames("space-y-4", className, layout)}>
                {Object.keys(hiddenFields).filter(name => hiddenFields[name] !== undefined).map(name => (
                    <input key={name} type="hidden" hidden name={name} value={hiddenFields[name] === null ? '' : hiddenFields[name]}/>
                ))}
                {fieldsets.map((fieldset, index) => {
                    // Handle when it evaluates to false to hide this fieldset otherwise if it's undefined null or true then
                    // show it
                    if (evaluate(null, fieldset.showsWhen, context.values) === false) return null;

                    if (fieldset.divider === true || _isNumber(fieldset.divider)) {
                        return (
                            <hr key={`divider_${index}`} className={classNames(
                                "border-panels-300",
                                fieldset.divider === 2 ? "border-b" : null,
                                fieldset.divider === 3 ? "border-b-2" : null,
                            )}/>
                        );
                    }

                    if (fieldset.childrenOnly && (!props.extendable || !fieldset.extendable)) {
                        return null;
                    }

                    if (!props.builder && fieldset.editing && !fieldset.system) {
                        return (
                            <Fragment key={`${fieldset.label}_${index}_editing`}>
                                {index > 0 ? (<hr className={"border-panels-300"}/>) : null}
                                <FieldsetBuilder
                                    extendable={fieldset.extandable ?? props.extendable}
                                    userDefinedFieldsetsScope={props.userDefinedFieldsetsScope}
                                    layout={layout}
                                    editing={fieldset.editing}
                                    fieldset={fieldset}
                                    onActionClick={(e, action, fieldset) => {
                                        if (action.primary) {
                                            props.onFieldsetSettingsUpdated(e, index, fieldset);
                                        } else if (action.destructive) {
                                            props.onFieldsetSettingsDeleted(e, index);
                                        } else {
                                            onEditFieldsetSettings(e, index, false);
                                        }
                                    }}
                                />
                                {index !== (fieldsets.length - 1) ? (<hr className={"border-panels-300"}/>) : null}
                            </Fragment>
                        );
                    }

                    let Layout;
                    switch (fieldset.layout || layout) {
                        case 'simple-stacked':
                            Layout = SimpleStackedLayout;
                            break;
                        case 'panel-style':
                            Layout = PanelLayout;
                            break;
                        case 'product-search-block':
                            Layout = ProductSearchBlockLayout
                            break;
                        case 'panel-list-form':
                            Layout = PanelListForm;
                            break;
                        case 'website':
                            Layout = WebsiteLayout;
                            break;
                        case 'two-column':
                        default:
                            Layout = TwoColumnLayout;
                    }

                    const editButton = props.extendable && fieldset.extendable && !(fieldset.id === null || fieldset.id === undefined) && !fieldset.editing && Layout.EditButton ? (
                        <Layout.EditButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditFieldsetSettings(e, index, true);
                            }}
                        >Edit fieldset</Layout.EditButton>
                    ) : null;

                    return (
                        <Layout
                            key={`${fieldset.label}_${fieldset.id}_${context.id ? index : null}`}
                            fieldset={fieldset}
                            editButton={editButton}
                            {...props}
                        >
                            {children}
                        </Layout>
                    )
                })}
                {layout !== "panel-list-form" ? (
                    <Actions
                        actions={bodyActions}
                        onActionClick={props.onActionClick}
                        className={fieldsets?.length && layout !== "product-search-block" ? "border-t border-panels-300 py-4" : null}
                    />
                ): null}
                {props.extendable && !props.builder ? (
                    <>
                        {fieldsets.length ? (<hr className={"border-panels-300"}/>) : null}
                        <FieldsetBuilder
                            extendable={props.extendable}
                            userDefinedFieldsetsScope={props.userDefinedFieldsetsScope}
                            layout={layout}
                            onActionClick={(e, action, fieldset) => {
                                if (action.primary) {
                                    props.onFieldsetSettingsAdded(e, fieldset);
                                }
                            }}
                        />
                    </>
                ): null}
            </div>
        </>
    )
});

FormLayout.propTypes = {
    className: PropTypes.string,
    layout: PropTypes.oneOf(['simple-stacked', 'two-column', 'panel-style', 'panel-list-form', 'product-search-block', 'website']),
    actions: PropTypes.array,
    hiddenFields: PropTypes.object,
    fieldsets: PropTypes.array,
    onFieldsetSettingsAdded: PropTypes.func,
    onFieldsetSettingsUpdated: PropTypes.func,
    onFieldsetSettingsDeleted: PropTypes.func,
    onEditFieldsetSettings: PropTypes.func,
    onEditFieldSettings: PropTypes.func,
    onFieldSettingsAction: PropTypes.func,
    onFieldChange: PropTypes.func,
    onActionClick: PropTypes.func
};

FormLayout.defaultProps = {
    layout: 'two-column',
    actions: [],
    hiddenFields: {},
    fieldsets: [],
    onFieldsetSettingsAdded: () => {},
    onFieldsetSettingsUpdated: () => {},
    onFieldsetSettingsDeleted: () => {},
    onEditFieldsetSettings: () => {},
    onEditFieldSettings: () => {},
    onFieldSettingsAction: () => {},
    onFieldChange: () => {},
    onActionClick: () => {}
};

export default FormLayout;