import './index.css';
import Design1 from './design-1';

ProductAccessBlock.propTypes = {};

ProductAccessBlock.defaultProps = {};

export default function ProductAccessBlock(props) {
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} />);
        default:
            return (<Design1 {...props} />);
    }
};