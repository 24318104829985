import classNames from "classnames";
import Countdown from "react-countdown";
import {countdownRenderer} from "./design-1";
import {countdownPosition} from "./design-1";

import Block from '../block'
import {
    backgroundColour,
    textAlignment,
    imagePosition,
    flexDirectionBasedOnImagePosition,
    marginForTextAlignment,
    backgroundPattern,
    frameType, aspectRatio, setTitleDecorationByClass, setTitleDecorationByElement, objectFit
} from "../../utils/block-utils";
import useCdnUrl from "../../hooks/cdn-hook";
import {useEffect} from "react";
import Action from "../../components/action";
import {pickerDate} from "./index";
import {Image} from "../../components/image";

export default function Design2(props) {
    const flexDirection = flexDirectionBasedOnImagePosition(props);

    let radius = '0';
    useEffect(() => {
        radius = getComputedStyle(props.pageRef.current)?.getPropertyValue('--container-roundness');
    }, [props.pageRef]);

    return (
        <Block {...props} className={classNames(
            props.className,
            !props.values?.options?.inset ? backgroundPattern(props) : null,
            !props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
        )}>
            <div className={classNames(
                "mx-auto max-w-7xl section flex items-center",
                textAlignment(props),
                flexDirection,
                props.values?.options?.inset ? 'rounded-theme' : null,
                props.values?.options?.inset ? backgroundPattern(props) : null,
                props.values?.options?.inset ? backgroundColour(props.values?.options?.backgroundColour) : null
            )}>
                <div className={classNames(
                    flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5"
                )}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                    <Action className={"my-6"} {...props.values} title={null}/>
                    <div className={classNames("flex items-center space-x-2 my-5", countdownPosition(props))}>
                        <Countdown date={props?.values?.date ?? pickerDate()}
                                   renderer={countdownRenderer}
                                   zeroPadDays={4}
                                   zeroPadTime={4}
                        />
                    </div>
                </div>
                <div style={{borderRadius: radius === '0px' ? '0' : ''}}
                     className={classNames(
                         "relative rounded-theme",
                         flexDirection === 'flex-col sm:flex-row' ? "w-full sm:w-1/2" : "w-full sm:w-3/5",
                         imagePosition(props),
                         frameType(props) === 'border' ? "border-8 border-base bg-base" : '',
                         frameType(props) === 'circle' ? 'clip-circle' : '',
                         frameType(props) === 'slant' ? 'clip-slant !rounded-none' : ''
                     )}>
                    {props.values.image ? (
                        <Image
                            id={props.values.imageState?.__cx__?.id ? `image_${props.values.imageState?.__cx__?.id}` : null}
                            alt={props?.values?.altText}
                            src={props.values.imageState?.__cx__?.key || props.values?.image}
                            className={classNames(
                                "lazyload lazymotion relative z-[1] w-full h-full object-center",
                                aspectRatio(props),
                                objectFit(props.values?.options?.imagePresentation),
                                frameType(props) === 'slant' ? '!rounded-none' : '',
                                frameType(props) === 'border' ? "rounded-theme-half" : ''
                            )}
                        />
                    ) : null}
                    {frameType(props) === 'offsetBorder' ? (
                        <div className={"absolute w-full h-full left-3 z-0 top-3 bottom-0 rounded-theme bg-base"}/>
                    ) : null}
                </div>
            </div>
        </Block>
    )
}