import {useState, useRef, useEffect} from 'react';
import BidCountdown from "../product-card/components/bid-countdown";
import BidModal from "./components/bid-modal";
import {AUCTIONSTATUSENUM, getStatus} from "./index";

function BidSalesEvent({salesEvent, onBidSuccess, usersBid, bidButtonClasses, profile}) {
    const [offerBidModalOpen, setOfferBidModalOpen] = useState(false);
    const startDate = salesEvent?.startDate;
    const endDate = salesEvent?.endDate;
    const secondsRemaining = salesEvent?.secondsRemaining
    const [secondsToStart, setSecondsToStart] = useState(salesEvent?.secondsToStart)

    const [status, setStatus] = useState(() => getStatus(salesEvent, startDate, endDate, secondsToStart, secondsRemaining));

    const nowRef = useRef(Date.now());
    const [now, setNow] = useState(nowRef.current);

    useEffect(() => {
        nowRef.current = Date.now();
        setNow(nowRef.current);
    }, []);

    function handleBidSuccess(amount) {
        onBidSuccess(amount);
    }

    function closeBidModal() {
        setOfferBidModalOpen(false);
    }

    function openBidModal() {
        setOfferBidModalOpen(true);
    }

    return (
        <>
            {salesEvent && (
                <>
                    {status === AUCTIONSTATUSENUM.NOT_STARTED &&
                        <div>
                            <div className="text-base font-semibold leading-none">Starts in</div>
                            <div className={"text-xl"} key={salesEvent.secondsToStart}>
                                <BidCountdown
                                    salesEvent={salesEvent}
                                    date={now + salesEvent.secondsToStart * 1000}
                                    warningMinutes={1}
                                    onComplete={() => {
                                        // Add code to trigger the next bid count down seconds remaining.
                                        nowRef.current = Date.now();
                                        setNow(nowRef.current);
                                        setSecondsToStart(0)
                                        setStatus(AUCTIONSTATUSENUM.OPEN);
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {status === AUCTIONSTATUSENUM.OPEN && <div>
                        <div className="text-base font-semibold leading-none">Time left</div>
                        <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                            <BidCountdown
                                salesEvent={salesEvent}
                                date={now + salesEvent.secondsRemaining * 1000}
                                warningMinutes={1}
                                onComplete={() => setStatus(AUCTIONSTATUSENUM.ENDED)}
                            />
                        </div>
                    </div>}
                    {status === AUCTIONSTATUSENUM.CLOSED && usersBid?.isWinner &&
                        <div>
                            <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                                <span>🏆 You Won!</span>
                            </div>
                        </div>}
                    {status === AUCTIONSTATUSENUM.CLOSED && !usersBid?.isWinner && usersBid?.amount &&
                        <div>
                            <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                                <span>You didn't win</span>
                            </div>
                        </div>}
                    {status === AUCTIONSTATUSENUM.CLOSED && !usersBid?.isWinner && !usersBid?.amount &&
                        <div>
                            <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                                <span>Someone else won</span>
                            </div>
                        </div>}
                    {status === AUCTIONSTATUSENUM.ENDED && usersBid?.amount &&
                        <div>
                            <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                                <span>We’ll be in contact if you’re successful</span>
                            </div>
                        </div>}
                    {status === AUCTIONSTATUSENUM.ENDED && !usersBid?.amount && <div>
                        <div className={"text-xl"} key={salesEvent.secondsRemaining}>
                            <span className={"text-red-500"}>Bidding Ended</span>
                        </div>
                    </div>}
                    {status === AUCTIONSTATUSENUM.OPEN &&
                        <>
                            <BidModal.BidButton
                                saleMethod={salesEvent.sellingMethod}
                                usersBid={usersBid}
                                disabled={!profile}
                                openModal={() => {
                                    if (profile.customer && profile.registered) openBidModal();
                                    else window.location.href = `my-account/sign-in?returnUrl=${window.location.pathname}`
                                }}
                                className={bidButtonClasses}
                            />
                            <BidModal
                                isOpen={offerBidModalOpen && status === AUCTIONSTATUSENUM.OPEN}
                                closeModal={closeBidModal}
                                usersBid={usersBid}
                                setStatus={setStatus}
                                onBidSuccess={handleBidSuccess}
                                salesEvent={salesEvent}
                                now={now}
                            />
                        </>
                    }
                </>
            )}
        </>
    );
}

export default BidSalesEvent;
