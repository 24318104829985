import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {getAcceptedFileTypes, getFileSource, getUploaderVariant, isExternalFile} from './uploader/uploader-utils';

import UploaderDropZone from "./uploader/uploader-drop-zone";
import UploaderActions from "./uploader/uploader-actions";
import UploaderFiles from "./uploader/uploader-files";
import useUploaderStateManager from "./uploader/uploader-state-manager-hook";
import {UploaderContext} from './uploader/uploader-context';
import _cloneDeep from "lodash/cloneDeep";
import {FormContext, Loader} from "../../index";
import {getValueRelativeToField} from "../utils";
import _get from "lodash/get";
import FaviconUploaderFilePreview from "./uploader/previews/favicon-uploader-preview";
import _isEqual from "lodash/isEqual";
import _set from "lodash/set";
import useCdn from "../../../websites/src/hooks/cdn-hook";
import {WebsiteContext} from "../../../websites";
import {toast} from "sonner";

Uploader.propTypes = {
    accept: PropTypes.string,
    minFileSize: PropTypes.number,
    maxFileSize: PropTypes.number,
    multiple: PropTypes.bool,
    preview: PropTypes.shape({
        className: PropTypes.string,
        title: PropTypes.string
    }),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    unsplash: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            initialSearchFieldName: PropTypes.string,
            q: PropTypes.oneOf([...(new Array(100)), 100].map((_, i) => i)), // Generates entries from 0 - 100
            dpr: PropTypes.oneOf([...(new Array(5))].map((_, i) => i + 1)), // Generates entries from 1 - 5
            w: PropTypes.number,
            h: PropTypes.number,
            crop: PropTypes.oneOf([
                'top', 'bottom', 'left', 'right', 'faces', 'focalpoint', 'edges', 'entropy'
            ]),
            fm: PropTypes.oneOf([
                'avif', 'gif', 'jp2', 'jpg', 'json', 'jxr', 'pjpg', 'mp4', 'png', 'png8',
                'png32', 'webm', 'webp', 'blurhash'
            ]),
            auto: PropTypes.oneOf([
                'compress', 'enhance', 'format', 'compress,enhance', 'compress,format', 'compress,enhance,format'
            ]),
            fit: PropTypes.oneOf([
                'clamp', 'clip', 'crop', 'facearea', 'fill', 'fillmax', 'max', 'min', 'scale'
            ])
        })
    ]),
    pintura: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            imagePresentationFieldName: PropTypes.string,
            imageAspectRatioFieldName: PropTypes.string,
            livePreview: PropTypes.bool,
        })
    ]),
    focalPoint: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            // For future if we need to define any additional settings 
        })
    ]),

    /**
     * Overrides the aspect ratio of preview and the pintura editor to always be this aspect ratio
     */
    aspectRatio: PropTypes.number,
};

Uploader.defaultProps = {
    unsplash: {
        initialSearchFieldName: "text",
        secondarySearchFieldName: "title",
        q: 75,
        w: 1920
    },
    pintura: {
        imageAspectRatioFieldName: "imageAspectRatio",
        livePreview: false,
    },
    focalPoint: false,
    multiple: false,
    preview: {
        className: "w-full h-full",
    },
    aspectRatio: 0
};

Uploader.DisplayView = function ({value, ...props}) {
    const url = isExternalFile(value) 
        ? value 
        : value?.startsWith('/api') 
            ? value 
            : useCdn(value, 'file');
    
    return (
        value && value !== '\u2014' ?
            <a href={url} className="text-link">Download {props.label}</a>
            : '—'
    )
}

Uploader.WebsiteProductDisplayView = function ({value, ...props}) {
    const url = isExternalFile(value)
        ? value
        : value?.startsWith('/api')
            ? value
            : useCdn(value, 'file');
    
    return (
        <div className="grid grid-cols-2 gap-x-4 items-start mb-1">
            <div className="font-semibold col-span-1">{props.label}</div>
            <div
                className="col-span-1">
                {value ? <a href={url}>Download {props.label}</a> : '—'}
            </div>
        </div>
    )
}

export default function Uploader({children, ...props}) {
    const context = useContext(FormContext);
    const websiteContext = useContext(WebsiteContext);
    const fileUploadRef = useRef();

    // Calculate the variant of this uploader control
    const variant = useMemo(() => getUploaderVariant(props), [props.type])

    // Prepare focal point settings
    const focalPoint = useMemo(() => {
        let focalPoint = {};
        if (typeof props.focalPoint === 'object') {
            Object.assign(focalPoint, _cloneDeep(props.focalPoint));
        } else {
            focalPoint = props.focalPoint;
        }

        // Disable `focalPoint` if using the following variant
        if (['favicon', 'logo', 'document', 'video'].includes(variant)) {
            focalPoint = false;
        }

        return focalPoint;
    }, [props.focalPoint]);

    // Prepare pintura settings
    const pintura = useMemo(() => {
        let pintura = _cloneDeep(Uploader.defaultProps.pintura);
        if (typeof props.pintura === 'object') {
            Object.assign(pintura, props.pintura);
            if (!pintura.imageCropAspectRatio) {
                pintura.imageCropAspectRatio = getValueRelativeToField(props.name, _get(pintura, 'imageAspectRatioFieldName'), context.values);
                delete pintura.imageAspectRatioFieldName;
            }

            if (pintura.imagePresentationFieldName) {
                pintura.objectFit = getValueRelativeToField(props.name, _get(pintura, 'imagePresentationFieldName'), context.values)
                delete pintura.imagePresentationFieldName;
            }


            if (props.aspectRatio > 0) {
                pintura.imageCropAspectRatio = props.aspectRatio;
            }
        } else if (props.pintura === false) {
            pintura = false;
        }

        // Disable `pintura` if using the following variant
        if (['favicon', 'logo', 'document', 'video'].includes(variant)) {
            pintura = false;
        }

        return pintura;
    }, [props.pintura, focalPoint, props.aspectRatio, context.values]);

    // Prepare unsplash settings
    const unsplash = useMemo(() => {
        let unsplash = _cloneDeep(Uploader.defaultProps.unsplash);
        if (typeof props.unsplash === 'object') {
            Object.assign(unsplash, _cloneDeep(props.unsplash));
            unsplash.search = getValueRelativeToField(props.name, _get(unsplash, 'initialSearchFieldName'), context.values)
            unsplash.searchSecondary = getValueRelativeToField(props.name, _get(unsplash, 'secondarySearchFieldName'), context.values)
            delete unsplash.initialSearchFieldName;
        } else {
            unsplash = props.unsplash;
        }

        if (['favicon', 'logo', 'document', 'video'].includes(variant)) {
            unsplash = false;
        }

        return unsplash;
    }, [props.unsplash, context.values]);

    /**
     * @type {UploaderContext}
     */
    const uploaderContext = useMemo(() => {
        // noinspection JSValidateTypes,UnnecessaryLocalVariableJS
        /**
         *
         * @type {UploaderContext}
         */
        const result = {
            // Defaults files to private when dealing with the website.
            private: !!websiteContext?.website,
            ...(_cloneDeep(props)),
            unsplash,
            pintura,
            focalPoint,
            aspectRatio: pintura.imageCropAspectRatio ? pintura.imageCropAspectRatio : props.aspectRatio,
            accept: getAcceptedFileTypes(props),
            revert: false,
            variant,
            path: [
                context.fileUploadSettings?.basePath,
                props.path
            ].filter(item => !!item).join('/'),
            fileUploadRef
        };

        return result;
    }, [context.fileUploadSettings?.basePath, pintura, focalPoint, unsplash]);

    const files = useUploaderStateManager(uploaderContext);

    const revertFileUploader = (err) => {
        uploaderContext.revert = true;
        context.setContext((previous) => {
            const result = {...previous}
            _set(result.values, uploaderContext.id, uploaderContext.value)
            return result
        })
        props.onChange();
        uploaderContext.revert = false;

        if (err) {
            if (err.code === "ERR_CANCELED") return;
            const errMessage = err.response?.status === 413 ? "File too large" : "Failed to upload"
            if (!websiteContext?.website) toast.error(errMessage);
            if (websiteContext?.website) alert(errMessage);
        }
    }

    useEffect(() => {
        if (pintura && pintura.objectFit === 'contain') {
            if (files[0] && files[0].pintura?.imageState?.__cx__.key) {
                // Dynamically import pintura
                import('pintura').then(({processDefaultImage}) => {
                    window.requestAnimationFrame(() => {
                        let needsUpdating = false;
                        // Processes new image for fit-in to keep all pintura's settings
                        processDefaultImage(getFileSource(files[0]), {
                            imageWriter: {
                                preprocessImageState: (imageState) => {
                                    const crop = imageState.crop;
                                    imageState = {...imageState, ...files[0].pintura.imageState}

                                    // If the crop is already the same as the reset value then theres no need to re-upload/process the image.
                                    if (!_isEqual(crop, files[0].pintura.imageState.crop)) {
                                        needsUpdating = true;
                                        files[0].update({status: 'processing'});
                                    }
                                    // Reset image crop for fit in option.
                                    imageState.crop = crop;
                                    // return updated image state
                                    return imageState;
                                },
                                store: (state, options, onprogress) => {
                                    if (needsUpdating) {
                                        // Update the state of the file so that we can prerender the preview state
                                        return files[0].update({
                                            pintura: {
                                                // There are more properties then needed on the state variable
                                                // so object deconstruction is not used here for that reason 
                                                src: state.src,
                                                dest: state.dest,
                                                imageState: state.imageState,
                                                blob: state.blob,
                                            }
                                        })
                                            .then((file) => {
                                                // Ignores setting the status to `uploading` as the pintura editor will manage the
                                                // upload progress state then when successful will close the editor
                                                return file.upload(null, (progress, event) => {
                                                    onprogress(event);
                                                }, true);
                                            })
                                            // Finally return the file 
                                            .then((file) => {
                                                state.file = file;
                                                state.file._links = _cloneDeep(files[0]._links)
                                                state.file.key = _cloneDeep(files[0].key)
                                                return state;
                                            });
                                    } else {
                                        return {};
                                    }
                                },
                                outputProps: ['src', 'dest', 'imageState', 'blob', 'file'],
                                targetSize: variant === 'favicon' ? {
                                    width: 192,
                                    height: 192
                                } : undefined
                            },
                        }).then((result) => {
                            if (result.file) {
                                result.file.update({status: 'ready'})
                            }
                        }).catch((e) => console.log(e))

                    })
                })
            }
        }
    }, [pintura?.objectFit])

    return (
        <UploaderContext.Provider value={uploaderContext}>
            {/* This hidden input is for setting up native required validation */}
            <input
                id={props.id}
                type="text"
                name={props.name}
                className="absolute opacity-0 pointer-events-none"
                required={props.required}
                disabled={props.disabled}
                defaultValue={files.map(({id}) => id).join('|') || ''}
            />

            <UploaderDropZone
                {...props}
                onDrop={(e) => {
                    files.add(Array.from(e.dataTransfer.files));
                }}
            >
                <UploaderActions {...props} files={files}/>
                <UploaderFiles files={files} onChange={props.onChange} revertFileUploader={revertFileUploader}>
                    {!files?.length && variant === 'favicon' ? (
                        <FaviconUploaderFilePreview/>
                    ) : null}
                </UploaderFiles>
            </UploaderDropZone>
            {children}
        </UploaderContext.Provider>
    );
};