import React, {Component, useState, useContext, useEffect, useMemo, useRef} from "react";
import axios from "axios";
import classNames from 'classnames';
import _get from "lodash/get";
import _set from "lodash/set";
import _cloneDeep from "lodash/cloneDeep";
import _mergeWith from "lodash/mergeWith";
import _sortBy from "lodash/sortBy";
import _groupBy from "lodash/groupBy";
import _isFunction from "lodash/isFunction";
import _debounce from "lodash/debounce";
import _camelCase from "lodash/camelCase";

import Form from '@autocx/forms';
import Block from "../block";
import {backgroundColour, backgroundPattern} from "../../utils/block-utils";

const _poly = {
    assign: Object.assign
}

const scope = {
    Form,
    axios,
    useEffect,
    useMemo,
    useRef,
    useState,
    classNames,
    _get,
    _set,
    _cloneDeep,
    _mergeWith,
    _sortBy,
    _groupBy,
    _debounce
}

const evalCode = (code = '', scope = {}) => {
    try {
        const scopeKeys = Object.keys(scope);
        const scopeValues = scopeKeys.map(key => scope[key]);
        const res = new Function('_poly', 'React', ...scopeKeys, code);
        res(_poly, React, ...scopeValues);
        return true;
    } catch (err) {
        return false;
    }
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, message: '' };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, message: error.message };
    }

    render() {
        return !this.state.hasError
            ? (this.props.children || null) 
            : (<p className={"p-2"}>{this.state.message}</p>);
    }
}

export default function CustomBlock(props) {
    const exports = {};
    const code = props.values?.transpiledCode
    if (evalCode(code, {...scope, exports})) {
        const Element = _isFunction(exports.default) ? exports.default : null;
        const settings = (props.values?.userDefinedFieldsets || []).reduce((result, fieldset) => {
            (fieldset.fields || []).forEach(({id, label, fieldName}) => {
                result[fieldName || _camelCase(label)] = props.values[id];
            });

            return result;
        }, {});
        
        return (
            <Block {...props} className={classNames(props.className)}>
                <ErrorBoundary key={code}>
                    {props.values?.css ? (
                        <style>
                            {props.values?.css}
                        </style>
                    ) : null}
                    {Element ? <Element {...settings} /> : <p className={'p-2'}>Missing default export</p>}
                </ErrorBoundary>
            </Block>
        )
    } else {
        return <Block {...props} className={classNames(props.className)} />
    }
}