import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import classNames from "classnames";
import _find from "lodash/find";

const getSortOptions = (sortFields) => {
    const sortOptions = [];

    sortFields?.forEach(field => {
        const label = field.split(':')[0];
        const type = field.split(':')[1];
        const id = field.split(':')[2];
        const sort = ['asc', 'desc'];
        let labelText;

        switch (type) {
            case 'text':
                labelText = ['A to Z', 'Z to A'];
                break;
            case 'number':
            case 'currency':
                labelText = ['Low to High', 'High to Low'];
                break;
            case 'date':
                labelText = ['Newest to Oldest', 'Oldest to Newest'];
                break;
        }

        if (label.toLowerCase().includes('year')) labelText = ['Newest to Oldest', 'Oldest to Newest'];
        
        id === 'createdAt'
            ? sortOptions.push({label: `${label}`, value: [id, sort?.[1]]})
            : sortOptions.push({
                label: `${label}: ${labelText?.[0]}`,
                value: [id, sort?.[0]]
            }, {label: `${label}: ${labelText?.[1]}`, value: [id, sort?.[1]]})
    })

    return sortOptions;
}

export default function Sort(props) {
    const sortOptions = getSortOptions(props?.sortFields);
    const activeSortOption = _find(sortOptions, {value: props?.activeFilters?.sort});
    
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    {sortOptions.length >= 1 ?
                        <Menu.Button
                            className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            Sort <span className={"hidden sm:inline-block"}>&nbsp;by {activeSortOption?.label ?? sortOptions[0].label}</span>
                            <span className={classNames(
                                "border-current ml-1 h-6 w-6",
                                open ? "chevron--up" : "chevron--down"
                            )}>
                        <svg
                            width="20"
                            height="20"
                            version="1.1"
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid"
                            stroke="currentColor">
                            <g className="chevron__container">
                                <line className="chevron__line1" x1="20" y1="50" x2="50" y2="50" style={{strokeWidth: 8}}/>
                                <line className="chevron__line2" x1="80" y1="50" x2="50" y2="50" style={{strokeWidth: 8}}/>
                            </g>    
                        </svg>
                    </span>
                        </Menu.Button>
                        : null}
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="absolute right-0 space-y-1 z-10 mt-2 w-40 px-2 py-2 origin-top-right rounded-theme bg-white shadow-[0_2px_6px_-4px_rgba(0,0,0,0.3),0px_10px_20px_-15px_rgba(0,0,0,0.2)] ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {sortOptions.map((option) => (
                                <Menu.Item key={option.label}>
                                    <div onClick={() => {
                                        props.onClick(option.value)
                                    }}
                                         style={{borderRadius: 'calc(var(--container-roundness) / 2)'}}
                                         className={classNames(
                                             props.activeFilters?.sort?.join() === option.value?.join() ? 'font-medium text-gray-600' : 'text-gray-900',
                                             'block px-2 py-1 text-sm cursor-pointer hover:bg-black/5 hover:brightness-125'
                                         )}>
                                        {option.label}
                                    </div>
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}