import React from 'react';
import ReactDom from 'react-dom';
import App from './app';

const script = document.getElementById('view_state');

ReactDom.hydrate(
    React.createElement(App, JSON.parse(script.dataset.object)),
    document.querySelector('[data-reactroot]').parentElement
);

script.remove();