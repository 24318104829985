import Design1 from './design-1';

import PropTypes from 'prop-types';
import {useContext, useEffect} from "react";
import fitty from "fitty"
import classNames from "classnames";

import './index.css';
import Design2 from "./design-2";
import Block from "../block";
import {WebsiteContext} from "../../index";

SiteNavigationBlock.propTypes = {
    items: PropTypes.array,
    settings: PropTypes.object,
};

SiteNavigationBlock.defaultProps = {};

export function handleBackgroundColour(value, position) {
    if (Array.isArray(value)) {
        return value[position]
    } else {
        return value
    }
}

export const hasStoreActions = () => {
    const context = useContext(WebsiteContext);
    return !!(context.website?.store?.accountManagementEnabled || context.website?.store?.hasShoppingCart || context.website?.store?.canSearch)
}

export function AccountButton(props) {
    return (
        <a href="/my-account/" className={classNames(props.className, "flex items-center justify-center")}>
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.105 24.21C18.7369 24.21 24.2121 18.7273 24.2121 12.105C24.2121 5.47312 18.7273 0 12.0954 0C5.47523 0 0 5.47312 0 12.105C0 18.7273 5.48484 24.21 12.105 24.21ZM12.105 21.8255C6.71085 21.8255 2.39412 17.4991 2.39412 12.105C2.39412 6.71085 6.70124 2.38452 12.0954 2.38452C17.4895 2.38452 21.8276 6.71085 21.8276 12.105C21.8276 17.4991 17.4991 21.8255 12.105 21.8255ZM19.9181 19.8361L19.8766 19.6418C19.14 17.9212 16.196 16.1442 12.105 16.1442C8.02569 16.1442 5.07959 17.9212 4.33545 19.6322L4.29397 19.8361C6.49826 21.8651 9.86835 22.9826 12.105 22.9826C14.3534 22.9826 17.6946 21.8747 19.9181 19.8361ZM12.105 14.2132C14.3794 14.2324 16.1414 12.2979 16.1414 9.78468C16.1414 7.4196 14.3655 5.4478 12.105 5.4478C9.84656 5.4478 8.05898 7.4196 8.0707 9.78468C8.08031 12.2979 9.84234 14.194 12.105 14.2132Z" />
            </svg>
        </a>
    )
}

export function StoreActions() {
    const context = useContext(WebsiteContext);
    return (
        <>
            {context.website?.store?.canSearch ? (
                <div className={"w-4 h-4"}>
                    <svg width="16" height="16">
                        <path
                            d="M6 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM0 6a6 6 0 1 1 10.89 3.477l4.817 4.816a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 0 6z"
                            fill="currentColor" fillRule="evenodd"/>
                    </svg>
                </div>
            ) : null}
            {context.website?.store?.hasShoppingCart &&
                <div className={"flex justify-end"}>
                    <button className={"cart badge"} data-badge={"0"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M3 3h2l.4 2M7 13h10l4-8h3.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                        </svg>
                    </button>
                </div>
            }
            {context.website?.store?.accountManagementEnabled && <AccountButton className={""}/>}
        </>
    )
}

export function Header(props) {
    return (
        <Block actions={props.actions} hideInlineAddBlockButton={true} as={"header"} id={'navigation'}
            className={classNames(props.settings?.design !== undefined || props.settings?.design !== null ? `navigation-block-${props.settings?.design ?? 0}` : null, props.className)
        }>
            {props.children}
        </Block>
    )
}

export default function SiteNavigationBlock(props) {
    const width = props.settings?.image?.width || 1; 
    const height = props.settings?.image?.height || 1; 
    const logoAspectRatio = width / height;
    
    useEffect(() => {
        const siteNavigation = document.getElementById('block_navigation');
        const updateHeaderHeight = () => {
            const headerHeight = siteNavigation.getBoundingClientRect().height;
            document.documentElement.style.setProperty('--site-nav-height', `${headerHeight}px`);
        };

        updateHeaderHeight(); // Initial calculation

        const resizeObserver = new ResizeObserver(updateHeaderHeight);
        resizeObserver.observe(siteNavigation);

        return () => {
            resizeObserver.unobserve(siteNavigation);
        };

    }, []);
    
    useEffect(() => {
        // If no logo is present, fits the website name into the logo area
        fitty("header .fit-text", {minSize: 16, maxSize: 32, multiLine: true, observeMutations: {attributes: true, subtree: true, childList: true, characterData: true}});
    }, [])
    
    useEffect(() => {
        fitty("header .fit-text", {minSize: 16, maxSize: 32, multiLine: true, observeMutations: {attributes: true, subtree: true, childList: true, characterData: true}});
    },[props.settings])
    
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} logoAspectRatio={logoAspectRatio} />);
        case 1 :
            return (<Design2 {...props} logoAspectRatio={logoAspectRatio} />);
        default:
            return (<Design1 {...props} logoAspectRatio={logoAspectRatio} />);
    }
};