import {WebsiteContext} from "../../../index";
import {useContext} from "react";
import Currency from "@autocx/forms/src/controls/currency";


export const vehicleLoanCalculator = {
    label: "Vehicle Finance Calculator",
    fields: [
        {
            label: "Loan Amount",
            type: "currency",
            name: "loanAmount",
            inlineAddons: true,
            required: true
        },
        {
            label: "Initial Deposit / Trade-In",
            type: "currency",
            name: "deposit",
            default: 0
        },
        {
            label: "Interest Rate",
            type: "number",
            name: "interestRate",
            required: true,
            suffix: "%",
            default: "6.49"
        },
        {
            label: "Repayment Frequency",
            type: "radio",
            style: "cards",
            options: [
                { label: "Weekly", value: "weekly" },
                { label: "Fortnightly", value: "fortnightly" },
                { label: "Monthly", value: "monthly" }
            ],
            name: "repaymentFrequency",
            default: "weekly",
            required: true
        },
        {
            label: "Loan Length",
            type: "select",
            options: [
                { label: "1 Year (12 months)", value: 1 },
                { label: "2 years (24 months)", value: 2 },
                { label: "3 years (36 months)", value: 3 },
                { label: "4 years (48 months)", value: 4 },
                { label: "5 years (60 months)", value: 5 },
                { label: "6 years (72 months)", value: 6 },
                { label: "7 years (84 months)", value: 7 },
            ],
            name: "loanLength",
            default: 3,
            required: true,
        },
        {
            label: "Balloon Amount",
            description: "A balloon is an amount that is owed as a lump sum payment at the end of your loan.",
            type: "currency",
            name: "balloonAmount",
            default: 0
        }
    ],
    variables: {
        repaymentPeriodsPerYear: {
            expression: "repaymentFrequency === 'weekly' ? 52 : repaymentFrequency === 'fortnightly' ? 26 : 12"
        }
    },
    formulas: [
        {
            name: "principal",
            expression: "loanAmount - deposit"
        },
        {
            name: "periodicInterestRate",
            expression: "interestRate / 100 / repaymentPeriodsPerYear"
        },
        {
            name: "numberOfPayments",
            expression: "loanLength * repaymentPeriodsPerYear"
        },
        {
            name: "repayment",
            expression: "((principal - (balloonAmount / Math.pow(1 + periodicInterestRate, numberOfPayments))) * periodicInterestRate) / (1 - Math.pow(1 + periodicInterestRate, -numberOfPayments))"
        }
    ],
    disclaimer: {
        text: "The calculator is provided only as a general self-help planning tool, and does not include any additional fees or charges that may apply. Results depend on many factors, including the assumptions you provide. We do not guarantee their accuracy, or applicability to your circumstances."
    },
    output: ({ computedValues, inputs }) => {
        const context = useContext(WebsiteContext)
        const { repayment } = computedValues;
        
        return (
            <div className="outputs section-white p-4 rounded-theme text-center border border-base border-2 mt-4 text-lg"
                onClick={() => document.getElementById("loanAmount").focus()}
            >
                {inputs.loanAmount > 0 && !isNaN(inputs.loanAmount) ? (
                    <>
                        Your {inputs.repaymentFrequency} repayment would be
                        <br />
                        <span className={"text-lg font-bold"}>
                            <Currency.DisplayValue currency={context.currency} value={repayment} />
                        </span>
                    </>
                ) : (
                    <span className={"text-base text-gray-600"}>Please enter a loan amount</span>
                )}
            </div>
        );
    },
}
