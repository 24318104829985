import Design1 from './design-1';
import {useEffect, useState} from "react";
import axios from "axios";
import {Loader} from "@autocx/forms";
import Spinner from "../../components/spinner";

ConfirmationBlock.propTypes = {};

ConfirmationBlock.defaultProps = {};

export default function ConfirmationBlock(props) {
    const [order, setOrder] = useState({});
    const [loading, setIsLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(false)
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        if(props.values.uuid === '' || props.values.uuid === undefined) return;
        
        let timer = setTimeout(() => {
            setShowLoader(true);
        }, 400);
        
        axios.get('/api/v2/order/' + props.values.uuid)
            .then((response) => {
                setOrder(response.data);
            })
            .then(() => {
                axios.get('/api/v2/publicusers/profile/')
                    .then(({data}) => {
                        setProfile(data)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .finally(() => {
                setIsLoading(false); 
                setShowLoader(false)
                clearTimeout(timer);
            });

    }, [props.values.uuid]);

    if (loading) {
        if (showLoader) {
            return <Spinner containerClassName={"h-[50vh]"}/>;
        }
        return null; 
    }
    
    switch (props.settings?.design) {
        case 0 :
            return (<Design1 {...props} order={order} profile={profile}/>);
        default:
            return (<Design1 {...props} order={order} profile={profile}/>);
    }
    
};