import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from "react";
import Input from "./input";

DateOfBirth.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

DateOfBirth.defaultProps = {
    onChange: () => {
    },
}
export function formatDateWithAge(date, locale = "en-AU"){
    const dob = new Date(date);
    const day = dob.getDate();
    const month = dob.toLocaleString(locale, { month: 'long' });
    const year = dob.getFullYear();

    const now = new Date();
    let age = now.getFullYear() - dob.getFullYear();
    const m = now.getMonth() - dob.getMonth();

    // If this year's birthday has not occurred yet, subtract one year from the age
    if (m < 0 || (m === 0 && now.getDate() < dob.getDate())) {
        age--;
    }

    return `${month} ${day}, ${year} (${age} years old)`
}

const defaultFormatter = (date) => {
    const dob = new Date(date);
    return dob.toLocaleDateString('en-AU');
};

DateOfBirth.DisplayView = function ({date, formatter, value, context}) {
    const locale = context?.locale || context?.website?.workspaceCulture || 'en-AU';
    const dateValue = date || value;
    
    if (!dateValue) return <span>—</span>
    
    // Use the formatter function passed in props, or default to the default formatter
    const displayDate = formatter ? formatter(dateValue) : formatDateWithAge(dateValue, locale);
    return <span>{displayDate}</span>
};

DateOfBirth.DisplayView.propTypes = {
    date: PropTypes.string,
    formatter: PropTypes.func
};

DateOfBirth.DisplayView.defaultProps = {
    formatter: null
};

export default function DateOfBirth(props) {
    const [error, setError] = useState();
    const [date, setDate] = useState(() => {
        if (props.value) {
            const d = new Date(props.value)
            return {day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear()};
        }
        return {day: "", month: "", year: ""}
    })
    const inputRef = useRef();

    const isValidDate = (day, month, year) => {
        // Remember that the month is 0-based so February is actually 1
        const d = new Date(year, month, day);
        return d.getFullYear() === year && d.getMonth() === month && d.getDate() === day && (d.getTime() <= new Date().getTime());
    }

    const dateWithoutTimezone = (date) => {
        const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
        return new Date(date.valueOf() - tzoffset)
            .toISOString()
            .slice(0, -1);
    };

    const handleInputChange = () => {
        if (error) setError('');
        if (date.day && date.month && date.year && date.year > 1000) {
            if (isValidDate(date.day, date.month - 1, date.year)) {
                const d = new Date(date.year, date.month - 1, date.day);
                props.onChange({target: inputRef.current}, inputRef.current.name, dateWithoutTimezone(d))
            } else {
                props.onChange({target: inputRef.current}, inputRef.current.name, null);
                setError('Date is invalid');
            }
        }

        if (!date.day && !date.month && !date.year && props.value) props.onChange({target: inputRef.current}, inputRef.current.name, null)
    }

    useEffect(() => {
        handleInputChange();
    }, [date]);

    return (
        <>
            <input ref={inputRef} type="text" className="absolute opacity-0 pointer-events-none" tabIndex={-1}
                   name={props.name}/>
            <div className="w-full relative flex space-x-2">
                <Input
                    disabled={props.disabled}
                    type="number"
                    id="day"
                    name="day"
                    placeholder="dd"
                    required={props.required}
                    min={0}
                    value={date.day}
                    data-error-message={props.errorMessage}
                    onChange={e => {
                        if (e.target.value.length > 2) return;
                        if (e.target.value && e.target.value >= 0) setDate({...date, day: +e.target.value})
                        else setDate({...date, day: ""})
                    }}
                />
                <Input
                    disabled={props.disabled}
                    type="number"
                    id="month"
                    name="month"
                    placeholder="mm"
                    required={props.required}
                    min={0}
                    value={date.month}
                    data-error-message={props.errorMessage}
                    onChange={e => {
                        if (e.target.value.length > 2) return;
                        if (e.target.value && e.target.value >= 0) setDate({...date, month: +e.target.value})
                        else setDate({...date, month: ""})
                    }}
                />
                <Input
                    disabled={props.disabled}
                    type="number"
                    id="year"
                    name="year"
                    placeholder="yyyy"
                    required={props.required}
                    min={0}
                    value={date.year}
                    data-error-message={props.errorMessage}
                    onChange={e => {
                        if (e.target.value.length > 4) return;
                        if (e.target.value && e.target.value >= 0) setDate({...date, year: +e.target.value})
                        else setDate({...date, year: ""})
                    }}
                />
            </div>
            {error && <div className="text-red-500">{error}</div>}
        </>
    );
}