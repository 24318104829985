import _get from "lodash/get";
import React, {Fragment, useContext, useEffect, useState} from "react";
import CONTROLS from "@autocx/forms/src/controls";
import {WebsiteContext} from "../index";

const getFieldDisplayView = (allControls, fieldLookup, fieldId, values, component, hideEmptyValues = false) => {
    const context = useContext(WebsiteContext);
    const value = _get(values, fieldId, values[fieldId]);
    let fieldType = fieldLookup[fieldId].type;
    let DisplayView;

    if (!fieldType || fieldType === "step" || fieldType === "form-text") return null
    if (fieldType.includes('uploader')) fieldType = 'uploader'

    if (fieldLookup[fieldId].label || value) {
        if (allControls[fieldType]?.[component]) DisplayView = allControls[fieldType][component]
        else if (allControls.input?.[component]) DisplayView = allControls.input?.[component]
        else DisplayView = allControls.input?.DisplayValue;
    }

    return (
        DisplayView && DisplayView({
            type: fieldType,
            value: value,
            formValues: values,
            label: fieldLookup[fieldId].label,
            context: context
        }) ?
            (
                hideEmptyValues && !value
                    ? null
                    : <Fragment key={fieldId}>
                        <DisplayView {...fieldLookup[fieldId]} value={value} formValues={values} context={context}/>
                    </Fragment>
            ) : null
    )
}

export default function WebsiteFieldRenderer({
                                                 fields,
                                                 values,
                                                 component = "WebsiteProductDisplayView",
                                                 hideEmptyValues,
                                                 pluginControls = []
                                             }) {
    const [allControls, setAllControls] = useState({...CONTROLS, ...pluginControls});

    useEffect(() => {
        if (pluginControls?.length > 0) setAllControls({...CONTROLS, ...pluginControls})
    }, [pluginControls]);

    // create a lookup so we can get the value of the field id off the root of the object
    const fieldLookup = fields?.reduce((lookup, field) => {
        lookup[field?.id] = {...field};
        return lookup;
    }, {});


    return (
        fieldLookup ? Object.keys(fieldLookup).map((fieldId) => (
            getFieldDisplayView(allControls, fieldLookup, fieldId, values, component, hideEmptyValues)
        )) : ''
    )
}