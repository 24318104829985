export const bmiCalculator = {
    label: "BMI Calculator",
    fields: [
        {
            label: "Weight",
            type: "number",
            name: "weight",
            suffix: "kg",
            required: true
        },
        {
            label: "Height",
            type: "number",
            name: "height",
            suffix: "m",
            required: true
        }
    ],
    formulas: [
        {
            name: "bmi",
            expression: "Math.round((weight / (height * height)) * 10) / 10"
        },
        {
            name: "bmiCategory",
            expression: "bmi < 18.5 ? 'Underweight' : bmi < 24.9 ? 'Normal weight' : bmi < 29.9 ? 'Overweight' : bmi > 30 ? 'Obesity' : ''"
        }
    ],
    outputs: [
        {
            label: "Your BMI is ",
            type: "number",
            name: "bmi"
        },
        {
            label: "BMI Category: ",
            type: "text",
            name: "bmiCategory"
        }
    ]
}