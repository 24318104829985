import ArrowNarrowLeftIcon from '@heroicons/react/solid/ArrowNarrowLeftIcon'
import ArrowNarrowRightIcon from '@heroicons/react/solid/ArrowNarrowRightIcon'
import classNames from "classnames";
import _isNumber from "lodash/isNumber";
import _times from "lodash/times";

/**
 *
 * @param current Current page number
 * @param total Total amount of pages
 * @param delta Number of pages you would like left of right of current page
 * @param gap String you want when theres a big number gap. I.e "..." will show "1 2 ... 5 6 7"
 * @returns {(number|T|*)[]|number[]}
 */
function pagination(current = 1, total, delta = 2, gap = ". . .") {
    // If theres less than 7 pages show all pages in pagination
    if (total < 7) return _times(total, (i) => i + 1);
    
    if (total <= 1) return [1]

    const center = [current]

    for (let i = 1; i <= delta; i++) {
        center.unshift(current - i)
        center.push(current + i)
    }

    const filteredCenter = center.filter(page => page > 1 && page < total)

    const includeLeftGap = current > 3 + delta
    const includeLeftPages = current === 3 + delta
    const includeRightGap = current < total - (2 + delta)
    const includeRightPages = current === total - (2 + delta)

    if (includeLeftPages) filteredCenter.unshift(2)
    if (includeRightPages) filteredCenter.push(total - 1)
    if (includeLeftGap) filteredCenter.unshift(gap)
    if (includeRightGap) filteredCenter.push(gap)

    return [1, ...filteredCenter, total]
}

export default function Pagination(props) {
    const current = +props.currentPagination || 1;
    const pages = pagination(current, +props.pages);
    const canPrev = current > 1;
    const canNext = current < +props.pages;

    return (
        <nav className={classNames(
            "flex items-center justify-between border-t border-gray-200 pt-4 sm:justify-center",
            props.className
        )}>
            <div className="-mt-px flex">
                <button
                    onClick={() => {
                        if (canPrev) props.onPreviousClick();
                    }}
                    className={classNames(!canPrev ? "pointer-events-none" : null, "cursor-pointer")}>
                    <ArrowNarrowLeftIcon className={classNames(!canPrev ? "text-gray-300" : null, "h-5 w-5 text-gray-600")} aria-hidden="true"/>
                </button>
            </div>
            <div className="flex items-center gap-2 mx-4">
                {pages?.map((page) => (
                        <button key={page}
                              onClick={() => {
                                  if (_isNumber(page)) props.onPaginationClick(page)
                              }}
                              className={classNames(
                                  _isNumber(page) ? 'text-[color:var(--primary-button-background-colour)]' : "pointer-events-none opaicty-80",
                                  page === current ? "rounded-theme bg-[color:var(--primary-button-background-colour)] text-[color:var(--primary-button-text-colour)] font-bold h-7 w-7 justify-center" : "px-2 text-[color:var(--text-colour)]",
                                  "inline-flex items-center text-base font-medium")}>{page}</button>
                    )
                )}
            </div>
            <div className="-mt-px flex">
                <button onClick={() => {
                    if (canNext) props.onNextClick()
                }}
                      className={classNames(!canNext ? "pointer-events-none" : null, "cursor-pointer")}>
                    <ArrowNarrowRightIcon className={classNames(!canNext ? "text-gray-300" : null, "h-5 w-5 text-gray-600")} aria-hidden="true"/>
                </button>
            </div>
        </nav>
    )
}