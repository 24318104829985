import PropTypes from "prop-types";
import classNames from 'classnames';

import {Disclosure} from "@headlessui/react";
import AnimateHeight from 'react-animate-height';
import ChevronIcon from '@autocx/icons/src/chevron-icon';

import Fields from "./fields";
import Actions from "./actions";

const CollapseExpandButton = function (props) {
    if (props.canExpand) {
        return (
            <Disclosure.Button as={"div"} className={classNames("w-full flex justify-between items-center group cursor-pointer")}>
                <div>
                    {props.fieldset.label ? (
                        <h4 className="text-base font-bold text-gray-700">{props.fieldset.label}</h4>
                    ): null}
                    {props.children}
                </div>
                <div className="h-7 flex items-center">
                    <span className={classNames("border-current h-6 w-6", props.open ? "chevron--up" : "chevron--down")}>
                        <ChevronIcon />
                    </span>
                </div>
            </Disclosure.Button>
        );
    } else {
        return (
            <>
                {props.fieldset.label ? (
                    <h4 className="text-base font-bold text-gray-700">{props.fieldset.label}</h4>
                ): null}
                {props.children}
            </>
        );
    }
}

PanelListForm.propTypes = {
    fieldset: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func,
    onEditFieldsetSettings: PropTypes.func,
}

PanelListForm.defaultProps = {
    fieldsets: {},
    onFieldChange: () => {},
}

export default function PanelListForm({editButton, children, ...props}) {
    const canExpand = props.fieldset.collapsable === true && !props.fieldset.childrenOnly;
    return (
        <Disclosure as={"div"} defaultOpen={!(props.fieldset.collapsable === true) || props.fieldset.expanded === true}>
            {({ open }) => (
                <>
                    <CollapseExpandButton {...props} open={open} canExpand={canExpand}>
                        {children}
                    </CollapseExpandButton>
                    {canExpand ? (
                        <AnimateHeight
                            height={open ? 'auto' : 0}
                            animateOpacity={true}
                        >
                            <Disclosure.Panel static>
                                {!props.fieldset.childrenOnly && props.fieldset.description ? (
                                    <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                                ) : null}
                                <Fields {...props} className={"space-y-4 pt-2 pb-2"}>
                                    <Actions actions={props.actions} onActionClick={props.onActionClick} />
                                </Fields>
                            </Disclosure.Panel>
                        </AnimateHeight>
                    ): (
                        <>
                            {!props.fieldset.childrenOnly && props.fieldset.description ? (
                                <p className="mb-3 text-sm text-gray-500">{props.fieldset.description}</p>
                            ) : null}
                            <Fields {...props} className={"space-y-4 pt-2 pb-2"}>
                                <Actions actions={props.actions} onActionClick={props.onActionClick} />
                            </Fields>
                        </>
                    )}
                </>
            )}
        </Disclosure>
    )
};

PanelListForm.EditButton = function (props) {
    return (<a className={"display-block"} href="forms/src/layouts/panel-list-form#" onClick={props.onClick}>{props.children}</a>)
};