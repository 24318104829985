import {useContext} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import _get from 'lodash/get';

import Field from "./field";
import InputGroup from "./input-group";
import FormContext from "../form-context";
import Spacer from "./spacer";
import Paragraph from "./paragraph";
import Block from "./block";
import {evaluate} from "../utils";

ControlGroup.propTypes = {
    className: PropTypes.string,
    hideFieldLabels: PropTypes.bool,
    fields: PropTypes.array.isRequired,
    onFieldChange: PropTypes.func.isRequired,
}

ControlGroup.defaultProps = {
    fields: [],
    onFieldChange: () => {}
}

export default function ControlGroup(props) {
    const context = useContext(FormContext);
    const errors = props.fields.map((field) => {
        return !!_get(context.errors, field.name);
    }).filter(error => !!error);
    const fields = (props.fields || []).filter(field => evaluate(null, field.showsWhen, context.values) !== false);
    if (!fields.length) return null;

    if (!props.hideFieldLabels) {
        return (
            <div className={classNames("space-y-2 grid grid-cols-1 gap-2 sm:space-y-0 sm:grid-cols-2", props.className)}>
                {fields.map(({fieldClassName, className, ...field}, index) => (
                    <div key={`${field.name}_${index}`} className={fieldClassName}>
                        {field.type !== 'spacer' && field.type !== 'paragraph' && field.type !== 'block' ? (
                            <InputGroup
                                className={classNames(className, "w-full")}
                                {...field}
                                onFieldChange={props.onFieldChange}
                            />
                        ) : null}
                        {field.type === 'spacer' ? (
                            <Spacer {...field} className={className} />
                        ) : null}
                        {field.type === 'paragraph' ? (
                            <Paragraph {...field} className={className} />
                        ) : null}
                        {field.type === 'block' ? (
                            <Block {...field} className={className} />
                        ) : null}
                    </div>
                ))}
            </div>
        );
    }
    
    return (
        <div className={props.className}>
            {props.label ? (
                <div className={`flex mb-1 ${props.label ? 'justify-between' : 'justify-end'}`}>
                    <label htmlFor={props.id} className={classNames(
                        "block text-sm font-semibold",
                        errors.length ? "text-error-500" : "text-gray-700"
                    )}>
                        {props.label}
                    </label>
                    {props.optional ? (
                        <span className={"text-xs font-medium leading-5 text-gray-500"}>Optional</span>
                    ) : null}
                </div>
            ) : null}
            <div className={classNames("space-y-2 grid grid-cols-1 gap-2 sm:space-y-0 sm:grid-cols-2", props.className)}>
                {(props.fields || []).map(({fieldClassName, className, ...field}, index) => (
                    <div key={`${field.name}_${index}`} className={fieldClassName}>
                        {field.type !== 'spacer' && field.type !== 'paragraph' && field.type !== 'block' ? (
                            <Field
                                className={classNames(className, "w-full")}
                                {...field}
                                onFieldChange={props.onFieldChange}
                            />
                        ) : null}
                        {field.type === 'spacer' ? (
                            <Spacer {...field} className={className} />
                        ) : null}
                        {field.type === 'paragraph' ? (
                            <Paragraph {...field} className={className} />
                        ) : null}
                        {field.type === 'block' ? (
                            <Block {...field} className={className} />
                        ) : null}
                    </div>
                ))}
            </div>
            {!errors.length && props.description ? (
                <p className="mt-1 mb-0 text-xs text-gray-500">
                    {props.description}
                </p>
            ) : null}
        </div>
    );
};