import PropTypes from 'prop-types';
import {useContext} from "react";
import FormContext from "../form-context";
import _isFunction from "lodash/isFunction";
import _cloneDeep from "lodash/cloneDeep";

Block.propTypes = {
    className: PropTypes.string,
    renderOnChange: PropTypes.bool,
    value: PropTypes.any,
};

Block.defaultProps = {};

export default function Block(props) {
    const context = useContext(FormContext);
    return _isFunction(props.value) ? props.value(_cloneDeep(context.values)) : props.value
};