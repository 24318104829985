import _uniq from 'lodash/uniq';

/**
 * Defines the list of image mime types that are supported image file formats that don't require any conversion rules
 * to be applied.
 * 
 * @type {string[]}
 */
export const SUPPORTED_IMAGE_MEDIA_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/svg+xml', 'image/gif'];

/**
 * Defines the list of image mime types that are supported favicon image file formats that don't require any conversion 
 * rules to be applied.
 *
 * @type {string[]}
 */
export const SUPPORTED_FAVICON_IMAGE_MEDIA_TYPES = ['image/png'];

/**
 * @typedef {Object.<string, string>} ConversionRules
 */
/**
 * Defines a mapping of image mime types where the key is the source mime type and the value is the destination 
 * mime type. This map will be used to convert from source mime type to destination mime type before uploading file
 * 
 * @type {ConversionRules}
 */
export const CONVERSION_RULES = {
    'image/tiff': 'image/jpg'
};

/**
 * @typedef {Object.<string, string[]>} MimeTypes
 */
/**
 * Contains default mime types for the different types of uploader controls. To register a new uploader type
 * simply add a new entry into this object like the others. The key is the value of the first portion of the uploader
 * control type
 *
 * For example:
 *     variant | type
 *     ----------------------------
 *     archive | archive-uploader
 *     testing | testing-uploader
 *     
 * @type {MimeTypes}
 */
export const MIME_TYPES = {
    image: [...SUPPORTED_IMAGE_MEDIA_TYPES, 'image/vnd.microsoft.icon', 'image/tiff'],
    video: ['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/mp2t', 'video/webm', 'video/3gpp', 'video/3gpp2','video/quicktime'],
    audio: ['audio/aac', 'audio/midi', 'audio/x-midi', 'audio/mpeg', 'audio/ogg', 'audio/opus', 'audio/wav', 'audio/webm'],
    document: [
        // PDF
        'application/pdf',

        // Word
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12',

        // Excel
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',

        // Powerpoint
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',

        // Access
        'application/vnd.ms-access',

        // OSX
        'application/vnd.apple.pages',
        'application/vnd.apple.keynote',
        'application/vnd.apple.numbers',
        'application/x-iwork-keynote-sffkey',
        'application/x-iwork-pages-sffpages',
        'application/x-iwork-numbers-sffnumbers',
        
        // Zip files
        'application/zip',
        'application/gzip',
        'application/x-7z-compressed'
    ]
};
MIME_TYPES['media'] = [].concat(MIME_TYPES.image, MIME_TYPES.video); 
MIME_TYPES['favicon'] = [...MIME_TYPES.image];
MIME_TYPES['logo'] = [...MIME_TYPES.image];

/**
 * Returns the CSS classes to apply aspect ratio styling on DOM element
 * 
 * @param {number} ratio The radio to determine what classes to return
 * @returns {string|null} The classes chosen based on the provided ratio  
 */
export const aspectRatioClassNames = (ratio) => {
    switch (ratio) {
        case 1:
            return 'aspect-[1/1]'
        case 1.3333333333:
            return 'aspect-[4/3]'
        case 1.7777777778:
            return 'aspect-[16/9]'
        case 0.75:
            return 'aspect-[3/4]'
        case 0.5625:
            return 'aspect-[9/16]'
        default:
            return null;
    }
}

/**
 * Returns an absolute url to the asset in cloudfront
 *
 * @param {string} cdnUrl The Cloudfront CDN URL
 * @param {UploaderFileState} file A reference of the file to get the preview src
 * @returns {string|null}
 */
export const getPreviewSrc = (cdnUrl, file) => {
    if (!file) return null;

    // Handle when the file has been processed with pintura and return a base64 string of the processed file
    if (file.pintura?.blob) return URL.createObjectURL(file.pintura.blob)

    // Handle when the file has been edited with pintura and use the new key
    let key = file.pintura?.imageState?.__cx__?.key || file.key;

    // If there is no key then ignore returning a cdn url of the preview
    if (!key) return null;
    
    if (isExternalFile(key)) return key;
    
    key = !key.startsWith('/') ? `/${key}` : key;

    if (key.endsWith('.ico') || key.endsWith('.svg') || key.endsWith('.gif') || key.startsWith('/resize')) {
        return `${cdnUrl}${key}`;
    } else if (key.endsWith('.png')) {
        return`${cdnUrl}/resize${key}/fit-in/800x0/filters:quality(50)/filters:format(webp)`;
    } else {
        return `${cdnUrl}/resize${key}/fit-in/800x0/filters:quality(50)/filters:format(jpg)`;
    }
}

/**
 * Determines if the provided value is an absolute url indicating that it is an external file.
 * 
 * @param {string} value The value to read 
 * @returns {boolean}
 */
export function isExternalFile(value) {
    if (!value) return false;
    return value.startsWith('http://') || value.startsWith('https://') || value.startsWith('//')
}

/**
 * Returns the variant of the uploader control reading the type prop. If there are no mime types for evaluated variant
 * then null is returned indicating that it's a generic file uploader
 *
 * @param {Object} props A reference to the props object of the uploader control
 * @returns {string|null}
 */
export function getUploaderVariant(props) {
    const variant = props.type.split('-')[0];
    return MIME_TYPES[variant] ? variant : null;
}

/**
 * Returns mime types that are accepted based on the variant of the uploader control. If the accept prop is defined then
 * no lookup logic is run and the value will be passed through
 * 
 * @param {Object} props A reference to the props object of the uploader control
 * @returns {string} A comma delimited list of mime types that are accepted for uploading
 */
export function getAcceptedFileTypes(props) {
    if (props.accept) return props.accept;
    
    // Handle when the accept prop is not provided. Default it to the correct mime types based on the type of uploader
    // or a unique list of all mime types defined in {@see MimeTypes}
    const variant = getUploaderVariant(props);
    if (MIME_TYPES[variant]) {
        return MIME_TYPES[variant].join(',');
    } else {
        return _uniq(
            Object.values(MIME_TYPES).reduce((result, fileTypes) => {
                result = result.concat(fileTypes);
                return result;
            }, [])
        ).join(',');
    }
}

/**
 * Determines which source to choose from depending on the state of the provided file.  
 * 
 * @param {UploaderFileState} file The file to examine
 * @returns {File|string} 
 */
export function getFileSource(file) {
    if (file.constructor.name === 'File') return file;
    if (isExternalFile(file.key)) return file.key;
    return file._links.src.href;
}