import classNames from 'classnames';
import { Disclosure } from '@headlessui/react'
import AnimateHeight from 'react-animate-height';
import {
    backgroundColour,
    textAlignment,
    backgroundPattern, setTitleDecorationByClass, setTitleDecorationByElement,
} from "../../utils/block-utils";
import Block from '../block'
import Action from "../../components/action";

export default function Design1(props) {
    return (
        <Block {...props}
               className={classNames(
                   props.className,
                   !props.values.options?.inset ? backgroundPattern(props): null,
                   !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
               }>            
            <div
                className={classNames(
                    "mx-auto max-w-7xl section flex flex-col items-center",
                    props.values.options?.inset ? 'rounded-theme' : null,
                    props.values.options?.inset ? backgroundPattern(props): null,
                    props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
                )}>
                {props.values.title || props.values.text ?
                    <div className={classNames(
                        "w-full",
                        textAlignment(props)
                    )}>
                        {props.values.title &&
                            <>
                                <h3 className={classNames(setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                                {props.values?.options?.titleDecoration &&
                                    setTitleDecorationByElement(props.values?.options?.titleDecoration)
                                }
                            </>
                        }
                        {props.values.text &&
                            <div dangerouslySetInnerHTML={{__html: props.values.text}} className={"rich-text"}/>}
                    </div>
                    : null
                }
                <ul className={"flex flex-col mb-4 divide-y divide-current w-full"}>
                    {props.values.items?.map((item, index) => (
                        <Disclosure as="li" key={index} className={"flex flex-col border-opacity-25"}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="w-full flex justify-between items-start py-5">
                                        <h5 className={"text-left"}>
                                            {item.title}
                                        </h5>
                                        <span className="ml-6 h-7 flex items-center">
                                            <span className={classNames(
                                                "border-current h-6 w-6",
                                                open ? "chevron--up" : "chevron--down"
                                            )}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    version="1.1"
                                                    viewBox="0 0 100 100"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    preserveAspectRatio="xMidYMid"
                                                    stroke="currentColor">
                                                    <g className="chevron__container">
                                                        <line className="chevron__line1" x1="10" y1="50" x2="50" y2="50"/>
                                                        <line className="chevron__line2" x1="90" y1="50" x2="50" y2="50"/>
                                                    </g>    
                                                </svg>
                                            </span>
                                        </span>
                                    </Disclosure.Button>

                                    <AnimateHeight
                                        id={`${index}`}
                                        height={open ? 'auto' : 0}
                                        animateOpacity={true}
                                    >
                                        <Disclosure.Panel as="div" static className={classNames("mb-8 -mt-1.5 rich-text")} dangerouslySetInnerHTML={{ __html: item.text }}>
                                        </Disclosure.Panel>
                                    </AnimateHeight>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </ul>
                <Action className={"mt-6"} {...props.values} title={null} />
            </div>
        </Block>
    )
}