import Input from "./input";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import {FormContext} from "../../index";

const {prefix, suffix, type, pattern, ...inputPropTypes} = Input.propTypes;
const {...inputDefaultProps} = Input.defaultProps;

const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}).replace(/\d/g, '').trim()

Currency.propTypes = {
    ...inputPropTypes,
    locale: PropTypes.string,
    currency: PropTypes.string,
    valueAsCents: PropTypes.bool,
    emptyPlaceholder: PropTypes.string,
    hidePrefix: PropTypes.bool
};

Currency.defaultProps = {
    ...inputDefaultProps,
    inlineAddons: false,
    hidePrefix: false,
    pattern: "[-]?\\d+([.]\\d{1,2})?",
    placeholder: "0.00",
    locale: "en-AU",
    currency: 'AUD',
    valueAsCents: true,
    emptyPlaceholder: '\u2014'
};

// Function to return a displayable value  
export const getCurrencyLabel = function (value, currency, valueAsCents, emptyPlaceholder) {
    const locale = navigator.language;

    value = value === undefined || value === null || typeof value !== 'number' ? '' : Number(value);
    if (isNaN(value)) value = '';

    if (value !== '' && valueAsCents && value > 0) {
        value = value / 100;
    }

    const fractionDigits = value && value !== Math.floor(value) ? 2 : 0;

    return value ? value.toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }) : emptyPlaceholder
}

// Function to return a value into the correct currency 
export const getCurrencyValue = function (value, valueAsCents, valueAsDollars, emptyPlaceholder) {
    value = value === undefined || value === null || typeof value !== 'number' ? '' : Number(value);
    if (isNaN(value)) value = '';

    if (value !== '' && valueAsCents && value > 0) {
        value = value / 100;
    }

    if (value !== '' && valueAsDollars && value > 0) {
        value = value * 100;
    }

    const fractionDigits = value && value !== Math.floor(value) ? 2 : 0;
    
    return value ? value.toFixed(fractionDigits) : emptyPlaceholder
}

Currency.WebsiteProductDisplayView = function (props) {
    const [locale, setLocale] = useState(props?.locale || props?.context?.website?.workspaceCulture);

    useEffect(() => {
        if (navigator.language) setLocale(navigator.language);
    }, [])

    let value = props.value === undefined || props.value === null || typeof props.value !== 'number' ? '' : Number(props.value);
    if (isNaN(value)) value = '';

    if (value !== '' && props.valueAsCents && value > 0) {
        value = value / 100;
    }

    const fractionDigits = value && value !== Math.floor(value) ? 2 : 0;

    value = value ? value.toLocaleString(locale, {
        style: "currency",
        currency: props.currency ?? props.context?.currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }) : props.emptyPlaceholder

    return (
        <div className="grid grid-cols-2 gap-x-4 items-start mb-1">
            <div className="font-semibold col-span-1">{props.label}</div>
            <div
                className="col-span-1">{props.prefix} {value || '—'} {props.suffix}</div>
        </div>
    )
}

Currency.WebsiteProductDisplayView.propTypes = {
    value: PropTypes.any,
    locale: PropTypes.string,
    currency: PropTypes.string,
    valueAsCents: PropTypes.bool,
    emptyPlaceholder: PropTypes.string,
};
Currency.WebsiteProductDisplayView.defaultProps = {
    valueAsCents: true,
    emptyPlaceholder: '\u2014'
};

Currency.DisplayValue = function (props) {
    const [locale, setLocale] = useState(props?.locale || props?.context?.website?.workspaceCulture);

    useEffect(() => {
        if (navigator.language) setLocale(navigator.language);
    }, [])

    let value = props.value === undefined || props.value === null || typeof props.value !== 'number' ? '' : Number(props.value);
    if (isNaN(value)) value = '';

    if (value !== '' && props.valueAsCents && value > 0) {
        value = value / 100;
    }

    const fractionDigits = value && value !== Math.floor(value) ? 2 : 0;

    return value ? value.toLocaleString(locale, {
        style: "currency",
        currency: props.currency ?? props.context?.currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }) : props.emptyPlaceholder
}

Currency.DisplayValue.propTypes = {
    value: PropTypes.any,
    locale: PropTypes.string,
    currency: PropTypes.string,
    valueAsCents: PropTypes.bool,
    emptyPlaceholder: PropTypes.string,
};
Currency.DisplayValue.defaultProps = {
    locale: "en-AU",
    currency: 'AUD',
    valueAsCents: true,
    emptyPlaceholder: '\u2014'
};

Currency.DisplayView = Currency.DisplayValue;

export default function Currency(props) {
    const context = useContext(FormContext);
    const locale = context?.appContext ? context.appContext.locale : props.locale;
    const currency = context?.appContext ? context.appContext.currency : props.currency;
    const prefix = props.hidePrefix ? '' : getCurrencySymbol(locale, currency)

    let value = props.value === undefined || props.value === null || typeof props.value !== 'number' ? '' : Number(props.value);
    if (props.value && typeof +props.value[0] === 'number') value = Number(props.value);
    if (isNaN(value)) value = '';

    if (value !== '' && props.valueAsCents && value > 0) {
        value = value / 100;
    }

    const fractionDigits = value && value !== Math.floor(value) ? 2 : 0;

    const [input, setInput] = useState(value ? value.toFixed(fractionDigits) : value);

    useEffect(() => {
        setInput(value ? value.toFixed(fractionDigits) : value)
    }, [props.value])

    return (
        <Input
            {...props}
            name={""}
            prefix={prefix}
            value={input}
            onKeyDown={(e) => {
                if (props.readOnly || props.disabled) return;

                // Handles when arrow keys are pressed to increment or decrement the current value
                // When alt or shift is used in combination with the arrow keys then it changes the increment size

                let increment = 1;

                if (e.altKey === true && e.shiftKey === false) {
                    increment = 0.1;
                }

                if (e.shiftKey === true && e.altKey === false) {
                    increment = 10;
                }

                // ArrowUp or ArrowDown
                if (e.keyCode === 38 || e.keyCode === 40) {

                    // ArrowDown decrements so change the increment to a decrement
                    if (e.keyCode === 40) {
                        increment = increment * -1;
                    }

                    const fractionDigits = e.target.value.includes('.') ? 2 : 0;

                    let value = Number(Number(e.target.value.replace(/[^.\d-]/gi, '') || 0).toFixed(fractionDigits));
                    value = value + increment;

                    setInput(value.toFixed(fractionDigits));

                    if (props.valueAsCents) {
                        value = value * 100;
                    }

                    props.onChange(e, props.name, Number(value.toFixed(fractionDigits)));
                }
            }}
            onChange={(e, name, value) => {
                value = value.replace(/[^.\d-]/gi, '');

                // Set the state of the display value to a value including only the currency characters
                setInput(value);

                // Take the string and parse it into a number so we can set the value in the form context 
                // to of type number not of type string
                if (value) {
                    value = Number(value);
                    if (props.valueAsCents) {
                        value = value * 100;
                    }
                    props.onChange(e, props.name, Number(value.toFixed(2)));
                } else {
                    props.onChange(e, props.name, null);
                }
            }}
        />
    );
}