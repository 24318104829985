import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Switch} from "@headlessui/react";
import {isChecked} from "../utils";
import CheckIcon from "@heroicons/react/outline/CheckIcon";

CustomSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    disclaimer: PropTypes.string,
    invertValue: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    truthyValue: PropTypes.any,
    truthyDisplayValue: PropTypes.any,
    falsyValue: PropTypes.any,
    falsyDisplayValue: PropTypes.any,
    errorMessage: PropTypes.string,
    modified: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    small: PropTypes.bool,
    labelClassName: PropTypes.any,
    flipLayout: PropTypes.bool,
};

CustomSwitch.defaultProps = {
    onChange: () => {}
}

CustomSwitch.DisplayValue = function (props) {
    if (isChecked(props)) {
        if (props.truthyDisplayValue) {
            return (<span>{props.truthyDisplayValue}</span>);
        } else {
            return (<CheckIcon className={"h-5 w-5 text-primary-500"} />);
        }
    } else {
        if (props.falsyDisplayValue) {
            return (<span>{props.falsyDisplayValue}</span>);
        } else {
            return '\u2014';
        }
    }
}

export default function CustomSwitch(props) {
    const inputRef = useRef();
    const checked = isChecked(props);
    
    const containerSize = props.small ? 'h-5 w-8' : 'h-6 w-11'
    const handelSize = props.small ? 'h-4 w-4' : 'h-5 w-5';
    const translatePosition = props.small ? 'translate-x-3' : 'translate-x-5';
    
    return (
        <Switch.Group as="div" className={classNames("flex flex-col", props.className)}>
            <div className={classNames(
                "flex items-center",
                props.flipLayout ? "flex-row-reverse justify-between" : null
            )}>
                <input
                    ref={inputRef}
                    tabIndex={-1}
                    type="checkbox"
                    className="hidden"
                    name={props.name} 
                    value={checked}
                    required={props.required}
                    disabled={props.disabled}
                    data-error-message={props.errorMessage}
                />
                <Switch
                    as={"span"}
                    checked={checked}
                    className={classNames(
                        props.disabled ? (checked ? 'bg-primary-300' : 'bg-gray-200') : (checked ? 'bg-primary-600' : 'bg-gray-300'),
                        props.disabled ? 'focus:ring-transparent' : 'focus:ring-primary-500 focus:ring-offset-2',
                        !props.disabled ? 'cursor-pointer' : null,
                        'relative inline-flex shrink-0 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200',
                        props.modified ? 'focus:outline-dashed focus:outline-offset-2 focus:outline-fuchsia-400 outline-dashed outline-offset-2 outline-fuchsia-400' : null,
                        containerSize
                    )}
                    onChange={(checked) => {
                        if (props.disabled) {
                            return;
                        }
                        
                        // If either truthy or falsy value is provided then use these values or empty string when 
                        // setting the value of this switch
                        if ((props.truthyValue !== undefined && props.truthyValue !== null) || (props.falsyValue !== undefined && props.falsyValue !== null)) {
                            props.onChange({target: inputRef.current}, props.name, checked ? props.truthyValue : props.falsyValue);
                        } else if (props.invertValue) {
                            props.onChange({target: inputRef.current}, props.name, !checked);
                        } else {
                            props.onChange({target: inputRef.current}, props.name, checked);
                        }
                    }}>
                    <span className="sr-only">{props.label}</span>
                    <span
                        aria-hidden="true"
                        className={classNames(
                            checked ? translatePosition : 'translate-x-0',
                            'pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                            handelSize,
                            props.modified ? 'bg-primary-50' : null,
                        )}
                    />
                </Switch>
                <Switch.Label as="label" className={classNames(
                    "flex flex-col text-sm",
                    !props.flipLayout ? "ml-3" : null
                )}>
                    <span className={classNames(
                        'font-semibold',
                        props.disabled ? 'text-gray-500' : (props.error ? 'text-error-900' : 'text-gray-700'),
                        props.labelClassName
                    )}>
                        {props.label}
                        {props.editing && !props.system ? (
                            <span
                                className={"ml-1 text-xs font-medium text-primary-500 leading-5 cursor-pointer hover:underline"}
                                onClick={props.onEditFieldSettings}
                            >Edit</span>
                        ) : null}
                    </span>
                    {props.disclaimer ? (
                        <span className={classNames(
                            props.disabled ? 'text-gray-400' : (props.error ? 'text-error-500' : 'text-gray-500'),
                        )}> {props.disclaimer}</span>
                    ):null}
                    {props.description ? (
                        <p className={classNames(
                            "whitespace-pre-line mr-2",
                            props.disabled ? 'text-gray-400' : (props.error ? 'text-error-500' : 'text-gray-600'),
                        )}>
                            {props.description}
                        </p>
                    ):null}
                </Switch.Label>
            </div>
        </Switch.Group>
    )
}
